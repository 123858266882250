import { Box, Typography } from '@mui/material';
import { WhiteStationIcon20, RankBadge, BlankCard, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { AllowedColors, AutoHireVacancy } from '@stationwise/share-types';
import { getFormattedTimes } from '../../AutoHire/utils';

export interface AutoHireVacancyInfoCardProps {
  vacancy: AutoHireVacancy;
  color?: string;
  type?: 'default' | 'disable' | 'border';
  onClick: () => void;
}

export const AutoHireVacancyInfoCard = ({ vacancy, type = 'default', onClick }: AutoHireVacancyInfoCardProps) => {
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  const { startTimeFormatted, endTimeFormatted } = getFormattedTimes(vacancy, departmentInfo);

  return (
    <BlankCard
      type={type}
      sxProps={() => ({
        width: '100%',
        height: '120px',
        border: 'none',
        '&:hover': {
          border: 'none',
        },
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        mb: '8px',
      })}
      onClick={onClick}
    >
      <Box display="flex" height="100%" width="100%" flexDirection={'column'} justifyContent="center">
        <Box display="flex" height="90%" flexDirection="column">
          <RankBadge
            rank={{ name: vacancy.rank.name, code: vacancy.rank.code, color: vacancy.rank.color as AllowedColors }}
            maxLength={20}
            sx={{ alignSelf: 'flex-start' }}
          />
          <Box display="flex" alignItems="center" sx={{ mt: '10px' }} gap="5px">
            <WhiteStationIcon20 />
            <Typography
              variant="bodyMMedium"
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                whiteSpace: 'nowrap',
              })}
            >
              {vacancy.stationName}, {vacancy.apparatusName}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography
          sx={(theme) => ({
            color: theme.palette.stationGray[500],
            fontWeight: 500,
            pt: '3px',
          })}
          variant="bodyMRegular"
        >
          {startTimeFormatted} - {endTimeFormatted}
        </Typography>
      </Box>
    </BlankCard>
  );
};
