import React, { createContext, useContext, useReducer } from 'react';
import { LoggedInEmployeeDataView } from '@stationwise/share-types';

type State = {
  employee: LoggedInEmployeeDataView | null;
};

type LoadedState = {
  employee: LoggedInEmployeeDataView;
};

const AuthUserStateContext = createContext<State | null>(null);
AuthUserStateContext.displayName = 'AuthUserStateContext';

const AuthUserUpdateContext = createContext<React.Dispatch<Action>>(() => null);
AuthUserUpdateContext.displayName = 'AuthUserUpdateContext';

type ActionType = 'SET_USERNAME.SUCCESS' | 'SET_USER_UNREAD_MESSAGES.SUCCESS' | 'LOGOUT_USER.SUCCESS' | 'SET_IS_APPARATUS_LOGIN';

type BaseAction<Type extends ActionType, Payload = undefined> = Payload extends undefined
  ? { type: Type }
  : { type: Type; payload: Payload };

type Action =
  | BaseAction<'SET_USERNAME.SUCCESS', LoggedInEmployeeDataView>
  | BaseAction<'SET_USER_UNREAD_MESSAGES.SUCCESS', number[]>
  | BaseAction<'LOGOUT_USER.SUCCESS'>
  | BaseAction<'SET_IS_APPARATUS_LOGIN', boolean>;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_USERNAME.SUCCESS': {
      return {
        ...state,
        employee: action.payload,
      };
    }
    case 'SET_USER_UNREAD_MESSAGES.SUCCESS': {
      if (!state.employee) {
        return state;
      }
      const employee = { ...state.employee, unreadMessages: action.payload };
      return { ...state, employee };
    }
    case 'LOGOUT_USER.SUCCESS': {
      return { ...state, employee: null };
    }
    case 'SET_IS_APPARATUS_LOGIN': {
      if (!state.employee) {
        return state;
      }
      const employee = { ...state.employee, isApparatusLogin: action.payload };
      return { ...state, employee };
    }
    default: {
      throw new Error(`Unexpected action: ${action}`);
    }
  }
};

interface AuthUserProviderProps {
  children: React.ReactNode;
  initialState?: State;
}

export function AuthUserProvider({
  children,
  initialState = {
    employee: null,
  },
}: AuthUserProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthUserStateContext.Provider value={state}>
      <AuthUserUpdateContext.Provider value={dispatch}>{children}</AuthUserUpdateContext.Provider>
    </AuthUserStateContext.Provider>
  );
}

function useAuthUserState() {
  const context = useContext(AuthUserStateContext);
  if (!context) {
    throw new Error('useAuthUserState hook must be within an AuthUserProvider');
  }
  return context;
}

function useAuthUserUpdater() {
  const context = useContext(AuthUserUpdateContext);
  if (!context) {
    throw Error('useAuthUserUpdater hook must be within an AuthUserProvider');
  }
  return context;
}

export function useAuthUserContext() {
  return { state: useAuthUserState(), dispatch: useAuthUserUpdater() };
}

export function useLoadedAuthUserContext() {
  const { state, ...context } = useAuthUserContext();
  if (!state.employee) {
    throw new Error('Auth user info is not loaded');
  }
  return { ...context, state: state as LoadedState };
}
