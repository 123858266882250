import { ChevronRight } from '@mui/icons-material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { Box } from '@mui/material';
import { useState } from 'react';
import { TeamFlag, UsersIconComponent as UsersIcon, theme } from '@stationwise/component-module';
import { ConversationListView } from '@stationwise/share-types';

interface MessageHeaderProps {
  selectedConversation: ConversationListView;
  handleChevronRightClick: () => void;
}

export const MessageHeader = ({ selectedConversation, handleChevronRightClick }: MessageHeaderProps) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleCopyLink = (selectedConversation: ConversationListView) => {
    if (selectedConversation.link) {
      navigator.clipboard.writeText(selectedConversation.link);
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 1000);
    }
  };

  return (
    <Box sx={{ margin: theme.spacing(2) }}>
      <Box sx={{ pb: theme.spacing(1), typography: 'bodyLSemibold' }}>{selectedConversation.subject}</Box>

      {selectedConversation.link && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: theme.spacing(1),
          }}
          onClick={() => handleCopyLink(selectedConversation)}
        >
          <Box sx={{ color: theme.palette.stationGray[500], typography: 'bodyMRegular' }}>
            {selectedConversation.link.length > 38 ? `${selectedConversation.link.slice(0, 38)}...` : selectedConversation.link}
          </Box>

          <ContentCopyRoundedIcon
            fontSize="small"
            style={{
              color: isClicked ? theme.palette.stationGray[500] : theme.palette.stationGray[400],
              transition: 'color 0.1s ease',
            }}
          />
        </Box>
      )}
      {/* TODO: implement logic to show correct first two avatars depending on if recipient is and individual, group or shift */}
      {selectedConversation.recipients && (
        <Box
          sx={{
            backgroundColor: theme.palette.stationGray[100],
            p: theme.spacing(0.5),
            mt: theme.spacing(0.5),
            borderRadius: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '32px',
          }}
          onClick={handleChevronRightClick}
        >
          <Box display="flex">
            <UsersIcon color={theme.palette.stationCyan[500]} showBorder />
            <TeamFlag color="red" sx={{ background: theme.palette.common.white, ml: '-10px', mr: 1 }} />
            <Box sx={{ typography: 'bodyMRegular' }}>{`${selectedConversation.recipients.length} recipients`}</Box>
          </Box>
          <ChevronRight style={{ color: theme.palette.stationGray[900] }} />
        </Box>
      )}
      <Box sx={{ borderBottom: `1px solid ${theme.palette.stationGray[100]}`, mt: 2 }} />
    </Box>
  );
};
