import { Box, useTheme } from '@mui/material';
import { RankBadge } from '@stationwise/component-module';
import { EmployeePayroll, PayPeriod } from '@stationwise/share-types';
import { StaffingSection } from '../../../TeamSettings/components/EmployeeProfile/DefaultStaffingSection';
import { EmployeePayrollSigned } from './EmployeePayrollSigned';

type EmployeeInfoProps = {
  employeePayroll: EmployeePayroll;
  setSelectedPayPeriod: React.Dispatch<React.SetStateAction<PayPeriod | null>>;
};
export const EmployeeInfo = (props: EmployeeInfoProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        '@media (max-width: 1900px)': {
          flexDirection: 'column',
        },
        '@media (min-width: 1901px)': {
          flexDirection: 'row',
        },
        gap: theme.spacing(3),
        minWidth: 'max-content',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={(theme) => ({
          paddingLeft: theme.spacing(3),
          backgroundColor: theme.palette.common.white,
          borderRadius: '12px',
          border: `1px solid ${theme.palette.stationGray[200]}`,
          paddingTop: theme.spacing(3),
          position: 'relative',
          minWidth: 'max-content',
          height: theme.spacing(19),
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.spacing(2),
          }}
        >
          <Box
            sx={(theme) => ({
              borderRadius: '50%',
              backgroundColor: ['ADMIN', 'SUPER_ADMIN'].includes(props.employeePayroll.employee.role)
                ? theme.palette.stationGreen[500]
                : theme.palette.stationLightBlue[500],
              color: theme.palette.common.white,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 56,
              height: 56,
              typography: 'bodyXLMedium',
              textTransform: 'uppercase',
            })}
          >
            {props.employeePayroll.employee.name.charAt(0)}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(0.5),
            }}
          >
            <Box sx={{ typography: 'bodyXXLMedium' }}>{props.employeePayroll.employee.name}</Box>
            <Box sx={{ typography: 'bodySRegular' }}>{props.employeePayroll.employee.email}</Box>
          </Box>
        </Box>

        <StaffingSection
          plannedShiftDetails={props.employeePayroll.employee.plannedShiftDetails}
          rankName={props.employeePayroll.employee.rank.name}
          isPayroll={true}
        />

        <Box
          sx={{
            position: 'absolute',
            top: 25,
            right: 25,
          }}
        >
          <RankBadge
            rank={props.employeePayroll.employee.rank}
            rankField="name"
            maxLength={props.employeePayroll.employee.rank.name.length}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: '12px',
          border: `1px solid ${theme.palette.stationGray[200]}`,
          height: theme.spacing(19),
          width: '100%',
          minWidth: 'max-content',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            paddingX: theme.spacing(3),
            width: '100%',
            marginY: theme.spacing(3),
            typography: 'bodyXXLMedium',
            color: theme.palette.stationGray[900],
          }}
        >
          Approval
        </Box>
        <EmployeePayrollSigned employeePayroll={props.employeePayroll} setSelectedPayPeriod={props.setSelectedPayPeriod} />
      </Box>
    </Box>
  );
};
