import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, TextField } from '@mui/material';
import { useState } from 'react';
import { Button } from '@stationwise/component-module';

interface CancelMessageModalProps {
  open?: boolean;
  onClose?: () => void;
  onCancelConversation?: (note: string) => void;
}

export const CancelMessageModal = ({ open, onClose, onCancelConversation }: CancelMessageModalProps) => {
  const [currentNote, setCurrentNote] = useState('');

  const handleCancelMessage = () => {
    if (onCancelConversation) {
      onCancelConversation(currentNote);
      setCurrentNote('');
    }
  };

  return (
    <Modal open={!!open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        sx={(theme) => ({
          background: theme.palette.common.white,
          borderRadius: theme.spacing(1.5),
          padding: theme.spacing(3),
        })}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                typography: 'bodyXLSemibold',
              })}
            >
              Cancel Message
            </Box>
            <Box
              sx={{
                mt: '-12px',
                mr: '-12px',
                cursor: 'pointer',
                borderRadius: '50%',
              }}
              onClick={onClose}
            >
              <CloseIcon
                sx={(theme) => ({
                  height: '24px',
                  width: '24px',
                  color: theme.palette.stationGray[400],
                  borderRadius: '50%',
                  '&:hover': {
                    background: theme.palette.stationGray[100],
                  },
                })}
              />
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[700],
              m: theme.spacing(1, 0, 5, 0),
              typography: 'bodyMRegular',
            })}
          >
            You might send additional information to your employees
          </Box>
          <Box sx={(theme) => ({ mb: theme.spacing(0.5), typography: 'bodySMedium' })}>Note</Box>
          <Box>
            <TextField
              fullWidth
              minRows={3}
              multiline
              maxRows={3}
              value={currentNote}
              onChange={(e) => setCurrentNote(e.target.value)}
              sx={{
                '.MuiInputBase-root': {
                  padding: 0,
                },
              }}
            />
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'flex-end',
              mt: theme.spacing(5),
            })}
          >
            <Box sx={{ display: 'flex', gap: '12px' }}>
              <Button onClick={onClose} buttonType="tertiary">
                Close
              </Button>
              <Button onClick={handleCancelMessage} disabled={currentNote.length <= 0}>
                Cancel Message
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
