import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { TeamFlag, useRosterContext, CertBadge } from '@stationwise/component-module';
import { EmployeeOffView, RosterStation, RosterEmployee } from '@stationwise/share-types';
import { formatEmployeeName } from '@stationwise/share-utils';
import { checkIsEvent, getPositionSplits } from '@stationwise/shift-summary-helper';
import { findChiefAndOvertimeEmployees } from './PositionRows';
import { PrintDayScheduleBox } from './PrintDayScheduleBox';
export const MAX_BOX_HEIGHT = 950;

const calculateTotalRows = (station: RosterStation) => {
  let rowCount = 0;
  station.apparatuses.forEach((apparatus) => {
    if (!apparatus.isForShiftLeader) {
      rowCount += 1;
      apparatus.positions.forEach((position) => {
        const splits = getPositionSplits(position);
        rowCount += splits.length;
      });
    }
  });
  return rowCount;
};

const renderBattalionChief = (battalionChief: RosterEmployee | null) => {
  if (!battalionChief) return null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography sx={{ color: 'stationGray.600', typography: 'bodyMRegular' }}>Battalion Chief:</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Box sx={{ typography: 'bodyMSemibold' }}>{formatEmployeeName(battalionChief.name)}</Box>
        {battalionChief.certifications.map((cert, index) => (
          <CertBadge key={`${cert.code}-${index}`} cert={cert} sx={(theme) => ({ color: theme.palette.stationGray[900] })} />
        ))}
      </Box>
    </Box>
  );
};

export const PrintDaySchedule = () => {
  const { preferences, employeesOffState, shiftSummaryHelper } = useRosterContext();
  const [stations] = useState(() => Array.from(shiftSummaryHelper.allStationCards.values()));
  const floaters = shiftSummaryHelper.unassignedCards;
  const selectedStations = stations.filter(
    (station) =>
      (preferences.selectedStationsMap.get(preferences.selectedBattalionId || -1) || new Map()).get(station.stationName) ||
      (checkIsEvent(station) &&
        (preferences.selectedStationsMap.get(preferences.selectedBattalionId || -1) || new Map()).get('Event Groups')),
  );
  const [positionEmployeesOffMap] = useState(() => {
    const map = new Map<string, EmployeeOffView[]>();
    employeesOffState.employeesOff.forEach((employeeOffData) => {
      employeeOffData.positions.forEach((positionId) => {
        const key = positionId.toString();
        const value = map.get(key) || [];
        !map.has(key) && map.set(key, value);
        value.push(employeeOffData);
      });
    });
    return map;
  });

  const STATION_ROW_HEIGHT = 21;

  const [firstBoxStations, secondBoxStations] = (() => {
    const maxRowsPerBox = Math.floor(MAX_BOX_HEIGHT / STATION_ROW_HEIGHT);
    let currentRowCount = 0;
    let splitIndex = 0;

    // Find the split point for two boxes by counting rows
    for (let i = 0; i < selectedStations.length; i++) {
      const stationRows = calculateTotalRows(selectedStations[i]);
      if (currentRowCount + stationRows + 1 > maxRowsPerBox) {
        splitIndex = i;
        break;
      }
      currentRowCount += stationRows;
      if (i === selectedStations.length - 1) {
        splitIndex = selectedStations.length;
      }
    }
    return [selectedStations.slice(0, splitIndex), selectedStations.slice(splitIndex)];
  })();

  const { battalionChief, overtimeEmployees } = findChiefAndOvertimeEmployees(selectedStations);

  return (
    <Box sx={{ backgroundColor: 'white' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
          <Box component="h1" sx={{ m: 0, typography: 'bodyXXLSemibold', lineHeight: '28px' }}>
            Schedule for {format(shiftSummaryHelper.shiftDuration.startTime, 'MMMM dd, yyyy')}
          </Box>
          <Box sx={{ flex: 1 }} />
          <TeamFlag
            color={shiftSummaryHelper.shiftColor}
            sx={{ width: '28px', height: '28px', '& svg': { width: '16px', height: '16px' } }}
          />
          {shiftSummaryHelper.currentTeams.map((team) => team.name).join(', ')}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%', justifyContent: 'flex-start' }}>
          {renderBattalionChief(battalionChief)}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          mb: 2,
          alignItems: 'flex-start',
        }}
      >
        <PrintDayScheduleBox
          stations={firstBoxStations}
          positionEmployeesOffMap={positionEmployeesOffMap}
          isLastBox={false}
          overtimeEmployees={overtimeEmployees}
          floaters={floaters}
        />
        <PrintDayScheduleBox
          stations={secondBoxStations}
          positionEmployeesOffMap={positionEmployeesOffMap}
          isLastBox={true}
          overtimeEmployees={overtimeEmployees}
          floaters={floaters}
        />
      </Box>
    </Box>
  );
};
