import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthUserProvider, DepartmentInfoProvider, ThemeProvider } from '@stationwise/component-module';
import { DeviceInfoProvider } from '../context/DeviceInfoContext';

interface AppProvidersProps {
  children: React.ReactNode;
}

export const AppProviders = ({ children }: AppProvidersProps) => {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <AuthUserProvider>
          <DeviceInfoProvider>
            <DepartmentInfoProvider>{children}</DepartmentInfoProvider>
          </DeviceInfoProvider>
        </AuthUserProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};
