import { Box, Button, Drawer } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DrawerFooter, CheckIcon40, theme } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { ROUTES } from '../../../../../core/Routes';
import { LeaderInfo } from './LeaderInfo';
import { RequestVolunteersSignUpView } from './RequestVolunteersSignUpMain';
import { RequestVolunteersSignUpTopBar } from './TopBar';
import { VacanciesInfo } from './VacanciesInfo';

interface RequestDTO {
  bulk_call_id: string;
}

interface RequestVolunteersSignUpContentProps {
  data: RequestVolunteersSignUpView;
}

export const RequestVolunteersSignUpContent = ({ data }: RequestVolunteersSignUpContentProps) => {
  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [applyBorderStyles, setApplyBorderStyles] = useState(false);

  const handleOnClose = () => {
    setIsDrawerOpen(false);
    navigate(ROUTES.MY_DASHBOARD.fullRoute);
  };

  const handleScroll = () => {
    setApplyBorderStyles(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const requestOvertimeSignUp = async (requestDto: RequestDTO) => {
    try {
      await client.post('/shift/overtime-sign-up/', requestDto);
      setIsDrawerOpen(true);
    } catch (error) {
      captureException(error);
    }
  };

  const handleSubmitRequest = () => {
    const requestDto: RequestDTO = {
      bulk_call_id: data.bulkCallId,
    };
    requestOvertimeSignUp(requestDto);
  };

  // TODO: Date formatting should be generalized across the codebase.
  const formattedStartDate = new Date(data.startDate)
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .replace(/\s/g, ' ')
    .replace('.', '');

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.stationGray[100],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100px',
        }}
      >
        <RequestVolunteersSignUpTopBar
          shiftName={data.shiftName}
          shiftColor={data.teamColor}
          startDate={data.startDate}
          endDate={data.endDate}
          startTime={data.startTime}
          endTime={data.endTime}
          applyBorderStyles={applyBorderStyles}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '1px',
          background: `linear-gradient(to right, ${theme.palette.common.white} 0%, ${theme.palette.common.white} 4%, ${theme.palette.stationGray[100]} 4%, ${theme.palette.stationGray[100]} 96%, ${theme.palette.common.white} 96%, ${theme.palette.common.white} 100%)`,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          background: theme.palette.stationGray[100],
        }}
      >
        <LeaderInfo
          stationLeader={data.bulkCallCreatorName}
          stationLeaderRankCode={data.bulkCallCreatorRank}
          stationLeaderRankColor={data.bulkCallCreatorRankColor}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'relative',
          alignItems: 'center',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <VacanciesInfo numberOfVacancies={data.vacancies.length} vacancies={data?.vacancies} />
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          position: 'fixed',
          bottom: '0px',
          width: '100%',
          height: '76px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px -20px 25px -5px rgba(10, 14, 22, 0.1), 0px -10px 10px -5px rgba(10, 14, 22, 0.04)',
          maxWidth: '768px',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            width: '92%',
            height: '80%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={(theme) => ({
              width: '50%',
              height: '44px',
              m: theme.spacing(1),
            })}
          >
            <Button
              variant="contained"
              disabled={false}
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => navigate(ROUTES.MY_DASHBOARD.fullRoute)}
              sx={(theme) => ({
                background: theme.palette.common.white,
                color: theme.palette.common.black,
                width: '100%',
                height: '100%',
                textTransform: 'none',
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: theme.spacing(0.75),
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none',
                },
              })}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Ignore
              </Box>
            </Button>
          </Box>
          <Box
            sx={(theme) => ({
              width: '50%',
              height: '44px',
              m: theme.spacing(1),
            })}
          >
            <Button
              variant="contained"
              disabled={false}
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => handleSubmitRequest()}
              sx={(theme) => ({
                backgroundColor: theme.palette.stationPrimary[400],
                color: theme.palette.common.black,
                width: '100%',
                height: '100%',
                textTransform: 'none',
                borderRadius: theme.spacing(0.75),
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none',
                },
              })}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Sign Up
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleOnClose}>
        <Box
          sx={{
            width: '100%',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: { iosWebViewPaddingBottom },
          }}
        >
          <Box
            sx={{
              width: '80px',
              height: '80px',
              mt: '44px',
              mb: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              border: `1px solid ${theme.palette.stationGray[50]}`,
              backgroundColor: theme.palette.common.white,
              boxShadow: '0px 4px 6px -1px rgba(10, 14, 22, 0.102)',
            }}
          >
            <CheckIcon40 />
          </Box>
          <Box
            sx={{
              width: 'calc(100% - 80px)',
              height: '96px',
              mb: '16px',
              textAlign: 'center',
              typography: 'bodyXXLSemibold',
            }}
          >
            {`You have successfully signed up for ${data.vacancies.length} vacancies on ${formattedStartDate}`}
          </Box>
          <Box
            sx={(theme) => ({
              width: 'calc(100% - 80px)',
              height: '48px',
              mb: '48px',
              color: theme.palette.stationGray[400],
              textAlign: 'center',
              typography: 'bodyLSemibold',
            })}
          >
            You will be notified about any processing of this request.
          </Box>
        </Box>
        <DrawerFooter
          loading={false}
          buttons={[
            {
              label: 'Go to my dashboard',
              onClick: () => navigate(ROUTES.MY_DASHBOARD.fullRoute),
              backgroundColor: theme.palette.stationBlue[600],
            },
          ]}
        />
      </Drawer>
    </Box>
  );
};
