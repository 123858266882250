import { Box } from '@mui/material';
import { format, isValid, startOfDay } from 'date-fns';
import { useDeferredValue, useState } from 'react';
import { ErrorPage, useFetchStaffingLists, useSelectedStaffingList } from '@stationwise/component-module';
import { SelectedStaffingListControls } from './SelectedStaffingListControls';
import { StaffingListControls } from './StaffingListControls';
import { StaffingListItems } from './StaffingListItems';

export const StaffingListsContent = () => {
  const [searchInput, setSearchInput] = useState('');
  const deferredSearchInput = useDeferredValue(searchInput);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedLastOvertimeDate, setSelectedLastOvertimeDate] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(startOfDay(new Date()));
  const staffingListsResponse = useFetchStaffingLists();
  const { isLoading, isError, staffingLists, selectedStaffingList, setSelectedStaffingList } = useSelectedStaffingList({
    selectedDate: selectedDate && isValid(selectedDate) ? format(selectedDate, 'MM/dd/yyyy') : '',
    staffingListsResponse,
  });

  return (
    <Box sx={{ pb: 8, width: '75%', mx: 'auto' }}>
      <StaffingListControls
        isLoading={isLoading}
        staffingLists={staffingLists}
        selectedStaffingList={selectedStaffingList}
        setSelectedStaffingList={setSelectedStaffingList}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <SelectedStaffingListControls
        selectedStaffingList={selectedStaffingList}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        selectedLastOvertimeDate={selectedLastOvertimeDate}
        setSelectedLastOvertimeDate={setSelectedLastOvertimeDate}
      />
      {!isLoading && isError && <ErrorPage />}
      {!isError && (
        <StaffingListItems
          isLoading={isLoading}
          selectedStaffingList={selectedStaffingList}
          searchInput={deferredSearchInput}
          selectedTeam={selectedTeam}
          selectedLastOvertimeDate={selectedLastOvertimeDate}
        />
      )}
    </Box>
  );
};
