import { useDroppable } from '@dnd-kit/core';
import { Box, useTheme } from '@mui/material';
import { max, min } from 'date-fns';
import { Fragment } from 'react';
import { RosterApparatus, RosterPosition as RosterPositionType, RosterEmployee } from '@stationwise/share-types';
import { differenceInUTCMinutes, makeTestIdentifier } from '@stationwise/share-utils';
import { checkIsShift, getPositionSplits, formatShiftDuration, makePositionOverId } from '@stationwise/shift-summary-helper';
import { useAuthUserCapabilities } from '../../../../utils/capabilities';
import { DraggableCard } from '../DraggableCard';
import { getLastChangesErrorPopoverAnchorProps } from '../LastChangesErrorPopover';
import { useRosterContext } from '../RosterContext';
import { RosterAssignedEmployeeCard } from './RosterAssignedEmployeeCard';
import { RosterEmptyPositionCard } from './RosterEmptyPositionCard';

interface RosterPositionProps {
  apparatus: RosterApparatus;
  position: RosterPositionType;
  isCollapsed: boolean;
}

export const RosterPosition = ({ apparatus, position, isCollapsed }: RosterPositionProps) => {
  const theme = useTheme();
  const capabilities = useAuthUserCapabilities();
  const { selectedFilledPositionState } = useRosterContext();

  const isDroppableDisabled = !checkIsShift(position);
  const droppable = useDroppable({ id: makePositionOverId(position), disabled: isDroppableDisabled });

  const splits = getPositionSplits(position);

  const getSplitOverlaps = (splitIndex: number) => {
    const split = splits[splitIndex];
    const overlaps: Pick<RosterEmployee, 'id' | 'startDateTime' | 'endDateTime'>[] = [];
    splits.forEach((otherSplit, otherSplitIndex) => {
      if (
        otherSplitIndex > splitIndex &&
        split.employee &&
        otherSplit.employee &&
        split.employee.startDateTime < otherSplit.employee.endDateTime &&
        split.employee.endDateTime > otherSplit.employee.startDateTime
      ) {
        overlaps.push({
          id: otherSplit.employee.id,
          startDateTime: max([split.employee.startDateTime, otherSplit.employee.startDateTime]),
          endDateTime: min([split.employee.endDateTime, otherSplit.employee.endDateTime]),
        });
      }
    });
    return overlaps;
  };

  let border = '0';
  if (droppable.isOver) {
    border = `2px solid ${theme.palette.stationGray[500]}`;
  } else if (splits.some((split) => !split.employee || split.employee.activeId === droppable.active?.id)) {
    border = `2px dashed ${theme.palette.stationGray[600]}`;
  }

  return (
    <Box data-cy={`position-${makeTestIdentifier(position.rank.code)}`} sx={{ mt: 1, position: 'relative' }}>
      <Box
        ref={droppable.setNodeRef}
        sx={{ border, borderRadius: '12px', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      />
      <Box sx={{ border, borderColor: 'transparent', borderRadius: '12px' }}>
        {splits.map(({ vacancy, employee }, splitIndex) => {
          const overlaps = getSplitOverlaps(splitIndex);
          const overlapMinutes = overlaps.reduce((sum, e) => sum + differenceInUTCMinutes(e.endDateTime, e.startDateTime), 0);
          const overlapCount = new Set(overlaps.map((e) => e.id)).size;

          const cardSxProps = {
            borderTopLeftRadius: splitIndex === 0 ? '12px' : '0',
            borderTopRightRadius: splitIndex === 0 ? '12px' : '0',
            borderBottomLeftRadius: splitIndex === splits.length - 1 ? '12px' : '0',
            borderBottomRightRadius: splitIndex === splits.length - 1 ? '12px' : '0',
          };

          return (
            <Fragment key={`${makePositionOverId(vacancy)}:${employee?.activeId}`}>
              <Box sx={{ position: 'relative' }}>
                {employee && (
                  <Box
                    data-roster-employee-id={employee.id}
                    {...getLastChangesErrorPopoverAnchorProps(employee.activeId)}
                    sx={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                  >
                    <DraggableCard
                      activeId={employee.activeId}
                      disabled={!capabilities.EDIT_ROSTER_ASSIGNMENTS || !checkIsShift(position)}
                      onClick={(event) => selectedFilledPositionState.setEmployee(employee, event.currentTarget)}
                      sx={cardSxProps}
                    >
                      <RosterAssignedEmployeeCard
                        apparatus={apparatus}
                        position={position}
                        employee={employee}
                        isCollapsed={isCollapsed}
                        cardSxProps={cardSxProps}
                      />
                    </DraggableCard>
                  </Box>
                )}
                <RosterEmptyPositionCard
                  apparatus={apparatus}
                  position={vacancy}
                  isCollapsed={isCollapsed}
                  isOver={droppable.isOver}
                  cardSxProps={cardSxProps}
                />
              </Box>
              {!!overlapCount && (
                <Box
                  sx={{
                    background: theme.palette.stationOrange[50],
                    color: theme.palette.stationOrange[900],
                    display: 'flex',
                    p: theme.spacing(1, 1.5),
                    typography: 'bodyXSRegular',
                  }}
                >
                  {`${(overlapMinutes / 60).toFixed(overlapMinutes % 60 ? 1 : 0)}h`}
                  <Box component="span" sx={{ flex: 1 }} />
                  {overlapCount > 1 && `(${overlapCount} people)`}
                  {overlapCount === 1 &&
                    formatShiftDuration({
                      startTime: overlaps[0].startDateTime,
                      endTime: overlaps[0].endDateTime,
                    })}
                </Box>
              )}
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};
