import { format } from 'date-fns';
import {
  PayCode,
  DetailCode,
  RosterPosition,
  RosterEmployee,
  RosterStrikeTeamApparatus,
  StrikeTeamCandidate,
} from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import {
  checkIsPlannedEmployee,
  copyBoardWithNewApparatus,
  getBoardEmployeeNoteOverride,
  getBoardEmployees,
  getOverrideEmployeePositionFields,
  getPositionEmployeeSortKey,
  makeTemporaryPosition,
} from '../board';
import { checkIsEvent, checkIsStrikeTeam, checkIsStrikeTeamApparatus, setEmployeeActiveId } from '../id';
import { getMandatoryPayCodes } from '../payCode';
import { removeEmployeeAvailability } from '../removeEmployeeAvailability';
import { mergeConsecutiveWorkDurations, getPositionRequirementErrorMessages } from '../requirement';
import { IShiftSummaryHelper } from '../types';
import { getStrikeTeamFutureActiveDurations } from './active';

export const checkStrikeTeamCandidateMaxConsecutiveHours = (
  shiftSummaryHelper: IShiftSummaryHelper,
  apparatus: RosterStrikeTeamApparatus,
  duration: Pick<RosterPosition, 'startDateTime' | 'endDateTime'>,
  candidate: Pick<RosterEmployee, 'id' | 'maybeConsecutiveWorkDurations'>,
  toDate: string,
) => {
  const { departmentInfo, shiftDuration } = shiftSummaryHelper;
  if (typeof departmentInfo.settings.maxConsecutiveHoursRule.consecutiveHours === 'number') {
    const maxConsecutiveHours = departmentInfo.settings.maxConsecutiveHoursRule.consecutiveHours;
    const consecutiveWorkDurations = mergeConsecutiveWorkDurations(
      candidate.maybeConsecutiveWorkDurations || [],
      [duration, ...getBoardEmployees(shiftSummaryHelper).filter((e) => e.id === candidate.id)],
      getStrikeTeamFutureActiveDurations(apparatus, duration, toDate),
      shiftDuration,
      departmentInfo.settings.maxConsecutiveHoursRule.breakHours ?? 0,
    );
    return consecutiveWorkDurations.filter((d) => d.hours > maxConsecutiveHours);
  }

  return [];
};

const checkIsStrikeTeamAssignmentValid = (
  shiftSummaryHelper: IShiftSummaryHelper,
  assignedApparatus: RosterStrikeTeamApparatus,
  assignedPosition: RosterPosition,
  assignee: RosterEmployee,
) => {
  for (const station of shiftSummaryHelper.allStationCards.values()) {
    for (const apparatus of station.apparatuses) {
      for (const position of apparatus.positions) {
        for (const employee of position.employees) {
          if (employee.id === assignee.id) {
            if (employee.startDateTime < assignee.endDateTime && employee.endDateTime > assignee.startDateTime) {
              if (checkIsStrikeTeam(position)) {
                return { messages: ['This person is already assigned to an event'] };
              }
            }
          }
        }
      }
    }
  }

  const messages: string[] = [];
  messages.push(...getPositionRequirementErrorMessages(shiftSummaryHelper, assignedPosition, assignee));

  const { departmentInfo, shiftDuration } = shiftSummaryHelper;
  const maxConsecutiveHourErrors = checkStrikeTeamCandidateMaxConsecutiveHours(
    shiftSummaryHelper,
    assignedApparatus,
    { startDateTime: assignee.startDateTime, endDateTime: assignee.endDateTime },
    assignee,
    format(shiftDuration.startTime, 'yyyy-MM-dd'),
  );
  if (maxConsecutiveHourErrors.length && typeof departmentInfo.settings.maxConsecutiveHoursRule.consecutiveHours === 'number') {
    const consecutiveWorkHours = Math.max(...maxConsecutiveHourErrors.map((d) => d.hours));
    const message = `This person is working ${consecutiveWorkHours} consecutive hours, which exceeds the maximum ${departmentInfo.settings.maxConsecutiveHoursRule.consecutiveHours} hours`;
    messages.push(message);
  }

  return !messages.length ? undefined : { messages, canOverride: true };
};

export const assignStrikeTeamEmployee = (
  shiftSummaryHelper: IShiftSummaryHelper,
  apparatus: RosterStrikeTeamApparatus,
  position: RosterPosition | null,
  candidate: StrikeTeamCandidate,
  startDateTime: Date,
  endDateTime: Date,
  toDate: string,
  payCodes: PayCode[],
  dutyDayPayCodes: PayCode[] | null,
  detailCodes: DetailCode[],
) => {
  let newShiftSummaryHelper = removeEmployeeAvailability({
    shiftSummaryHelper,
    employeeId: `${candidate.id}`,
    startTime: startDateTime,
    endTime: endDateTime,
    // Do not remove employee availability from event positions.
    // An employee cannot be assigned to a strike team if they are already assigned to an event during the same time.
    // In this case the admin should see two event cards for the same employee, and the error popover.
    checkIsPositionExcluded: (p) => checkIsEvent(p),
  });

  const newEmployee = setEmployeeActiveId({
    id: `${candidate.id}`,
    dataSource: apparatus.dataSource,
    name: candidate.name,
    rank: candidate.rank,
    certifications: candidate.certifications,
    team: candidate.team,
    defaults: candidate.defaults,
    startDateTime,
    endDateTime,
    payCodes,
    detailCodes,
    ...getOverrideEmployeePositionFields(),
    noteOverride: getBoardEmployeeNoteOverride(newShiftSummaryHelper, `${candidate.id}`),
    maybeConsecutiveWorkDurations: candidate.maybeConsecutiveWorkDurations,
  });

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(newShiftSummaryHelper, apparatus.id);

  let newPosition = position;
  if (position) {
    newPosition = newApparatus.positions.find((p) => p.id === position.id) || null;
  } else {
    newPosition = makeTemporaryPosition(apparatus, newEmployee);
    newApparatus.positions.push(newPosition);
  }

  if (!checkIsStrikeTeamApparatus(newApparatus) || !newPosition) {
    throw new Error('Cannot assign strike team employee');
  }

  const newPayCodes = getMandatoryPayCodes(newShiftSummaryHelper, newPosition, newEmployee);
  const newDutyDayPayCodes = dutyDayPayCodes
    ? getMandatoryPayCodes(newShiftSummaryHelper, newPosition, { ...newEmployee, payCodes: dutyDayPayCodes })
    : null;
  if (newDutyDayPayCodes && checkIsPlannedEmployee(newShiftSummaryHelper, newEmployee)) {
    newEmployee.payCodes = newDutyDayPayCodes;
  } else {
    newEmployee.payCodes = newPayCodes;
  }

  newPosition.employees.push(newEmployee);
  newPosition.employees.sort(getPositionEmployeeSortKey);

  newApparatus.strikeTeamAssignmentPayloadMap = new Map(newApparatus.strikeTeamAssignmentPayloadMap);
  newApparatus.strikeTeamAssignmentPayloadMap.set(newPosition.id, [
    ...(newApparatus.strikeTeamAssignmentPayloadMap.get(newPosition.id) || []),
    {
      positionId: newPosition.isTemporary ? null : newPosition.id,
      candidateId: candidate.id,
      strikeTeamId: apparatus.strikeTeam.id,
      startTime: differenceInUTCMinutes(newEmployee.startDateTime, newShiftSummaryHelper.shiftDuration.startTime),
      endTime: differenceInUTCMinutes(newEmployee.endDateTime, newShiftSummaryHelper.shiftDuration.startTime),
      toDate,
      payCodeIds: newPayCodes.map((pc) => pc.id),
      dutyDayPayCodeIds: newDutyDayPayCodes?.map((pc) => pc.id) || null,
      detailCodeIds: detailCodes.map((dc) => dc.id),
    },
  ]);

  const error = checkIsStrikeTeamAssignmentValid(newShiftSummaryHelper, newApparatus, newPosition, newEmployee);
  newShiftSummaryHelper = { ...newShiftSummaryHelper, allStationCards: newAllStationCards };
  return { newShiftSummaryHelper, newEmployee, error };
};
