import { Box, FormControl, MenuItem, Select, useTheme } from '@mui/material';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { ChevronDownIcon16, DatePickerComp } from '@stationwise/component-module';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';

interface StaffingListControlsProps {
  isLoading: boolean;
  staffingLists: ListFieldsStaffingList[];
  selectedStaffingList: ListFieldsStaffingList | undefined;
  setSelectedStaffingList: Dispatch<SetStateAction<ListFieldsStaffingList | undefined>>;
  selectedDate: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date | null>>;
}

export const StaffingListControls = (props: StaffingListControlsProps) => {
  const theme = useTheme();

  const staffingListsByName = useMemo(() => new Map(props.staffingLists.map((sl) => [sl.name, sl])), [props.staffingLists]);

  return (
    <Box sx={{ display: 'flex', gap: '15px', mb: '20px' }}>
      <FormControl variant="outlined">
        <Select
          data-cy="staffing-list-select"
          disabled={!props.staffingLists.length}
          value={props.selectedStaffingList?.name || (props.isLoading ? 'Loading...' : 'None')}
          renderValue={(value) => value}
          onChange={(event) => props.setSelectedStaffingList(staffingListsByName.get(event.target.value))}
          sx={{ borderRadius: '8px', typography: 'bodySRegular' }}
          IconComponent={ChevronDownIcon16}
          MenuProps={{
            autoFocus: false,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
          }}
        >
          {!props.staffingLists.length && <MenuItem value={props.isLoading ? 'Loading...' : 'None'} />}
          {props.staffingLists.map((sl) => (
            <MenuItem
              key={sl.id}
              data-cy={`${makeTestIdentifier(sl.name)}-option`}
              value={sl.name}
              sx={{ borderRadius: '8px', color: theme.palette.stationGray[900], typography: 'bodySRegular' }}
            >
              {sl.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DatePickerComp
        disabled={!props.staffingLists.length}
        value={props.selectedDate}
        setValue={(value) => props.setSelectedDate(value)}
        sxProps={{ border: 0, width: 180, '& .MuiInputBase-root': { borderRadius: '8px', typography: 'bodySRegular' } }}
      />
    </Box>
  );
};
