import { max, min } from 'date-fns';
import { RosterEmployee } from '@stationwise/share-types';
import { copyBoardWithNewApparatus, getOverrideEmployeePositionFields, getPositionEmployeeSortKey } from './board';
import { checkIsAdministration, checkIsAdministrationPosition, setEmployeeActiveId } from './id';
import { mergeEmployees } from './mergeEmployees';
import { getDepartmentPayCodes } from './payCode';
import { IShiftSummaryHelper } from './types';

export const getPlannedAdministrationPosition = (shiftSummaryHelper: IShiftSummaryHelper, employee: RosterEmployee) => {
  for (const station of shiftSummaryHelper.allStationCards.values()) {
    if (checkIsAdministration(station)) {
      for (const apparatus of station.apparatuses) {
        let positionIndex = 0;
        for (const position of apparatus.positions) {
          if (checkIsAdministrationPosition(position) && `${position.nonShiftEmployee.id}` === employee.id) {
            return { station, apparatus, position, positionIndex };
          }
          positionIndex += 1;
        }
      }
    }
  }

  return { station: null, apparatus: null, position: null, positionIndex: -1 };
};

export const createAdministrationEmployee = (shiftSummaryHelper: IShiftSummaryHelper, employee: RosterEmployee) => {
  const newShiftSummaryHelper = { ...shiftSummaryHelper };
  const { station, apparatus, position, positionIndex } = getPlannedAdministrationPosition(shiftSummaryHelper, employee);
  if (!station || !apparatus || !position) {
    return newShiftSummaryHelper;
  }

  const minStartDateTime = new Date(position.nonShiftEmployee.startDateTime);
  const maxEndDateTime = new Date(position.nonShiftEmployee.endDateTime);
  const newEmployee = setEmployeeActiveId({
    ...employee,
    dataSource: position.dataSource,
    startDateTime: max([minStartDateTime, employee.startDateTime]),
    endDateTime: min([maxEndDateTime, employee.endDateTime]),
    payCodes: getDepartmentPayCodes(shiftSummaryHelper.departmentInfo, [employee.defaults.regularAssignmentPayCode]),
    detailCodes: [],
    ...getOverrideEmployeePositionFields(),
  });

  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(newShiftSummaryHelper, apparatus.id);
  const newPosition = newApparatus.positions[positionIndex];
  newPosition.employees.push(newEmployee);
  newPosition.employees.sort(getPositionEmployeeSortKey);
  newPosition.employees = mergeEmployees(newPosition.employees);
  return { ...newShiftSummaryHelper, allStationCards: newAllStationCards };
};
