import { useCallback, useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { TimeOffRequestDTO, UseFetchTimeOffRequestDataResult } from '../types';

interface UseFetchTimeOffRequestDataProps {
  id: string;
}

export const UseFetchTimeOffRequestData = ({ id }: UseFetchTimeOffRequestDataProps): UseFetchTimeOffRequestDataResult => {
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<TimeOffRequestDTO | null>(null);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setError(null);
    setData(null);

    client
      .get(`/request/time-off-request/${id}/`)
      .then((response) => {
        if (response.data.status !== 'USER_MESSAGE_SENT') {
          setIsError(true);
          setError('This request is not on waitlist');
          return;
        }
        setData(response.data);
      })
      .catch((error) => {
        setIsError(true);
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, fetchNoRequest]);

  const forceRefetch = useCallback(() => {
    setFetchNoRequest((prevValue) => prevValue + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    isLoading,
    isError,
    error,
    data,
    forceRefetch,
  };
};
