import { EventClickArg, EventInput, EventSourceFuncArg } from '@fullcalendar/core/index.js';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, formatDate, isValid, parseISO } from 'date-fns';
import { useRef, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SelectItem, isPastDate, parseDateParam } from '@stationwise/component-module';
import { ScheduledShiftsDataView } from '@stationwise/share-types';
import { AbstractChannel, PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { CustomDayCellContent } from './CustomDayCellContent';
import { CustomEventContent } from './CustomEventContent';
import { CustomEventContentLoading } from './CustomEventContentLoading';
import { LeftSideFilters, RightSideFilters } from './Filters';
import { Header } from './Header';
import { scheduledShiftsToEventInput } from './calendarHelper';
import './calendar.css';

const daysAheadList: SelectItem[] = [{ label: 'All days', value: '0' }].concat(
  Array.from(Array(7), (_, index) => {
    return {
      value: (index + 1).toString(),
      label: `${index + 1} ${index === 0 ? 'day' : 'days'} ahead`,
    };
  }),
);

interface CalendarProps {
  onCellClick: (date: string) => void;
  getAllCalendarData: (fetchInfo: EventSourceFuncArg, battalionId?: number) => Promise<ScheduledShiftsDataView[]>;
  setRefetchEvents: Dispatch<SetStateAction<boolean>>;
  refetchEvents: boolean;
  refreshTriggerChannel: AbstractChannel | null;
  selectedBattalionId: number | undefined;
  setSelectedBattalionId: Dispatch<SetStateAction<number | undefined>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  count: number;
}

export const Calendar = ({
  onCellClick,
  getAllCalendarData,
  refetchEvents,
  setRefetchEvents,
  refreshTriggerChannel,
  selectedBattalionId,
  setSelectedBattalionId,
  setOpen,
  open,
  count,
}: CalendarProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const calendarRef = useRef<InstanceType<typeof FullCalendar>>(null);
  const [title, setTitle] = useState('');
  const [shiftList, setShiftList] = useState<string[]>(['All shifts']);
  const [selectedShift, setSelectedShift] = useState<string>(shiftList[0]);
  const [selectedDaysAhead, setSelectedDaysAhead] = useState<string>(daysAheadList[0].value);
  const [selectedDate, setSelectedDate] = useState(() => parseDateParam(searchParams.get('date') || ''));
  const selectedView = 'dayGridMonth';
  const [isLoading, setIsLoading] = useState(false);
  const selectedBattalionIdRef = useRef(selectedBattalionId);

  const splittedEvents = useRef<EventInput[]>([]);
  const [rawEvents, setRawEvents] = useState<ScheduledShiftsDataView[]>([]);

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (data.triggerAll || data.message === PUSHER_UPDATE_MESSAGE) {
        setRefetchEvents(true);
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.STAFFING];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [refreshTriggerChannel, refetchEvents, setRefetchEvents]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const api = calendarRef?.current?.getApi();
    api && setTitle(api.view.title);

    const parsedDate = parseDateParam(searchParams.get('date') || '');
    if (format(parsedDate, 'yyyy-MM') !== format(selectedDate, 'yyyy-MM')) {
      setSelectedDate(parsedDate);

      //if the date change is coming from the url editing and not the calendar interface
      if (api) {
        queueMicrotask(() => {
          api.gotoDate(parsedDate);
          setTitle(api.view.title);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (isMounted) {
      const isDateValid = isValid(parseISO(searchParams.get('date') || ''));
      if (!isDateValid) {
        setSearchParams((prevSearchParams) => {
          const newSearchParams = new URLSearchParams(prevSearchParams);
          !isDateValid && newSearchParams.set('date', format(selectedDate, 'yyyy-MM'));
          return newSearchParams;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  useEffect(() => {
    if (refetchEvents) {
      const api = calendarRef?.current?.getApi();
      if (api) {
        queueMicrotask(() => {
          api.refetchEvents();
          setRefetchEvents(false);
        });
      }
    }
  }, [setRefetchEvents, refetchEvents]);

  useEffect(() => {
    if (selectedBattalionId !== selectedBattalionIdRef.current) {
      selectedBattalionIdRef.current = selectedBattalionId;
      setRefetchEvents(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBattalionId]);

  const setDateInUrl = (urlDate: Date) => {
    const dateStr = format(urlDate, 'yyyy-MM');
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.set('date', dateStr);
      return newSearchParams;
    });
    setSelectedDate(parseDateParam(dateStr));
  };

  const nextHandle = () => {
    const api = calendarRef?.current?.getApi();
    if (api) {
      api.next();
      setDateInUrl(api.view.currentStart);
      setTitle(api.view.title);
    }
  };

  const prevHandle = () => {
    const api = calendarRef?.current?.getApi();
    if (api) {
      api.prev();
      setDateInUrl(api.view.currentStart);
      setTitle(api.view.title);
    }
  };

  const todayHandle = () => {
    const api = calendarRef?.current?.getApi();
    if (api) {
      const thisMonth = parseDateParam(format(new Date(), 'yyyy-MM'));
      api.gotoDate(thisMonth);
      setDateInUrl(thisMonth);
      setTitle(api.view.title);
    }
  };

  const handleEventClick = (eventClickArg: EventClickArg) => {
    if (eventClickArg.event.start) {
      onCellClick(eventClickArg.event.startStr.split('T')[0]);
    }
  };

  const equalToSelectedDaysAhead = (date: Date) => {
    const today = new Date();
    const daysAheadSelection = new Date(today.setDate(today.getDate() + Number(selectedDaysAhead)));
    return date.setHours(0, 0, 0, 0) === daysAheadSelection.setHours(0, 0, 0, 0);
  };
  const handleInitialEvents = (
    fetchInfo: EventSourceFuncArg,
    successCallback: (events: EventInput[]) => void,
    failureCallback: (error: Error) => void,
  ) => {
    getAllCalendarData(fetchInfo, selectedBattalionIdRef.current)
      .then((events) => {
        setRawEvents(events);
        const shifts: string[] = ['All shifts'];
        const mapped = scheduledShiftsToEventInput(events);

        events.forEach((ev) => {
          if (ev.shiftName && shifts.indexOf(ev.shiftName) === -1) {
            shifts.push(ev.shiftName);
          }
        });
        splittedEvents.current = mapped;
        setShiftList(shifts);
        successCallback(mapped);
      })
      .catch((err) => {
        captureException(err);
        failureCallback(err);
      });
  };

  return (
    <Box
      className={`calendar-${selectedView} dashboard`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Header
        nextHandle={nextHandle}
        prevHandle={prevHandle}
        title={title}
        rightSideComponents={
          <RightSideFilters
            shifts={shiftList}
            selectedShift={selectedShift}
            setSelectedShift={setSelectedShift}
            selectedBattalionId={selectedBattalionId}
            setSelectedBattalionId={setSelectedBattalionId}
            daysAheadList={daysAheadList}
            selectedDaysAhead={selectedDaysAhead}
            setSelectedDaysAhead={setSelectedDaysAhead}
            setOpen={setOpen}
            open={open}
            count={count}
          />
        }
        leftSideComponents={<LeftSideFilters todayHandle={todayHandle} />}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          overflowY: 'scroll',
        }}
      >
        <FullCalendar
          ref={calendarRef}
          displayEventTime={false}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          loading={(loading: boolean) => {
            if (loading !== isLoading) setIsLoading(loading);
          }}
          height="auto"
          timeZone="local"
          dayMaxEventRows={true}
          progressiveEventRendering={true}
          initialDate={selectedDate}
          initialEvents={handleInitialEvents}
          firstDay={0}
          initialView={selectedView}
          locale="en-US"
          headerToolbar={false}
          views={{
            dayGridMonth: {
              titleFormat: { month: 'short', year: 'numeric' },
              dateClick: (dateClickArg) => {
                const hasDate = rawEvents.some((event) => {
                  const eventDate = formatDate(event.startDate, 'yyyy-MM-dd');
                  return eventDate === dateClickArg.dateStr;
                });

                if (hasDate || isPastDate(dateClickArg.date)) {
                  onCellClick(dateClickArg.dateStr);
                }
              },
              eventClick: handleEventClick,
              fixedWeekCount: true,
              dayCellContent: (dayCellContentArg) =>
                isLoading ? (
                  <CustomEventContentLoading cellContent={dayCellContentArg} />
                ) : (
                  <CustomDayCellContent
                    cellContent={dayCellContentArg}
                    selectedDaysAhead={selectedDaysAhead}
                    isEqualToSelectedDaysAhead={equalToSelectedDaysAhead(dayCellContentArg.date)}
                  />
                ),
              dayCellClassNames: (dayCellContentArg) => {
                return dayCellContentArg.isToday
                  ? 'today-cell'
                  : equalToSelectedDaysAhead(dayCellContentArg.date)
                    ? 'selected-days-ahead'
                    : '';
              },
              eventContent: (eventContentArg) => {
                return eventContentArg.isStart ? (
                  <CustomEventContent
                    eventContent={eventContentArg}
                    selectedDaysAhead={selectedDaysAhead}
                    selectedShift={selectedShift}
                    isEqualToSelectedDaysAhead={
                      eventContentArg.event.start ? equalToSelectedDaysAhead(eventContentArg.event.start) : false
                    }
                    selectedDate={selectedDate}
                  />
                ) : (
                  <Box></Box>
                );
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};
