import { OutlinedInput, OutlinedInputProps as InputProps } from '@mui/material';
import { useId } from 'react';
import { InputLabel } from './InputLabel';

// rename Input
export const Input = ({ label, sx, ...props }: InputProps) => {
  const defaultId = useId();
  const id = props.id ?? defaultId;
  return (
    <>
      {/* make label optional */}
      {label && (
        <InputLabel htmlFor={id} color={props.color} disabled={props.disabled} error={props.error}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput {...props} id={id} sx={[{ width: '100%' }, ...(Array.isArray(sx) ? sx : sx ? [sx] : [])]} />
    </>
  );
};
