import { SxProps, Theme } from '@mui/material';
import { CardTooltip } from './CardTooltip';

interface ShiftDurationIndicatorProps {
  durationLabel: string;
  durationMinutes: number;
  sx?: SxProps<Theme>;
}

export const ShiftDurationIndicator = ({ durationLabel, durationMinutes, sx }: ShiftDurationIndicatorProps) => {
  return (
    <CardTooltip
      anchorSx={[
        (theme) => ({
          color: theme.palette.stationGray[400],
          typography: 'bodyMRegular',
          [theme.breakpoints.up('sm')]: { typography: 'bodyXSRegular' },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
      anchorContent={durationLabel}
      popoverContent={`${(durationMinutes / 60).toFixed(durationMinutes % 60 ? 1 : 0)}h shift`}
    />
  );
};
