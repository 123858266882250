import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { client } from '@stationwise/share-api';
import { ShiftSummary } from '@stationwise/share-types';

export interface ShiftTemplateSummary extends Pick<ShiftSummary, 'id' | 'shiftTeams' | 'stations' | 'floaters' | 'battalion'> {
  updatedBy?: string;
  ccVersion: number;
  updatedAt?: Date;
  isCurrentlyActive?: boolean;
  date?: Date;
}

export const useFetchCurrentShiftTemplate = () => {
  const location = useLocation();
  // this value is only to force refetch
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ShiftTemplateSummary | null>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(location.state?.teamId || null);
  const [selectedBattalionId, setSelectedBattalionId] = useState<string | null>(location.state?.battalionId || null);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setError(null);
    setData(null);
    client
      .get('/shift/shift-template/', { params: { teamId: selectedTeamId, battalionId: selectedBattalionId } })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setIsError(true);
        setError(error);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fetchNoRequest, selectedBattalionId, selectedTeamId]);

  const forceRefetch = useCallback((teamId?: string, battalionId?: string) => {
    if (teamId) {
      setSelectedTeamId(teamId);
    }
    if (battalionId) {
      setSelectedBattalionId(battalionId);
    }
    setFetchNoRequest((prevValue) => prevValue + 1);
  }, []);

  return {
    isLoading,
    setIsLoading,
    isError,
    error,
    data,
    forceRefetch,
  };
};
