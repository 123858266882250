import { Box } from '@mui/material';
import { AllowedColors } from '@stationwise/share-types';
import { AvatarEmail } from '../../../AvatarEmail';
import { TeamBadge } from '../../../Badge';
import { Checkbox } from '../../../Checkbox';

export interface SelectContactOptionProps {
  id?: number[];
  category?: string;
  chipBackgroundColor?: string;
  chipLabel: string;
  name: string;
  email: string;
  userImg?: string;
  onClick?: () => void;
  checked?: boolean;
  memberIds?: string[];
  filter?: string;
  count?: number;
}

export const SelectContactOption = ({
  chipBackgroundColor,
  chipLabel,
  name,
  email,
  userImg,
  onClick,
  checked,
}: SelectContactOptionProps) => {
  return (
    <Box
      data-cy={`recipient-option-${name.toLowerCase().replace(' ', '-')}`}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: theme.spacing(2),
        })}
      >
        <Checkbox checked={checked} onClick={onClick} />
        <AvatarEmail name={name} email={email} userImg={userImg} />
      </Box>
      <TeamBadge team={{ name: chipLabel, color: (chipBackgroundColor || 'gray') as AllowedColors }} />
    </Box>
  );
};
