import { Avatar, Box, Chip, styled, Theme, useTheme, useMediaQuery } from '@mui/material';
import { ReactElement } from 'react';
import { XCloseIcon16 } from '../../../assets';
import { LetterAvatar } from '../../LetterAvatar';

export interface IRecipientUI {
  id: number;
  category?: string;
  name: string;
  memberIds?: number[];
  userImg?: string;
  chipBgColor?: string;
  chipLabel: string;
  checked?: boolean;
  onClick?: () => void;
  icon: ReactElement | null;
  inGroup?: boolean;
}

const getChipStyles = (theme: Theme) => {
  return {
    paddingLeft: theme.spacing(0.5),
    borderRadius: '24px',
    border: `1px solid ${theme.palette.stationGray[200]}`,
    pr: theme.spacing(1),
    '& .MuiChip-label': {
      flex: 1,
      minWidth: '1px',
      pl: '10px',
      pr: theme.spacing(2),
    },
    '& .MuiChip-deleteIcon path': {
      stroke: theme.palette.stationGray[400],
    },
  };
};

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.2),
}));

const getChipLabel = (name: string) => (
  <Box component="span" sx={{ typography: 'bodySRegular' }}>
    {name}
  </Box>
);

const getChipLabelAbbreviated = (name: string) => {
  const nameParts = name.split(' ');
  if (nameParts.length > 1) {
    const initials =
      nameParts
        .slice(0, -1)
        .map((part) => part.charAt(0))
        .join('. ') + '.';
    const lastName = nameParts[nameParts.length - 1];
    const abbreviatedName = `${initials} ${lastName}`;
    return getChipLabel(abbreviatedName);
  } else {
    return getChipLabel(name);
  }
};

interface RecipientProps {
  recipient: IRecipientUI;
  removeRecipient: (category: string | undefined, id: number) => void;
  isEditable: boolean;
}

export const Recipient = ({ recipient, removeRecipient, isEditable }: RecipientProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ListItem>
      {recipient.userImg ? (
        <Chip
          disabled={!isEditable}
          variant="outlined"
          avatar={<Avatar alt={`Picture of ${recipient.name}`} src={recipient.userImg} />}
          sx={(theme) => getChipStyles(theme)}
          label={isSmallScreen ? getChipLabelAbbreviated(recipient.name) : getChipLabel(recipient.name)}
          deleteIcon={<XCloseIcon16 />}
          onDelete={() => removeRecipient(recipient.category, recipient.id)}
        />
      ) : (
        <Chip
          disabled={recipient.name === 'All working employees' ? true : !isEditable}
          sx={(theme) => getChipStyles(theme)}
          variant="outlined"
          icon={recipient.icon || <LetterAvatar firstName={recipient.name} size="xsmall" color={recipient.chipBgColor} />}
          label={isSmallScreen ? getChipLabelAbbreviated(recipient.name) : getChipLabel(recipient.name)}
          deleteIcon={<XCloseIcon16 />}
          onDelete={() => removeRecipient(recipient.category, recipient.id)}
        />
      )}
    </ListItem>
  );
};
