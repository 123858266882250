import { Box } from '@mui/material';
import React from 'react';
import { ChevronRightIcon24 } from '../../assets';

interface DrawerHeaderProps {
  children: React.ReactNode;
  title: string;
  titleComponent?: React.ReactNode;
  onClick: () => void;
}

export const DrawerHeader = ({ children, title, titleComponent, onClick }: DrawerHeaderProps) => {
  const Header = () => (
    <Box sx={{ width: '100%', height: '100%' }} role="presentation">
      <Box
        display="flex"
        flexDirection="column"
        sx={(theme) => ({
          height: '64px',
          borderBottom: `1px solid ${theme.palette.stationGray[200]}`,
          borderTop: `1px solid ${theme.palette.stationGray[100]}`,
          pb: 0.5,
          pt: 0.5,
        })}
      >
        <Box display="grid" gridTemplateColumns="1fr 55px" alignItems="center" height="100%">
          {titleComponent ? titleComponent : <Box sx={{ typography: 'bodyMSemibold', lineHeight: '28px' }}>{title}</Box>}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            sx={(theme) => ({
              borderLeft: `1px solid ${theme.palette.stationGray[200]}`,
              cursor: 'pointer',
              '& svg path': {
                stroke: theme.palette.stationGray[900],
              },
            })}
            onClick={onClick}
          >
            <ChevronRightIcon24 />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: '100%', height: 'calc(100% - 64px)' }}>{children}</Box>
    </Box>
  );

  return <>{Header()}</>;
};
