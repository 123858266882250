import { Box } from '@mui/material';
import { useEffect } from 'react';
import { LottieLogoPageLoader, useDocumentTitle, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { PUSHER_EVENT_TYPES, PUSHER_UPDATE_MESSAGE, RefreshEventCallback } from '@stationwise/share-utils';
import { AppPageLayout } from '../../../AppPageLayout';
import { UseFetchTimeOffRequestData } from '../hooks/useFetchTimeOffRequestData';
import { SignUpPage } from './SignUpPage';
import { WaitlistVerificationErrorPage } from './WaitlistVerificationErrorPage';

interface WaitlistVerificationViewProps {
  id: string;
}

export const WaitlistVerificationView = ({ id }: WaitlistVerificationViewProps) => {
  useDocumentTitle('Waitlist Verification Sign-up');
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const refreshTriggerChannel = departmentContext.refreshTriggerChannel;
  const { isLoading, isError, data, forceRefetch } = UseFetchTimeOffRequestData({
    id: id,
  });

  useEffect(() => {
    if (!refreshTriggerChannel) return;

    const handlePusherUpdate: RefreshEventCallback = (data) => {
      if (data.triggerAll || data.message === PUSHER_UPDATE_MESSAGE) {
        forceRefetch();
      }
    };

    const EVENT_TYPES_LISTENED = [PUSHER_EVENT_TYPES.STAFFING];

    refreshTriggerChannel.bind_many(EVENT_TYPES_LISTENED, handlePusherUpdate);

    return () => {
      if (refreshTriggerChannel) {
        refreshTriggerChannel.unbind_many(EVENT_TYPES_LISTENED);
      }
    };
  }, [refreshTriggerChannel, forceRefetch]);

  const renderContent = () => {
    if (isLoading) {
      return <LottieLogoPageLoader />;
    }

    if (isError) {
      return <WaitlistVerificationErrorPage />;
    }
    if (data !== null) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            alignContent: 'center',
          }}
        >
          <SignUpPage data={data} forceRefetch={forceRefetch} />
        </Box>
      );
    }
    return <WaitlistVerificationErrorPage />;
  };

  return (
    <AppPageLayout fixedPosition>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          height: '100%',
          width: '100%',
          p: 0,
          maxWidth: '768px',
          margin: '0 auto',
        }}
      >
        {renderContent()}
      </Box>
    </AppPageLayout>
  );
};
