import { Box } from '@mui/material';
import { format } from 'date-fns';
import { Fragment } from 'react';
import { Chip, OvalChip, useRosterContext, CertBadge, theme, RankBadge, DriverIcon20 } from '@stationwise/component-module';
import { RosterApparatus, RosterPosition, RosterEmployee, EmployeeOffView, RosterStation } from '@stationwise/share-types';
import { formatEmployeeName, makeFirstLettersCapital } from '@stationwise/share-utils';
import {
  checkHasMandatoryOvertimeDetailCode,
  checkHasVoluntaryOvertimeDetailCode,
  checkIsActingAsRank,
  filterNonRankCertifications,
} from '@stationwise/shift-summary-helper';
import { Cell } from './Cell';
import { Row } from './Row';

interface PositionRowsProps {
  position: RosterPosition;
  rowNumber: number;
  splits: { vacancy: RosterPosition; employee: RosterEmployee | null }[];
}

export const PositionRows = ({ position, rowNumber, splits }: PositionRowsProps) => {
  const { shiftSummaryHelper } = useRosterContext();

  const getRowClasses = (splitItem: RosterPosition | RosterEmployee, splitIndex: number) => {
    const classes: string[] = [];
    if (splitItem === splits[splitIndex].vacancy) {
      classes.push('SWPrintDaySchedule-vacant-row');
    }
    if (splitIndex > 0) {
      classes.push('SWPrintDaySchedule-split-row');
    }
    return classes.join(' ');
  };

  const renderEmployeeName = (employee: RosterEmployee) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', textWrap: 'nowrap' }}>
        <Box sx={{ fontSize: '12px', fontWeight: 400 }}>{formatEmployeeName(employee.name)}</Box>
        {checkIsActingAsRank(shiftSummaryHelper, position, employee) && (
          <Box sx={{ gap: 0 }}>
            <Box sx={{ display: 'inline-flex', position: 'relative', left: theme.spacing(1.5) }}>
              {renderPositionRank(employee.rank.code)}
            </Box>
            <Box sx={{ display: 'inline-flex', position: 'relative', left: theme.spacing(1.5), alignItems: 'center' }}>
              <Chip
                label="A"
                chipSxProps={() => ({
                  maxHeight: '12px',
                  minWidth: '15px',
                  p: '0px',
                })}
                typographyStyles={{
                  fontSize: '10px',
                  lineHeight: '12px',
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const renderTimeLabel = (splitItem: RosterPosition | RosterEmployee) => {
    return (
      <Box>
        <Box component="span">{format(splitItem.startDateTime, 'HHmm')}</Box>
        {'-'}
        <Box component="span">{format(splitItem.endDateTime, 'HHmm')}</Box>
      </Box>
    );
  };

  const renderEmployeeShiftType = (employee: RosterEmployee) => {
    const label = checkHasMandatoryOvertimeDetailCode(employee)
      ? 'MND'
      : checkHasVoluntaryOvertimeDetailCode(employee)
        ? 'VOL'
        : employee.trade
          ? 'TRD'
          : '';

    return (
      label && (
        <Box sx={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
          <OvalChip label={label} backgroundColor={theme.palette.stationGray[500]} color="white" />
        </Box>
      )
    );
  };

  const renderEmployeeNotes = (employee: RosterEmployee) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, pl: 0.5 }}>
        {renderEmployeeShiftType(employee)}
        {position.isTemporary && <OvalChip label="EXC" backgroundColor={theme.palette.stationGray[500]} color="white" />}
        {position.driver && (
          <Box sx={{ display: 'inline-flex', color: theme.palette.stationGray[500] }}>
            <DriverIcon20 />
          </Box>
        )}
        {employee.trade && (
          <Box sx={{ typography: 'bodyXSRegular', color: theme.palette.stationGray[500] }}>
            {formatEmployeeName(employee.trade.requester.name)}
          </Box>
        )}
        {splits.length > 1 && (
          <Box sx={{ typography: 'bodyXSRegular', color: theme.palette.stationGray[900] }}>{renderTimeLabel(employee)}</Box>
        )}
      </Box>
    );
  };

  const renderVacantRow = (vacancy: RosterPosition, splitIndex: number) => {
    return (
      <Row
        key={`vacant:${position.id}:${vacancy.startDateTime.getTime()}:${vacancy.endDateTime.getTime()}`}
        className={getRowClasses(vacancy, splitIndex)}
        sx={{ background: 'white', color: 'black' }}
      >
        <Cell colIndex={0} sx={{ borderRight: `1px dashed ${theme.palette.stationGray[300]}` }}>
          {rowNumber}
        </Cell>
        <Cell colIndex={1}>
          <Box>Vacant</Box>
        </Cell>
        <Cell colIndex={2}>{renderPositionRank(position.rank.code)}</Cell>
        <Cell colIndex={3} />
        <Cell colIndex={4} />
      </Row>
    );
  };

  const renderAssignedRow = (employee: RosterEmployee, splitIndex: number) => {
    return (
      <Row key={`assigned:${position.id}:${employee.activeId}`} className={getRowClasses(employee, splitIndex)}>
        <Cell colIndex={0} sx={{ borderRight: `1px dashed ${theme.palette.stationGray[300]}` }}>
          {rowNumber + splitIndex}
        </Cell>
        <Cell colIndex={1}>{renderEmployeeName(employee)}</Cell>
        <Cell colIndex={2}>{renderPositionRank(position.rank.code)}</Cell>
        <Cell colIndex={3}>{renderEmployeeCertifications(employee)}</Cell>
        <Cell colIndex={4} sx={{ pl: 0 }}>
          {renderEmployeeNotes(employee)}
        </Cell>
      </Row>
    );
  };

  return splits.map(({ vacancy, employee }, splitIndex) => {
    if (employee) {
      return renderAssignedRow(employee, splitIndex);
    } else {
      return renderVacantRow(vacancy, splitIndex);
    }
  });
};

export const renderAbsentPersonnel = (positionEmployeesOffMap: Map<string, EmployeeOffView[]>) => {
  if (positionEmployeesOffMap.size === 0) {
    return null;
  }
  let rowCounter = 1;
  return (
    <Fragment>
      <Row
        sx={(theme) => ({
          background: theme.palette.stationGray[900],
          borderColor: theme.palette.stationGray[900],
        })}
      >
        <Cell colIndex={1} scope="head" sx={{ py: 0.5, width: '100%' }}>
          <Box sx={{ pl: '8px', color: theme.palette.common.white }}>Absent Personnel</Box>
        </Cell>
      </Row>
      {Array.from(positionEmployeesOffMap.values()).map((employeesOff) =>
        employeesOff.map((employee) => {
          const currentRow = rowCounter++;
          return (
            <Row key={`absent:${employee.employeeOff.id}`}>
              <Cell colIndex={0} sx={{ borderRight: `1px dashed ${theme.palette.stationGray[300]}` }}>
                {currentRow}
              </Cell>
              <Cell colIndex={1}>
                <Box sx={{ fontSize: '12px', fontWeight: 400 }}>
                  {`${employee.employeeOff.lastName} ${employee.employeeOff.firstName.charAt(0).toUpperCase()}.`}
                </Box>
              </Cell>
              <Cell colIndex={2}>{renderPositionRank(employee.employeeOff.rank.code)}</Cell>
              <Cell colIndex={3} sx={{ textWrap: 'nowrap', pl: 1 }}>
                {makeFirstLettersCapital(employee.title.toLowerCase())}
              </Cell>
              <Cell colIndex={4} />
            </Row>
          );
        }),
      )}
    </Fragment>
  );
};

export const renderOvertimeHires = (overtimeEmployees: OvertimeEmployeeAssignment[]) => {
  let rowCounter = 1;
  if (overtimeEmployees.length === 0) return null;

  return (
    <Fragment>
      <Row
        sx={(theme) => ({
          background: theme.palette.stationGray[900],
          borderColor: theme.palette.stationGray[900],
        })}
      >
        <Cell colIndex={1} scope="head" sx={{ py: 0.5, width: '100%', backgroundColor: theme.palette.stationGray[900] }}>
          <Box sx={{ pl: '8px', color: theme.palette.common.white }}>Overtime Hires</Box>
        </Cell>
      </Row>
      {overtimeEmployees.map(({ employee, position, apparatus, station }) => {
        const currentRow = rowCounter++;
        return (
          <Row key={`overtime:${employee.activeId}`}>
            <Cell colIndex={0} sx={{ borderRight: `1px dashed ${theme.palette.stationGray[300]}` }}>
              <Box>{currentRow}</Box>
            </Cell>
            <Cell colIndex={1}>
              <Box sx={{ fontSize: '12px', fontWeight: 400 }}>{`${formatEmployeeName(employee.name)}`}</Box>
            </Cell>
            <Cell colIndex={2}>{renderPositionRank(position.rank.code)}</Cell>
            <Cell colIndex={3} sx={{ textWrap: 'nowrap', pl: 1 }}>{`${station.stationName} > ${apparatus.name}`}</Cell>
            <Cell colIndex={4} />
          </Row>
        );
      })}
    </Fragment>
  );
};

export const renderFloaters = (floaters: RosterEmployee[]) => {
  let rowCounter = 1;
  if (floaters.length === 0) return null;

  return (
    <Fragment>
      <Row
        sx={(theme) => ({
          background: theme.palette.stationGray[900],
          borderColor: theme.palette.stationGray[900],
        })}
      >
        <Cell colIndex={1} scope="head" sx={{ py: 0.5, width: '100%', backgroundColor: theme.palette.stationGray[900] }}>
          <Box sx={{ pl: '8px', color: theme.palette.common.white }}>Floaters</Box>
        </Cell>
      </Row>
      {floaters.map((floater) => {
        const currentRow = rowCounter++;
        return (
          <Row key={`floater:${floater.activeId}`}>
            <Cell colIndex={0} sx={{ borderRight: `1px dashed ${theme.palette.stationGray[300]}` }}>
              <Box>{currentRow}</Box>
            </Cell>
            <Cell colIndex={1}>
              <Box sx={{ fontSize: '12px', fontWeight: 400 }}>{formatEmployeeName(floater.name)}</Box>
            </Cell>
            <Cell colIndex={2}>{renderPositionRank(floater.rank.code)}</Cell>
            <Cell colIndex={3}>{renderEmployeeCertifications(floater)}</Cell>
            <Cell colIndex={4} />
          </Row>
        );
      })}
    </Fragment>
  );
};

const renderPositionRank = (rankCode: string) => {
  return (
    <Box sx={{ display: 'inline-flex', textWrap: 'nowrap' }}>
      <RankBadge
        rank={{ code: rankCode, name: rankCode, color: 'gray' }}
        sx={{ borderRadius: '10px', padding: '0px 4px', height: '18px', alignItems: 'center' }}
      />
    </Box>
  );
};

const renderEmployeeCertifications = (employee: RosterEmployee) => {
  const nonRankCerts = filterNonRankCertifications(employee.certifications, employee.rank);
  const displayCerts = nonRankCerts.slice(0, 3);

  return (
    <Box sx={{ display: 'flex', gap: '1px' }}>
      {displayCerts.map((cert, index) => (
        <CertBadge
          key={`${index}-${cert.code}`}
          cert={cert}
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
            typography: 'bodyXSRegular',
            lineHeight: theme.spacing(2),
            padding: '0px 2px',
          })}
        />
      ))}
    </Box>
  );
};

interface ChiefAndOvertimeResult {
  battalionChief: RosterEmployee | null;
  overtimeEmployees: OvertimeEmployeeAssignment[];
}

export const findChiefAndOvertimeEmployees = (stations: RosterStation[]): ChiefAndOvertimeResult => {
  let battalionChief: RosterEmployee | null = null;
  const overtimeEmployees: OvertimeEmployeeAssignment[] = [];

  // Iterate through stations to find both battalion chief and overtime employees
  for (const station of stations) {
    for (const apparatus of station.apparatuses) {
      if (apparatus.isForShiftLeader) {
        const position = apparatus.positions[0];
        if (position?.employees.length > 0) {
          battalionChief = position.employees[0];
        }
      }

      apparatus.positions.forEach((position) => {
        position.employees.forEach((employee) => {
          if (checkHasMandatoryOvertimeDetailCode(employee) || checkHasVoluntaryOvertimeDetailCode(employee)) {
            overtimeEmployees.push({
              employee,
              position,
              apparatus,
              station,
            });
          }
        });
      });
    }
  }
  return {
    battalionChief,
    overtimeEmployees,
  };
};

export interface OvertimeEmployeeAssignment {
  employee: RosterEmployee;
  position: RosterPosition;
  apparatus: RosterApparatus;
  station: RosterStation;
}
