import { Box, BoxProps as RowProps } from '@mui/material';

export const Row = ({ children, sx, ...props }: RowProps<'div'>) => {
  return (
    <Box
      {...props}
      sx={[
        (theme) => ({
          height: '20px',
          borderWidth: 0,
          borderTopWidth: '1px',
          borderStyle: 'dashed',
          borderColor: theme.palette.stationGray[300],
          color: theme.palette.stationGray[900],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&.SWPrintDaySchedule-vacant-row': {
            background: (theme) => `repeating-linear-gradient(
              135deg,
              transparent,
              transparent 6px,
              ${theme.palette.stationGray[200]} 6px,
              ${theme.palette.stationGray[200]} 7px
            )`,
          },
          '&.SWPrintDaySchedule-split-row': {
            borderTopWidth: '0px',
          },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {children}
    </Box>
  );
};
