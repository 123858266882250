import { Box, Button, Checkbox, FormControlLabel, SelectChangeEvent, useTheme } from '@mui/material';
import { isEqual, setMinutes } from 'date-fns';
import { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { DEFAULT_RANK, RosterEmployee, RosterEditableEmployeeSplit } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { checkIsPlannedEmployee, diffCycleMinutes } from '@stationwise/shift-summary-helper';
import { RankBadge } from '../../../Badge';
import { useRosterContext } from '../RosterContext';
import { Reason, ReasonOption } from './Reason';
import { SplitTime } from './SplitTime';
import { TimeOffAccrualLimitError } from './TimeOffAccrualLimitError';

export interface SplitStatus {
  isDisabled: boolean;
  errors: Set<string>;
}

interface SplitProps {
  timeOffReasonOptions: Map<string, ReasonOption>;
  employee: RosterEmployee;
  initialSplits: RosterEditableEmployeeSplit[];
  splits: RosterEditableEmployeeSplit[];
  setSplits: Dispatch<SetStateAction<RosterEditableEmployeeSplit[]>>;
  splitStatuses: SplitStatus[];
  index: number;
}

const NULL_DATE = new Date('NULL_DATE');

export const Split = (props: SplitProps) => {
  const theme = useTheme();
  const { shiftSummaryHelper } = useRosterContext();
  const { shiftDuration } = shiftSummaryHelper;
  const isPlannedEmployee = checkIsPlannedEmployee(shiftSummaryHelper, props.employee);
  const split = props.splits[props.index];
  const splitStatus = props.splitStatuses[props.index];
  const hasDurationError = splitStatus.errors.has('DURATION');
  const isFirstSplit = props.index === 0;
  const isLastSplit = props.index === props.splits.length - 1;
  const isStartDateTimeDisabled = (isPlannedEmployee && isFirstSplit) || !!props.splitStatuses[props.index - 1]?.isDisabled;
  const isEndDateTimeDisabled = (isPlannedEmployee && isLastSplit) || !!props.splitStatuses[props.index + 1]?.isDisabled;

  const hasInitiallyConsecutiveSplits = useMemo(() => {
    return props.initialSplits.every((split, i) => {
      const prevSplit = props.initialSplits[i - 1];
      return !prevSplit || isEqual(prevSplit.endDateTime, split.startDateTime);
    });
  }, [props.initialSplits]);

  const onChangeEmployeeOff = (event: ChangeEvent<HTMLInputElement>) => {
    const newSplits = [...props.splits];
    if (event.target.checked) {
      newSplits[props.index] = {
        ...split,
        reference: { type: 'TIME_OFF_REQUEST', id: 0, payCode: { code: '' } },
      };
    } else {
      newSplits[props.index] = { ...split, reference: split.initialReference };
    }
    props.setSplits(newSplits);
  };

  const onChangeTimeOffReason = (event: SelectChangeEvent<string>) => {
    if (split.reference.type === 'TIME_OFF_REQUEST') {
      const newSplits = [...props.splits];
      newSplits[props.index] = {
        ...split,
        reference: { ...split.reference, payCode: { ...split.reference.payCode, code: event.target.value } },
      };
      props.setSplits(newSplits);
    }
  };

  const onChangeDateTime = (key: 'startDateTime' | 'endDateTime', value: Date | null) => {
    let minutes = diffCycleMinutes(value || NULL_DATE, shiftDuration.startTime);
    minutes = minutes || (isFirstSplit && key === 'startDateTime' ? 0 : 24 * 60);
    const newDateTime = setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + minutes);
    const newSplits = [...props.splits];
    newSplits[props.index] = { ...split, [key]: newDateTime };

    const adjacentKey = key === 'endDateTime' ? 'startDateTime' : 'endDateTime';
    const adjacentIndex = props.index + (key === 'endDateTime' ? 1 : -1);
    const adjacentSplit = props.splits[adjacentIndex];
    if (
      hasInitiallyConsecutiveSplits &&
      adjacentSplit &&
      !props.splitStatuses[adjacentIndex]?.isDisabled &&
      isEqual(split[key], adjacentSplit[adjacentKey])
    ) {
      newSplits[adjacentIndex] = { ...adjacentSplit, [adjacentKey]: newDateTime };
    }

    props.setSplits(newSplits);
  };

  const onClickSplit = () => {
    const midDurationMinutes = Math.floor(differenceInUTCMinutes(split.endDateTime, split.startDateTime) / 2);
    const midDateTime = setMinutes(split.startDateTime, split.startDateTime.getMinutes() + midDurationMinutes);
    const newSplits = [...props.splits];
    newSplits.splice(props.index, 1, { ...split, endDateTime: midDateTime }, { ...split, startDateTime: midDateTime });
    props.setSplits(newSplits);
  };

  const renderName = () => {
    if (split.reference.type === 'ASSIGNMENT') {
      const rank = split.reference.position?.rank || props.employee.rank;

      let name = 'Floater';
      if (split.reference.station && split.reference.apparatus) {
        name = `${split.reference.station.name} / ${split.reference.apparatus.name}`;
      }

      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'flex-start', gap: 1 }}>
          {rank.id !== DEFAULT_RANK.id && <RankBadge rank={rank} />}
          {name}
        </Box>
      );
    } else if (split.reference.type === 'SHIFT_TRADE_REQUEST') {
      return 'Shift Trade';
    } else {
      return 'Off Roster';
    }
  };

  const renderDuration = () => {
    let durationMinutes = differenceInUTCMinutes(split.endDateTime, split.startDateTime);
    const durationHours = Math.floor(durationMinutes / 60);
    durationMinutes -= durationHours * 60;
    return `${`${durationHours}`.padStart(2, '0')}h ${`${durationMinutes}`.padStart(2, '0')}m`;
  };

  return (
    <Box sx={{ border: `1px solid ${theme.palette.stationGray[200]}`, borderRadius: '12px', p: 2, mt: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
        <Box sx={{ flex: 1, typography: 'bodySMedium' }}>{renderName()}</Box>
        {split.initialReference.type === 'ASSIGNMENT' && (
          <FormControlLabel
            label="Employee Off"
            sx={{ m: theme.spacing(-1.5, 0, 0, 0) }}
            control={
              <Checkbox
                checked={split.reference.type === 'TIME_OFF_REQUEST'}
                disabled={splitStatus.isDisabled}
                onChange={onChangeEmployeeOff}
              />
            }
          />
        )}
      </Box>
      {split.reference.type === 'TIME_OFF_REQUEST' && (
        <Reason
          options={props.timeOffReasonOptions}
          disabled={splitStatus.isDisabled}
          value={split.reference.payCode.code}
          onChange={onChangeTimeOffReason}
        />
      )}
      {splitStatus.errors.has('TIME_OFF_ACCRUAL_LIMIT') && (
        <TimeOffAccrualLimitError split={split} splits={props.splits} options={props.timeOffReasonOptions} />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mt: 3 }}>
        <SplitTime
          disabled={splitStatus.isDisabled || isStartDateTimeDisabled}
          label="Start"
          value={split.startDateTime === NULL_DATE ? null : split.startDateTime}
          onChange={(value) => onChangeDateTime('startDateTime', value)}
          slotProps={{ textField: { error: hasDurationError } }}
        />
        <SplitTime
          disabled={splitStatus.isDisabled || isEndDateTimeDisabled}
          label="End"
          value={split.endDateTime === NULL_DATE ? null : split.endDateTime}
          onChange={(value) => onChangeDateTime('endDateTime', value)}
          slotProps={{ textField: { error: hasDurationError } }}
        />
        <Box sx={{ flex: 1, whiteSpace: 'nowrap', color: hasDurationError ? theme.palette.error.main : undefined }}>
          Duration
          <br />
          {renderDuration()}
        </Box>
        {split.initialReference.type === 'ASSIGNMENT' && (
          <Button
            variant="outlined"
            disabled={splitStatus.isDisabled}
            sx={{ p: '9px 16px', textTransform: 'none' }}
            onClick={onClickSplit}
          >
            Split
          </Button>
        )}
      </Box>
    </Box>
  );
};
