import { AutoAwesomeOutlined as AutoAwesomeIcon } from '@mui/icons-material';
import { Box, ButtonBase, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  BarChartSquare02Icon20,
  DateChanger,
  UsersXIcon20,
  getLastChangesErrorPopoverFallbackAnchorProps,
  useDateQueryParam,
  useRosterContext,
  PrinterIcon20,
  Edit05Icon20,
  theme,
  PlusIcon20,
  StationsSelect,
  OvertimeListIcon20,
  getTooltipProps,
  ClockRewindIcon20,
  useAuthUserCapabilities,
  SvgIcon,
} from '@stationwise/component-module';
import { AutoHireInfoView, BulkCallDetailsView, InstantHireView } from '@stationwise/share-types';
import { TeamColoredBar } from '../../../../../components/Common';
import { ROUTES } from '../../../../../core/Routes';
import { HiringEngineModal } from '../../../HiringEngine/HiringEngineModal';
import { HiringEngineVacancy, VacantStation } from '../../../HiringEngine/Vacancies/vacanciesHelper';
import { DRAWER_TYPE } from '../../constants';
import { PrintDayScheduleModal } from '../PrintDayScheduleModal';
import { Responsibility } from '../Responsibility';
import { CollapseSwitch } from './CollapseSwitch';

interface TopBarProps {
  openedDrawer: DRAWER_TYPE | null;
  setOpenedDrawer: React.Dispatch<React.SetStateAction<DRAWER_TYPE | null>>;
  saveChanges?: () => void;
  isSaving?: boolean;
  toggleMiniCalendar: () => void;
  saveDisabled?: boolean;
  groupedVacancies: Record<number, HiringEngineVacancy[]>;
  forceRefetch: () => void;
  vacantStations: VacantStation[];
  hiringEngineResponse: {
    isLoading: boolean;
    bulkCalls: BulkCallDetailsView[];
    autoHireInfo: AutoHireInfoView | null;
    instantHires: InstantHireView[];
  };
}

export const TopBar = ({
  openedDrawer,
  setOpenedDrawer,
  saveChanges,
  isSaving,
  toggleMiniCalendar,
  saveDisabled = false,
  groupedVacancies,
  forceRefetch,
  vacantStations,
  hiringEngineResponse,
}: TopBarProps) => {
  const { isReadonly, lastChanges, resetValues, userHasMadeChanges, preferences, shiftSummaryHelper } = useRosterContext();
  const capabilities = useAuthUserCapabilities();
  const isShiftTemplateEnabled = capabilities.EDIT_SHIFT_TEMPLATE;
  const canSendMessages = capabilities.SEND_MESSAGES;
  const canViewChangelog = capabilities.VIEW_CHANGE_LOGS;
  const canEditOffRoster = capabilities.EDIT_OFF_ROSTER;
  const canFillFromStaffingList = capabilities.MANAGE_ROSTER_HIRING;

  const { addDays, selectedDateAsDate, selectedDate } = useDateQueryParam();

  const { currentTeams } = shiftSummaryHelper;
  const topBarTeamInfo =
    currentTeams.length === 1
      ? currentTeams[0]
      : { id: 0, name: format(shiftSummaryHelper.shiftDuration.startTime, 'EEEE'), color: shiftSummaryHelper.shiftColor };

  const toggleOpenedDrawer = (next: DRAWER_TYPE) => {
    setOpenedDrawer((prev) => (prev === next ? null : next));
  };

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const isDateInPast = selectedDateAsDate ? selectedDateAsDate.setHours(0, 0, 0) <= new Date().setHours(0, 0, 0) : false;
  const [hiringEngineModalOpen, setHiringEngineModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleHiringEngineClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setHiringEngineModalOpen(true);
  };

  const handlePrintModalOpen = () => {
    setIsPrintModalOpen(true);
  };

  const handlePrintModalClose = () => {
    setIsPrintModalOpen(false);
  };

  return (
    <Box>
      <Box
        display="flex"
        width="100%"
        sx={(theme) => ({
          background: theme.palette.common.white,
          p: '8px 24px',
        })}
        justifyContent="space-between"
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Box display="flex">
            <Box sx={(theme) => ({ mr: theme.spacing(1.5) })}>
              <DateChanger
                currentDate={selectedDateAsDate || new Date()}
                addDays={addDays}
                toggleMiniCalendar={toggleMiniCalendar}
              />
            </Box>
          </Box>

          <Box display="flex" alignSelf="center">
            <Responsibility />
          </Box>

          <Box display="flex" alignSelf="center">
            <StationsSelect />
          </Box>
        </Box>
        <Box display="flex">
          <Box display="flex" justifyContent="center" alignItems="center" sx={(theme) => ({ gap: theme.spacing(2) })} mr={5}>
            {/* TODO: uncomment when Search is implemented.
            <ButtonBase
              sx={(theme) => ({
                borderRadius: theme.spacing(1),
                p: theme.spacing(1),
                '&:hover': {
                  backgroundColor: theme.palette.stationGray[200],
                },
              })}
              onClick={() => {
                throw new Error('Testing Sentry Error Boundary');
              }}
            >
              <SearchIcon />
            </ButtonBase>
            */}
            {isShiftTemplateEnabled && (
              <Tooltip arrow title="Shift template" placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  data-cy="shift-template-button"
                  component={Link}
                  to={ROUTES.SHIFT_TEMPLATE.fullRoute}
                  state={{
                    teamId: currentTeams && currentTeams.length ? currentTeams[0].id : null,
                    battalionId: preferences.selectedBattalionId,
                  }}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <Edit05Icon20 />
                </ButtonBase>
              </Tooltip>
            )}
            {canSendMessages && (
              <Tooltip arrow title="Send message " placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  onClick={() => toggleOpenedDrawer(DRAWER_TYPE.MESSAGE)}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <PlusIcon20 />
                </ButtonBase>
              </Tooltip>
            )}
            <Tooltip arrow title="Print roster" placement="bottom" slotProps={getTooltipProps()}>
              <ButtonBase
                data-cy="print-button"
                onClick={() => handlePrintModalOpen()}
                sx={(theme) => ({
                  borderRadius: theme.spacing(1),
                  p: theme.spacing(1),
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[200],
                  },
                })}
              >
                <PrinterIcon20 />
              </ButtonBase>
            </Tooltip>
            <PrintDayScheduleModal open={isPrintModalOpen} handleClose={handlePrintModalClose} />
            {canFillFromStaffingList && (
              <Tooltip arrow title="Staffing lists" placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  data-cy="overtime-button"
                  onClick={() => toggleOpenedDrawer(DRAWER_TYPE.HIREBACK)}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    backgroundColor: openedDrawer === DRAWER_TYPE.HIREBACK ? theme.palette.stationGray[200] : 'transparent',
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <OvertimeListIcon20 />
                </ButtonBase>
              </Tooltip>
            )}
            {canEditOffRoster && (
              <Tooltip arrow title="Off roster" placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  data-cy="employees-off-button"
                  onClick={() => toggleOpenedDrawer(DRAWER_TYPE.EMPLOYEES_OFF)}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    backgroundColor: openedDrawer === DRAWER_TYPE.EMPLOYEES_OFF ? theme.palette.stationGray[200] : 'transparent',
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <UsersXIcon20 />
                </ButtonBase>
              </Tooltip>
            )}
            <Tooltip arrow title="Staffing Stats" placement="bottom" slotProps={getTooltipProps()}>
              <ButtonBase
                data-cy="staffing-stats-button"
                onClick={() => toggleOpenedDrawer(DRAWER_TYPE.STAFFING_STATS)}
                sx={(theme) => ({
                  borderRadius: theme.spacing(1),
                  p: theme.spacing(1),
                  backgroundColor: openedDrawer === DRAWER_TYPE.STAFFING_STATS ? theme.palette.stationGray[200] : 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.stationGray[200],
                  },
                })}
              >
                <BarChartSquare02Icon20 />
              </ButtonBase>
            </Tooltip>
            {canViewChangelog && (
              <Tooltip arrow title="Change Log" placement="bottom" slotProps={getTooltipProps()}>
                <ButtonBase
                  onClick={() => toggleOpenedDrawer(DRAWER_TYPE.CHANGELOG)}
                  sx={(theme) => ({
                    borderRadius: theme.spacing(1),
                    p: theme.spacing(1),
                    '&:hover': {
                      backgroundColor: theme.palette.stationGray[200],
                    },
                  })}
                >
                  <ClockRewindIcon20 />
                </ButtonBase>
              </Tooltip>
            )}
            {capabilities.MANAGE_ROSTER_HIRING &&
              hiringEngineResponse.autoHireInfo &&
              (hiringEngineResponse.autoHireInfo.autoHires.length > 0 ||
                hiringEngineResponse.instantHires.length > 0 ||
                !isDateInPast) &&
              (hiringEngineResponse.autoHireInfo.autoHires.length > 0 ||
                hiringEngineResponse.instantHires.length > 0 ||
                Object.keys(groupedVacancies).length !== 0) && (
                <Tooltip arrow title="Hiring engine" placement="bottom" slotProps={getTooltipProps()}>
                  <ButtonBase
                    data-cy="hiring-engine-button"
                    onClick={handleHiringEngineClick}
                    sx={(theme) => ({
                      borderRadius: theme.spacing(1),
                      p: theme.spacing(1),
                      '&:hover': {
                        backgroundColor: theme.palette.stationGray[200],
                      },
                    })}
                  >
                    <SvgIcon component={AutoAwesomeIcon} sx={{ color: theme.palette.stationGray[500] }} />
                  </ButtonBase>
                </Tooltip>
              )}
            <HiringEngineModal
              open={hiringEngineModalOpen}
              setOpen={setHiringEngineModalOpen}
              anchorEl={anchorEl}
              forceRefetch={forceRefetch}
              vacantStations={vacantStations}
              selectedDate={selectedDate}
              selectedBattalionId={preferences.selectedBattalionId}
              isDateInPast={isDateInPast}
              groupedVacancies={groupedVacancies}
              hiringEngineResponse={hiringEngineResponse}
              hasRosterChanges={(userHasMadeChanges || saveDisabled) && !lastChanges && !isSaving && !isReadonly}
            />

            <CollapseSwitch />
            <Box display="flex" gap={1} alignItems="center">
              <Button buttonType="tertiary" disabled={!userHasMadeChanges || isSaving} onClick={resetValues} data-cy="cancel">
                Cancel
              </Button>
              <Tooltip title="You cannot save changes on past dates" disableHoverListener={!isReadonly}>
                <span>
                  <Button
                    {...getLastChangesErrorPopoverFallbackAnchorProps()}
                    disabled={!userHasMadeChanges || !!lastChanges || isSaving || isReadonly || saveDisabled}
                    onClick={saveChanges}
                    data-cy="save"
                  >
                    Save
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      <TeamColoredBar shiftTeam={topBarTeamInfo} />
    </Box>
  );
};
