import { Box, Typography } from '@mui/material';
import { getTeamColors } from '@stationwise/component-module';

interface ShiftNameProps {
  shiftName: string;
  shiftColor?: string;
}

export const ShiftName = ({ shiftName, shiftColor = 'gray' }: ShiftNameProps) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
      })}
    >
      <Box
        sx={(theme) => ({
          width: '4px',
          height: '14px',
          borderRadius: theme.spacing(1),
          ...getTeamColors({ theme, color: shiftColor }),
        })}
      ></Box>
      <Typography
        variant="buttonM"
        sx={(theme) => ({
          color: theme.palette.stationGray[700],
        })}
      >
        {shiftName.toUpperCase()}
      </Typography>
    </Box>
  );
};
