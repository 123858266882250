import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LottieLogoPageLoader, useDocumentTitle } from '@stationwise/component-module';
import { GetRequestVolunteersSignUpDTO } from '@stationwise/share-types';
import { ROUTES } from '../../../../../core/Routes';
import { AppPageLayout } from '../../../../AppPageLayout';
import { useFetchRequestVolunteersSignUpData } from '../hooks/useFetchRequestVolunteersSignUpData';
import { RequestVolunteersSignUpContent } from './RequestVolunteersSignUpContent';

// DTOs
export interface VacancyInfoView {
  apparatusName: string;
  stationName: string;
  actingRankName: string;
  actingRankColor: string;
}

export interface RequestVolunteersSignUpView {
  shiftName: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  teamColor: string;
  bulkCallId: string;
  bulkCallCreatorName: string;
  bulkCallCreatorRank: string;
  bulkCallCreatorRankColor: string;
  vacancies: VacancyInfoView[];
}

interface RequestVolunteersSignUpMainProps {
  getRequestVolunteersSignUpDto: GetRequestVolunteersSignUpDTO;
}

export const RequestVolunteersSignUpMain = ({ getRequestVolunteersSignUpDto }: RequestVolunteersSignUpMainProps) => {
  useDocumentTitle('Overtime Sign-up');
  const navigate = useNavigate();

  const { isLoading, isError, data } = useFetchRequestVolunteersSignUpData({
    requestVolunteersId: getRequestVolunteersSignUpDto.requestVolunteersId,
  });
  useEffect(() => {
    if (isError || !getRequestVolunteersSignUpDto.requestVolunteersId) {
      navigate(ROUTES.MY_DASHBOARD.fullRoute + '/?bulk_call_error=true');
    }
  }, [getRequestVolunteersSignUpDto.requestVolunteersId, isError, navigate]);

  const renderContent = () => {
    if (isLoading) {
      return <LottieLogoPageLoader />;
    }

    if (data !== null) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            alignContent: 'center',
          }}
        >
          <RequestVolunteersSignUpContent data={data} />
        </Box>
      );
    }

    return;
  };

  return (
    <AppPageLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          height: '100%',
          width: '100%',
          p: 0,
          maxWidth: '768px',
          margin: '0 auto',
        }}
      >
        {renderContent()}
      </Box>
    </AppPageLayout>
  );
};
