import { Box } from '@mui/material';
import { CertificationRequirementCount, RankRequirementCount } from '@stationwise/shift-summary-helper';
import { CertificationRecord } from './CertificationRecord';
import { RankRecord } from './RankRecord';
import { IChipFilterType, ChipFilterType } from './StationMinReq';

interface ApparatusMinReqProps extends IChipFilterType {
  apparatus: {
    name: string;
    certMap: Map<string, CertificationRequirementCount>;
    rankMap: Map<string, RankRequirementCount>;
  };
}

export const ApparatusMinReq = ({ apparatus, type }: ApparatusMinReqProps) => {
  const certArray = Array.from(apparatus.certMap, ([key, value]) => ({
    name: key,
    values: value,
  }));
  const rankArray = Array.from(apparatus.rankMap, ([key, value]) => ({
    name: key,
    values: value,
  }));

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={(theme) => ({
        background: theme.palette.stationGray[800],
        color: theme.palette.stationGray[400],
        p: 1.5,
        mt: 1.5,
        borderRadius: 1.5,
      })}
    >
      <Box sx={{ typography: 'bodyXSSemibold' }}>{apparatus.name.toUpperCase()}</Box>
      {type !== ChipFilterType.certifications &&
        rankArray.map((rank) => {
          if (type === ChipFilterType.errors && rank.values.total <= rank.values.amount) {
            return null;
          }
          return (
            <RankRecord
              key={rank.name}
              name={rank?.values?.obj?.name || rank.name}
              total={rank.values.total}
              amount={rank.values.amount}
              color={rank.values.obj?.color || 'gray'}
            />
          );
        })}
      {type !== ChipFilterType.ranks &&
        certArray.map((cert) => {
          if (type === ChipFilterType.errors && cert.values.total <= cert.values.amount) {
            return null;
          }
          return (
            <CertificationRecord
              total={cert.values.total}
              amount={cert.values.amount}
              name={cert.name}
              key={cert.name}
              color={cert.values.obj?.color || 'gray'}
            />
          );
        })}
    </Box>
  );
};
