import { useCallback } from 'react';
import { useFetchBulkCallForShift } from '../../../hooks';
import { useFetchAutoHire } from './useFetchAutoHire';
import { useFetchInstantHires } from './useFetchInstantHires';

export const useFetchHiringEngineData = ({
  selectedDate,
  skip,
  battalionId,
}: {
  selectedDate: string;
  skip: boolean;
  battalionId: number | undefined;
}) => {
  const {
    isLoading: isLoadingBulkCall,
    isError: isErrorBulkCall,
    data: bulkCalls,
    forceRefetch: forceRefetchBulkCall,
  } = useFetchBulkCallForShift({
    selectedDate: selectedDate,
    skip: skip,
  });
  const {
    isLoading: isLoadingAutoHire,
    isError: isErrorAutoHire,
    autoHireInfo: dataAutoHire,
    forceRefetch: forceRefetchAutoHire,
  } = useFetchAutoHire({
    skip: skip,
    battalionId: battalionId,
  });
  const {
    isLoading: isLoadingInstantHires,
    isError: isErrorInstantHires,
    data: instantHires,
    forceRefetch: forceRefetchInstantHires,
  } = useFetchInstantHires({
    skip: skip,
    selectedDate: selectedDate,
  });

  const forceRefetchHiringEngine = useCallback((battalionId?: number) => {
    forceRefetchBulkCall();
    forceRefetchAutoHire();
    forceRefetchInstantHires();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoadingHiringEngine: isLoadingBulkCall || isLoadingAutoHire || isLoadingInstantHires,
    isErrorHiringEngine: isErrorBulkCall || isErrorAutoHire || isErrorInstantHires,
    bulkCalls,
    autoHireInfo: dataAutoHire,
    instantHires,
    forceRefetchHiringEngine,
  };
};
