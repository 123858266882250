import { useTheme } from '@mui/material';
import { GenericDrawer, FeedbackMessage } from '@stationwise/component-module';
import { useIOSPaddingBottom } from '../../../../context/DeviceInfoContext';

interface CompTimeAccrualResponseProps {
  setOpenResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  openResponseModal: boolean;
  setOpenCompTimeModal: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
  message: string;
  setRefetchCounter: React.Dispatch<React.SetStateAction<number>>;
  refetchCounter: number;
}

export const CompTimeAccrualResponse = ({
  setOpenResponseModal,
  openResponseModal,
  setOpenCompTimeModal,
  error,
  message,
  setRefetchCounter,
  refetchCounter,
}: CompTimeAccrualResponseProps) => {
  const theme = useTheme();
  const status = error ? 'error' : 'success';
  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  const goBackToPayroll = () => {
    setOpenResponseModal(false);
    setOpenCompTimeModal(false);
    setRefetchCounter(refetchCounter + 1);
  };

  return (
    <GenericDrawer
      anchor="bottom"
      loading={false}
      drawerOpen={openResponseModal}
      handleOnClose={goBackToPayroll}
      footerButtons={[
        {
          label: 'Go to payroll',
          onClick: goBackToPayroll,
          backgroundColor: theme.palette.common.black,
        },
      ]}
      paddingBottom={iosWebViewPaddingBottom}
    >
      <FeedbackMessage status={status} firstMessage={message} />
    </GenericDrawer>
  );
};
