import { Box, Dialog } from '@mui/material';
import { useMemo, useState } from 'react';
import { Button, useDepartmentInfoContext, MultipleSelect } from '@stationwise/component-module';
import { WorkGroup } from '@stationwise/share-types';
import { usePatchRequest } from './PatchRequestProvider';

interface EditGroupInfoProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  employeeWorkGroups: WorkGroup[];
}

export const EditGroupInfo = ({ showModal, setShowModal, employeeWorkGroups }: EditGroupInfoProps) => {
  const initialWorkGroups = employeeWorkGroups.map((wg) => wg.name);
  const [selectedWorkGroups, setSelectedWorkGroups] = useState(initialWorkGroups);
  const { state: department } = useDepartmentInfoContext();

  const { handlePatchRequest } = usePatchRequest();

  const workGroupOptions = department.departmentInfo?.workGroups.map((wg) => ({
    label: wg.name,
    value: wg.name,
    color: wg.color,
  }));

  const handleSave = () => {
    const data = {
      workGroups: selectedWorkGroups,
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedWorkGroups(initialWorkGroups);
  };

  const isDisabled = useMemo(() => {
    const selectedSet = new Set(selectedWorkGroups);
    const workGroupSet = new Set(initialWorkGroups);

    return selectedSet.size === workGroupSet.size && [...selectedSet].every((item) => workGroupSet.has(item));
  }, [initialWorkGroups, selectedWorkGroups]);

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Groups
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodyLMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(1),
            })}
          >
            Work groups
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            {workGroupOptions && (
              <MultipleSelect
                items={workGroupOptions}
                selectedItems={selectedWorkGroups}
                setSelectedItems={setSelectedWorkGroups}
                placeholder="Add work groups"
                hideValue={true}
              />
            )}
          </Box>

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button
              buttonType="tertiary"
              variant="contained"
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              onClick={() => handleClose()}
            >
              <Box component="span" sx={{ typography: 'buttonM', letterSpacing: '0.03em' }}>
                Cancel
              </Box>
            </Button>
            <Button
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              sx={(theme) => ({
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                borderRadius: theme.spacing(0.75),
                '&:hover': { backgroundColor: 'black', boxShadow: 'none' },
              })}
              buttonType="tertiary"
              variant="contained"
              disableFocusRipple={true}
              disableTouchRipple={true}
              onClick={() => handleSave()}
              disabled={isDisabled}
            >
              <Box component="span" sx={{ typography: 'buttonM', letterSpacing: '0.03em' }}>
                Save
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
