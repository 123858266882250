import { captureException } from '@sentry/react';
import { Dispatch, useState, useEffect, useMemo } from 'react';
import { SnackbarService, SwDialog, useDepartmentInfoContext } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { AutoHireInfoView, EligibleEmployeeForHiringEngine } from '@stationwise/share-types';
import { HiringEngineVacancy } from '../Vacancies/vacanciesHelper';
import { AutoHireHistory } from './AutoHireHistory';
import { AutoHireVacancies } from './AutoHireVacancies';
import { generateRankOrder } from './utils';

interface AutoHireMainProps {
  autoHireModalOpen: boolean;
  setAutoHireModalOpen: Dispatch<React.SetStateAction<boolean>>;
  isDateInPast: boolean;
  autoHireInfo: AutoHireInfoView | null;
  groupedVacancies: Record<number, HiringEngineVacancy[]>;
  forceRefetch: () => void;
  selectedBattalionId: number;
  selectedDate: string;
  hasRosterChanges: boolean;
}

export const AutoHireMain = ({
  autoHireModalOpen,
  setAutoHireModalOpen,
  isDateInPast,
  autoHireInfo,
  groupedVacancies,
  forceRefetch,
  selectedBattalionId,
  selectedDate,
  hasRosterChanges,
}: AutoHireMainProps) => {
  const { state: department } = useDepartmentInfoContext();

  const [autoHireRequestLoading, setAutoHireRequestLoading] = useState(false);
  const [rankOrders, setRankOrders] = useState<Record<number, { rankId: number; isSelected: boolean }[]>>(() =>
    Object.keys(groupedVacancies).reduce<Record<number, { rankId: number; isSelected: boolean }[]>>((acc, rankIdStr) => {
      const rankId = Number(rankIdStr);
      const autoHireOrder = autoHireInfo?.autoHireDefaultRankOrders[rankId];
      acc[rankId] = autoHireOrder ? autoHireOrder : generateRankOrder(rankId, department.departmentInfo?.ranks || []);
      return acc;
    }, {}),
  );
  const [vacancyRankCallOrder, setVacancyRankCallOrder] = useState<number[]>([]);

  const [disabled, setDisabled] = useState(false);
  const [autoHireHistoryOpen, setAutoHireHistoryOpen] = useState<boolean>(false);
  const [autoHireHistoryRankId, setAutoHireHistoryRankId] = useState<number | null>(null);
  const [currentEligibleEmployees, setCurrentEligibleEmployees] = useState<Record<number, EligibleEmployeeForHiringEngine[]>>({});
  const [selectedStaffingListId, setSelectedStaffingListId] = useState<number | null>(null);
  const initialCheckedRanks = useMemo(
    () =>
      Object.keys(groupedVacancies).reduce<Record<number, boolean>>((acc, rankIdStr) => {
        const rankId = Number(rankIdStr);
        acc[rankId] = false;
        return acc;
      }, {}),
    [groupedVacancies],
  );

  const [checkedRanks, setCheckedRanks] = useState<Record<number, boolean>>(initialCheckedRanks);

  useEffect(() => {
    if (autoHireInfo?.autoHireRankProcessOrder) {
      setVacancyRankCallOrder(autoHireInfo.autoHireRankProcessOrder);
    }
  }, [autoHireInfo]);

  const handleAutoHireHistoryOpen = (rankId: number) => {
    setAutoHireHistoryRankId(rankId);
    setAutoHireHistoryOpen(true);
  };

  const handleAutoHireHistoryClose = () => {
    setAutoHireHistoryOpen(false);
  };

  const onCancel = () => {
    setAutoHireModalOpen(false);
    setRankOrders({});
  };

  const onSubmitAutoHire = async () => {
    try {
      setAutoHireRequestLoading(true);
      if (selectedDate) {
        const params = {
          shift_date: selectedDate,
          battalion_id: selectedBattalionId || 0,
          rank_call_orders: Object.entries(rankOrders)
            .filter(([rankId, ranks]) => checkedRanks[Number(rankId)])
            .map(([rankId, ranks]) => ({
              rank_id: Number(rankId),
              ranks_id_list: ranks.filter((rankObj) => rankObj.isSelected).map((rankObj) => rankObj.rankId),
              eligible_employee_ids: currentEligibleEmployees[Number(rankId)].map((employee) => employee.id),
            })),
          staffing_list_id: selectedStaffingListId,
          rank_process_order: vacancyRankCallOrder.filter((rankId) => rankOrders[rankId]),
        };

        const response = await client.post('auto-hire/', params);
        let snackbar_message = 'Auto Hire Started successfully';
        if (response.status === 200) {
          snackbar_message = 'Auto Hire already started';
        }
        SnackbarService.notify({
          content: snackbar_message,
          severity: 'success',
          duration: 10000,
        });
        setAutoHireRequestLoading(false);
        forceRefetch();
      }
    } catch (error) {
      SnackbarService.notify({
        content: 'Auto Hire failed to start',
        severity: 'error',
        duration: 10000,
      });
      captureException(error);
      setAutoHireRequestLoading(false);
      console.error('An error occurred while trying to start auto hire', error);
    } finally {
      setAutoHireModalOpen(false);
    }
  };

  return (
    <SwDialog
      isOpen={autoHireModalOpen}
      onSubmit={onSubmitAutoHire}
      onCancel={onCancel}
      title="Auto Hire"
      label={'Start Auto Hire'}
      loading={autoHireRequestLoading}
      disabled={disabled || hasRosterChanges}
      width="350px"
      displayButton={!isDateInPast}
      buttonTooltipText={hasRosterChanges ? 'You should save your roster changes before starting auto hire' : ''}
    >
      {autoHireInfo && (
        <AutoHireVacancies
          groupedVacancies={groupedVacancies}
          rankOrders={rankOrders}
          setRankOrders={setRankOrders}
          setDisabled={setDisabled}
          autoHireInfo={autoHireInfo}
          handleAutoHireHistoryOpen={handleAutoHireHistoryOpen}
          vacancyRankCallOrder={vacancyRankCallOrder}
          setVacancyRankCallOrder={setVacancyRankCallOrder}
          isDateInPast={isDateInPast}
          loading={autoHireRequestLoading}
          setCurrentEligibleEmployees={setCurrentEligibleEmployees}
          checkedRanks={checkedRanks}
          setCheckedRanks={setCheckedRanks}
          setSelectedStaffingListId={setSelectedStaffingListId}
        />
      )}
      {autoHireInfo && autoHireHistoryRankId && (
        <AutoHireHistory
          open={autoHireHistoryOpen}
          onClose={handleAutoHireHistoryClose}
          autoHireInfo={autoHireInfo}
          rankId={autoHireHistoryRankId}
          forceRefetch={forceRefetch}
        />
      )}
    </SwDialog>
  );
};
