import { Modal, useRosterContext } from '@stationwise/component-module';
import { TemporaryNonShiftType } from '@stationwise/share-types';
import { MoveToTemporaryNonShiftStepper } from './MoveToTemporaryNonShiftStepper';

interface MoveToTemporaryNonShiftModalProps {
  temporaryNonShiftType: TemporaryNonShiftType;
}

export const MoveToTemporaryNonShiftModal = ({ temporaryNonShiftType }: MoveToTemporaryNonShiftModalProps) => {
  const { selectedFilledPositionState } = useRosterContext();

  return (
    <Modal
      open={selectedFilledPositionState.selectedMoveToTemporaryNonShiftType === temporaryNonShiftType}
      setOpen={(open) => selectedFilledPositionState.setSelectedMoveToTemporaryNonShiftType(open ? temporaryNonShiftType : null)}
    >
      <MoveToTemporaryNonShiftStepper temporaryNonShiftType={temporaryNonShiftType} />
    </Modal>
  );
};
