import { useState } from 'react';
import { EmployeeOffView } from '@stationwise/share-types';

export const useEmployeesOff = (employeesOff: EmployeeOffView[]) => {
  const [temporaryNonShiftIdsToCancel, setTemporaryNonShiftIdsToCancel] = useState<number[]>([]);

  const resetState = () => {
    setTemporaryNonShiftIdsToCancel([]);
  };

  const setTemporaryNonShiftToCancel = (id: number) => setTemporaryNonShiftIdsToCancel([...temporaryNonShiftIdsToCancel, id]);

  return {
    employeesOff,
    temporaryNonShiftIdsToCancel,
    setTemporaryNonShiftToCancel,
    resetState,
  };
};
