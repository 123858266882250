import { Box, Chip, styled, Theme } from '@mui/material';
import { Team, WorkGroup } from '@stationwise/share-types';
import { XCloseIcon16 } from '../../../assets';
import { TeamFlag } from '../../TeamFlag';
import { UsersIcon } from '../../UsersIcon';

const getChipStyles = (theme: Theme) => {
  return {
    paddingLeft: theme.spacing(0.5),
    borderRadius: '24px',
    border: `1px solid ${theme.palette.stationGray[200]}`,
    pr: theme.spacing(1),
    '& .MuiChip-label': {
      pl: '10px',
      pr: theme.spacing(2),
    },
    '& .MuiChip-deleteIcon path': {
      stroke: theme.palette.stationGray[400],
    },
  };
};

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.2),
}));

const getChipLabel = (name: string) => (
  <Box component="span" sx={{ typography: 'bodySRegular' }}>
    {name}
  </Box>
);

interface RecipientGroupsProps {
  workGroup?: WorkGroup;
  team?: Team;
}

export const RecipientGroups = ({ workGroup, team }: RecipientGroupsProps) => {
  return (
    <ListItem>
      {workGroup && (
        <Chip
          disabled={true}
          variant="outlined"
          avatar={<UsersIcon color={workGroup.color} showBorder />}
          sx={(theme) => getChipStyles(theme)}
          label={getChipLabel(workGroup.name)}
          deleteIcon={<XCloseIcon16 />}
          onDelete={() => console.log()}
        />
      )}

      {team && (
        <Chip
          disabled={true}
          variant="outlined"
          avatar={<TeamFlag color={team.color} />}
          sx={(theme) => getChipStyles(theme)}
          label={getChipLabel(team.name)}
          deleteIcon={<XCloseIcon16 />}
          onDelete={() => console.log()}
        />
      )}
    </ListItem>
  );
};
