import { useDeferredValue, useEffect, useState } from 'react';
import {
  AssignmentForm,
  CandidateCard,
  Filter,
  getCertColors,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { Candidate, RosterApparatus, RosterEmployee, RosterPosition } from '@stationwise/share-types';
import { useShiftTemplateContext } from '../../contexts/ShiftTemplateContext';
import { useFetchCandidates } from '../../hooks/useFetchCandidates';

interface ShiftTemplateAssignmentFormProps {
  position: RosterPosition | null;
  apparatus: RosterApparatus;
  activeEmployee?: RosterEmployee | null;
  closeForm: () => void;
}

export const ShiftTemplateAssignmentForm = ({
  position,
  apparatus,
  closeForm,
  activeEmployee,
}: ShiftTemplateAssignmentFormProps) => {
  const { assignEmployeeToPosition, addExcessCapacity, setUnsavedUnassignedEmployees } = useShiftTemplateContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();

  const [searchValue, setSearchValue] = useState('');
  const deferredSearchValue = useDeferredValue(searchValue);

  const [selectedCertifications, setSelectedCertifications] = useState(() => {
    const certifications: string[] = [];
    position?.certifications.forEach((cert) => {
      certifications.push(cert.code);
    });
    return new Set(certifications);
  });
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate | null>(null);

  const candidates = useFetchCandidates({
    searchValue: deferredSearchValue,
    certifications: selectedCertifications,
  });

  useEffect(() => {
    setSelectedCandidate(null);
  }, [deferredSearchValue, selectedCertifications]);

  const onConfirmSubmit = () => {
    let successfulAssignment = true;

    if (selectedCandidate) {
      if (position) {
        successfulAssignment = assignEmployeeToPosition(apparatus, position, selectedCandidate, activeEmployee);
      } else {
        successfulAssignment = addExcessCapacity(apparatus, selectedCandidate);
      }

      if (successfulAssignment) {
        setUnsavedUnassignedEmployees((prev) =>
          prev.filter(
            (employee) =>
              employee.id !== selectedCandidate.id.toString() &&
              employee.startDateTime !== selectedCandidate.startDateTime &&
              employee.endDateTime !== selectedCandidate.endDateTime,
          ),
        );
      }
    }
  };

  const onSubmit = () => {
    closeForm();

    if (selectedCandidate) {
      onConfirmSubmit();
    }
  };

  const onSelectCandidate = (id: number, startDateTime?: Date) => {
    const selected = candidates.data.find(
      (candidate) =>
        candidate.id === id && (!candidate.startDateTime || (startDateTime && candidate.startDateTime === startDateTime)),
    );
    if (selected) {
      setSelectedCandidate(selected);
    }
  };

  const filters = (
    <Filter
      value={selectedCertifications}
      onChange={setSelectedCertifications}
      filterName="certification"
      filters={departmentContext.departmentInfo.certifications}
      getFilterColors={getCertColors}
    />
  );
  const searchResults = candidates.data.map((candidate) => (
    <CandidateCard
      key={`${candidate.id}_${candidate.startDateTime || ''}`}
      candidate={candidate}
      isSelected={
        candidate.id === selectedCandidate?.id &&
        (!candidate.startDateTime || (candidate.startDateTime && candidate.startDateTime === selectedCandidate.startDateTime))
      }
      onClick={onSelectCandidate}
    />
  ));

  return (
    <AssignmentForm
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      title="Staff to position"
      subtitle={
        apparatus.id === 'floater-apparatus' ? 'Floater' : `${apparatus.name} - ${position?.rank.name || 'excess capacity'}`
      }
      belowSearchContent={filters}
      onCancel={closeForm}
      onSubmit={onSubmit}
      isLoading={candidates.isLoading}
      hasNextPage={candidates.hasNextPage}
      loadMore={candidates.loadMore}
      disableSubmit={!selectedCandidate}
      searchResults={searchResults}
      noResults={candidates.data.length === 0}
    />
  );
};
