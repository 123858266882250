import { Box, Divider } from '@mui/material';
import { useState } from 'react';
import { DetailCode, PayCode, RosterEmployee } from '@stationwise/share-types';
import { renameMandatoryDetailCode, useLoadedDepartmentInfoContext } from '../Department';
import { OverrideModal } from '../Override';
import { CodeAutocomplete } from './CodeAutocomplete';
import { PreviewButton } from './PreviewButton';

interface PayAndDetailCodesProps {
  employee: RosterEmployee;
  defaultPayCodes: PayCode[];
  handleDetailCodeChange: (detailCodes: DetailCode[], employee: RosterEmployee) => void;
  handlePayCodeChange: (payCodes: PayCode[], detailCodes: DetailCode[], note: string, employee: RosterEmployee) => void;
  disableOverrideNotes?: boolean;
  isChangeDisabled?: boolean;
}

export const PayAndDetailCodes = ({
  employee,
  defaultPayCodes,
  handleDetailCodeChange,
  handlePayCodeChange,
  disableOverrideNotes,
  isChangeDisabled = false,
}: PayAndDetailCodesProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { departmentInfo } = departmentInfoState;

  const [currentPayCodes, setCurrentPayCodes] = useState(employee.payCodes);
  const [currentDetailCodes, setCurrentDetailCodes] = useState(employee.detailCodes);
  const [overrideModalIsOpen, setOverrideModalIsOpen] = useState(false);
  const employeeOverrideNote = employee.noteOverride?.note || '';
  const [overrideNote, setOverrideNote] = useState(employeeOverrideNote);
  const [overrideBackupState, setOverrideBackupState] = useState({ currentPayCodes, currentDetailCodes, overrideNote });

  const getSelectablePayCodes = (currentPayCode?: PayCode) => {
    const currentPayCodeIds = new Set(currentPayCodes.map((pc) => pc.id));
    return departmentInfo.payCodes.filter((pc) => {
      const isNew = !currentPayCodeIds.has(pc.id);
      const isCurrent = !!(currentPayCode && currentPayCode.id === pc.id);
      return !['TIME_OFF', 'HOLIDAY', 'INCENTIVE_PAY'].includes(pc.payCodeType) && (isNew || isCurrent);
    });
  };

  const getSelectableDetailCodes = (currentDetailCode?: DetailCode) => {
    const currentDetailCodeIds = new Set(currentDetailCodes.map((dc) => dc.id));

    if (currentPayCodes.map((pc) => pc.payCodeType).includes('OVERTIME')) {
      return departmentInfo.detailCodes.filter((dc) => {
        const isNew = !currentDetailCodeIds.has(dc.id);
        const isCurrent = !!(currentDetailCode && currentDetailCode.id === dc.id);
        const skipOvertimeDcs =
          currentDetailCodes.some((dc) => dc.payCodeType === 'OVERTIME') && currentDetailCode?.payCodeType !== 'OVERTIME';
        return (
          dc.payCodeType !== 'ACCRUALS_OR_BANKED_HOURS' &&
          ((isNew && (!skipOvertimeDcs || dc.payCodeType !== 'OVERTIME')) || isCurrent)
        );
      });
    }
    return departmentInfo.detailCodes.filter((dc) => {
      const isNew = !currentDetailCodeIds.has(dc.id);
      const isCurrent = !!(currentDetailCode && currentDetailCode.id === dc.id);
      return currentPayCodes.map((pc) => pc.payCodeType).includes(dc.payCodeType) && (isNew || isCurrent);
    });
  };

  const openOverrideModal = () => {
    setOverrideNote(employeeOverrideNote);
    setOverrideBackupState({ currentPayCodes, currentDetailCodes, overrideNote: employeeOverrideNote });
    setOverrideModalIsOpen(true);
  };

  const onPayCodeChange = (event: React.SyntheticEvent, value: PayCode | DetailCode, index: number) => {
    const newPayCodes = [...currentPayCodes];
    if (value.code === '') {
      newPayCodes.splice(index, 1);
    } else {
      const newPayCode = departmentInfo.payCodes.find((pc) => pc.code === value.code);
      if (!newPayCode) {
        throw new Error(`Cannot find PayCode with code ${value.code}`);
      }
      newPayCodes[index] = newPayCode;
    }
    const newDetailCodes = currentDetailCodes.filter((dc) => newPayCodes.map((pc) => pc.payCodeType).includes(dc.payCodeType));
    setCurrentPayCodes(newPayCodes);
    setCurrentDetailCodes(newDetailCodes);
    if (disableOverrideNotes || newPayCodes.some((npc) => defaultPayCodes.map((dpc) => dpc.code).includes(npc.code))) {
      handlePayCodeChange(newPayCodes, newDetailCodes, employeeOverrideNote, employee);
    } else {
      openOverrideModal();
    }
  };

  const onAddPayCode = () => {
    const firstPayCode = getSelectablePayCodes()[0];
    if (firstPayCode) {
      const newPayCodes = [...currentPayCodes, firstPayCode];
      setCurrentPayCodes(newPayCodes);
      if (disableOverrideNotes || employeeOverrideNote) {
        handlePayCodeChange(newPayCodes, currentDetailCodes, employeeOverrideNote, employee);
      } else {
        openOverrideModal();
      }
    }
  };

  const onDetailCodeChange = (event: React.SyntheticEvent, detailCode: DetailCode, index: number) => {
    const newDetailCodes = [...currentDetailCodes];
    if (detailCode.code === '') {
      newDetailCodes.splice(index, 1);
    } else {
      const newDetailCode = departmentInfo.detailCodes.find((dc) => dc.code === detailCode.code);
      if (!newDetailCode) {
        throw new Error(`Cannot find DetailCode with code ${detailCode.code}`);
      }
      newDetailCodes[index] = newDetailCode;
    }
    setCurrentDetailCodes(newDetailCodes);
    handleDetailCodeChange(newDetailCodes, employee);
  };

  const onAddDetailCode = () => {
    const firstDetailCode = getSelectableDetailCodes()[0];
    if (firstDetailCode) {
      const newDetailCodes = [...currentDetailCodes, firstDetailCode];
      setCurrentDetailCodes(newDetailCodes);
      handleDetailCodeChange(newDetailCodes, employee);
    }
  };

  const onApply = () => {
    handlePayCodeChange(currentPayCodes, currentDetailCodes, overrideNote, employee);
    setOverrideModalIsOpen(false);
  };

  const onCancel = () => {
    setCurrentPayCodes(overrideBackupState.currentPayCodes);
    setCurrentDetailCodes(overrideBackupState.currentDetailCodes);
    setOverrideNote(overrideBackupState.overrideNote);
    setOverrideModalIsOpen(false);
  };

  return (
    <>
      <OverrideModal
        isOpen={overrideModalIsOpen}
        onApply={onApply}
        onCancel={onCancel}
        overrideNote={overrideNote}
        setOverrideNote={setOverrideNote}
        subtitle="Please describe the reason why you are overriding the original pay code."
        title="Pay code override"
      />

      {currentPayCodes.map((payCode, index) => (
        <CodeAutocomplete
          key={payCode.id}
          label="Shift Pay Code"
          handleChange={(event, payCode) => onPayCodeChange(event, payCode, index)}
          options={
            index === 0
              ? getSelectablePayCodes(payCode)
              : [{ id: 0, code: '', name: 'None', payCodeType: '' }, ...getSelectablePayCodes(payCode)]
          }
          value={payCode}
          showIconAdornment={!disableOverrideNotes && !defaultPayCodes.map((pc) => pc.code).includes(payCode.code)}
          isDisabled={isChangeDisabled}
        />
      ))}
      {!isChangeDisabled && getSelectablePayCodes().length > 0 && (
        <Box display="flex" justifyContent="end">
          <PreviewButton buttonType="link" onClick={onAddPayCode}>
            + Add pay code
          </PreviewButton>
        </Box>
      )}
      <Divider sx={(theme) => ({ m: 0.5, borderColor: theme.palette.stationGray[600] })} />
      {currentDetailCodes.map((detailCode, index) => (
        <CodeAutocomplete
          key={detailCode.id}
          handleChange={(event, detailCode) => onDetailCodeChange(event, detailCode, index)}
          label="Detail code"
          options={[{ id: 0, code: '', name: 'None', payCodeType: '' }, ...getSelectableDetailCodes(detailCode)]}
          getDisplayedOption={(option) => renameMandatoryDetailCode(departmentInfoState, option)}
          value={detailCode}
          isDisabled={isChangeDisabled}
        />
      ))}
      {!isChangeDisabled && getSelectableDetailCodes().length > 0 && (
        <Box display="flex" justifyContent="end">
          <PreviewButton buttonType="link" onClick={onAddDetailCode}>
            + Add detail code
          </PreviewButton>
        </Box>
      )}
    </>
  );
};
