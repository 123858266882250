import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import { Autocomplete, Box, CircularProgress, Dialog, FormControl, TextField, IconButton } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useState } from 'react';
import { Button, SnackbarService, useLoadedDepartmentInfoContext, RankBadge } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { CapabilityPermission, Rank } from '@stationwise/share-types';
import { AddEmployeeModal } from './AddEmployeeModal';

interface CapabilityPermissionUpdate {
  ranks: number[];
  employees: string[];
}

interface CapabilityPermissionModalProps {
  onSaveSuccess: () => void;
  selectedCapabilityPermission?: CapabilityPermission;
  setShowModal: (show: boolean) => void;
  showModal: boolean;
}

const newCapabilityPermission: CapabilityPermission = {
  id: -1,
  capability: '',
  name: '',
  ranks: [],
  employees: [],
  capabilityDescription: '',
};

export const CapabilityPermissionModal = ({
  onSaveSuccess,
  selectedCapabilityPermission,
  setShowModal,
  showModal,
}: CapabilityPermissionModalProps) => {
  const {
    state: { departmentInfo },
    dispatch,
  } = useLoadedDepartmentInfoContext();

  const title = 'Edit Permission';
  const [capabilityPermission, setCapabilityPermission] = useState<CapabilityPermission>(
    selectedCapabilityPermission ? selectedCapabilityPermission : newCapabilityPermission,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPersonSelectModal, setShowPersonSelectModal] = useState(false);

  const disabled = !capabilityPermission.capability || isSubmitting;

  const handleClose = () => {
    setIsSubmitting(false);
    setShowModal(false);
    setCapabilityPermission(newCapabilityPermission);
  };

  const handleSuccess = () => {
    handleClose();
    onSaveSuccess();
    dispatch({ type: 'REFETCH_DEPARTMENT_INFO' });
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    if (selectedCapabilityPermission) {
      try {
        const updateData: CapabilityPermissionUpdate = {
          ranks: capabilityPermission.ranks.map((rank) => rank.id),
          employees: capabilityPermission.employees.map((employee) => employee.id),
        };

        const response = await client.patch(`/customization/capability-permissions/${capabilityPermission.id}/`, updateData);
        SnackbarService.notify({
          content: `Successfully updated capability permission: ${response.data.name}`,
          severity: 'success',
          duration: 5000,
        });
        handleSuccess();
      } catch (error) {
        SnackbarService.notify({
          content: `Failed to save capability permission: ${isAxiosError(error) ? error.message : ''}`,
          severity: 'error',
          duration: 5000,
        });
        handleClose();
      }
    }
  };

  const handleRemoveEmployee = (employeeId: string) => {
    setCapabilityPermission({
      ...capabilityPermission,
      employees: capabilityPermission.employees.filter((emp) => emp.id !== employeeId),
    });
  };

  return (
    <>
      <Dialog open={showModal} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.common.white,
              justifyContent: 'center',
              borderRadius: theme.spacing(1.5),
              p: theme.spacing(3),
              width: '496px',
              display: 'flex',
              flexDirection: 'column',
              '.MuiFormControl-root': {
                mb: 4,
                '.MuiInputLabel-root': {
                  top: '-5px',
                },
              },
            })}
          >
            <Box
              sx={{
                typography: 'bodyXLSemibold',
                mb: 2,
              }}
            >
              {title}
            </Box>

            <Box sx={{ mb: 2 }}>
              <Box sx={{ typography: 'h6', mb: 1, fontWeight: 400 }}>{capabilityPermission.name}</Box>
              <Box sx={{ typography: 'body1', color: 'text.secondary' }}>{capabilityPermission.capabilityDescription}</Box>
            </Box>

            <FormControl>
              <Box sx={{ typography: 'subtitle1', mb: 1 }}>Ranks</Box>
              <Autocomplete
                multiple
                value={capabilityPermission.ranks.map((rank) => rank.name)}
                options={departmentInfo.ranks.map((rank) => rank.name)}
                onChange={(event, value) =>
                  setCapabilityPermission({
                    ...capabilityPermission,
                    ranks: value
                      .map((name) => departmentInfo.ranks.find((rank) => rank.name === name))
                      .filter((rank): rank is Rank => rank !== undefined),
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Ranks"
                    helperText="Employees with these ranks will automatically inherit this capability"
                  />
                )}
                sx={{
                  '.MuiFormControl-root': {
                    mb: 0,
                  },
                }}
              />
            </FormControl>

            <Box sx={{ mb: 4 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Box sx={{ typography: 'subtitle1' }}>Employees</Box>
                <Button
                  buttonType="secondary"
                  variant="outlined"
                  onClick={() => setShowPersonSelectModal(true)}
                  sx={{
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  Add employees
                </Button>
              </Box>

              {capabilityPermission.employees.length > 0 ? (
                <Box
                  sx={{
                    maxHeight: '250px',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(0,0,0,.2)',
                      borderRadius: '4px',
                    },
                  }}
                >
                  {capabilityPermission.employees.map((employee) => (
                    <Box
                      key={employee.id}
                      sx={{
                        py: 2,
                        px: 2,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <RankBadge rank={employee.rank} />
                        <Box>
                          <Box sx={{ typography: 'subtitle1' }}>{employee.name}</Box>
                        </Box>
                      </Box>
                      <IconButton
                        onClick={() => handleRemoveEmployee(employee.id)}
                        sx={{
                          p: 0,
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <PersonRemoveOutlinedIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box sx={{ color: 'text.secondary', mb: 1 }}>No persons selected</Box>
              )}

              <Box
                sx={{
                  typography: 'caption',
                  color: 'text.secondary',
                  fontSize: '0.85rem',
                  mt: 1,
                }}
              >
                Specific Employees who lack the above Rank can be overridden to have this permission
              </Box>
            </Box>

            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
              }}
            >
              <Button
                buttonType="tertiary"
                variant="contained"
                sx={(theme) => ({
                  width: '216px',
                  height: '44px',
                })}
                onClick={() => handleClose()}
              >
                <Box component="span" sx={{ typography: 'buttonL' }}>
                  Cancel
                </Box>
              </Button>

              <Button
                sx={(theme) => ({
                  width: '216px',
                  height: '44px',
                  backgroundColor: theme.palette.common.black,
                  color: theme.palette.common.white,
                  borderRadius: theme.spacing(0.75),
                  '&:hover': { backgroundColor: theme.palette.common.black, boxShadow: 'none' },
                })}
                buttonType="tertiary"
                variant="contained"
                onClick={() => handleSave()}
                disabled={disabled}
              >
                {isSubmitting && (
                  <CircularProgress
                    sx={(theme) => ({
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    })}
                    color="inherit"
                    size="1.5rem"
                  />
                )}
                <Box component="span" sx={isSubmitting ? visuallyHidden : { typography: 'buttonL' }}>
                  Save
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>

      <AddEmployeeModal
        open={showPersonSelectModal}
        onClose={() => setShowPersonSelectModal(false)}
        existingEmployees={capabilityPermission.employees}
        selectedRanks={capabilityPermission.ranks}
        onSave={(selectedEmployees: CapabilityPermission['employees']) => {
          setCapabilityPermission({
            ...capabilityPermission,
            employees: selectedEmployees,
          });
          setShowPersonSelectModal(false);
        }}
      />
    </>
  );
};
