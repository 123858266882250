import { EventContentArg } from '@fullcalendar/core';
import { Box, useTheme } from '@mui/material';
import { getTeamColors } from '@stationwise/component-module';

export const CustomEventContent = ({
  eventContent,
  selectedDaysAhead,
  selectedShift,
  isEqualToSelectedDaysAhead,
  selectedDate,
}: {
  eventContent: EventContentArg;
  selectedDaysAhead: string;
  selectedShift: string;
  isEqualToSelectedDaysAhead: boolean;
  selectedDate: Date;
}) => {
  const theme = useTheme();

  //Only future dates present in the current month view are displaying full information about vacancies and floaters
  const isOutOfRange = (date: Date) => {
    const today = new Date();
    return today.getMonth() > date.getMonth() && eventContent.view.currentStart.getMonth() > today.getMonth();
  };
  const isPastDateFromCurrentMonth = (date: Date) => {
    return date.getMonth() === new Date().getMonth() && date.getDate() < new Date().getDate();
  };
  const isFutureDateFromCurrentMonth = (date: Date) => {
    return date.getDate() >= new Date().getDate() && date.getMonth() === new Date().getMonth();
  };
  const isNotCurrentlySelectedMonth = (date: Date) => {
    return date.getMonth() !== selectedDate.getMonth();
  };
  const isPastMonth = (date: Date) => {
    return date.getMonth() < new Date().getMonth();
  };
  const isFutureMonth = (date: Date) => {
    return date.getMonth() > new Date().getMonth();
  };
  const doesNotMatchShiftSelection = () => {
    return selectedShift.split(' ')[0] !== eventContent.event.title;
  };

  const badgeOpacity = (() => {
    if (
      eventContent.event.start &&
      !eventContent.isToday &&
      doesNotMatchShiftSelection() &&
      ((!isEqualToSelectedDaysAhead && selectedDaysAhead !== '0' && selectedShift.split(' ')[0] !== 'All') ||
        (selectedDaysAhead === '0' && selectedShift.split(' ')[0] !== 'All'))
    ) {
      return 0;
    } else if (
      eventContent.event.start &&
      (isPastMonth(eventContent.event.start) ||
        isPastDateFromCurrentMonth(eventContent.event.start) ||
        isNotCurrentlySelectedMonth(eventContent.event.start) ||
        (!isEqualToSelectedDaysAhead && selectedDaysAhead !== '0' && !eventContent.isToday))
    ) {
      return 0.3;
    } else {
      return 'inherit';
    }
  })();

  const eventOpacity = (() => {
    if (
      !eventContent.isToday &&
      !isEqualToSelectedDaysAhead &&
      doesNotMatchShiftSelection() &&
      (selectedDaysAhead !== '0' || (selectedDaysAhead === '0' && selectedShift.split(' ')[0] !== 'All'))
    ) {
      return 0;
    } else if (eventContent.event.start && eventContent.event.start.getMonth() < new Date().getMonth()) {
      return 0.3;
    } else {
      return 'inherit';
    }
  })();

  const renderCount = (count: number, singular: string, plural: string, dotColor: string) => {
    if (count <= 0) {
      return null;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          typography: 'bodyXSRegular',
          maxWidth: '100%',
        }}
      >
        <Box sx={{ flexShrink: 0, width: '8px', height: '8px', borderRadius: '50%', backgroundColor: dotColor }} />
        <Box sx={{ flexShrink: 1, textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {count === 1 ? `${count} ${singular}` : `${count} ${plural}`}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          ...getTeamColors({ theme, color: eventContent.event.backgroundColor }),
          height: '20px',
          width: '50px',
          padding: '0px 8px 0px 8px',
          borderRadius: '20px',
          textTransform: 'none',
          textAlign: 'center',
          mt: '7px',
          ml: eventContent.isToday ? '5px' : 'unset',
          opacity: badgeOpacity,
          typography: 'bodyXSMedium',
        })}
        className="event-content-cell"
      >
        {eventContent.event.title}
      </Box>
      {(() => {
        const { start, extendedProps } = eventContent.event;
        const { vacancies, floaters, employeesOff } = extendedProps;
        if (!start || !(isFutureMonth(start) || isFutureDateFromCurrentMonth(start)) || isOutOfRange(start)) {
          return null;
        }

        if (vacancies <= 0 && floaters <= 0 && employeesOff <= 0) {
          return (
            <Box
              className="event-content-cell"
              sx={(theme) => ({
                backgroundColor: theme.palette.stationTeal[50],
                color: theme.palette.stationTeal[400],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: eventOpacity,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                typography: 'bodyXSRegular',
                zIndex: -1,
              })}
            >
              Fully staffed
            </Box>
          );
        }

        return (
          <Box
            className="event-content-cell"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              ml: '-25px',
              mt: '20px',
              opacity: eventOpacity,
              maxWidth: '100%',
            }}
          >
            {renderCount(eventContent.event.extendedProps['vacancies'], 'Vacancy', 'Vacancies', theme.palette.stationRose[500])}
            {renderCount(eventContent.event.extendedProps['floaters'], 'Floater', 'Floaters', theme.palette.stationYellow[500])}
            {renderCount(
              eventContent.event.extendedProps['employeesOff'],
              'Employee Off',
              'Employees Off',
              theme.palette.stationGray[500],
            )}
          </Box>
        );
      })()}
    </>
  );
};
