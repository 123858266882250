import { RosterEmployee } from '@stationwise/share-types';
import { setEmployeeActiveId } from './id';

const getPayCodeIdString = ({ payCodes }: RosterEmployee) => {
  const ids = payCodes.map((pc) => pc.id).sort();
  return ids.join(',');
};

const getDetailCodeIdString = ({ detailCodes }: RosterEmployee) => {
  const ids = detailCodes.map((dc) => dc.id).sort();
  return ids.join(',');
};

const checkAreEmployeesConsecutive = (prev: RosterEmployee, curr: RosterEmployee) => {
  return prev.id === curr.id && curr.startDateTime <= prev.endDateTime;
};

const checkAreEmployeesMergeable = (prev: RosterEmployee, curr: RosterEmployee) => {
  return (
    checkAreEmployeesConsecutive(prev, curr) &&
    getPayCodeIdString(prev) === getPayCodeIdString(curr) &&
    getDetailCodeIdString(prev) === getDetailCodeIdString(curr) &&
    prev.trade?.id === curr.trade?.id &&
    prev.trade?.requester.id === curr.trade?.requester.id
  );
};

export const mergeEmployees = (employees: RosterEmployee[]) => {
  if (employees.length <= 1) {
    return employees;
  }

  const merged = [employees[0]];
  employees.slice(1, employees.length).forEach((curr) => {
    const prev = merged[merged.length - 1];

    if (checkAreEmployeesMergeable(prev, curr)) {
      if (curr.endDateTime > prev.endDateTime) {
        const newPrev = setEmployeeActiveId({ ...prev, endDateTime: curr.endDateTime });

        if (prev.staffedAt && curr.staffedAt && curr.staffedAt < prev.staffedAt) {
          newPrev.staffedAt = curr.staffedAt;
        } else if (!prev.staffedAt && curr.staffedAt) {
          newPrev.staffedAt = curr.staffedAt;
        }

        merged[merged.length - 1] = newPrev;
      }
    } else {
      merged.push(curr);
    }
  });

  return merged;
};
