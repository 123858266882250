import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { DateSelector } from './DateSelector';

interface HeaderProps {
  title: string;
  prevHandle: () => void;
  nextHandle: () => void;
  leftSideComponents: ReactNode;
  rightSideComponents: ReactNode;
}

export const Header = ({ title, prevHandle, nextHandle, leftSideComponents, rightSideComponents }: HeaderProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={(theme) => ({
        pb: '12px',
        pt: '12px',
        top: '0px',
        zIndex: theme.zIndex.fab,
        background: theme.palette.common.white,
        borderTop: `1px dashed ${theme.palette.stationGray[100]}`,
      })}
    >
      <Box display="flex" sx={{ ml: '16px', minWidth: 0 }}>
        <Box
          sx={(theme) => ({
            m: theme.spacing(0, 3, 0, 1),
            typography: 'heading4',
          })}
        >
          Dashboard
        </Box>
        <Box sx={{ mt: { md: '10px' }, minWidth: 0 }}>
          <DateSelector title={title} prevHandle={prevHandle} nextHandle={nextHandle} />
        </Box>
        {leftSideComponents}
      </Box>
      {rightSideComponents}
    </Box>
  );
};
