import { Box, IconButton, Typography, Tooltip } from '@mui/material';
import { captureException } from '@sentry/react';
import { format } from 'date-fns';
import { useDeferredValue, useEffect, useRef, useState } from 'react';
import {
  RankBadge,
  CertBadge,
  CustomTable,
  TeamBadge,
  LottieLogo,
  ResponseBadgeApproved20,
  ResponseBadgePending20,
  ResponseBadgeRejected20,
  SearchAndFilter,
  TableProps,
  XCloseIcon24,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { BulkCallDetailsView } from '@stationwise/share-types';
import { camelCaseToTitle, capitalize } from '@stationwise/share-utils';
import { RequestVolunteersHistoryCard } from './RequestVolunteersHistoryCard';
import { NotifiedEmployee } from './tpyes';

interface FilterOptions {
  [key: string]: string[];
}
interface RequestVolunteersHistoryContentProps {
  bulkCall: BulkCallDetailsView | null;
  closeModal: () => void;
  index: number;
}

export const RequestVolunteersHistoryContent = ({ bulkCall, closeModal, index }: RequestVolunteersHistoryContentProps) => {
  const [notifiedEmployees, setNotifiedEmployees] = useState<NotifiedEmployee[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const deferredSearchInput = useDeferredValue(searchInput);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await client.get('employee/request-volunteers/filter_options/');
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    const getNotifiedEmployees = async () => {
      if (!bulkCall || !bulkCall.bulkCallHistory) return;
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();

      try {
        const filterParams = Object.fromEntries(Object.entries(selectedFilters).filter(([_, value]) => value));
        const response = await client.get('employee/request-volunteers/notified_employees/', {
          params: {
            bulkCallId: bulkCall.id,
            ...(deferredSearchInput && { search: deferredSearchInput }),
            ...filterParams,
          },
          signal: abortControllerRef.current.signal, // Move signal here
        });
        setNotifiedEmployees(response.data);
        setIsLoading(false);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };

    getNotifiedEmployees();
  }, [deferredSearchInput, selectedFilters, bulkCall]);

  const handleFilterChange = (filterName: string, value: string) => {
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleSearchChange = (value: string) => {
    setSearchInput(value);
  };

  const filters = Object.entries(filterOptions).map(([filterName, options]) => ({
    name: camelCaseToTitle(capitalize(filterName)),
    options: options.map((option) => ({
      label: capitalize(option),
      value: capitalize(option),
    })),
    selected: selectedFilters[filterName],
    onChange: (value: string) => handleFilterChange(filterName, value),
  }));

  const clearAllFilters = () => {
    setSelectedFilters({});
  };
  const columns: TableProps['columns'] = [
    { id: 'name', label: 'Name', minWidth: 200, align: 'left' },
    { id: 'rank', label: 'Rank', minWidth: 64, align: 'center' },
    { id: 'certificates', label: 'Certificates', minWidth: 150, align: 'left' },
    { id: 'shift', label: 'Shift', minWidth: 100, align: 'left' },
    { id: 'status', label: 'Status', minWidth: 80, align: 'center' },
    { id: 'timeseen', label: 'Time Seen', minWidth: 100, align: 'right' },
  ];

  const data: TableProps['data'] = notifiedEmployees.map((employee) => ({
    name: (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          typography: 'bodySMedium',
        }}
      >
        {employee.name}
      </Box>
    ),
    rank: <RankBadge rank={employee.rank} />,
    certificates: (
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          typography: 'bodySMedium',
        })}
      >
        {employee.certifications.map((cert) => (
          <CertBadge key={cert.id} cert={cert} />
        ))}
      </Box>
    ),
    shift:
      employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0 ? (
        <TeamBadge
          team={{
            name: employee.plannedShiftDetails[0].teamName,
            color: employee.plannedShiftDetails[0].shiftColor,
          }}
        />
      ) : (
        <TeamBadge team={{ name: 'Unassigned', color: 'gray' }} />
      ),
    status: (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          {employee.signUpTime ? (
            <Tooltip title="Volunteered" arrow>
              <ResponseBadgeApproved20 />
            </Tooltip>
          ) : employee.timeSeen ? (
            <Tooltip title="Notification opened, but did not volunteer" arrow>
              <ResponseBadgeRejected20 />
            </Tooltip>
          ) : (
            <Tooltip title="Notification not opened" arrow>
              <ResponseBadgePending20 />
            </Tooltip>
          )}
        </Box>
      </Box>
    ),
    timeseen: (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        {employee.timeSeen ? format(new Date(employee.timeSeen), 'MMM dd, HH:mm aa') : ''}
      </Box>
    ),
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography variant="bodyXLSemibold">Request volunteers summary</Typography>
        <IconButton onClick={closeModal}>
          <XCloseIcon24 />
        </IconButton>
      </Box>
      <Box>
        <RequestVolunteersHistoryCard bulkCall={bulkCall} index={index} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          typography: 'bodySMedium',
          alignItems: 'center',
        }}
      >
        <SearchAndFilter
          searchText={searchInput}
          setSearchText={handleSearchChange}
          filters={filters}
          clearAllFilters={clearAllFilters}
          searchPlaceholder="Search for people in your team"
        />
      </Box>
      <Box>
        {isLoading && (
          <Box display="flex" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <LottieLogo width={'150px'} height={'150px'} />
          </Box>
        )}
        {!isLoading && <CustomTable columns={columns} data={data} />}
      </Box>
    </Box>
  );
};
