import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import { captureException } from '@sentry/react';
import { format, parse } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import {
  SnackbarService,
  useDepartmentInfoContext,
  useRosterContext,
  XCloseIcon24,
  Loader,
  ErrorBanner,
  StaffingListMainControls,
  useStaffingListFilterGroup,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { parseHourAndMinute } from '@stationwise/share-utils';
import { HiringEngineStaffinListFilterPopover } from '../HiringEngineStaffingListFilterPopover';
import { HiringEngineVacancy } from '../Vacancies/vacanciesHelper';
import { InstantHireCards } from './InstantHireCards';
import { calculateMaximumMatching } from './calculateMaximumMatching';
import { matchEmployeesToVacancies, VacancyEligibilityDetailed } from './matchEmployeesToVacancies';

interface CreateInstantHireModalProps {
  createInstantHireModalOpen: boolean;
  setCreateInstantHireModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allVacancies: HiringEngineVacancy[];
  selectedDate: string;
  selectedBattalionId: number;
  forceRefetch: () => void;
}

export const CreateInstantHireModal = ({
  createInstantHireModalOpen,
  setCreateInstantHireModalOpen,
  allVacancies,
  selectedDate,
  selectedBattalionId,
  forceRefetch,
}: CreateInstantHireModalProps) => {
  const {
    state: { departmentInfo },
  } = useDepartmentInfoContext();
  const [departmentStartHours, departmentStartMinutes] = departmentInfo ? parseHourAndMinute(departmentInfo.shiftStart) : [0, 0];
  const theme = useTheme();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedVacancies, setSelectedVacancies] = useState<HiringEngineVacancy[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { staffingListsState } = useRosterContext();
  const operators = staffingListsState.availableStaffingListItems;

  const [selectedTeamOptions, setSelectedTeamOptions] = useState(new Set<string>());
  const [selectedBattalionOptions, setSelectedBattalionOptions] = useState(new Set<string>());
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const [hasInitiallySelected, setHasInitiallySelected] = useState(false);
  const filterStates = [
    useStaffingListFilterGroup(selectedTeamOptions, setSelectedTeamOptions),
    useStaffingListFilterGroup(selectedBattalionOptions, setSelectedBattalionOptions),
  ];

  const vacancyEligibilityList: VacancyEligibilityDetailed[] = useMemo(() => {
    return matchEmployeesToVacancies({
      staffingListItems: operators,
      vacancies: allVacancies,
      selectedTeamOptions,
      selectedBattalionOptions,
    });
  }, [operators, allVacancies, selectedTeamOptions, selectedBattalionOptions]);
  const [previousAssignments, setPreviousAssignments] = useState<Record<number, string | null>>({});
  const [previousSelectedVacanciesState, setPreviousSelectedVacanciesState] = useState<HiringEngineVacancy[]>([]);

  useEffect(() => {
    setHasInitiallySelected(false);
  }, [staffingListsState.selectedStaffingList]);

  useEffect(() => {
    if (!createInstantHireModalOpen) return;

    const previouslySelectedSet = new Set(previousSelectedVacanciesState);
    const currentlySelectedSet = new Set(selectedVacancies);

    const newlyAdded = selectedVacancies.filter((v) => !previouslySelectedSet.has(v));
    const removed = previousSelectedVacanciesState.filter((v) => !currentlySelectedSet.has(v));

    if (removed.length > 0 || !hasInitiallySelected) {
      if (!hasInitiallySelected) {
        const validVacancies = vacancyEligibilityList.filter((v) => v.eligibleEmployees.length > 0).map((v) => v.vacancy);
        setSelectedVacancies(validVacancies);
        setSelectAll(validVacancies.length > 0);
        setHasInitiallySelected(true);
      }

      const newAssignments = performGlobalMatch(selectedVacancies, vacancyEligibilityList, allVacancies);
      setPreviousAssignments(newAssignments);
      setPreviousSelectedVacanciesState(selectedVacancies);
    } else if (newlyAdded.length > 0) {
      const newAssignments = performIncrementalAssignment(previousAssignments, newlyAdded, vacancyEligibilityList, allVacancies);
      setPreviousAssignments(newAssignments);
      setPreviousSelectedVacanciesState(selectedVacancies);
    }
  }, [
    selectedVacancies,
    vacancyEligibilityList,
    allVacancies,
    previousAssignments,
    createInstantHireModalOpen,
    previousSelectedVacanciesState,
    hasInitiallySelected,
  ]);

  const newSelectedEligibleEmployeeIds = useMemo(() => {
    const selectedIndices = selectedVacancies.map((v) => allVacancies.indexOf(v));
    return selectedIndices.map((idx) => previousAssignments[idx]).filter((id): id is string => id !== null);
    // eslint-disable-next-line
  }, [selectedVacancies, previousAssignments, allVacancies, createInstantHireModalOpen]);

  const formatDisplayDate = (dateString: string | null | undefined) => {
    if (!dateString) return '';
    const parsedDate = parse(dateString, 'MM/dd/yyyy', new Date());
    return format(parsedDate, 'MMMM dd, yyyy');
  };

  const closeModal = () => {
    setCreateInstantHireModalOpen(false);
    setSelectedVacancies([]);
    setSelectAll(false);
    setPreviousAssignments({});
    setPreviousSelectedVacanciesState([]);
    setHasInitiallySelected(false);
    setSelectedTeamOptions(new Set<string>());
    setSelectedBattalionOptions(new Set<string>());
    setFilterPopoverAnchorEl(null);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    if (selectedVacancies.length === 0 || newSelectedEligibleEmployeeIds.length === 0) {
      setCreateInstantHireModalOpen(false);
      return;
    }

    const formattedVacancies = selectedVacancies.map((vacancy, index) => {
      const empId = newSelectedEligibleEmployeeIds[index];
      const tempDate = new Date(vacancy.startDateTime);
      tempDate.setHours(departmentStartHours, departmentStartMinutes, 0, 0);

      let startMinutes = Math.floor((vacancy.startDateTime.getTime() - tempDate.getTime()) / 60000);
      let endMinutes = Math.floor((vacancy.endDateTime.getTime() - tempDate.getTime()) / 60000);

      // Handle shifts that go past midnight
      if (endMinutes < startMinutes) {
        endMinutes += 24 * 60; // Add 24 hours worth of minutes
      }

      // Handle shifts that start before department start time
      if (startMinutes < 0) {
        startMinutes += 24 * 60;
        endMinutes += 24 * 60;
      }
      return {
        position_id: vacancy.id,
        eligible_employee_id: empId,
        start_time: startMinutes,
        end_time: endMinutes,
      };
    });

    const params = {
      shiftDate: selectedDate,
      battalionId: selectedBattalionId,
      vacancies: formattedVacancies,
    };
    try {
      await client.post('instant-hire/', params);
      SnackbarService.notify({
        content: 'Instant Hire started successfully',
        severity: 'success',
        duration: 10000,
      });
      forceRefetch();
    } catch (error) {
      SnackbarService.notify({
        content: 'Failed to start Instant Hire, please refresh the page and try again.',
        severity: 'error',
        duration: 10000,
      });
      captureException(error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const handleToggleVacancy = (vacancy: HiringEngineVacancy) => {
    setSelectedVacancies((prev) => {
      const isSelected = prev.includes(vacancy);
      const newSelected = isSelected ? prev.filter((v) => v !== vacancy) : [...prev, vacancy];

      const validVacancies = vacancyEligibilityList.filter((v) => v.eligibleEmployees.length > 0).map((v) => v.vacancy);
      setSelectAll(newSelected.length === validVacancies.length);

      return newSelected;
    });
  };

  const handleToggleSelectAll = (selectAllNow: boolean) => {
    if (selectAllNow) {
      const validVacancies = vacancyEligibilityList.filter((v) => v.eligibleEmployees.length > 0).map((v) => v.vacancy);
      setSelectedVacancies(validVacancies);
      setSelectAll(true);
    } else {
      setSelectedVacancies([]);
      setSelectAll(false);
    }
  };

  return (
    <Dialog
      open={createInstantHireModalOpen}
      onClose={closeModal}
      scroll="paper"
      PaperProps={{
        sx: {
          width: '600px',
          height: '800px',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ typography: 'bodyXLSemibold' }}>Instant Hire</Box>
          <Typography variant="bodyMRegular">{formatDisplayDate(selectedDate)}</Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <XCloseIcon24 />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: 1.5 }}>
          <StaffingListMainControls
            setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
            filterStates={filterStates}
            isHiringEngine={true}
          />
          <HiringEngineStaffinListFilterPopover
            items={operators}
            filterPopoverAnchorEl={filterPopoverAnchorEl}
            setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
            filterStates={filterStates}
          />

          {staffingListsState.isLoading && <Loader />}
          {!staffingListsState.isLoading && staffingListsState.isError && (
            <ErrorBanner>{'Unable to load staffing list.'}</ErrorBanner>
          )}
          {!staffingListsState.isLoading && !staffingListsState.isError && (
            <InstantHireCards
              allVacancies={allVacancies}
              vacancyEligibility={vacancyEligibilityList}
              selectedVacancies={selectedVacancies}
              selectedEligibleEmployeeIds={newSelectedEligibleEmployeeIds}
              selectAll={selectAll}
              onToggleVacancy={handleToggleVacancy}
              onToggleSelectAll={handleToggleSelectAll}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button
            variant="outlined"
            size="large"
            sx={{ paddingX: '22px', paddingY: 1, textTransform: 'none' }}
            onClick={closeModal}
          >
            <Typography variant="buttonL">Cancel</Typography>
          </Button>
          <LoadingButton
            variant="contained"
            size="large"
            sx={{ paddingX: '22px', paddingY: 1, textTransform: 'none' }}
            onClick={handleConfirm}
            disabled={selectedVacancies.length === 0 || newSelectedEligibleEmployeeIds.length === 0}
            loading={isLoading}
          >
            <Typography variant="buttonL">Confirm</Typography>
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

// Global match function
function performGlobalMatch(
  vacancies: HiringEngineVacancy[],
  eligibilityList: VacancyEligibilityDetailed[],
  allVacancies: HiringEngineVacancy[],
): Record<number, string | null> {
  const selectedIndices = vacancies.map((v) => allVacancies.indexOf(v));
  const filteredEligibility = selectedIndices.map((i) => ({
    vacancyIndex: i,
    eligibleEmployeeIds: eligibilityList[i].eligibleEmployees.map((e) => e.id),
  }));

  const { vacancyToEmployee } = calculateMaximumMatching(filteredEligibility);
  const newAssignments: Record<number, string | null> = {};
  selectedIndices.forEach((idx) => {
    newAssignments[idx] = vacancyToEmployee[idx] || null;
  });
  return newAssignments;
}

// Incremental assignment function
function performIncrementalAssignment(
  currentAssignments: Record<number, string | null>,
  newlyAdded: HiringEngineVacancy[],
  eligibilityList: VacancyEligibilityDetailed[],
  allVacancies: HiringEngineVacancy[],
): Record<number, string | null> {
  const assignedEmployees = new Set(Object.values(currentAssignments).filter((x) => x !== null));
  const updatedAssignments = { ...currentAssignments };

  for (const newVac of newlyAdded) {
    const vacIndex = allVacancies.indexOf(newVac);
    const eligibleEmployees = eligibilityList[vacIndex].eligibleEmployees;
    const candidate = eligibleEmployees.find((emp) => !assignedEmployees.has(emp.id));
    if (candidate) {
      updatedAssignments[vacIndex] = candidate.id;
      assignedEmployees.add(candidate.id);
    } else {
      updatedAssignments[vacIndex] = null;
    }
  }

  return updatedAssignments;
}
