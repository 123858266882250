import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { BlankCard, Chip, getEventDisplayedTitle, useLoadedDepartmentInfoContext } from '@stationwise/component-module';

export const RequestTradeShiftCard = ({
  shift,
  children,
  handleClick,
}: {
  shift: EventInput | EventImpl;
  children?: React.ReactNode;
  handleClick?: (arg0: EventInput | EventImpl) => void;
}) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();

  return (
    <Box
      className="shift-card-box"
      sx={{
        ml: '16px',
        mr: '16px',
        pb: '16px',
      }}
      onClick={() => handleClick && handleClick(shift)}
    >
      <BlankCard>
        <Box>
          <Chip
            label={getEventDisplayedTitle(departmentInfoState, shift.title || '').toUpperCase()}
            chipSxProps={() => ({
              background: shift.backgroundColor,
              height: '24px',
              width: '110%',
              p: '4px 2px',
              borderRadius: '4px',
            })}
            variant="eyebrow"
            typographyStyles={{
              color: shift.textColor,
            }}
          />
        </Box>
        <Box display="flex">
          {shift.start?.toString() && <Typography variant="bodyMRegular">{format(shift.start?.toString(), 'M/d/yy')}</Typography>}
          <Box
            display="flex"
            sx={(theme) => ({
              ml: '10px',
              color: theme.palette.stationGray[400],
            })}
          >
            {shift.start?.toString() && (
              <Typography variant="bodyMRegular">{format(shift.start?.toString(), 'HH:mm')}</Typography>
            )}
            <Box sx={{ ml: '3px', mr: '3px' }}>-</Box>
            {shift.end?.toString() && <Typography variant="bodyMRegular">{format(shift.end?.toString(), 'HH:mm')}</Typography>}
          </Box>
        </Box>
        {children}
      </BlankCard>
    </Box>
  );
};
