import { useMemo } from 'react';
import { useAuthUserContext } from '../components/Auth/context/AuthUser';

export const CAPABILITIES = {
  ALL: 'ALL',
  SEND_MESSAGES: 'SEND_MESSAGES',
  EDIT_DEPARTMENT_SETTINGS: 'EDIT_DEPARTMENT_SETTINGS',
  VIEW_CHANGE_LOGS: 'VIEW_CHANGE_LOGS',
  VIEW_EMPLOYEES_PAGE: 'VIEW_EMPLOYEES_PAGE',
  ADD_NEW_EMPLOYEE: 'ADD_NEW_EMPLOYEE',
  EDIT_EMPLOYEE_PROFILE: 'EDIT_EMPLOYEE_PROFILE',
  EMPLOYEE_ACCOUNT_CONTROL: 'EMPLOYEE_ACCOUNT_CONTROL',
  PROMOTE_EMPLOYEE: 'PROMOTE_EMPLOYEE',
  MANAGE_SHIFT_TRADE_LEDGER: 'MANAGE_SHIFT_TRADE_LEDGER',
  MANAGE_WORK_GROUPS: 'MANAGE_WORK_GROUPS',
  MANAGE_INCIDENT_REPORT: 'MANAGE_INCIDENT_REPORT',
  REVIEW_ALL_REQUESTS: 'REVIEW_ALL_REQUESTS',
  REVIEW_ALL_ELIGIBLE_REQUESTS: 'REVIEW_ALL_ELIGIBLE_REQUESTS',
  VIEW_ADMIN_PAYROLL: 'VIEW_ADMIN_PAYROLL',
  EDIT_ADMIN_PAYROLL: 'EDIT_ADMIN_PAYROLL',
  EDIT_ACCRUALS: 'EDIT_ACCRUALS',
  VIEW_STAFFING_LIST: 'VIEW_STAFFING_LIST',
  EDIT_STAFFING_LIST: 'EDIT_STAFFING_LIST',
  EDIT_SHIFT_TEMPLATE: 'EDIT_SHIFT_TEMPLATE',
  EDIT_ROSTER_PAY_CODES: 'EDIT_ROSTER_PAY_CODES',
  EDIT_ROSTER_ASSIGNMENTS: 'EDIT_ROSTER_ASSIGNMENTS',
  EDIT_OFF_ROSTER: 'EDIT_OFF_ROSTER',
  MANAGE_ROSTER_HIRING: 'MANAGE_ROSTER_HIRING',
  EDIT_EVENTS: 'EDIT_EVENTS',
  MANAGE_EVENTS: 'MANAGE_EVENTS',
  VIEW_ADMIN_DASHBOARD: 'VIEW_ADMIN_DASHBOARD',
} as const;

export type Capability = (typeof CAPABILITIES)[keyof typeof CAPABILITIES];

export type ComputedCapabilities = {
  [K in keyof typeof CAPABILITIES]: boolean;
};

export function computeCapabilities(capabilities: string[]): ComputedCapabilities {
  const computed = Object.fromEntries(
    Object.values(CAPABILITIES).map((capability) => [
      capability,
      capabilities.includes('ALL') || capabilities.includes(capability),
    ]),
  ) as ComputedCapabilities;

  return computed;
}

export function useAuthUserCapabilities() {
  const { state } = useAuthUserContext();
  return useMemo(() => {
    const userCapabilities = state.employee?.capabilities || [];
    return computeCapabilities(userCapabilities);
  }, [state.employee?.capabilities]);
}
