import { Theme } from '@mui/material';

export interface GetComponentColorsProps {
  theme: Theme;
  color?: string | null;
  dark?: boolean;
}

export const getPalette = ({ theme, color: colorName }: GetComponentColorsProps) => {
  switch ((colorName || 'gray').toLowerCase()) {
    case 'rose':
    case 'red':
      return theme.palette.stationRed;
    case 'pink':
      return theme.palette.stationPink;
    case 'orange':
      return theme.palette.stationOrange;
    case 'gold':
    case 'yellow':
      return theme.palette.stationElectric;
    case 'teal':
    case 'green':
      return theme.palette.stationGreen;
    case 'cyan':
      return theme.palette.stationCyan;
    case 'lightblue':
      return theme.palette.stationLightBlue;
    case 'blue':
      return theme.palette.stationBlue;
    case 'indigo':
      return theme.palette.stationIndigo;
    case 'violet':
    case 'purple':
      return theme.palette.stationPurple;
    case 'gray':
    default:
      return theme.palette.stationGray;
  }
};

export const getRankColors = ({ theme, color: colorName, dark = false }: GetComponentColorsProps) => {
  const palette = getPalette({ theme, color: colorName });
  const values = {
    background: dark ? palette[900] : palette[100],
    color: dark ? palette[300] : palette[800],
  };
  switch (palette) {
    case theme.palette.stationElectric:
      values.background = dark ? palette[800] : palette[300];
      values.color = dark ? palette[200] : palette[800];
      return values;
    case theme.palette.stationIndigo:
    case theme.palette.stationPurple:
      values.color = dark ? palette[200] : palette[800];
      return values;
    case theme.palette.stationGray:
      values.background = dark ? palette[700] : palette[100];
      return values;
    default:
      return values;
  }
};

export const getCertColors = ({ theme, color: colorName, dark = false }: GetComponentColorsProps) => {
  const palette = getPalette({ theme, color: colorName });
  const values = {
    background: dark ? 'transparent' : theme.palette.common.white,
    color: dark ? palette[300] : palette[500],
  };
  switch (palette) {
    case theme.palette.stationRed:
    case theme.palette.stationPink:
    case theme.palette.stationOrange:
      values.color = dark ? palette[400] : palette[500];
      return values;
    case theme.palette.stationElectric:
      values.color = dark ? palette[300] : palette[700];
      return values;
    case theme.palette.stationGreen:
    case theme.palette.stationCyan:
      values.color = dark ? palette[400] : palette[600];
      return values;
    case theme.palette.stationGray:
      values.color = dark ? palette[300] : palette[600];
      return values;
    default:
      return values;
  }
};

export const getTeamColors = ({ theme, color: colorName, dark = false }: GetComponentColorsProps) => {
  const palette = getPalette({ theme, color: colorName });
  const values = {
    background: dark ? palette[400] : palette[500],
    color: dark ? theme.palette.stationGray[900] : theme.palette.common.white,
  };
  switch (palette) {
    case theme.palette.stationElectric:
      values.background = dark ? palette[300] : palette[600];
      return values;
    case theme.palette.stationGreen:
    case theme.palette.stationGray:
      values.background = dark ? palette[300] : palette[500];
      return values;
    default:
      return values;
  }
};

export const getAvatarColors = ({ theme, color: colorName, dark = false }: GetComponentColorsProps) => {
  const palette = getPalette({ theme, color: colorName });
  const values = getTeamColors({ theme, color: colorName, dark });
  switch (palette) {
    case theme.palette.stationCyan:
      values.background = dark ? palette[400] : palette[700];
      return values;
    default:
      return values;
  }
};
