import { Box, Typography } from '@mui/material';
import { ShiftName } from '../../../RequestVolunteersSignUp/components/TopBar/ShiftName';

interface AutoHireSignUpTopBarProps {
  shiftName: string;
  shiftColor?: string;
  applyBorderStyles: boolean;
}

export const AutoHireSignUpTopBar = ({ shiftName, shiftColor, applyBorderStyles = false }: AutoHireSignUpTopBarProps) => {
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomLeftRadius: applyBorderStyles ? theme.spacing(1.5) : theme.spacing(0),
        borderBottomRightRadius: applyBorderStyles ? theme.spacing(1.5) : theme.spacing(0),
        boxShadow: applyBorderStyles ? '0px 4px 6px -1px rgba(10, 14, 22, 0.102)' : 'none',
        backgroundColor: theme.palette.common.white,
      })}
    >
      <Box
        sx={{
          width: '92%',
          mt: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            variant="bodyLSemibold"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
            })}
          >
            Auto Hire Sign Up
          </Typography>
        </Box>
        <Box>
          <ShiftName shiftName={shiftName} shiftColor={shiftColor} />
        </Box>
      </Box>
    </Box>
  );
};
