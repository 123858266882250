export interface VacancyEligibility {
  vacancyIndex: number;
  eligibleEmployeeIds: string[];
}

interface MatchingResult {
  vacancyToEmployee: Record<number, string | null>;
}

export function calculateMaximumMatching(vacancyEligibilityList: VacancyEligibility[]): MatchingResult {
  const allEmployeeIds = new Set<string>();
  vacancyEligibilityList.forEach((item) => {
    item.eligibleEmployeeIds.forEach((id) => allEmployeeIds.add(id));
  });
  // Adjacency list for bipartite graph: vacancy -> employees
  const graph = new Map<number, string[]>();
  for (const { vacancyIndex, eligibleEmployeeIds } of vacancyEligibilityList) {
    graph.set(vacancyIndex, eligibleEmployeeIds);
  }

  const vacancyToEmployee: Record<number, string | null> = {};
  const employeeToVacancy: Record<string, number | null> = {};

  for (const empId of allEmployeeIds) {
    employeeToVacancy[empId] = null;
  }
  for (const { vacancyIndex } of vacancyEligibilityList) {
    vacancyToEmployee[vacancyIndex] = null;
  }

  for (const { vacancyIndex } of vacancyEligibilityList) {
    const visited = new Set<string>();
    tryAssign(vacancyIndex, visited, graph, vacancyToEmployee, employeeToVacancy);
  }

  return { vacancyToEmployee };
}

function tryAssign(
  vacancy: number,
  visited: Set<string>,
  graph: Map<number, string[]>,
  vacancyToEmployee: Record<number, string | null>,
  employeeToVacancy: Record<string, number | null>,
): boolean {
  const eligibleEmployees = graph.get(vacancy) || [];
  for (const empId of eligibleEmployees) {
    if (visited.has(empId)) continue;
    visited.add(empId);
    const currentVacancy = employeeToVacancy[empId];
    if (currentVacancy === null || tryAssign(currentVacancy, visited, graph, vacancyToEmployee, employeeToVacancy)) {
      vacancyToEmployee[vacancy] = empId;
      employeeToVacancy[empId] = vacancy;
      return true;
    }
  }
  return false;
}
