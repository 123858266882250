import AddIcon from '@mui/icons-material/Add';
import { Box, Typography, useTheme } from '@mui/material';
import { Button } from '@stationwise/component-module';

interface TopBarProps {
  requestsPanelEnabled: boolean;
  requestsPanelOpen: boolean;
  setRequestsPanelOpen: (value: boolean) => void;
  onComposeClick: () => void;
  canSendMessages: boolean;
  setIsCreatingMessage: (value: boolean) => void;
  isCreatingMessage: boolean;
}

export const TopBar = ({
  requestsPanelEnabled,
  requestsPanelOpen,
  setRequestsPanelOpen,
  onComposeClick,
  canSendMessages,
  setIsCreatingMessage,
  isCreatingMessage,
}: TopBarProps) => {
  const theme = useTheme();

  const handleRequestsPanel = () => {
    setRequestsPanelOpen(true);
    setIsCreatingMessage(false);
  };

  const handleMessagesPanel = () => {
    setRequestsPanelOpen(false);
    setIsCreatingMessage(false);
  };

  return (
    <Box
      display="flex"
      width="100%"
      sx={{
        flexDirection: 'row',
        height: { md: '100%', xs: '110%' },
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.common.white,
        borderBottom: {
          xs: `1px dashed ${theme.palette.stationGray[100]}`,
          md: 'none',
        },
        padding: '8px 0px 8px 0px',
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        sx={{
          gap: '6px',
          paddingLeft: theme.spacing(2),
          height: '46px',
        }}
      >
        {requestsPanelEnabled && (
          <Box onClick={handleRequestsPanel}>
            <Typography
              sx={(theme) => ({
                mr: theme.spacing(2),
                typography: theme.typography.bodyMSemibold,
                '&:hover': {
                  cursor: 'pointer',
                },
              })}
              color={requestsPanelOpen ? theme.palette.stationGray[900] : theme.palette.stationGray[400]}
            >
              Requests
            </Typography>
          </Box>
        )}
        <Box onClick={handleMessagesPanel}>
          <Typography
            sx={(theme) => ({
              typography: theme.typography.bodyMSemibold,
              '&:hover': {
                cursor: 'pointer',
              },
            })}
            color={requestsPanelOpen ? theme.palette.stationGray[400] : theme.palette.stationGray[900]}
          >
            Messages
          </Typography>
        </Box>
      </Box>
      {!requestsPanelOpen && canSendMessages && !isCreatingMessage && (
        <Button
          buttonType="dark"
          sx={(theme) => ({
            padding: theme.spacing(0.8, 1, 0.8, 1),
            marginRight: theme.spacing(2),
          })}
          startIcon={<AddIcon />}
          onClick={onComposeClick}
        >
          <Box component="span" sx={(theme) => ({ typography: theme.typography.bodySSemibold })}>
            Compose
          </Box>
        </Button>
      )}
    </Box>
  );
};
