import { Box, BoxProps } from '@mui/material';
import { useMemo } from 'react';
import {
  Snackbar,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  useAuthUserCapabilities,
  Capability,
} from '@stationwise/component-module';
import { EmployeeTypes } from '@stationwise/share-types';
import { IntercomHandler } from '../components/IntercomHandler';
import { MenuIconTypes } from '../components/Navigation/SideBar/MenuIcon';
import { SideBar } from '../components/Navigation/SideBar/SideBar';
import { TopBar } from '../components/Navigation/TopBar/TopBar';
import { ROUTES } from '../core/Routes';
import { NoMatch } from './pages/NoMatch';

export const calendarOptions = ['Month', 'List'];
export const calendarPaths = calendarOptions.map((option) => `${ROUTES.CALENDAR.fullRoute}?display=${option.toLowerCase()}`);

export const navigationItems: INavMenu[] = [
  // CAPABILITIY REQUIRED
  {
    type: 'dashboard',
    path: '/app/dashboard',
    match: ['/app/dashboard'],
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'roster',
    path: '/app/roster-view',
    match: ['/app/roster-view', '/app/shift-template'],
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'employees',
    path: '/app/employees',
    match: ['/app/employees', '/app/employees/:employeeId'],
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'staffing-lists',
    path: ROUTES.STAFFING_LISTS.fullRoute,
    match: [ROUTES.STAFFING_LISTS.fullRoute],
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'payroll',
    path: '/app/payroll',
    match: ['/app/payroll', '/app/payroll/employee/:employeeId'],
    mobileOnly: false,
    desktopOnly: true,
  },
  {
    type: 'department-settings',
    path: '/app/department-settings',
    match: ['/app/department-settings'],
    mobileOnly: false,
    desktopOnly: true,
  },
  // NO CAPABILITY REQUIRED
  // USER ITEMS
  {
    type: 'my-dashboard',
    path: ROUTES.MY_DASHBOARD.fullRoute,
    match: [ROUTES.MY_DASHBOARD.fullRoute],
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'calendar',
    path: calendarPaths[0],
    match: [ROUTES.CALENDAR.fullRoute].concat(calendarPaths),
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'my-roster',
    path: ROUTES.PERSONAL_ROSTER.fullRoute,
    match: [ROUTES.PERSONAL_ROSTER.fullRoute],
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'my-payroll',
    path: ROUTES.MY_PAYROLL.fullRoute,
    match: [ROUTES.MY_PAYROLL.fullRoute],
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'my-staffing-lists',
    path: ROUTES.MY_STAFFING_LISTS.fullRoute,
    match: [ROUTES.MY_STAFFING_LISTS.fullRoute],
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'preferences',
    path: ROUTES.PREFERENCES.fullRoute,
    match: [ROUTES.PREFERENCES.fullRoute],
    mobileOnly: false,
    desktopOnly: false,
  },
  {
    type: 'support',
    path: '',
    match: [],
    mobileOnly: true,
    desktopOnly: false,
  },
  {
    type: 'change-department',
    path: '',
    match: [],
    mobileOnly: true,
    desktopOnly: false,
  },
  {
    type: 'logout',
    path: '',
    match: [],
    mobileOnly: true,
    desktopOnly: false,
  },
];

export interface INavMenu {
  type: MenuIconTypes;
  path: string;
  match: string[];
  onClick?: () => void;
  desktopOnly: boolean;
  mobileOnly: boolean;
}

interface AppPageLayoutProps extends BoxProps<'div'> {
  fixedPosition?: boolean;
  requiredCapability?: Capability;
}

export const AppPageLayout = ({ children, fixedPosition = false, requiredCapability, sx, ...props }: AppPageLayoutProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { state: authUserState } = useLoadedAuthUserContext();
  const capabilities = useAuthUserCapabilities();
  const isDepartmentSettingsEnabled = capabilities.EDIT_DEPARTMENT_SETTINGS;
  const canEditRoster = capabilities.EDIT_ROSTER_ASSIGNMENTS;
  const canEditRosterPayCodes = capabilities.EDIT_ROSTER_PAY_CODES;
  const canViewPayroll = capabilities.VIEW_ADMIN_PAYROLL;
  const canViewAdminDashboard = capabilities.VIEW_ADMIN_DASHBOARD;
  const canViewTeamSettings = capabilities.VIEW_EMPLOYEES_PAGE;
  const canViewStaffingList = capabilities.VIEW_STAFFING_LIST;
  const isApparatusLogin = authUserState.employee.isApparatusLogin;
  const { sideNavItems, topNavItems } = useMemo(() => {
    const { isNonShift, mobileIntercomSupportEnabled } = authUserState.employee;
    const isVolunteer = authUserState.employee.employeeType === EmployeeTypes.VOLUNTEER;
    let adminRosterIsEnabled = false;

    const enabledNavItems = navigationItems.filter((navItem) => {
      if (navItem.type === 'my-dashboard') {
        return !isVolunteer && !isNonShift;
      }
      if (navItem.type === 'employees') {
        return canViewTeamSettings;
      }
      if (navItem.type === 'my-staffing-lists') {
        return !isVolunteer && !isNonShift;
      }
      if (navItem.type === 'support') {
        return mobileIntercomSupportEnabled;
      }
      if (navItem.type === 'change-department') {
        return authUserState.employee.departments.length > 1;
      }
      if (navItem.type === 'staffing-lists') {
        return canViewStaffingList;
      }
      if (navItem.type === 'department-settings') {
        return isDepartmentSettingsEnabled;
      }
      if (navItem.type === 'payroll') {
        return canViewPayroll;
      }
      if (navItem.type === 'roster') {
        adminRosterIsEnabled = canEditRoster || canEditRosterPayCodes;
        return adminRosterIsEnabled;
      }
      if (navItem.type === 'dashboard') {
        return canViewAdminDashboard;
      }
      return true;
    });

    // remove "my-roster" navItem if "roster" navItem is present
    // TODO: Remove logic when Roster pages are merged: https://app.clickup.com/t/9006090230/SW-5036
    const sideNavItems = enabledNavItems.filter(
      (navItem) => !navItem.mobileOnly && !(adminRosterIsEnabled && navItem.type === 'my-roster'),
    );
    const topNavItems = enabledNavItems.filter((navItem) => !navItem.desktopOnly);

    return { sideNavItems, topNavItems };
  }, [
    authUserState.employee,
    canViewTeamSettings,
    canViewStaffingList,
    isDepartmentSettingsEnabled,
    canViewPayroll,
    canEditRoster,
    canEditRosterPayCodes,
    canViewAdminDashboard,
  ]);

  if (requiredCapability && !capabilities[requiredCapability]) {
    return <NoMatch />;
  }

  return (
    <Box
      data-cy={`app-${departmentInfoState.departmentInfo.domain}`}
      {...props}
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.stationGray[100],
          height: '100vh',
          width: '100vw',
          position: 'relative',
          zIndex: 0,
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          height: { xs: 'default', lg: '100%' },
          width: '100%',
          position: 'relative',
        }}
      >
        {!isApparatusLogin && <SideBar navItems={sideNavItems} />}
        <Box
          sx={{
            flex: 1,
            ml: { xs: 0, lg: '64px' },
            height: '100%',
            mt: { xs: isApparatusLogin ? '0px' : '56px', lg: '0px' },
            width: { xs: '100%', lg: 'calc(100% - 64px)' },
            overflow: 'auto',
          }}
        >
          {!isApparatusLogin && (
            <Box
              sx={{
                display: { xs: 'block', lg: 'none' },
              }}
            >
              <TopBar navItems={topNavItems} fixedPosition={fixedPosition} />
            </Box>
          )}
          {children}
        </Box>
      </Box>
      <IntercomHandler />
      <Snackbar />
    </Box>
  );
};
