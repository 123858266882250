import { Box, Checkbox, TableRowProps, Tooltip } from '@mui/material';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import {
  CustomTable,
  LetterAvatar,
  TableProps,
  TeamBadge,
  CircleTickCIcon20,
  OrangeCircleWarningIcon20,
  XCircleIcon20,
  theme,
} from '@stationwise/component-module';
import { EmployeePayroll, SignedState } from '@stationwise/share-types';

interface PayrollTableProps {
  employeePayrolls: EmployeePayroll[];
  selectedEmployeePayrolls: EmployeePayroll[];
  setselectedEmployeePayrolls: React.Dispatch<React.SetStateAction<EmployeePayroll[]>>;
  clickedEmployeePayroll: EmployeePayroll | null;
  setClickedEmployeePayroll: React.Dispatch<React.SetStateAction<EmployeePayroll | null>>;
}

const SignedIcon = () => {
  return (
    <Box
      sx={(theme) => ({
        '& path': {
          fill: theme.palette.stationTeal[500],
        },
      })}
    >
      <CircleTickCIcon20 />
    </Box>
  );
};
const UnSignedIcon = () => {
  return (
    <Box
      sx={(theme) => ({
        '& path': {
          fill: theme.palette.stationGray[200],
        },
      })}
    >
      <XCircleIcon20 />
    </Box>
  );
};
const PressedLaterIcon = () => {
  return (
    <Box
      sx={(theme) => ({
        '& path': {
          fill: theme.palette.stationYellow[500],
        },
      })}
    >
      <Tooltip
        arrow
        title='Pressed "Sign Later"'
        placement="top"
        slotProps={{
          tooltip: {
            sx: {
              bgcolor: theme.palette.stationGray[800],
              fontFamily: 'Inter',
              fontSize: theme.spacing(1.75),
              fontWeight: 500,
              lineHeight: theme.spacing(2.5),
              borderRadius: theme.spacing(1),
              padding: theme.spacing(1.5),
              gap: theme.spacing(1),
              '& .MuiTooltip-arrow': {
                color: theme.palette.stationGray[800],
              },
            },
          },
        }}
      >
        <OrangeCircleWarningIcon20 />
      </Tooltip>
    </Box>
  );
};

const getIcon = (state: string) => {
  if (state === SignedState.PRESSED_LATER) {
    return <PressedLaterIcon />;
  } else if (state === SignedState.NOT_SIGNED) {
    return <UnSignedIcon />;
  } else if (state === SignedState.SIGNED) {
    return <SignedIcon />;
  }
  return null; // Default case if no condition is met
};

export const PayrollList = memo(
  ({ employeePayrolls, selectedEmployeePayrolls, setselectedEmployeePayrolls }: PayrollTableProps) => {
    const removeEmployeePayroll = (id: number) => {
      setselectedEmployeePayrolls((prevState) => prevState.filter((employeePayroll) => employeePayroll.id !== id));
    };
    const getRowProps = (row: TableProps['data'][number], rowIndex: number): TableRowProps => {
      return {
        sx: { cursor: 'pointer', '& td': { position: 'relative' } },
      };
    };

    const columns: TableProps['columns'] = [
      {
        id: 'checkbox',
        label: (
          <Checkbox
            checked={selectedEmployeePayrolls.length === employeePayrolls.length && employeePayrolls.length > 0}
            color="secondary"
            onClick={() => {
              selectedEmployeePayrolls.length > 1
                ? setselectedEmployeePayrolls([])
                : setselectedEmployeePayrolls(employeePayrolls);
            }}
          ></Checkbox>
        ),
        minWidth: 50,
        align: 'center',
      },
      { id: 'name', label: 'NAME', minWidth: 200, align: 'left' },
      { id: 'rank', label: 'RANK', minWidth: 100, align: 'center' },
      { id: 'shift', label: 'SHIFT', minWidth: 100, align: 'center' },
      { id: 'employeeSigned', label: 'Employee Signed', minWidth: 100, align: 'center' },
      { id: 'managerOneApproved', label: 'APPR 1', minWidth: 100, align: 'center' },
      { id: 'managerTwoApproved', label: 'APPR 2', minWidth: 100, align: 'center' },
      { id: 'managerThreeApproved', label: 'APPR 3', minWidth: 100, align: 'center' },
    ];

    const data: TableProps['data'] = employeePayrolls.map((employeePayroll) => ({
      employeePayroll,
      checkbox: (
        <Checkbox
          checked={selectedEmployeePayrolls.includes(employeePayroll)}
          color="secondary"
          onClick={() =>
            selectedEmployeePayrolls.includes(employeePayroll)
              ? removeEmployeePayroll(employeePayroll.id)
              : setselectedEmployeePayrolls((prev) => [...prev, employeePayroll])
          }
        ></Checkbox>
      ),
      name: (
        <Box
          component={Link}
          to={`/app/payroll/employee/${employeePayroll.id}`}
          sx={(theme) => ({ display: 'flex', gap: theme.spacing(1), alignItems: 'center', textDecoration: 'none' })}
        >
          <LetterAvatar
            firstName={employeePayroll.employee.name}
            color={employeePayroll.employee.hasAdminPermission ? 'green' : 'lightblue'}
          />
          <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySRegular' })}>
            <Box>{employeePayroll.employee.name}</Box>
            <Box sx={(theme) => ({ color: theme.palette.stationGray[600] })}>{employeePayroll.employee.email}</Box>
          </Box>
        </Box>
      ),

      rank: (
        <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
          {employeePayroll.employee.rank?.name || 'N/A'}
        </Box>
      ),
      shift:
        employeePayroll.employee.plannedShiftDetails && employeePayroll.employee.plannedShiftDetails.length > 0 ? (
          <TeamBadge
            team={{
              name: employeePayroll.employee.plannedShiftDetails[0].teamName,
              color: employeePayroll.employee.plannedShiftDetails[0].shiftColor,
            }}
          />
        ) : (
          <TeamBadge team={{ name: 'Non-Shift', color: 'gray' }} />
        ),
      employeeSigned: getIcon(employeePayroll.signedState),
      managerOneApproved: employeePayroll.approvalState.managerOne.approved ? <SignedIcon /> : <UnSignedIcon />,
      managerTwoApproved: employeePayroll.approvalState.managerTwo.approved ? <SignedIcon /> : <UnSignedIcon />,
      managerThreeApproved: employeePayroll.approvalState.managerThree?.approved ? <SignedIcon /> : <UnSignedIcon />,
    }));

    data.forEach((row) => {
      columns.forEach((column) => {
        row[column.id] = (
          <>
            <Box
              component={Link}
              to={`/app/payroll/employee/${row['employeePayroll'].id}`}
              tabIndex={-1}
              sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            />
            {row[column.id]}
          </>
        );
      });
    });

    return <CustomTable columns={columns} data={data} getRowProps={getRowProps} />;
  },
);
