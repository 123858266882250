import { Box, Autocomplete, createFilterOptions, TextField, Dialog } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Button, ChevronDownIcon20, formatPhoneNumber, Input, PhoneInput } from '@stationwise/component-module';
import { isValidEmail } from '@stationwise/share-utils';
import { EmployeeInfoSectionProps } from '../EmployeeInfoSection';
import { usePatchRequest } from './PatchRequestProvider';

interface EditEmployeeInfoProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  employeeInfo: EmployeeInfoSectionProps;
}

export const EditEmployeeInfo = ({ showModal, setShowModal, employeeInfo }: EditEmployeeInfoProps) => {
  const initialState = {
    phone: employeeInfo.phone,
    email: employeeInfo.email,
    emergencyContact: employeeInfo.emergencyContactPhone,
    emergencyContactName: employeeInfo.emergencyContactName,
    relationship: employeeInfo.emergencyContactRelationship,
    optionalEmergencyContactName: employeeInfo.optionalEmergencyContactName,
    optionalEmergencyContactPhone: employeeInfo.optionalEmergencyContactPhone,
    optionalEmergencyContactRelationship: employeeInfo.optionalEmergencyContactRelationship,
  };
  const [employeeData, setEmployeeData] = useState(initialState);

  const { handlePatchRequest } = usePatchRequest();

  const handleFieldChange = (fieldName: string, value: string | number) => {
    setEmployeeData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const [showSecondContact, setShowSecondContact] = useState(employeeInfo.optionalEmergencyContactName !== null);

  const handleSave = () => {
    const data = {
      email: employeeData.email,
      emergencyContactRelationship: employeeData.relationship,
      emergencyContactName: employeeData.emergencyContactName,
      optionalEmergencyContactName: employeeData.optionalEmergencyContactName,
      optionalEmergencyContactRelationship: employeeData.optionalEmergencyContactRelationship,
      phone: formatPhoneNumber(employeeData.phone),
      emergencyContactPhone: formatPhoneNumber(employeeData.emergencyContact),
      optionalEmergencyContactPhone: formatPhoneNumber(employeeData.optionalEmergencyContactPhone),
    };
    handlePatchRequest(data);
    setShowModal(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEmployeeData(initialState);
  };

  interface RelationType {
    inputValue?: string;
    relation: string;
  }

  const relations: RelationType[] = [
    { relation: 'Mother' },
    { relation: 'Father' },
    { relation: 'Spouse' },
    { relation: 'Child' },
    { relation: 'Significant other' },
  ];

  const filter = createFilterOptions<RelationType>();

  const isPhoneInputDisabled = (phone: string | null) => {
    return !(phone === null || phone === '' || phone.length === 10);
  };

  const arePhonesEqual = (p1: string | null, p2: string | null) => {
    const isEffectivelyEmpty = (value: string | null) => value === null || value === '';
    if (isEffectivelyEmpty(p1) && isEffectivelyEmpty(p2)) {
      return true;
    }
    return p1 === p2;
  };

  const isDisabled = useMemo(() => {
    return (
      employeeData.email === '' ||
      (arePhonesEqual(formatPhoneNumber(employeeData.phone), employeeInfo.phone) &&
        employeeData.email === employeeInfo.email &&
        employeeData.emergencyContactName === employeeInfo.emergencyContactName &&
        arePhonesEqual(formatPhoneNumber(employeeData.emergencyContact), employeeInfo.emergencyContactPhone) &&
        employeeData.relationship === employeeInfo.emergencyContactRelationship &&
        employeeData.optionalEmergencyContactName === employeeInfo.optionalEmergencyContactName &&
        arePhonesEqual(
          formatPhoneNumber(employeeData.optionalEmergencyContactPhone),
          employeeInfo.optionalEmergencyContactPhone,
        ) &&
        employeeData.optionalEmergencyContactRelationship === employeeInfo.optionalEmergencyContactRelationship) ||
      // accept empty or full length phone number '+1' excluded
      isPhoneInputDisabled(employeeData.phone) ||
      isPhoneInputDisabled(employeeData.emergencyContact) ||
      isPhoneInputDisabled(employeeData.optionalEmergencyContactPhone) ||
      (!!employeeData.email && !isValidEmail(employeeData.email))
    );
  }, [employeeData, employeeInfo]);

  return (
    <Dialog open={showModal} onClose={handleModalClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Employee info
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Phone
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            <PhoneInput handleChange={(value) => handleFieldChange('phone', value)} phone={employeeData.phone} />
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Email
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            <Input
              error={employeeData.email === ''}
              type="email"
              value={employeeData.email}
              onChange={(event) => {
                handleFieldChange('email', event.currentTarget.value);
              }}
            />
          </Box>
          {employeeData.email && !isValidEmail(employeeData.email) && (
            <Box
              sx={(theme) => ({
                typography: 'bodyXSMedium',
                textAlign: 'left',
                paddingTop: theme.spacing(0.5),
                color: theme.palette.stationRed[500],
              })}
            >
              * Enter a valid email
            </Box>
          )}

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Emergency contact name
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            <Input
              value={employeeData.emergencyContactName ?? ''}
              onChange={(event) => {
                handleFieldChange('emergencyContactName', event.currentTarget.value);
              }}
            />
          </Box>

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(1),
              width: '100%',
            })}
          >
            <Box
              sx={(theme) => ({
                marginRight: theme.spacing(1),
                width: '100%',
              })}
            >
              <Box
                sx={(theme) => ({
                  typography: 'bodySMedium',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                })}
              >
                Emergency contact phone
              </Box>

              <Box
                sx={(theme) => ({
                  py: theme.spacing(1),
                })}
              >
                <PhoneInput
                  handleChange={(value) => handleFieldChange('emergencyContact', value)}
                  phone={employeeData.emergencyContact}
                />
              </Box>
            </Box>

            <Box
              sx={(theme) => ({
                width: '100%',
                marginLeft: theme.spacing(1),
              })}
            >
              <Box
                sx={(theme) => ({
                  typography: 'bodySMedium',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                })}
              >
                Relationship
              </Box>

              <Box
                sx={(theme) => ({
                  py: theme.spacing(1),
                  width: '100%',
                })}
              >
                <Autocomplete
                  value={employeeData.relationship ?? ''}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      handleFieldChange('relationship', newValue);
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      handleFieldChange('relationship', newValue.inputValue);
                    } else {
                      handleFieldChange('relationship', newValue.relation);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.relation);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        relation: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={relations}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.relation;
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={props.key}>
                      {option.relation}
                    </li>
                  )}
                  disableClearable
                  forcePopupIcon={true}
                  popupIcon={<ChevronDownIcon20 />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        '& .MuiOutlinedInput-root': { padding: '2.5px 3px 2.5px 12px' },
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          {showSecondContact && (
            <Box>
              <Box
                sx={(theme) => ({
                  typography: 'bodySMedium',
                  textAlign: 'left',
                  paddingTop: theme.spacing(2),
                  color: theme.palette.stationGray[700],
                })}
              >
                Emergency contact name #2
              </Box>

              <Box
                sx={(theme) => ({
                  py: theme.spacing(1),
                })}
              >
                <Input
                  value={employeeData.optionalEmergencyContactName ?? ''}
                  onChange={(event) => {
                    handleFieldChange('optionalEmergencyContactName', event.currentTarget.value);
                  }}
                />
              </Box>

              <Box
                sx={(theme) => ({
                  justifyContent: 'space-between',
                  display: 'flex',
                  mt: theme.spacing(1),
                  width: '100%',
                })}
              >
                <Box
                  sx={(theme) => ({
                    marginRight: theme.spacing(1),
                    width: '100%',
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      typography: 'bodySMedium',
                      textAlign: 'left',
                      paddingTop: theme.spacing(2),
                      color: theme.palette.stationGray[700],
                    })}
                  >
                    Emergency contact phone #2
                  </Box>

                  <Box
                    sx={(theme) => ({
                      py: theme.spacing(1),
                    })}
                  >
                    <PhoneInput
                      handleChange={(value) => handleFieldChange('optionalEmergencyContactPhone', value)}
                      phone={employeeData.optionalEmergencyContactPhone}
                    />
                  </Box>
                </Box>

                <Box
                  sx={(theme) => ({
                    width: '100%',
                    marginLeft: theme.spacing(1),
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      typography: 'bodySMedium',
                      textAlign: 'left',
                      paddingTop: theme.spacing(2),
                      color: theme.palette.stationGray[700],
                    })}
                  >
                    Relationship #2
                  </Box>

                  <Box
                    sx={(theme) => ({
                      py: theme.spacing(1),
                      width: '100%',
                    })}
                  >
                    <Autocomplete
                      value={employeeData.optionalEmergencyContactRelationship ?? ''}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          handleFieldChange('optionalEmergencyContactRelationship', newValue);
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          handleFieldChange('optionalEmergencyContactRelationship', newValue.inputValue);
                        } else {
                          handleFieldChange('optionalEmergencyContactRelationship', newValue.relation);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.relation);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            inputValue,
                            relation: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={relations}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.relation;
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={props.key}>
                          {option.relation}
                        </li>
                      )}
                      disableClearable
                      forcePopupIcon={true}
                      popupIcon={<ChevronDownIcon20 />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            '& .MuiOutlinedInput-root': { padding: '2.5px 3px 2.5px 12px' },
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {!showSecondContact && (
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                paddingTop: theme.spacing(2),
                color: theme.palette.stationBlue[700],
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.stationBlue[900],
                },
              })}
              onClick={() => setShowSecondContact(true)}
            >
              + add 2nd emergency contact
            </Box>
          )}

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button
              buttonType="tertiary"
              variant="contained"
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              onClick={() => handleModalClose()}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Cancel
              </Box>
            </Button>
            <Button
              sx={(theme) => ({
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                borderRadius: theme.spacing(0.75),
                '&:hover': { backgroundColor: 'black', boxShadow: 'none' },
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              })}
              buttonType="tertiary"
              variant="contained"
              disableFocusRipple={true}
              disableTouchRipple={true}
              onClick={() => handleSave()}
              disabled={isDisabled}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Save
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
