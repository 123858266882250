import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { InstantHireView } from '@stationwise/share-types';
interface UseFetchInstantHiresProps {
  selectedDate: string;
  skip?: boolean;
}
interface UseFetchInstantHiresResult {
  isLoading: boolean;
  isError: boolean;
  data: InstantHireView[];
  forceRefetch: () => void;
}
export const useFetchInstantHires = ({ selectedDate, skip }: UseFetchInstantHiresProps): UseFetchInstantHiresResult => {
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<InstantHireView[]>([]);
  useEffect(() => {
    if (skip) {
      return;
    }
    const fetchInstantHires = async () => {
      try {
        setIsLoading(true);
        setIsError(false);
        setData([]);
        const response = await client.get('/instant-hire/', {
          params: {
            shift_date: selectedDate,
          },
        });
        setData(response.data);
      } catch (error) {
        setIsError(true);
        captureException(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchInstantHires();
  }, [selectedDate, fetchNoRequest, skip]);

  const forceRefetch = () => {
    setFetchNoRequest((prev) => prev + 1);
  };

  return {
    isLoading,
    isError,
    data,
    forceRefetch,
  };
};
