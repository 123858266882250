import { Box, Button, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { TeamFlag, NumberListIcon16, useLoadedAuthUserContext } from '@stationwise/component-module';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { capitalize } from '@stationwise/share-utils';

interface PersonalStaffingListPositionProps {
  selectedStaffingList: ListFieldsStaffingList | undefined;
  onClick: () => void;
}

export const PersonalStaffingListPosition = (props: PersonalStaffingListPositionProps) => {
  const theme = useTheme();
  const { state: authUserState } = useLoadedAuthUserContext();

  const { meItem, meTeam, meTeamPosition } = useMemo(() => {
    const items = props.selectedStaffingList?.items || [];
    const meItem = items.find((item) => item.employee.id === authUserState.employee.id);

    let meTeamPosition = -1;
    const meTeam = meItem?.employee.team;
    if (meTeam) {
      meTeamPosition = items.filter((item) => item.employee.team?.id === meTeam.id && item.order < meItem.order).length + 1;
    }

    return { meItem, meTeam, meTeamPosition };
  }, [props.selectedStaffingList?.items, authUserState.employee.id]);

  return (
    <Box sx={{ border: `1px solid ${theme.palette.stationGray[200]}`, borderRadius: 2, p: 1.5 }}>
      {meTeam && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TeamFlag color={meTeam.color} />
          <Box sx={{ flex: 1, typography: 'bodyMMedium' }}>{`${capitalize(meTeam.name)} position`}</Box>
          <Box sx={{ typography: 'bodyMSemibold' }}>{meTeamPosition}</Box>
        </Box>
      )}
      {meItem && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
          <Box
            sx={(theme) => ({
              border: `1px solid ${theme.palette.stationGray[200]}`,
              borderRadius: '50%',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '24px',
              width: '24px',
            })}
          >
            <NumberListIcon16 />
          </Box>
          <Box sx={{ typography: 'bodyMMedium', mr: -0.5 }}>Overall position</Box>
          <Box sx={{ flex: 1 }}>
            <Button
              data-cy="show-my-staffing-list-position-button"
              variant="outlined"
              color="inherit"
              size="medium"
              onClick={props.onClick}
              sx={{ borderRadius: '9999px', p: '5px 15px', textTransform: 'none' }}
            >
              Show
            </Button>
          </Box>
          <Box sx={{ typography: 'bodyMSemibold' }}>{meItem.order}</Box>
        </Box>
      )}
      {!meItem && (
        <Box sx={{ display: 'flex', py: 2.25, justifyContent: 'center', typography: 'bodyMMedium' }}>
          {(() => {
            if (!props.selectedStaffingList) {
              return 'There are no lists';
            } else if (!props.selectedStaffingList.items?.length) {
              return 'This list is empty. You are not in this list';
            } else {
              return 'You are not in this list';
            }
          })()}
        </Box>
      )}
    </Box>
  );
};
