import { Box, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { TeamFlag, RankBadge, CertBadge } from '@stationwise/component-module';
import { RosterEmployee } from '@stationwise/share-types';

interface ShiftTradeHeaderProps {
  employee: RosterEmployee;
  start: Date;
  end: Date;
}

export const ShiftTradeHeader = ({ employee, start, end }: ShiftTradeHeaderProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        boxShadow: '0px 10px 15px -3px rgba(10, 14, 22, 0.10), 0px 4px 6px -2px rgba(10, 14, 22, 0.05)',
        p: theme.spacing(2, 3),
      }}
    >
      <Box sx={(theme) => ({ borderBottom: `solid 1px ${theme.palette.stationGray[100]}` })}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between', gap: 1 }}>
          <Box sx={{ minWidth: '50%', display: 'flex', alignItems: 'center', gap: 1 }}>
            <RankBadge rank={employee.rank} />
            <Typography variant="bodyMMedium" sx={{ color: theme.palette.stationGray[900] }}>
              {employee.name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
            {employee.certifications.map((cert, index) => (
              <CertBadge key={`${index}-${cert.code}`} cert={cert} />
            ))}
          </Box>
        </Box>
        <Box display="flex" sx={{ py: theme.spacing(1) }}>
          <TeamFlag color={employee.team?.color} />
          <Typography
            variant="bodySMedium"
            sx={{
              color: theme.palette.stationGray[500],
              ml: theme.spacing(1),
              pt: '3px',
            }}
          >
            {employee.team?.name}
          </Typography>
        </Box>
      </Box>

      <Box sx={(theme) => ({ mt: theme.spacing(1.5), typography: 'bodyMRegular', display: 'flex' })}>
        <Box
          sx={(theme) => ({
            marginRight: theme.spacing(2),
          })}
        >
          {format(start, 'M/d/yy')}
        </Box>
        <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
          {format(start, 'HHmm')}
          {end > start && ' - '}
          {end > start && format(end, 'HHmm')}
        </Box>
      </Box>
    </Box>
  );
};
