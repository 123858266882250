import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { isAxiosError } from 'axios';
import { format } from 'date-fns';
import { SnackbarService, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { CreateButton } from '../../../../components/Common/CreateButton';
import { ShiftTemplateContextProvider } from '../contexts/ShiftTemplateContext';
import { ShiftTemplateSummary } from '../hooks/useFetchCurrentShiftTemplate';
import { useShiftTemplate } from '../hooks/useShiftTemplate';
import { getUpdatedPositionIds } from '../positions';
import { ShiftTemplateBoard } from './ShiftTemplateBoard';
import { StationDialog } from './Station/StationDialog';
import { TopBar } from './TopBar';

interface ISaveShiftTemplatePayload {
  employeeId: number | null;
  apparatusId: number | null;
  positionId: number | null;
  startTime: number;
  endTime: number;
  isTemporary: boolean;
  payCodes: number[];
  detailCodes?: number[];
}

export type shiftTemplateAction = 'publish' | 'discard';

interface ShiftTemplateContentProps {
  shiftTemplateSummary: ShiftTemplateSummary;
  forceRefetch: (teamId: string, battalionId: string) => void;
  setIsLoading: (loading: boolean) => void;
}

export const ShiftTemplateContent = ({ shiftTemplateSummary, forceRefetch, setIsLoading }: ShiftTemplateContentProps) => {
  const shiftTemplate = useShiftTemplate({
    shiftTemplateSummary: shiftTemplateSummary,
  });
  const { isStationDialogOpen, setIsStationDialogOpen, setSelectedStation, addAssignmentForNewlyCreatedPosition } = shiftTemplate;
  const shiftTemplateInfo = shiftTemplate.getShiftTemplateInfo();

  const lastSaved =
    shiftTemplateInfo.updatedAt && shiftTemplateInfo.updatedBy
      ? `Last saved by ${shiftTemplateInfo.updatedBy} on ${format(shiftTemplateInfo.updatedAt, 'MMM d')} at ${format(shiftTemplateInfo.updatedAt, 'HH:mm')}`
      : '';

  const { dispatch } = useLoadedDepartmentInfoContext();

  const changeAllStationCardsToShiftTemplateSummary = () => {
    let payload: ISaveShiftTemplatePayload[] = [];

    // Map each position to add to payload
    const { allStationCards, shiftDuration } = shiftTemplate.shiftTemplateHelper;
    Array.from(allStationCards.values()).forEach((station) => {
      // Not Temporary Positions
      station.apparatuses.forEach((apparatus) => {
        apparatus.positions
          .filter((position) => !position.isTemporary)
          .forEach((position) => {
            if ((station.isNewlyCreated && apparatus.isNewlyCreated) || position.isNewlyCreated) {
              addAssignmentForNewlyCreatedPosition(position, apparatus, station);
            } else if (position.employees.length > 0) {
              const employeeInPosition = position.employees.map((employee) => {
                return station.stationId === 'floater-station'
                  ? {
                      employeeId: Number(employee.id) || null,
                      apparatusId: null,
                      positionId: null,
                      startTime: differenceInUTCMinutes(employee.startDateTime, shiftDuration.startTime),
                      endTime: differenceInUTCMinutes(employee.endDateTime, shiftDuration.startTime),
                      isTemporary: false,
                      payCodes: employee.payCodes.map((pc) => pc.id),
                    }
                  : {
                      employeeId: employee.id ? Number(employee.id) : null,
                      apparatusId: Number(apparatus.id),
                      positionId: Number(position.id),
                      startTime: differenceInUTCMinutes(employee.startDateTime, shiftDuration.startTime),
                      endTime: differenceInUTCMinutes(employee.endDateTime, shiftDuration.startTime),
                      isTemporary: false,
                      payCodes: employee.payCodes.map((pc) => pc.id),
                      detailCodes: employee.detailCodes.map((detailCode) => detailCode.id),
                    };
              });

              payload = [...payload, ...employeeInPosition];
            } else {
              payload.push({
                employeeId: null,
                apparatusId: Number(apparatus.id),
                positionId: Number(position.id),
                startTime: 0,
                endTime: 24 * 60,
                isTemporary: false,
                payCodes: [],
              });
            }
          });
      });

      //Temporary Positions
      station.apparatuses.forEach((apparatus) => {
        apparatus.positions
          .filter((position) => position.isTemporary)
          .forEach((position) => {
            if (position.isNewlyCreated || apparatus.isNewlyCreated || station.isNewlyCreated) {
              addAssignmentForNewlyCreatedPosition(position, apparatus, station);
            } else {
              const employee = position.employees[0];
              payload.push({
                employeeId: employee.id ? Number(employee.id) : null,
                apparatusId: Number(apparatus.id),
                positionId: null,
                startTime: differenceInUTCMinutes(employee.startDateTime, shiftDuration.startTime),
                endTime: differenceInUTCMinutes(employee.endDateTime, shiftDuration.startTime),
                isTemporary: true,
                payCodes: employee.payCodes.map((pc) => pc.id),
                detailCodes: employee.detailCodes.map((detailCode) => detailCode.id),
              });
            }
          });
      });
    });
    return payload;
  };

  const saveChanges = async () => {
    try {
      setIsLoading(true);
      const assignments = changeAllStationCardsToShiftTemplateSummary();
      // we need to get all the positions that have been updated to bypass the initial backend validation since certification requirements may be different now
      const updatedPositionIds = getUpdatedPositionIds(shiftTemplate.manageStationsPayload);

      const response = await client.post('/shift/shift-template/', {
        assignments,
        shiftTemplateId: shiftTemplate.getShiftTemplateInfo().id,
        ccVersion: shiftTemplate.getShiftTemplateInfo().ccVersion,
        teamId: shiftTemplate.currentTeams[0].id,
        battalionId: shiftTemplate.currentBattalion.id,
        updatedApparatusModes: shiftTemplate.updatedApparatusModes,
        manageStationsPayload: shiftTemplate.manageStationsPayload,
        updatedPositionIds: updatedPositionIds,
      });
      forceRefetch(shiftTemplate.currentTeams[0].id.toString(), shiftTemplate.currentBattalion.id.toString());
      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      const message = isAxiosError(error) ? error.response?.data?.message || error.message : 'Error saving changes';

      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
      setIsLoading(false);
    }
  };

  const executeAction = async (action: shiftTemplateAction) => {
    try {
      setIsLoading(true);

      const response = await client.patch(`/shift/shift-template/${shiftTemplate.getShiftTemplateInfo().id}/`, {
        isPublish: action === 'publish',
        ccVersion: shiftTemplate.getShiftTemplateInfo().ccVersion,
      });

      forceRefetch(shiftTemplate.currentTeams[0].id.toString(), shiftTemplate.currentBattalion.id.toString());

      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });

      dispatch({ type: 'REFETCH_DEPARTMENT_INFO' }); // will update the stations in each DeparmentInfo.battalions to include the new ones
    } catch (error) {
      setIsLoading(false);
      const message = isAxiosError(error)
        ? error.response?.data?.message || error.message
        : `Error ${action === 'publish' ? 'publishing' : 'discarding'} shift template`;

      SnackbarService.notify({ content: message, severity: 'error' });
      captureException(error);
    }
  };

  const handleAddStation = () => {
    setSelectedStation(null);
    setIsStationDialogOpen(true);
  };

  return (
    <ShiftTemplateContextProvider shiftTemplate={shiftTemplate}>
      <Box>
        <TopBar
          saveChanges={saveChanges}
          forceRefetch={forceRefetch}
          lastSaved={lastSaved}
          executeAction={executeAction}
          isCurrentlyActive={shiftTemplate.getShiftTemplateInfo().isCurrentlyActive}
        />
      </Box>
      <ShiftTemplateBoard />
      <CreateButton onClick={handleAddStation} title="Add station" />
      {isStationDialogOpen && <StationDialog />}
    </ShiftTemplateContextProvider>
  );
};
