import { Box, SxProps, Theme, alpha, useTheme } from '@mui/material';
import { Certification } from '@stationwise/share-types';
import { getCertColors } from '../../utils';

interface CertBadgeProps {
  cert: Pick<Certification, 'code' | 'color'>;
  dark?: boolean;
  sx?: SxProps<Theme>;
}

export const CertBadge = (props: CertBadgeProps) => {
  const theme = useTheme();
  const { cert, dark = false, sx } = props;

  return (
    <Box
      sx={[
        {
          ...getCertColors({ theme, color: cert.color, dark }),
          borderRadius: '4px',
          boxShadow: `inset 0 0 0 1px ${dark ? alpha(theme.palette.common.white, 0.3) : alpha(theme.palette.common.black, 0.12)}`,
          display: 'inline-flex',
          padding: '2px 4px',
          typography: 'tagline1',
          textWrap: 'nowrap',
        },
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
    >
      {cert.code}
    </Box>
  );
};
