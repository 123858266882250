import { RosterCreateTimeOffPayload, RosterEditableEmployeeSplit } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { copyBoardWithNewApparatus, getPositionEmployeeSortKey, makeTemporaryPosition, sortTemporaryPositions } from './board';
import { checkIsShift, checkIsEvent, setEmployeeActiveId } from './id';
import { removeEmployeeAvailability } from './removeEmployeeAvailability';
import { IShiftSummaryHelper } from './types';
import { createUnassignedEmployee } from './unassigned';

export const splitEmployee = (
  shiftSummaryHelper: IShiftSummaryHelper,
  employeeId: string,
  splits: RosterEditableEmployeeSplit[],
) => {
  let newShiftSummaryHelper = removeEmployeeAvailability({
    shiftSummaryHelper,
    employeeId,
    startTime: shiftSummaryHelper.shiftDuration.startTime,
    endTime: shiftSummaryHelper.shiftDuration.endTime,
    checkIsPositionExcluded: (p) => !checkIsShift(p),
  });

  const newShiftSummarySplits = splits
    .filter((split) => !(split.reference.type === 'TIME_OFF_REQUEST' && split.reference.id <= 0))
    .map((split) => ({
      startTime: differenceInUTCMinutes(split.startDateTime, shiftSummaryHelper.shiftDuration.startTime),
      endTime: differenceInUTCMinutes(split.endDateTime, shiftSummaryHelper.shiftDuration.startTime),
      reference: split.reference,
    }));
  newShiftSummaryHelper.employeeSplits = new Map(newShiftSummaryHelper.employeeSplits);
  newShiftSummaryHelper.employeeSplits.set(employeeId, newShiftSummarySplits);

  splits.forEach((split) => {
    if (split.reference.type !== 'ASSIGNMENT' || !split.employee || checkIsEvent(split.employee)) {
      return;
    }

    const { apparatus, position } = split.reference;
    const newEmployee = setEmployeeActiveId({
      ...split.employee,
      startDateTime: split.startDateTime,
      endDateTime: split.endDateTime,
    });
    if (!apparatus) {
      newShiftSummaryHelper = createUnassignedEmployee(newShiftSummaryHelper, newEmployee);
      return;
    }

    const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(newShiftSummaryHelper, `${apparatus.id}`);
    const newPosition = newApparatus.positions.find((p) => p.id === `${position?.id}`);
    newShiftSummaryHelper.allStationCards = newAllStationCards;
    if (newPosition) {
      newPosition.employees.push(newEmployee);
      newPosition.employees.sort(getPositionEmployeeSortKey);
    } else {
      const newTemporaryPosition = { ...makeTemporaryPosition(newApparatus, newEmployee), employees: [newEmployee] };
      newApparatus.positions = sortTemporaryPositions([...newApparatus.positions, newTemporaryPosition]);
    }
  });

  return newShiftSummaryHelper;
};

export const makeCreateTimeOffPayloads = (
  shiftSummaryHelper: IShiftSummaryHelper,
  employeeId: string,
  splits: RosterEditableEmployeeSplit[],
) => {
  const payloads: RosterCreateTimeOffPayload[] = [];
  splits.forEach((split) => {
    if (split.initialReference.type === 'ASSIGNMENT' && split.reference.type === 'TIME_OFF_REQUEST') {
      payloads.push({
        employeeId,
        positionId: split.initialReference.position ? `${split.initialReference.position.id}` : null,
        payCode: split.reference.payCode.code,
        startTime: differenceInUTCMinutes(split.startDateTime, shiftSummaryHelper.shiftDuration.startTime),
        endTime: differenceInUTCMinutes(split.endDateTime, shiftSummaryHelper.shiftDuration.startTime),
        employeeTradeId: split.employee?.trade?.id,
      });
    }
  });

  const mergedPayloads: RosterCreateTimeOffPayload[] = [];
  payloads.forEach((payload) => {
    const prevPayload = mergedPayloads[mergedPayloads.length - 1];
    if (prevPayload && prevPayload.payCode === payload.payCode && payload.startTime <= prevPayload.endTime) {
      prevPayload.endTime = payload.endTime;
    } else {
      mergedPayloads.push({ ...payload });
    }
  });

  return mergedPayloads;
};
