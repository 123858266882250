import { Box } from '@mui/material';
import { HireBackCard } from '@stationwise/component-module';
import { DetailFieldsStaffingList } from '@stationwise/share-types';

export const OvertimeList = ({ overtimeInfo }: { overtimeInfo: DetailFieldsStaffingList }) => {
  return (
    <>
      {overtimeInfo.items.map((item) => (
        <Box
          key={item.employee.id}
          sx={(theme) => ({
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
            border: `solid 1px ${theme.palette.stationGray[50]}`,
          })}
        >
          <HireBackCard
            rank={item.employee.rank}
            nameLabel={item.employee.name}
            certs={item.employee.certifications}
            number={item.order}
            partialHoursOt={`${item.attributes.overtimeAccHours ?? ''}`}
            lastShiftOt={item.attributes.lastThreeOvertimes?.[0]?.date ?? ''}
            timeAsString={item.attributes.lastThreeOvertimes?.[0]?.staffedAt ?? ''}
            type="border"
            borderColor={item.employee.team?.color || 'gray'}
          />
        </Box>
      ))}
    </>
  );
};
