import { Box, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { selectStyle, ChevronDownIcon16, TeamFlag } from '@stationwise/component-module';
import { Team } from '@stationwise/share-types';
import { useShiftTemplateContext } from '../contexts/ShiftTemplateContext';

interface TeamSelectProps {
  teams: Team[];
  onChange: (selectedOption: string) => void;
  selectedTeam: string;
}

export const TeamSelect = ({ teams, onChange, selectedTeam }: TeamSelectProps) => {
  const { userHasMadeChanges } = useShiftTemplateContext();

  return (
    <Tooltip
      title="Save or cancel your changes before selecting another team"
      disableFocusListener={!userHasMadeChanges}
      disableHoverListener={!userHasMadeChanges}
    >
      <Select
        value={selectedTeam}
        onChange={(event: SelectChangeEvent) => onChange(event.target.value as string)}
        variant="standard"
        IconComponent={ChevronDownIcon16}
        disableUnderline
        sx={[(theme) => selectStyle(theme, '', undefined)]}
        disabled={userHasMadeChanges}
      >
        {teams.map((team) => {
          return (
            <MenuItem value={team.id.toString()} key={team.id}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={(theme) => ({
                    opacity: userHasMadeChanges ? 0.5 : 'unset',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    color: theme.palette.stationGray[900],
                    typography: 'bodyMRegular',
                  })}
                >
                  <TeamFlag color={team.color} />
                  {team.name}
                </Box>
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </Tooltip>
  );
};
