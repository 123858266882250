import { EventInput } from '@fullcalendar/core';
import { client } from '@stationwise/share-api';
import { DetailFieldsStaffingList } from '@stationwise/share-types';
import { OvertimeCard } from './OvertimeCard';

export const Overtime = ({ shift }: { shift: EventInput }) => {
  const displayOvertimeInfo = async (dateStr: string): Promise<DetailFieldsStaffingList> => {
    const response = await client.get(`/staffing-list/staffing-lists/${shift.id}/?lookup=mark`, {
      params: { date: dateStr },
    });
    return response.data;
  };

  return <OvertimeCard key={shift.id} shift={shift} displayOvertimeInfo={displayOvertimeInfo} />;
};
