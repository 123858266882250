import {
  Candidate,
  RosterApparatus,
  RosterDataSource,
  RosterEmployee,
  RosterPosition,
  RosterShiftDuration,
} from '@stationwise/share-types';
import {
  copyBoardWithNewApparatus,
  formatShiftDuration,
  getDepartmentPayCodes,
  getMissingCertifications,
  IShiftSummaryHelper,
  getOverrideEmployeePositionFields,
  makePlaceholderEmployee,
  setEmployeeActiveId,
} from '@stationwise/shift-summary-helper';
import { buildFloaterPosition } from './floaters';

const checkIsAssignmentValid = (shiftTemplateHelper: IShiftSummaryHelper, position: RosterPosition, assignee: RosterEmployee) => {
  if (
    new Date(position.startDateTime) < new Date(assignee.startDateTime) ||
    new Date(position.endDateTime) > new Date(assignee.endDateTime)
  ) {
    return {
      canOverride: false,
      message: `This person is not fully available for this position.`,
      secondaryMessage: 'Try assigning another employee.',
    };
  }
  for (const station of shiftTemplateHelper.allStationCards.values()) {
    for (const apparatus of station.apparatuses) {
      for (const position of apparatus.positions) {
        for (const employee of position.employees) {
          if (employee.id && employee.id === assignee.id) {
            if (employee.startDateTime < assignee.endDateTime && employee.endDateTime > assignee.startDateTime) {
              return {
                canOverride: false,
                message: `This person has an overlapping position in this team.`,
                secondaryMessage: `The ${apparatus.name && !position.isTemporary ? position.rank.name : ''} assignment on ${apparatus.name || 'floaters'} at ${formatShiftDuration({ startTime: employee.startDateTime, endTime: employee.endDateTime })} must be removed in order to create a new one.`,
              };
            }
          }
        }
      }
    }
  }

  const missingCertifications = getMissingCertifications(position.certifications, assignee.certifications);
  if (missingCertifications.length > 0) {
    const missingCertificationCodes = missingCertifications.map((cert) => cert.code).join(', ');
    return {
      message: `This person is missing ${missingCertificationCodes} certification and is unable to perform this role.`,
      secondaryMessage: 'Please, staff a qualified person.',
      canOverride: false,
    };
  }

  return undefined;
};

export const assignEmployee = (
  shiftTemplateHelper: IShiftSummaryHelper,
  apparatus: RosterApparatus,
  position: RosterPosition,
  candidate: Candidate,
  activeEmployee?: RosterEmployee | null,
) => {
  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftTemplateHelper, apparatus.id);

  const newPosition = newApparatus.positions.find((p) => p.id === position.id);

  if (newPosition) {
    const newEmployee = makeRosterEmployeeFromCandidate(shiftTemplateHelper, candidate, newPosition, activeEmployee);

    if (newPosition.employees.length === 0) {
      newPosition.employees.push(newEmployee);
    } else {
      const activeEmployeeIndex = newPosition.employees.findIndex((employee) => employee.activeId === newEmployee.activeId);
      if (activeEmployeeIndex) {
        newPosition.employees[activeEmployeeIndex] = newEmployee;
      }
    }

    const error = checkIsAssignmentValid(shiftTemplateHelper, newPosition, newEmployee);

    const newShiftTemplateHelper = error
      ? { ...shiftTemplateHelper }
      : { ...shiftTemplateHelper, allStationCards: newAllStationCards };

    return { newShiftTemplateHelper, newEmployee, assignmentError: error };
  }
  throw new Error('Cannot assign employee');
};

export const unassignEmployee = (
  shiftTemplateHelper: IShiftSummaryHelper,
  apparatus: RosterApparatus,
  position: RosterPosition,
  assignee: RosterEmployee,
) => {
  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftTemplateHelper, apparatus.id);

  if (newApparatus.id === 'floater-apparatus' || position.isTemporary) {
    // when unassigning a floater or excess capacity we remove the card completely
    newApparatus.positions = newApparatus.positions.filter((p) => p.id !== position.id);
  } else {
    const newPosition = newApparatus.positions.find((p) => p.id === position.id);

    const activeEmployeeIndex = newPosition?.employees.findIndex((e) => e.activeId === assignee.activeId);

    if (!newPosition || typeof activeEmployeeIndex === 'undefined' || activeEmployeeIndex < 0) {
      throw new Error('Cannot unassign employee');
    }

    // full time position
    if (newPosition.employees.length === 1) {
      newPosition.employees = [];
    } else {
      newPosition.employees[activeEmployeeIndex] = makePlaceholderEmployee(newPosition, {
        ...newPosition.employees[activeEmployeeIndex],
      });
    }
  }

  const newShiftTemplateHelper = { ...shiftTemplateHelper, allStationCards: newAllStationCards };

  return { newShiftTemplateHelper };
};

export const addExcessCapacityToApparatus = (
  shiftTemplateHelper: IShiftSummaryHelper,
  apparatus: RosterApparatus,
  candidate: Candidate,
) => {
  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftTemplateHelper, apparatus.id);
  const isFloaterApparatus = newApparatus.id === 'floater-apparatus';
  const newPosition = buildPositionForExtraEmployee(candidate, shiftTemplateHelper.shiftDuration, isFloaterApparatus);

  const newEmployee = makeRosterEmployeeFromCandidate(shiftTemplateHelper, candidate, newPosition, null);

  const error = checkIsAssignmentValid(shiftTemplateHelper, newPosition, newEmployee);

  newPosition.employees.push(newEmployee);
  newApparatus.positions = [...newApparatus.positions, newPosition];

  const newActive = { employee: newEmployee };
  const newShiftTemplateHelper = error
    ? { ...shiftTemplateHelper }
    : { ...shiftTemplateHelper, newActive, allStationCards: newAllStationCards };
  return { newShiftTemplateHelper, assignmentError: error };
};

const makeRosterEmployeeFromCandidate = (
  { departmentInfo }: IShiftSummaryHelper,
  candidate: Candidate,
  newPosition: RosterPosition,
  activeEmployee?: RosterEmployee | null,
): RosterEmployee => {
  return setEmployeeActiveId({
    id: `${candidate.id}`,
    dataSource: newPosition.dataSource,
    name: candidate.name,
    rank: candidate.rank,
    certifications: candidate.certifications,
    team: candidate.team,
    defaults: candidate.defaults,
    startDateTime: activeEmployee ? activeEmployee.startDateTime : candidate.startDateTime || newPosition.startDateTime,
    endDateTime: activeEmployee ? activeEmployee.endDateTime : candidate.endDateTime || newPosition.endDateTime,
    payCodes: getDepartmentPayCodes(departmentInfo, [candidate.defaults.regularAssignmentPayCode]),
    detailCodes: [],
    ...getOverrideEmployeePositionFields(),
  });
};

export const buildPositionForExtraEmployee = (
  newEmployee: Candidate,
  shiftDuration: RosterShiftDuration,
  isFloaterApparatus: boolean,
): RosterPosition => {
  return {
    id: isFloaterApparatus ? `floater-${newEmployee.id}` : `excess-capacity-${newEmployee.id}`,
    dataSource: isFloaterApparatus ? RosterDataSource.FLOATER : RosterDataSource.STATION,
    startDateTime: newEmployee.startDateTime || shiftDuration.startTime,
    endDateTime: newEmployee.endDateTime || shiftDuration.endTime,
    employees: [],
    certifications: newEmployee.certifications,
    driver: false,
    rank: newEmployee.rank,
    isTemporary: !isFloaterApparatus,
  };
};

export const getCurrentAssignations = (shiftTemplateHelper: IShiftSummaryHelper) => {
  const currentAssignations = [];

  for (const station of shiftTemplateHelper.allStationCards.values()) {
    for (const apparatus of station.apparatuses) {
      for (const position of apparatus.positions) {
        for (const employee of position.employees) {
          if (employee.id) {
            currentAssignations.push(employee);
          }
        }
      }
    }
  }

  return currentAssignations;
};

export const sendApparatusEmployeesToFloaterStation = (shiftTemplateHelper: IShiftSummaryHelper, apparatus: RosterApparatus) => {
  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftTemplateHelper, apparatus.id);
  const floaterApparatus = newAllStationCards.get('floater-station')?.apparatuses[0];

  const newFloaterPositions: RosterPosition[] = [];

  newApparatus.positions.forEach((position) => {
    if (position.employees.length === 1) {
      newFloaterPositions.push(buildFloaterPosition(shiftTemplateHelper, position.employees[0]));
      position.employees = [];
    } else {
      position.employees.forEach((employee, index) => {
        if (employee.id) {
          newFloaterPositions.push(buildFloaterPosition(shiftTemplateHelper, employee));
          position.employees[index] = makePlaceholderEmployee(position, employee);
        }
      });
    }
  });
  // make sure to remove completely excess capacity positions
  newApparatus.positions = newApparatus.positions.filter((p) => !p.isTemporary);

  if (floaterApparatus) {
    floaterApparatus.positions = floaterApparatus.positions.concat(newFloaterPositions);
  }
  const updatedShiftTemplateHelper = { ...shiftTemplateHelper, allStationCards: newAllStationCards };

  return { updatedShiftTemplateHelper };
};

export const sendPositionEmployeesToFloaterStation = (
  shiftTemplateHelper: IShiftSummaryHelper,
  position: RosterPosition,
  apparatus: RosterApparatus,
) => {
  const { newAllStationCards, newApparatus } = copyBoardWithNewApparatus(shiftTemplateHelper, apparatus.id);
  const floaterApparatus = newAllStationCards.get('floater-station')?.apparatuses[0];

  const newFloaterPositions: RosterPosition[] = [];

  const positionIndex = newApparatus.positions.findIndex((pos) => pos.id === position.id);
  if (positionIndex >= 0) {
    position.employees.forEach((employee, index) => {
      if (employee.id) {
        newFloaterPositions.push(buildFloaterPosition(shiftTemplateHelper, employee));
        newApparatus.positions[positionIndex].employees[index] = makePlaceholderEmployee(position, employee);
      }
    });
  }

  if (floaterApparatus) {
    floaterApparatus.positions = floaterApparatus.positions.concat(newFloaterPositions);
  }
  const updatedShiftTemplateHelper = { ...shiftTemplateHelper, allStationCards: newAllStationCards };

  return { updatedShiftTemplateHelper };
};
