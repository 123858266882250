import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  AdminSelectItem,
  PlusIcon16,
  getRankColors,
  getCertColors,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';
import { Certification, CertificationActAsRank, DEFAULT_RANK } from '@stationwise/share-types';
import { IPersonRequirements, ITeamDefinition } from '../..';
import { Person, checkIsPersonValid, copyPerson, makeDefaultPerson } from './Person';

export const PeopleAndRankRequirements = ({
  setNextDisabled,
  setPeopleAndRankRequirements,
  peopleAndRankRequirements,
  completeStep,
  teamDefinition,
  currentStep,
}: {
  setNextDisabled: (disabled: boolean) => void;
  setPeopleAndRankRequirements: (peopleAndRankRequirements: IPersonRequirements[]) => void;
  peopleAndRankRequirements: IPersonRequirements[];
  completeStep: () => void;
  teamDefinition: ITeamDefinition;
  currentStep: number;
}) => {
  const theme = useTheme();
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const ranks: AdminSelectItem[] = [
    { label: DEFAULT_RANK.name, value: DEFAULT_RANK.code, color: getRankColors({ theme, color: DEFAULT_RANK.color }).color },
  ].concat(
    departmentInfoState.departmentInfo.ranks.map((rank) => ({
      label: departmentInfoState.departmentInfo.certificationActAsRanks.some(
        (certActAsRank: CertificationActAsRank) => certActAsRank.rankId === rank.id,
      )
        ? `${rank.name} (or can act as one)`
        : rank.name,
      value: rank.code,
      secondLabel: rank.code,
      color: getRankColors({ theme, color: rank.color }).color,
    })),
  );

  const specialities: AdminSelectItem[] = [{ label: 'No additional requirements', value: '' }].concat(
    departmentInfoState.departmentInfo.certifications.map((certification: Certification) => ({
      label: certification.name,
      value: certification.code,
      secondLabel: certification.code,
      color: getCertColors({ theme, color: certification.color }).color,
    })),
  );

  const [personKey, setPersonKey] = useState(0);

  const addPerson = () => {
    const lastPerson = peopleAndRankRequirements[peopleAndRankRequirements.length - 1];
    const newPerson = lastPerson ? copyPerson(lastPerson) : makeDefaultPerson();
    setPeopleAndRankRequirements([...peopleAndRankRequirements, newPerson]);
  };

  const removePerson = (currentPerson: IPersonRequirements) => {
    setPeopleAndRankRequirements(peopleAndRankRequirements.filter((person) => person !== currentPerson));
    setPersonKey((c) => c + 1);
  };

  const updatePerson = (currentPerson: IPersonRequirements, newPerson: Partial<IPersonRequirements>) => {
    const newPersons = peopleAndRankRequirements.map((person) => {
      return person !== currentPerson ? person : { ...person, ...newPerson };
    });
    setPeopleAndRankRequirements(newPersons);
  };

  useEffect(() => {
    if (
      !teamDefinition.name ||
      ((!peopleAndRankRequirements.length || peopleAndRankRequirements.some((person) => !checkIsPersonValid(person))) &&
        currentStep === 1)
    ) {
      setNextDisabled(true);
    } else {
      completeStep();
      setNextDisabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleAndRankRequirements, currentStep]);

  return (
    <Box sx={{ scroll: 'auto', maxHeight: '600px', width: '544px' }}>
      {peopleAndRankRequirements.map((person, personIndex) => (
        <Person
          key={`${personKey}${personIndex}`}
          currentPerson={person}
          ranks={ranks}
          specialities={specialities}
          removePerson={removePerson}
          updatePerson={updatePerson}
          currentPersonNumber={personIndex + 1}
        />
      ))}
      <Box
        sx={(theme) => ({
          mt: theme.spacing(2.5),
          typography: 'buttonM',
          color: theme.palette.stationBlue[600],
          float: 'right',
          display: 'flex',
          cursor: 'pointer',
          p: theme.spacing(1),
          '& svg path': { stroke: theme.palette.stationBlue[600] },
          '&:hover': {
            backgroundColor: theme.palette.stationGray[100],
            borderRadius: '6px',
          },
        })}
        onClick={addPerson}
      >
        <Box
          sx={(theme) => ({
            mr: theme.spacing(0.5),
          })}
        >
          <PlusIcon16 />
        </Box>
        Add person
      </Box>
    </Box>
  );
};
