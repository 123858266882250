import { Rank } from './models';

export const DEFAULT_RANK: Rank = {
  id: -1,
  name: 'NO RANK',
  code: 'NO RANK',
  color: 'red',
  isOfficer: false,
  sortOrder: -1,
  payPeriodType: '',
  defaultCertifications: [],
};
