import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { AllowedColors, Rank, Certification, DEFAULT_RANK } from '@stationwise/share-types';
import { RankBadge } from '../Badge';
import { BlankCard } from '../BlankCard';
import { ActingAsIndicator } from './ActingAsIndicator';
import { CardCertBadges } from './CardCertBadges';
import { DriverIndicator } from './DriverIndicator';
import { ShiftDurationIndicator } from './ShiftDurationIndicator';

export interface CardProps {
  rank: Pick<Rank, 'id' | 'name' | 'code' | 'color'>;
  certs: Pick<Certification, 'name' | 'code' | 'color'>[];
  durationLabel: string;
  durationMinutes: number;
  nameLabel: string;
  borderColor?: AllowedColors;
  type?: 'default' | 'disable' | 'border';
  isDriver?: boolean;
  actingAsRank?: Pick<Rank, 'id' | 'name' | 'code' | 'color'>;
  indicators?: ReactNode;
  cardSxProps?: SxProps<Theme>;
  actions?: React.ReactNode;
}

export const Card = ({
  rank,
  durationLabel,
  durationMinutes,
  nameLabel,
  certs,
  borderColor = 'gray',
  type = 'default',
  isDriver = false,
  actingAsRank,
  indicators,
  cardSxProps,
  actions,
}: CardProps) => {
  const theme = useTheme();

  return (
    <BlankCard
      type={type}
      borderColor={borderColor}
      sxProps={[
        { justifyContent: 'space-between', position: 'relative' },
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="space-between">
          {isDriver && <DriverIndicator sx={{ mr: 0.5 }} />}
          {actingAsRank && actingAsRank.id !== DEFAULT_RANK.id && <ActingAsIndicator rank={actingAsRank} sx={{ mr: 0.75 }} />}
          {rank.id !== DEFAULT_RANK.id && <RankBadge rank={rank} />}
        </Box>
        <ShiftDurationIndicator durationLabel={durationLabel} durationMinutes={durationMinutes} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          color: theme.palette.stationGray[900],
          typography: 'bodyLMedium',
          [theme.breakpoints.up('sm')]: { typography: 'bodySMedium' },
        }}
      >
        {nameLabel}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <CardCertBadges certs={certs} />
        <Box sx={{ flex: 1 }} />
        {indicators}
        {actions && (
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[600],
              minWidth: '24px',
              position: 'relative',
              typography: 'bodySSemibold',
              lineHeight: '14px',
              [theme.breakpoints.up('sm')]: { typography: 'buttonS', fontWeight: 400 },
            })}
          >
            {actions}
          </Box>
        )}
      </Box>
    </BlankCard>
  );
};
