import { Box, FormHelperText, Modal, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Button, Input } from '@stationwise/component-module';

interface EditWorkGroupModalProps {
  name?: string | undefined;
  description?: string;
  title: string;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle?: React.Dispatch<React.SetStateAction<string>>;
  handleEdit?: (name: string, description: string) => void;
  handleCreate?: (name: string, description: string) => void;
  workGroupNames?: string[];
  onCardCreate?: (name: string, description: string) => void;
}

export const EditWorkGroupModal = ({
  name,
  description,
  title,
  showModal,
  setShowModal,
  setTitle,
  handleEdit,
  handleCreate,
  workGroupNames,
  onCardCreate,
}: EditWorkGroupModalProps) => {
  const isCreate = title === 'New work group';
  const [newName, setNewName] = useState(isCreate ? '' : name);
  const [newDescription, setNewDescription] = useState(isCreate ? '' : description ?? '');

  useEffect(() => {
    if (title === 'New work group') {
      setNewName('');
      setNewDescription('');
    } else {
      setNewName(name ?? '');
      setNewDescription(description ?? '');
    }
  }, [name, description, showModal, title]);

  const handleSave = () => {
    if (!newName) return;
    if (onCardCreate) {
      onCardCreate(newName, newDescription);
    } else if (isCreate && handleCreate) {
      handleCreate(newName, newDescription);
    } else if (handleEdit) {
      handleEdit(newName, newDescription);
    }
    handleClose();
  };

  const handleClose = () => {
    setShowModal(false);
    setTitle && setTitle('New work group');
    setNewDescription(description ?? '');
    setNewName(name);
  };

  return (
    <Modal open={showModal}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '90vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
            }}
          >
            {title}
          </Box>

          <Box
            sx={(theme) => ({
              marginRight: theme.spacing(1),
              width: '100%',
            })}
          >
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                paddingTop: theme.spacing(2),
                color: theme.palette.stationGray[700],
              })}
            >
              Name
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
              })}
            >
              <Input
                value={newName}
                onChange={(event) => {
                  setNewName(event.currentTarget.value);
                }}
              />
              <FormHelperText>
                {workGroupNames && newName && workGroupNames.includes(newName) ? 'This group already exists.' : ''}
              </FormHelperText>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              width: '100%',
            })}
          >
            <Box
              sx={(theme) => ({
                typography: 'bodySMedium',
                textAlign: 'left',
                paddingTop: theme.spacing(2),
                color: theme.palette.stationGray[700],
              })}
            >
              Description (optional)
            </Box>

            <Box
              sx={(theme) => ({
                py: theme.spacing(1),
              })}
            >
              <TextField
                value={newDescription}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewDescription(event.target.value);
                }}
                sx={(theme) => ({
                  width: '100%',
                })}
                multiline
                rows={3}
              />
            </Box>
          </Box>

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button
              buttonType="tertiary"
              variant="contained"
              sx={(theme) => ({
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              })}
              onClick={() => handleClose()}
            >
              <Box component="span" sx={{ typography: 'buttonL' }}>
                Cancel
              </Box>
            </Button>
            <Button
              sx={(theme) => ({
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                borderRadius: theme.spacing(0.75),
                '&:hover': { backgroundColor: theme.palette.common.black, boxShadow: 'none' },
              })}
              buttonType="tertiary"
              variant="contained"
              onClick={() => handleSave()}
              disabled={
                newName === '' ||
                (newName === name && (newDescription === description || newDescription === '')) ||
                (!!workGroupNames && !!newName && workGroupNames.includes(newName))
              }
            >
              <Box component="span" sx={{ typography: 'buttonL' }}>
                Save
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
