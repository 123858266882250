import { Box } from '@mui/material';
import { Fragment } from 'react';
import { RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { EmptyPosition } from './EmptyPosition';
import { FilledPosition } from './FilledPosition';

interface PositionProps {
  apparatus: RosterApparatus;
  position: RosterPosition;
  isCollapsed?: boolean;
  actions?: React.ReactNode;
  onClickPosition?: (
    position: RosterPosition,
    apparatus: RosterApparatus,
    anchorEl: HTMLElement | null,
    employee?: RosterEmployee,
  ) => void;
}

export const Position = ({ position, apparatus, isCollapsed = false, onClickPosition, actions }: PositionProps) => {
  if (position.employees.length === 0) {
    return (
      <Box
        sx={{ cursor: onClickPosition && !apparatus.isReserved ? 'pointer' : 'default' }}
        onClick={
          onClickPosition && !apparatus.isReserved
            ? (event) => onClickPosition(position, apparatus, event.currentTarget)
            : undefined
        }
      >
        <EmptyPosition position={position} isCollapsed={isCollapsed} actions={actions} />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          padding: '1px',
          borderRadius: theme.spacing(1.5),
        })}
      >
        {position.employees.map((employee, index) => {
          return (
            <Fragment key={`${position.id}:${index}`}>
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{ cursor: onClickPosition && !apparatus.isReserved ? 'pointer' : 'default' }}
                  onClick={
                    onClickPosition && !apparatus.isReserved
                      ? (event) => onClickPosition(position, apparatus, event.currentTarget, employee)
                      : undefined
                  }
                >
                  {employee.id ? (
                    <FilledPosition position={position} employee={employee} isCollapsed={isCollapsed} actions={actions} />
                  ) : (
                    <EmptyPosition position={position} isCollapsed={isCollapsed} actions={actions} />
                  )}
                </Box>
              </Box>
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};
