import { Box } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useConversations, useDateQueryParam, useSelectedBattalionPreference } from '@stationwise/component-module';
import { ConversationListView } from '@stationwise/share-types';
import { Audience } from '../../../Dashboard/components/Audience';
import { Conversation } from '../../../Dashboard/components/Conversation';
import { DRAWER_TYPE } from '../../constants';

interface MessageToWorkingEmployeesProps {
  isOpen: boolean;
  setOpenedDrawer: Dispatch<SetStateAction<DRAWER_TYPE | null>>;
}

export const MessageToWorkingEmployees = ({ isOpen, setOpenedDrawer }: MessageToWorkingEmployeesProps) => {
  const [openAudience, setOpenAudience] = useState<boolean>(false);
  const [selectedConversation, setSelectedConversation] = useState<ConversationListView | null>(null);
  const [selectedBattalionId, setSelectedBattalionId] = useSelectedBattalionPreference();
  const { selectedDate: paramsSelectedDate } = useDateQueryParam();

  useEffect(() => {
    if (!isOpen) return;
    setOpenAudience(false);
    setSelectedConversation(null);
  }, [isOpen, setSelectedBattalionId]);

  const handleClose = (_isOpen: boolean) => {
    setOpenedDrawer(null);
  };

  const closeAudience = () => {
    setOpenAudience(false);
  };

  const { onCancelConversation, forceRefetch, handleCreateConversation, handleUpdateConversation } = useConversations();

  if (!isOpen) return;
  if (!paramsSelectedDate) return;
  return (
    <Box>
      {openAudience && selectedConversation && (
        <Box
          sx={{
            overflowY: 'auto',
            width: '450px',
          }}
        >
          <Audience
            closeAudience={closeAudience}
            conversation={selectedConversation}
            handleCreateConversation={handleCreateConversation}
            refetchConversations={forceRefetch}
          />
        </Box>
      )}

      <Box
        sx={{
          width: '500px',
          overflow: 'hidden',
        }}
      >
        <Box>
          <Conversation
            openAudience={openAudience}
            setOpenAudience={setOpenAudience}
            openDrawer={isOpen}
            setOpenDrawer={handleClose}
            conversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
            onCancelConversation={onCancelConversation}
            refetchConversations={forceRefetch}
            handleCreateConversation={handleCreateConversation}
            handleUpdateConversation={handleUpdateConversation}
            rosterMessage
            selectedBattalionId={selectedBattalionId}
            date={paramsSelectedDate}
          />
        </Box>
      </Box>
    </Box>
  );
};
