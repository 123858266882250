import { RosterDataSource, RosterEmployee, RosterStation, ShiftSummaryEmployee } from '@stationwise/share-types';
import {
  IShiftSummaryHelper,
  getDepartmentPayCodes,
  getOverrideEmployeePositionFields,
  setEmployeeActiveId,
} from '@stationwise/shift-summary-helper';

export const buildFloaterStation = (shiftTemplateHelper: IShiftSummaryHelper, floaters: ShiftSummaryEmployee[]) => {
  const stationFloater: RosterStation = {
    stationId: 'floater-station',
    dataSource: RosterDataSource.FLOATER,
    apparatuses: [
      {
        id: 'floater-apparatus',
        dataSource: RosterDataSource.FLOATER,
        positions: floaters.map((floater) => buildFloaterPosition(shiftTemplateHelper, floater)),
        name: '',
        isForShiftLeader: false,
        certificationRequirements: [],
      },
    ],
    stationName: 'Floaters',
    certificationRequirements: [],
  };
  return stationFloater;
};

export const buildFloaterPosition = ({ departmentInfo }: IShiftSummaryHelper, floater: ShiftSummaryEmployee | RosterEmployee) => {
  const newFloater = setEmployeeActiveId({
    ...floater,
    id: String(floater.id),
    dataSource: RosterDataSource.FLOATER,
    startDateTime: new Date(floater.startDateTime),
    endDateTime: new Date(floater.endDateTime),
    payCodes: getDepartmentPayCodes(departmentInfo, floater.payCodes),
    detailCodes: [],
    ...getOverrideEmployeePositionFields(),
  });

  return {
    id: `floater-${newFloater.activeId}`,
    dataSource: RosterDataSource.FLOATER,
    startDateTime: newFloater.startDateTime,
    endDateTime: newFloater.endDateTime,
    employees: [newFloater],
    certifications: newFloater.certifications,
    driver: false,
    rank: newFloater.rank,
  };
};
