import { Autocomplete as MuiAutocomplete, AutocompleteProps, Box, Chip, TextField, MenuItem } from '@mui/material';
import { ReactElement } from 'react';
import { CheckIcon16, ChevronDownIcon16, XIcon16 } from '../../assets';
import { AdminSelectItem } from './AdminSelect';

interface AdminAutocompleteProps<Multiple extends boolean | undefined>
  extends Omit<AutocompleteProps<AdminSelectItem, Multiple, false, false>, 'renderInput'> {
  placeholder: string;
  endAdornment?: ReactElement;
  getDisplayedOption?: (option: AdminSelectItem) => AdminSelectItem;
}

export const AdminAutocomplete = <Multiple extends boolean | undefined>({
  placeholder,
  endAdornment,
  getDisplayedOption = (option) => option,
  multiple,
  sx,
  ...props
}: AdminAutocompleteProps<Multiple>) => {
  return (
    <MuiAutocomplete
      disablePortal
      multiple={multiple}
      {...props}
      sx={[
        (theme) => ({
          '& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: theme.palette.common.white,
            borderRadius: '8px',
            border: `1px solid ${theme.palette.stationGray[900]}`,
          },
          '& button.MuiButtonBase-root': {
            visibility: 'visible',
          },
          '& .MuiOutlinedInput-root': { p: theme.spacing(0.5) },
        }),
        ...(Array.isArray(sx) ? sx : sx ? [sx] : []),
      ]}
      disableCloseOnSelect={multiple}
      getOptionLabel={(option) => getDisplayedOption(option).label}
      isOptionEqualToValue={(option, selected) => option.value === selected.value}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          sx={(theme) => ({
            typography: 'bodySRegular',
            maxHeight: '220px',
            '& .MuiOutlinedInput-root': { p: '4px 9px' },
            '& .MuiIconButton-root': { mr: '4px' },
            color: theme.palette.stationGray[500],
          })}
          InputProps={
            !multiple && endAdornment
              ? {
                  ...params.InputProps,
                  endAdornment: (
                    <Box>
                      {endAdornment}
                      {params.InputProps.endAdornment}
                    </Box>
                  ),
                }
              : { ...params.InputProps }
          }
        />
      )}
      renderTags={
        multiple
          ? (value: readonly AdminSelectItem[], getTagProps) =>
              value.map((option: AdminSelectItem, index: number) => (
                <Chip
                  variant="outlined"
                  label={getDisplayedOption(option).value}
                  deleteIcon={<XIcon16 />}
                  {...getTagProps({ index })}
                  key={index}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.stationGray[100],
                    border: 'none',
                    height: '24px',
                  })}
                />
              ))
          : undefined
      }
      renderOption={(props, option, state) => (
        <MenuItem {...props} key={option.value} sx={(theme) => ({ mx: theme.spacing(1.5), borderRadius: '8px' })}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              width: '100%',
            }}
          >
            <Box sx={{ typography: 'bodyMRegular' }}>{getDisplayedOption(option).label}</Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                gap: 1,
                pt: '3px',
                pr: !multiple || state.selected ? '0px' : theme.spacing(2),
                typography: 'bodySMedium',
                lineHeight: '16px',
                color: theme.palette.stationGray[500],
                '& svg path': {
                  stroke: theme.palette.stationGray[900],
                },
              })}
            >
              {getDisplayedOption(option).value !== getDisplayedOption(option).label && (
                <Box>{getDisplayedOption(option).value}</Box>
              )}
              {state.selected && <CheckIcon16 />}
            </Box>
          </Box>
        </MenuItem>
      )}
      popupIcon={<ChevronDownIcon16 />}
    />
  );
};
