import { Box, Tooltip } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { theme, GreenLiveIcon16, getTooltipProps } from '@stationwise/component-module';
import { AutoHireHistoryView } from '@stationwise/share-types';

export enum AutoHireStatus {
  NOW_CHOOSING = 'Now choosing',
  SKIPPED = 'Skipped',
  SIGNED_UP = 'Signed up',
  TO_BE_NOTIFIED = 'To be notified',
  NOTIFIED = 'Notified',
}

export const getStatusChip = (status: AutoHireStatus, isActive: boolean) => {
  let specificStyle = {};
  let content = null;
  let hoverText = '';
  switch (status) {
    case AutoHireStatus.NOW_CHOOSING:
      content = (
        <>
          <GreenLiveIcon16 />
          {status}
        </>
      );
      hoverText = `Employee received the notification.`;
      break;
    case AutoHireStatus.SIGNED_UP:
      specificStyle = {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.stationGreen[500],
      };
      content = status;
      hoverText = `Employee signed up for the position.`;
      break;
    case AutoHireStatus.NOTIFIED:
      specificStyle = {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.stationTeal[600],
      };
      content = status;
      hoverText = isActive
        ? `Employee is notified but did not signed up in given duration. They can still sign up but next employees are also getting notified.`
        : `Employee is notified but did not signed up in given duration. They can not sign up since auto hire is deactivated.`;
      break;
    case AutoHireStatus.TO_BE_NOTIFIED:
      content = status;
      hoverText = `Waiting for the previous employees to be notified.`;
      break;
    case AutoHireStatus.SKIPPED:
      content = isActive ? AutoHireStatus.SKIPPED : status;
      hoverText = isActive
        ? `Employee is no longer eligible for vacant positions.`
        : `Employee is skipped because of auto hire is deactivated.`;
      break;
    default:
      content = isActive ? AutoHireStatus.SKIPPED : status;
      hoverText = isActive
        ? `Employee is no longer eligible for vacant positions.`
        : `Employee is skipped because of auto hire is deactivated.`;
      break;
  }

  return (
    <Tooltip arrow title={hoverText} placement="bottom" slotProps={getTooltipProps()}>
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing(1),
          p: '2px 8px 2px 8px',
          borderRadius: theme.spacing(2.5),
          border: `1px solid ${theme.palette.stationGray[200]}`,
          typography: 'bodySMedium',
          color: theme.palette.stationGray[900],
          backgroundColor: theme.palette.common.white,
          mt: theme.spacing(1.5),
          ml: theme.spacing(1.5),
          ...specificStyle,
        }}
      >
        {content}
      </Box>
    </Tooltip>
  );
};

export const DynamicTimeDisplay: React.FC<{ getTime: () => string }> = ({ getTime }) => {
  const [time, setTime] = useState(getTime());

  const updateTime = useCallback(() => {
    setTime(getTime());
  }, [getTime]);

  useEffect(() => {
    updateTime();
    const timer = setInterval(updateTime, 1000);

    return () => clearInterval(timer);
  }, [updateTime]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{time}</>;
};

export const formatRemainingTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export const getRemainingTime = (historyEntry: AutoHireHistoryView, currentTime: Date): string => {
  if (!historyEntry.notificationSentTime) return 'N/A';

  const notificationTime = new Date(historyEntry.notificationSentTime);
  const elapsedSeconds = Math.floor((currentTime.getTime() - notificationTime.getTime()) / 1000);
  const remainingSeconds = Math.max(0, 300 - elapsedSeconds); // Assuming 5 minutes (300 seconds) notification interval

  return formatRemainingTime(remainingSeconds);
};

export const getTimeElapsed = (historyEntry: AutoHireHistoryView, currentTime: Date): string => {
  if (!historyEntry.notificationSentTime) return 'N/A';

  const notificationTime = new Date(historyEntry.notificationSentTime);
  const elapsedSeconds = Math.floor((currentTime.getTime() - notificationTime.getTime()) / 1000);

  return formatRemainingTime(elapsedSeconds);
};
