import { useState, useEffect, useMemo, useRef } from 'react';
import { SelectContactOptionProps } from '../components/Conversation/SelectRecipients/Options';
import { ALL_CONTACTS, SHIFTS, WORK_GROUPS } from '../components/Conversation/SelectRecipients/constants';
import { ISelectedRecipients } from '../components/Conversation/SelectRecipients/types';
import { TeamFlag } from '../components/TeamFlag';
import { UsersIcon } from '../components/UsersIcon';

const filters = [
  {
    label: ALL_CONTACTS,
    icon: null,
  },
  {
    label: SHIFTS,
    icon: null,
  },
  {
    label: WORK_GROUPS,
    icon: null,
  },
];
const getFilterIcon = (filter: string, color: string) => {
  switch (filter) {
    case SHIFTS:
      return <TeamFlag color={color} />;
    case WORK_GROUPS:
      return <UsersIcon color={color} showBorder />;
    default:
      return null;
  }
};

interface IuseSelectConversationRecipients {
  data: {
    [key in string]: SelectContactOptionProps[];
  };
  onAddRecipients?: (selectedRecipients: ISelectedRecipients, close?: boolean) => void;
}

interface SelectedRecipientsInterface {
  [category: string]: Set<number>;
}

export const useSelectConversationRecipients = ({ data, onAddRecipients }: IuseSelectConversationRecipients) => {
  const [recipients, setRecipients] = useState<SelectContactOptionProps[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRecipients, setSelectedRecipients] = useState<SelectedRecipientsInterface>({});
  const [selectedFilter, setSelectedFilter] = useState(ALL_CONTACTS);
  const selectedRecipientsRef = useRef<ISelectedRecipients>({});

  useEffect(() => {
    setRecipients(data[ALL_CONTACTS]);
    setSearchValue('');
    setSelectedRecipients({});
  }, [data]);

  useEffect(() => {
    setRecipients(data[selectedFilter]);
    setSearchValue('');
  }, [data, selectedFilter]);

  const handleClearOptions = () => {
    setSelectedRecipients({});
    selectedRecipientsRef.current = {};
    if (onAddRecipients) {
      onAddRecipients({}, false);
    }
  };

  const filtersWithValues = useMemo(
    () =>
      filters.map((filter) => ({
        ...filter,
        action: () => setSelectedFilter(filter.label),
        isSelected: filter.label === selectedFilter,
        highlight:
          data && data[filter.label]
            ? data[filter.label].some((recipient) =>
                recipient && recipient.id && recipient.category && selectedRecipients[recipient.category]
                  ? recipient.id.every((id) => selectedRecipients[recipient.category || ''].has(id))
                  : false,
              )
            : false,
      })),
    [data, selectedFilter, selectedRecipients],
  );

  const recipientsWithValues = useMemo(
    () =>
      recipients.map((recipient) => ({
        ...recipient,
        onClick: () => {
          setSelectedRecipients((prevState) => {
            if (recipient && recipient.id && recipient.category) {
              // Update the selected categories depending on whether the recipient was selected or not.
              const category = recipient.category;
              const newState = { ...prevState };
              const categorySet = new Set<number>(newState[category] || []);

              recipient.id.forEach((id) => {
                if (categorySet.has(id)) {
                  selectedRecipientsRef.current[recipient.category || ''] = selectedRecipientsRef.current[
                    recipient.category || ''
                  ].filter((r) => r.id !== id);
                  categorySet.delete(id);
                } else {
                  categorySet.add(id);
                }
              });
              newState[category] = categorySet;

              return newState;
            }
            return prevState;
          });
        },
        checked:
          recipient && recipient.id && recipient.category && selectedRecipients[recipient.category]
            ? recipient.id.every((id) => selectedRecipients[recipient.category || ''].has(id))
            : false,
      })),
    [recipients, selectedRecipients],
  );

  const selectedGroups = recipientsWithValues
    .filter((recipient) => recipient.checked)
    .map((recipient) => ({
      // FIXME fix this logic to only use number id...
      // id: recipient.filter
      //   ? recipient.name
      //   : recipient.id
      //   ? recipient.id[0]
      //   : '',

      //todo we should fill memberIds getting from backend and correctly here if we wanted to remove duplicate selections
      id: recipient.id ? recipient.id[0] : 0,
      name: recipient.name,
      memberIds: recipient.filter ? recipient.id : [],
      userImg: recipient.userImg,
      chipBgColor: recipient.chipBackgroundColor,
      chipLabel: recipient.chipLabel,
      checked: recipient.checked,
      onClick: recipient.onClick,
      filter: recipient.filter,
      icon: getFilterIcon(recipient.filter || '', recipient.chipBackgroundColor || ''),
      category: recipient.category,
    }));

  selectedRecipientsRef.current[selectedFilter] = selectedGroups;

  const filteredRecipients = recipientsWithValues.filter((recipient) => {
    if (searchValue) {
      return recipient.name.toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const handleAllSelect = () => {
    if (recipientsWithValues.every((recipient) => recipient.checked)) {
      setSelectedRecipients((prevState) => {
        const recipient = recipientsWithValues[0];
        const newState = { ...prevState };
        if (recipient && recipient.id && recipient.category) {
          newState[recipient.category] = new Set<number>();
          return newState;
        }
        return {};
      });
    } else {
      const newSelectedRecipients: SelectedRecipientsInterface = {};

      recipientsWithValues.forEach((recipient) => {
        if (recipient && recipient.id && recipient.category) {
          if (!newSelectedRecipients[recipient.category]) {
            newSelectedRecipients[recipient.category] = new Set<number>();
          }
          recipient.id.forEach((id) => newSelectedRecipients[recipient.category || ''].add(id));
        }
      });
      setSelectedRecipients((prevState) => ({ ...prevState, ...newSelectedRecipients }));
    }
  };

  const handleEditRecipients = () => {
    if (onAddRecipients) {
      onAddRecipients(selectedRecipientsRef.current);
    }
  };

  return {
    handleAllSelect,
    handleClearOptions,
    handleChangeSearch: setSearchValue,
    handleEditRecipients,
    recipients: filteredRecipients,
    filters: filtersWithValues,
    searchValue,
    isAllSelected: recipients.every((recipient) =>
      recipient && recipient.id && recipient.category && selectedRecipients[recipient.category]
        ? recipient.id.every((id) => selectedRecipients[recipient.category || '']?.has(id))
        : false,
    ),
    amountSelected: Object.values(selectedRecipients).reduce((total, set) => total + set.size, 0),
    selectedFilter,
  };
};
