import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { XCloseIcon24 } from '@stationwise/component-module';
import { BulkCallDetailsView } from '@stationwise/share-types';
import { RequestVolunteersHistoryCard } from './RequestVolunteersHistoryCard';
import { RequestVolunteersHistoryContent } from './RequestVolunteersHistoryContent';

interface RequestVolunteersHistoryModalProps {
  modalOpen: boolean;
  setIsRequestVolunteersHistoryModalOpen: Dispatch<SetStateAction<boolean>>;
  bulkCalls: BulkCallDetailsView[];
  setBulkCallIsOpen: Dispatch<SetStateAction<boolean>>;
  hasRosterChanges: boolean;
  hasVacancies: boolean;
}

export const RequestVolunteersHistoryModal: React.FC<RequestVolunteersHistoryModalProps> = ({
  modalOpen,
  setIsRequestVolunteersHistoryModalOpen,
  bulkCalls,
  setBulkCallIsOpen,
  hasRosterChanges,
  hasVacancies,
}) => {
  const [selectedBulkCall, setSelectedBulkCall] = useState<BulkCallDetailsView | null>(null);
  const [selectedBulkCallIndex, setSelectedBulkCallIndex] = useState<number>(0);
  const [isBulkCallDetailsModalOpen, setIsBulkCallDetailsModalOpen] = useState(false);
  const closeDetailsModal = () => {
    setIsBulkCallDetailsModalOpen(false);
    setSelectedBulkCall(null);
  };

  const closeModal = () => {
    setIsRequestVolunteersHistoryModalOpen(false);
  };

  return (
    <Dialog
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={modalOpen}
      onClose={closeModal}
    >
      <DialogTitle>
        <Box sx={{ typography: 'bodyXLSemibold' }}>Request Volunteers</Box>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <XCloseIcon24 />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="buttonL">Request History</Typography>
            {hasVacancies && (
              <Tooltip title={hasRosterChanges ? 'You should save your roster changes before creating a new request' : ''}>
                <span>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setBulkCallIsOpen(true)}
                    disabled={hasRosterChanges}
                  >
                    Create a new request
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {bulkCalls.map((bulkCall, index) => (
              <RequestVolunteersHistoryCard
                key={bulkCall.id}
                bulkCall={bulkCall}
                setSelectedBulkCall={setSelectedBulkCall}
                setIsBulkCallDetailsModalOpen={setIsBulkCallDetailsModalOpen}
                setSelectedBulkCallIndex={setSelectedBulkCallIndex}
                index={index + 1}
              />
            ))}
          </Box>
        </Box>
        <Modal
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={isBulkCallDetailsModalOpen}
          onClose={closeModal}
        >
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.common.white,
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                padding: theme.spacing(3),
                flexDirection: 'column',
                height: '90vh',
                width: '60vw',
              })}
            >
              <RequestVolunteersHistoryContent
                bulkCall={selectedBulkCall}
                closeModal={closeDetailsModal}
                index={selectedBulkCallIndex}
              />
            </Box>
          </Box>
        </Modal>
      </DialogContent>
    </Dialog>
  );
};
