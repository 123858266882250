import { RosterDataSource, RosterEmployee } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { setEmployeeActiveId } from './id';
import { getMandatoryPayCodes } from './payCode';
import { IShiftSummaryHelper } from './types';

export const sortUnassignedCards = (unassignedCards: RosterEmployee[]) => {
  return [...unassignedCards].sort((a, b) => {
    return Number(a.id) - Number(b.id) || differenceInUTCMinutes(a.startDateTime, b.startDateTime);
  });
};

export const createUnassignedEmployee = (shiftSummaryHelper: IShiftSummaryHelper, employee: RosterEmployee) => {
  const newEmployee = setEmployeeActiveId({ ...employee, dataSource: RosterDataSource.FLOATER });
  newEmployee.payCodes = getMandatoryPayCodes(shiftSummaryHelper, null, newEmployee);
  const newUnassignedCards = sortUnassignedCards([newEmployee, ...shiftSummaryHelper.unassignedCards]);
  return { ...shiftSummaryHelper, unassignedCards: newUnassignedCards };
};
