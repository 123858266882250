import { useState, useEffect } from 'react';
import { axios, client } from '@stationwise/share-api';
import { ConversationInput, ConversationListView, ConversationUpdateInput } from '@stationwise/share-types';
import { useAuthUserContext } from '../components/Auth';
import { SnackbarService } from '../components/Snackbar';
import { useGetConversations } from './useGetConversations';

export const useConversations = () => {
  const {
    data: conversations,
    isLoading,
    isError,
    forceRefetch,
    hasNextPage,
    loadMore,
    searchConversation,
  } = useGetConversations();
  const [currentConversationId, setCurrentMessageId] = useState<number | null>(null);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const { state } = useAuthUserContext();

  useEffect(() => {
    if (currentConversationId !== null) {
      setOpenCancelModal(true);
    }
  }, [currentConversationId]);

  const handleCancelConversation = (conversationId: number) => (note: string) => {
    const input: ConversationUpdateInput = {
      cancelledAt: new Date(),
      cancelledBy: state.employee?.id,
      cancelledNote: note,
    };
    handleUpdateConversation(conversationId, input).then(() => {
      forceRefetch();
    });
    setOpenCancelModal(false);
    setCurrentMessageId(null);
  };

  const onCancelConversation = (id: number) => {
    setCurrentMessageId(id);
  };

  const handleCloseCancelModal = () => {
    setOpenCancelModal(false);
  };

  const handleCreateConversation = async (conversation: ConversationInput) => {
    try {
      const response = await client.post<ConversationListView>('/conversation/', conversation);
      return response.data;
    } catch (error) {
      const message = axios.isAxiosError(error)
        ? error.response?.data?.message || error.message
        : 'An unexpected error occurred.';
      SnackbarService.notify({
        content: message,
        severity: 'error',
        duration: 10000,
      });
      throw error;
    }
  };

  const handleUpdateConversation = async (id: number, conversation: ConversationUpdateInput) => {
    try {
      const response = await client.patch<ConversationListView>(`/conversation/${id}/`, conversation);
      return response.data;
    } catch (error) {
      const message = axios.isAxiosError(error)
        ? error.response?.data?.message || error.message
        : 'An unexpected error occurred.';
      SnackbarService.notify({
        content: message,
        severity: 'error',
        duration: 10000,
      });
      throw error;
    }
  };

  return {
    conversations,
    isLoading,
    isError,
    onCancelConversation,
    openCancelModal,
    handleCancelConversation: currentConversationId
      ? handleCancelConversation(currentConversationId)
      : () => console.log('No conversation selected to cancel.'),
    handleCloseCancelModal,
    handleCreateConversation,
    handleUpdateConversation,
    forceRefetch,
    hasNextPage,
    loadMore,
    searchConversation,
  };
};
