import { Box, Menu, MenuItem } from '@mui/material';
import { useCallback, useEffect, useId, useState } from 'react';
import { RosterEmployee, RosterTemporaryNonShiftApparatus, RosterTemporaryNonShiftPosition } from '@stationwise/share-types';
import { cancelTemporaryNonShiftAssignment } from '@stationwise/shift-summary-helper';
import { ChevronDownIcon16 } from '../../../../assets';
import { PreviewButton, ViewProfileButton } from '../../../EmployeePreview';
import { useRosterContext } from '../RosterContext';

interface TemporaryNonShiftActionsProps {
  apparatus: RosterTemporaryNonShiftApparatus;
  position: RosterTemporaryNonShiftPosition;
  employee: RosterEmployee;
}

export const TemporaryNonShiftActions = ({ apparatus, position, employee }: TemporaryNonShiftActionsProps) => {
  const {
    employeesOffState,
    selectedFilledPositionState,
    shiftSummaryHelper,
    setUserHasMadeChanges,
    setShiftSummaryHelper,
    temporaryNonShiftAssignmentsState,
  } = useRosterContext();

  const id = useId();
  const [cancelAnchorEl, setCancelAnchorEl] = useState<HTMLElement | null>(null);
  const [inProgress, setInProgress] = useState(false);

  const closeCancelMenu = () => setCancelAnchorEl(null);

  const openCancelMenu = (event: React.MouseEvent<HTMLButtonElement>) => setCancelAnchorEl(event.currentTarget);

  const cancel = useCallback(() => {
    if (position.temporaryNonShiftAssignment.id > 0) {
      employeesOffState.setTemporaryNonShiftToCancel(position.temporaryNonShiftAssignment.id);
    } else {
      temporaryNonShiftAssignmentsState.removeAssignment(position.temporaryNonShiftAssignment.employee.id);
    }

    const { newShiftSummaryHelper } = cancelTemporaryNonShiftAssignment(shiftSummaryHelper, position, apparatus);
    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
    closeCancelMenu();
    selectedFilledPositionState.closeDialog();

    setInProgress(false);
  }, [
    position,
    employeesOffState,
    temporaryNonShiftAssignmentsState,
    shiftSummaryHelper,
    apparatus,
    selectedFilledPositionState,
    setShiftSummaryHelper,
    setUserHasMadeChanges,
  ]);

  const handleCancel = () => {
    setInProgress(true);
  };

  useEffect(() => {
    if (inProgress) cancel();
  }, [cancel, inProgress]);

  return (
    <Box sx={{ display: 'flex', gap: 2, mt: 2, '& .MuiButton-root': { width: '100%' } }}>
      <Box sx={{ flex: 1 }}>
        <ViewProfileButton employeeId={employee.id} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <PreviewButton
          buttonType="primary"
          id={`${id}cancel-button`}
          aria-controls={cancelAnchorEl ? `${id}cancel-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={cancelAnchorEl ? 'true' : undefined}
          onClick={openCancelMenu}
          sx={{
            gap: 0.5,
            pl: 1,
            pr: 0.75,
            '& svg': { minWidth: '16px' },
            '&[aria-expanded="true"] svg': { transform: 'rotate(180deg)' },
          }}
        >
          Actions
          <ChevronDownIcon16 />
        </PreviewButton>
      </Box>
      <Menu
        id={`${id}cancel-menu`}
        anchorEl={cancelAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -8, horizontal: 'right' }}
        open={!!cancelAnchorEl}
        onClose={closeCancelMenu}
        MenuListProps={{ 'aria-labelledby': `${id}cancel-button` }}
        sx={(theme) => ({
          '& .MuiMenu-paper': {
            backgroundColor: theme.palette.stationGray[700],
            border: `1px solid ${theme.palette.stationGray[600]}`,
            borderRadius: '12px',
          },
          '& .MuiMenu-list': {
            p: theme.spacing(1.5),
          },
          '& .MuiMenuItem-root': {
            backgroundColor: theme.palette.stationGray[700],
            borderRadius: '8px',
            color: theme.palette.common.white,
            p: theme.spacing(1, 1.5),
            typography: 'bodyS',
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: theme.palette.stationGray[800],
          },
        })}
      >
        <MenuItem onClick={handleCancel} disabled={inProgress}>
          Cancel light duty
        </MenuItem>
      </Menu>
    </Box>
  );
};
