import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import {
  BlankCard,
  Chip,
  getEventDisplayedTitle,
  STATUS_TITLES,
  useLoadedDepartmentInfoContext,
} from '@stationwise/component-module';

export const RequestTimeOffCard = ({
  shift,
  children,
  handleClick,
  status,
}: {
  shift: EventInput | EventImpl;
  children?: React.ReactNode;
  handleClick?: (arg0: EventInput | EventImpl) => void;
  status: string;
}) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  return (
    <Box
      className="shift-card-box"
      sx={(theme) => ({
        mx: theme.spacing(2),
        pb: theme.spacing(2),
      })}
      onClick={() => handleClick && handleClick(shift)}
    >
      <BlankCard>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Chip
            label={
              getEventDisplayedTitle(departmentInfoState, shift.title || '').toUpperCase() +
              (status !== STATUS_TITLES.APPROVED ? ' REQUEST' : '')
            }
            chipSxProps={() => ({
              background: shift.backgroundColor,
              height: '24px',
              width: '110%',
              p: '4px 2px',
              borderRadius: '4px',
            })}
            variant="eyebrow"
            typographyStyles={{
              letterSpacing: '0.03em',
              color: shift.textColor,
            }}
          />
          {(status === STATUS_TITLES.APPROVED || status === STATUS_TITLES.DENIED) && (
            <Box
              sx={(theme) => {
                let backgroundColor = theme.palette.common.white;
                let color = theme.palette.stationGray[700];
                if (status === STATUS_TITLES.APPROVED) {
                  backgroundColor = theme.palette.stationGreen[100];
                  color = theme.palette.stationGreen[600];
                } else if (status === STATUS_TITLES.DENIED) {
                  backgroundColor = theme.palette.stationRed[100];
                  color = theme.palette.stationRed[600];
                }
                return {
                  backgroundColor,
                  color,
                  borderRadius: '16px',
                  padding: '2px 12px 2px 12px',
                  typography: 'bodySMedium',
                };
              }}
            >
              {status === STATUS_TITLES.APPROVED && 'Approved'}
              {status === STATUS_TITLES.DENIED && 'Denied'}
            </Box>
          )}
        </Box>
        <Box display="flex">
          {shift.start?.toString() && <Typography variant="bodyMRegular">{format(shift.start?.toString(), 'M/d/yy')}</Typography>}
          <Box
            display="flex"
            sx={(theme) => ({
              ml: theme.spacing(1.5),
              color: theme.palette.stationGray[400],
            })}
          >
            {shift.start?.toString() && (
              <Typography variant="bodyMRegular">{format(shift.start?.toString(), 'HH:mm')}</Typography>
            )}
            <Box sx={{ ml: '3px', mr: '3px' }}>-</Box>
            {shift.end?.toString() && <Typography variant="bodyMRegular">{format(shift.end?.toString(), 'HH:mm')}</Typography>}
          </Box>
        </Box>
        {children}
      </BlankCard>
    </Box>
  );
};
