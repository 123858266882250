import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LetterAvatar, XCloseIcon16, theme } from '@stationwise/component-module';
import { EmployeeDetails } from '@stationwise/share-types';
import { ROUTES } from '../../../../../core/Routes';

type ShiftTradeRequesterProps = {
  employee: EmployeeDetails;
};

export const ShiftTradeRequester = ({ employee }: ShiftTradeRequesterProps) => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: theme.spacing(2) }}>
        <Box
          onClick={() => navigate(ROUTES.MY_DASHBOARD.fullRoute)}
          sx={(theme) => ({
            width: '28px',
            height: '28px',
            borderRadius: '20px',
            background: theme.palette.stationGray[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.stationGray[500],
          })}
        >
          <XCloseIcon16 />
        </Box>
      </div>
      <Box sx={{ display: 'flex', gap: '12px', mx: '25px' }}>
        <Box>
          <LetterAvatar firstName={employee.name} />
        </Box>
        <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodyXLSemibold' })}>
          {employee.name} is asking you to trade a shift with them
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          color: theme.palette.stationGray[500],
          pt: theme.spacing(2),
          ml: theme.spacing(2),
          mr: theme.spacing(2),
          pb: theme.spacing(2),
          typography: 'bodyLRegular',
        })}
      >
        Here is the shift review. Let {employee.name} know if you accept or deny his request.
      </Box>
    </>
  );
};
