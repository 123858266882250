import { Box, Typography } from '@mui/material';
import { MenuAltIcon } from '@stationwise/component-module';
import { DetailFieldsStaffingList } from '@stationwise/share-types';
import { OvertimeList } from './OvertimeList';

export const FetchedDataOvertimeCard = ({ displayOvertimeInfo }: { displayOvertimeInfo: DetailFieldsStaffingList }) => {
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={(theme) => ({
          borderTop: `solid 1px ${theme.palette.stationGray[100]}`,
          pt: 2,
        })}
      >
        <Typography
          variant="bodyLMedium"
          sx={(theme) => ({
            color: theme.palette.stationGray[900],
          })}
        >
          Voluntary Overtime
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={(theme) => ({
          borderBottom: `solid 1px ${theme.palette.stationGray[100]}`,
          pb: 2,
        })}
      >
        <Box display="flex">
          <Box sx={{ marginTop: '2px' }}>
            <MenuAltIcon />
          </Box>
          <Typography
            variant="bodyLMedium"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              ml: 2,
              fontSize: '16px',
            })}
          >
            Overall position
          </Typography>
        </Box>
        <Box
          display="flex"
          sx={{
            textAlign: 'right',
          }}
        >
          <Typography
            variant="bodyLSemibold"
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              fontSize: '16px',
            })}
          >
            Not on the list
          </Typography>
        </Box>
      </Box>
      {displayOvertimeInfo.items.length > 0 && <OvertimeList overtimeInfo={displayOvertimeInfo} />}
    </Box>
  );
};
