import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Checkbox, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Card, EmptyCard } from '@stationwise/component-module';
import { EligibleEmployeeForHiringEngine } from '@stationwise/share-types';
import { formatShiftDuration } from '@stationwise/shift-summary-helper';
import { HiringEngineVacancy } from '../Vacancies/vacanciesHelper';

interface VacancyEmployeePairProps {
  vacancy: HiringEngineVacancy;
  employee?: EligibleEmployeeForHiringEngine;
  isSelected: boolean;
  isDisabled: boolean;
  onToggle: () => void;
  durationMinutes: number;
}

export const VacancyEmployeePair = ({
  vacancy,
  employee,
  isSelected,
  isDisabled,
  onToggle,
  durationMinutes,
}: VacancyEmployeePairProps) => {
  const theme = useTheme();
  useEffect(() => {
    if (isDisabled && isSelected) {
      onToggle();
    }
  }, [isDisabled, isSelected, onToggle]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        backgroundColor: theme.palette.stationGray[200],
        marginTop: theme.spacing(1),
        borderRadius: 2.5,
        flexDirection: 'column',
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox checked={isSelected} onChange={onToggle} disabled={isDisabled} />
        <Typography variant="bodyMRegular">{`${vacancy.stationName} | ${vacancy.apparatusName}`}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          flexDirection: 'row',
          alignItems: 'center',
          marginX: 2,
          marginBottom: 1.5,
        }}
      >
        <EmptyCard
          rank={vacancy.rank}
          certs={vacancy.certifications}
          durationLabel={formatShiftDuration({
            startTime: vacancy.startDateTime,
            endTime: vacancy.endDateTime,
          })}
        />
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.stationGray[300]}`,
            display: 'flex',
            padding: 0.5,
            alignItems: 'center',
            borderRadius: 3,
            justifyContent: 'center',
          }}
        >
          <ArrowBackIcon sx={{ color: 'action.active' }} />
        </Box>
        {employee && isSelected ? (
          <Card
            rank={employee.rank}
            durationLabel={formatShiftDuration({
              startTime: vacancy.startDateTime,
              endTime: vacancy.endDateTime,
            })}
            certs={employee.certifications}
            durationMinutes={durationMinutes}
            nameLabel={employee.name}
          />
        ) : (
          <EmptyCard
            withPlusSign={false}
            certs={[]}
            durationLabel={formatShiftDuration({
              startTime: vacancy.startDateTime,
              endTime: vacancy.endDateTime,
            })}
            infoText={isDisabled ? 'No eligible employee found' : 'Did not select this position'}
          />
        )}
      </Box>
    </Box>
  );
};
