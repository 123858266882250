import { ReactComponent as ArrowCircleUpSymbol } from '@material-symbols/svg-400/outlined/arrow_circle_up.svg';
import { Box, Button } from '@mui/material';
import { isEqual } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { RosterEditableEmployeeSplit } from '@stationwise/share-types';
import { getEmployeeSplitReferenceKey } from '@stationwise/shift-summary-helper';
import { SvgIcon } from '../../../SvgIcon';
import { SplitStatus } from './Split';

interface MergeSplitsButtonGroupProps {
  splits: RosterEditableEmployeeSplit[];
  setSplits: Dispatch<SetStateAction<RosterEditableEmployeeSplit[]>>;
  splitStatuses: SplitStatus[];
  index: number;
}

export const MergeSplitsButtonGroup = (props: MergeSplitsButtonGroupProps) => {
  const prevSplit = props.splits[props.index];
  const nextSplit = props.splits[props.index + 1];
  const prevSplitStatus = props.splitStatuses[props.index];
  const nextSplitStatus = props.splitStatuses[props.index + 1];
  if (!prevSplit || !nextSplit || prevSplitStatus.isDisabled || nextSplitStatus.isDisabled) {
    return null;
  }

  const areSplitReferencesEqual = getEmployeeSplitReferenceKey(prevSplit) === getEmployeeSplitReferenceKey(nextSplit);

  const areSplitsMergeable =
    !prevSplitStatus.errors.has('DURATION') &&
    !nextSplitStatus.errors.has('DURATION') &&
    prevSplit.reference.type === nextSplit.reference.type &&
    isEqual(prevSplit.endDateTime, nextSplit.startDateTime);

  const renderMergeButton = (direction?: 'UP' | 'DOWN') => {
    const onClickMerge = () => {
      const newSplits = [...props.splits];
      newSplits.splice(props.index, 2, {
        ...(direction === 'UP' ? prevSplit : nextSplit),
        startDateTime: prevSplit.startDateTime,
        endDateTime: nextSplit.endDateTime,
      });
      props.setSplits(newSplits);
    };

    return (
      <Button
        variant="outlined"
        disabled={!areSplitsMergeable}
        sx={{ flex: 1, gap: 1, p: '9px 16px', textTransform: 'none' }}
        onClick={onClickMerge}
      >
        {direction && (
          <SvgIcon
            component={ArrowCircleUpSymbol}
            sx={{ fontSize: '18px', transform: direction === 'DOWN' ? 'rotate(180deg)' : undefined }}
          />
        )}
        {!direction && 'Merge'}
        {direction === 'UP' && 'Merge Up'}
        {direction === 'DOWN' && 'Merge Down'}
      </Button>
    );
  };

  return (
    <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
      {areSplitReferencesEqual && renderMergeButton()}
      {!areSplitReferencesEqual && renderMergeButton('UP')}
      {!areSplitReferencesEqual && renderMergeButton('DOWN')}
    </Box>
  );
};
