import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorPage, Modal, theme, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { AutoHireVacancy } from '@stationwise/share-types';
import { ROUTES } from '../../../../../core/Routes';
import { getFormattedTimes } from '../../AutoHire/utils';
import { AutoHireSignUpView } from '../types';
import { SignUpModalContent } from './SignUpModalContent';
import { AutoHireSignUpTopBar } from './TopBar';
import { VacanciesInfo } from './VacanciesInfo';

interface AutoHireSignUpContentProps {
  autoHireId: string;
  data: AutoHireSignUpView;
  forceRefetch: () => void;
}

export const AutoHireSignUpContent = ({ autoHireId, data, forceRefetch }: AutoHireSignUpContentProps) => {
  const [applyBorderStyles, setApplyBorderStyles] = useState(false);
  const [vacantPosition, setVacantPosition] = useState<AutoHireVacancy>(data.autoHireVacancies[0]);
  const [openModal, setModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  const onPressVacancy = (autoHireVacany: AutoHireVacancy) => {
    setVacantPosition(autoHireVacany);
    setModalOpen(true);
  };

  const handleScroll = () => {
    setApplyBorderStyles(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  let startTimeFormatted = '';
  let endTimeFormatted = '';
  let difference = '';

  if (vacantPosition) {
    const formattedTimes = getFormattedTimes(vacantPosition, departmentInfo);
    startTimeFormatted = formattedTimes.startTimeFormatted;
    endTimeFormatted = formattedTimes.endTimeFormatted;

    const hoursPart = formattedTimes.hoursDifference ? `${formattedTimes.hoursDifference}hrs` : '';
    const minutesPart = formattedTimes.minutesDifference ? `${formattedTimes.minutesDifference}min` : '';

    difference = `${hoursPart}${hoursPart && minutesPart ? ' ' : ''}${minutesPart}`;
  }

  const onclickSignUp = async () => {
    if (!vacantPosition) {
      return;
    }

    try {
      const params = {
        autoHireId: autoHireId,
        positionId: vacantPosition.positionId,
        startTime: vacantPosition.startTime,
        endTime: vacantPosition.endTime,
      };
      await client.post('/auto-hire/sign-up/', params);
      forceRefetch();
      navigate(`${ROUTES.PERSONAL_ROSTER.fullRoute}/?date=${format(parseISO(data.autoHire.shiftDate), 'yyyy-MM-dd')}`);
    } catch (error) {
      isAxiosError(error);
      setIsError(true);
    } finally {
      setModalOpen(false);
    }
  };

  const onclickCancel = () => {
    setModalOpen(false);
  };

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.stationGray[100],
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'fixed',
          width: '100%',
          height: '70px',
          zIndex: 3,
        }}
      >
        <AutoHireSignUpTopBar
          shiftName={data.autoHire.teamName}
          shiftColor={data.autoHire.shiftColor}
          applyBorderStyles={applyBorderStyles}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '1px',
          position: 'relative',
          top: '70px',
          zIndex: 2,
          background: `linear-gradient(to right, ${theme.palette.common.white} 0%, ${theme.palette.common.white} 4%, ${theme.palette.stationGray[100]} 4%, ${theme.palette.stationGray[100]} 96%, ${theme.palette.common.white} 96%, ${theme.palette.common.white} 100%)`,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'relative',
          top: '70px',
          mb: '76px',
          alignItems: 'center',
          flexDirection: 'column',
          overflowY: 'auto',
        }}
      >
        <VacanciesInfo
          numberOfVacancies={data.autoHireVacancies.length}
          vacancies={data.autoHireVacancies}
          shiftDate={data.autoHire.shiftDate}
          onPressVacancy={onPressVacancy}
        />
      </Box>
      <Modal open={openModal} setOpen={setModalOpen} hideCloseIcon={false} margin={theme.spacing(2)}>
        <SignUpModalContent
          vacantPosition={vacantPosition}
          shiftDate={data.autoHire.shiftDate}
          startTimeFormatted={startTimeFormatted}
          endTimeFormatted={endTimeFormatted}
          difference={difference}
          onClickSignUp={onclickSignUp}
          onClickCancel={onclickCancel}
        />
      </Modal>
    </Box>
  );
};
