import { Button, Typography, useTheme, Box } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { ResponseBadgeApproved20, ResponseBadgePending20 } from '@stationwise/component-module';
import { InstantHireView } from '@stationwise/share-types';

interface InstantHireHistoryCardProps {
  index: number;
  instantHire: InstantHireView;
  setSelectedInstantHire: Dispatch<SetStateAction<InstantHireView>>;
  setOpenInstantHireHistoryDetails: Dispatch<SetStateAction<boolean>>;
}

export const InstantHireHistoryCard = ({
  index,
  instantHire,
  setSelectedInstantHire,
  setOpenInstantHireHistoryDetails,
}: InstantHireHistoryCardProps) => {
  const theme = useTheme();
  const numberOfAcknowledged = instantHire.positions.filter((position) => position.isAcknowledged).length;

  const handleShowDetails = () => {
    setSelectedInstantHire(instantHire);
    setOpenInstantHireHistoryDetails(true);
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        width: '400px',
        marginBottom: theme.spacing(2),
      })}
    >
      <Typography
        variant="bodySRegular"
        sx={(theme) => ({
          color: theme.palette.stationGray[500],
        })}
      >
        Instant Hire {index}
      </Typography>
      <Typography
        variant="bodyMSemibold"
        sx={(theme) => ({
          color: theme.palette.stationGray[800],
        })}
      >
        {instantHire.positions.length} {instantHire.positions.length === 1 ? 'Person' : 'People'}
        {' Staffed '}
        <Typography component="span" variant="bodyMRegular" sx={{ display: 'inline' }}>
          on
        </Typography>{' '}
        {instantHire.createdAt ? format(instantHire.createdAt, 'MMM dd, HH:mm aa') : 'N/A'}
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          <ResponseBadgeApproved20 />
          <Typography variant="bodySRegular">{numberOfAcknowledged} Acknowledged</Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          <ResponseBadgePending20 />
          <Typography variant="bodySRegular">{instantHire.positions.length - numberOfAcknowledged} Not Acknowledged</Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        sx={{
          borderRadius: '6px',
          width: '100%',
          height: '36px',
          textTransform: 'none',
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.stationGray[300]}`,
          boxShadow: 'none',
          color: theme.palette.stationGray[700],
          '&:hover': {
            backgroundColor: theme.palette.common.white,
            boxShadow: 'none',
          },
        }}
        onClick={handleShowDetails}
      >
        Show details
      </Button>
    </Box>
  );
};
