import { setMinutes } from 'date-fns';
import {
  RosterEmployee,
  RosterEmployeeSplit,
  RosterEmployeeOffPayloads,
  ShiftSummaryEmployeeSplit,
} from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { IShiftSummaryHelper } from './types';

interface GetEmployeeSplitsInput extends RosterEmployeeOffPayloads {
  shiftSummaryHelper: IShiftSummaryHelper;
  employeeSplits: Map<string, ShiftSummaryEmployeeSplit[]>;
}

export const getEmployeeSplits = (input: GetEmployeeSplitsInput) => {
  const { currentBattalion, shiftDuration } = input.shiftSummaryHelper;
  const employeeSplits = new Map<string, RosterEmployeeSplit[]>();
  if (!input.employeeSplits.size) {
    return employeeSplits;
  }

  const pushSplit = (employeeInput: RosterEmployee | string, { startTime, endTime, ...split }: ShiftSummaryEmployeeSplit) => {
    const employee = typeof employeeInput === 'string' ? undefined : employeeInput;
    const employeeId = typeof employeeInput === 'string' ? employeeInput : employeeInput.id;
    if (input.employeeSplits.has(employeeId)) {
      const startDateTime = setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + startTime);
      const endDateTime = setMinutes(shiftDuration.startTime, shiftDuration.startTime.getMinutes() + endTime);
      const splits = employeeSplits.get(employeeId) || [];
      splits.push({ ...split, employee, startDateTime, endDateTime });
      employeeSplits.set(employeeId, splits);
    }
  };

  input.shiftSummaryHelper.allStationCards.forEach((station) => {
    station.apparatuses.forEach((apparatus) => {
      apparatus.positions.forEach((position) => {
        position.employees.forEach((employee) => {
          pushSplit(employee, {
            startTime: differenceInUTCMinutes(employee.startDateTime, shiftDuration.startTime),
            endTime: differenceInUTCMinutes(employee.endDateTime, shiftDuration.startTime),
            reference: {
              type: 'ASSIGNMENT',
              battalion: { id: currentBattalion.id, name: currentBattalion.name },
              station: { id: `${station.stationId}`, name: station.stationName },
              apparatus: { id: `${apparatus.id}`, name: apparatus.name },
              position: position.isTemporary ? null : { id: `${position.id}`, rank: position.rank },
            },
          });
        });
      });
    });
  });

  input.shiftSummaryHelper.unassignedCards.forEach((employee) => {
    pushSplit(employee, {
      startTime: differenceInUTCMinutes(employee.startDateTime, shiftDuration.startTime),
      endTime: differenceInUTCMinutes(employee.endDateTime, shiftDuration.startTime),
      reference: { type: 'ASSIGNMENT', battalion: null, station: null, apparatus: null, position: null },
    });
  });

  const cancelledOffKeys = new Set([
    ...input.cancelTemporaryNonShiftAssignmentPayloads.map((id) => `TEMPORARY_NON_SHIFT:${id}`),
    ...input.cancelShiftTradePayloads.map(({ shiftTradeId: id }) => `SHIFT_TRADE_REQUEST:${id}`),
    ...input.cancelTimeOffPayloads.map(({ timeOffId: id }) => `TIME_OFF_REQUEST:${id}`),
  ]);
  input.employeeSplits.forEach((splits, employeeId) => {
    splits.forEach((split) => {
      if (split.reference.type === 'ASSIGNMENT') {
        if (split.reference.battalion && split.reference.battalion.id !== currentBattalion.id) {
          pushSplit(employeeId, split);
        }
      } else {
        if (!cancelledOffKeys.has(`${split.reference.type}:${split.reference.id}`)) {
          pushSplit(employeeId, split);
        }
      }
    });
  });

  input.createTemporaryNonShiftAssignmentPayloads.forEach((payload) => {
    pushSplit(payload.candidateId, {
      startTime: payload.startTime,
      endTime: payload.endTime,
      reference: { type: 'TEMPORARY_NON_SHIFT', id: 0 },
    });
  });

  input.createShiftTradePayloads.forEach((payload) => {
    pushSplit(payload.senderId, {
      startTime: payload.startTime,
      endTime: payload.endTime,
      reference: { type: 'SHIFT_TRADE_REQUEST', id: 0 },
    });
  });

  input.createTimeOffPayloads.forEach((payload) => {
    pushSplit(payload.employeeId, {
      startTime: payload.startTime,
      endTime: payload.endTime,
      reference: { type: 'TIME_OFF_REQUEST', id: 0, payCode: { code: payload.payCode } },
    });
  });

  employeeSplits.forEach((splits) => splits.sort((a, b) => differenceInUTCMinutes(a.startDateTime, b.startDateTime)));
  return employeeSplits;
};

export const getEmployeeSplitReferenceKey = (split: Pick<RosterEmployeeSplit, 'reference'>) => {
  const keys: string[] = [split.reference.type];
  if (split.reference.type === 'ASSIGNMENT') {
    keys.push(`${split.reference.battalion?.id}`, `${split.reference.apparatus?.id}`, `${split.reference.position?.id}`);
  } else if (split.reference.type === 'TIME_OFF_REQUEST') {
    keys.push(`${split.reference.id}`, split.reference.payCode.code);
  } else {
    keys.push(`${split.reference.id}`);
  }
  return keys.join('|');
};
