import { Box } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';
import { ChevronDownIcon20, TimePicker } from '@stationwise/component-module';

interface TimeSelectProps {
  label: string;
  dateTime: string;
  setDateTime: Dispatch<SetStateAction<string>>;
  minTime: Date;
  maxTime: Date;
  usePreciseMinutes?: boolean;
  paddingBottom: string;
}

export const TimeSelect = ({
  label,
  dateTime,
  setDateTime,
  minTime,
  maxTime,
  usePreciseMinutes,
  paddingBottom,
}: TimeSelectProps) => {
  const [openTimeSelect, setOpenTimeSelect] = useState(false);

  return (
    <Box
      sx={{
        width: '-webkit-fill-available',
      }}
    >
      <Box
        sx={(theme) => ({
          typography: 'bodyLMedium',
          display: 'flex',
          ml: theme.spacing(0.25),
        })}
      >
        {label}
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          width: '100%',
          mt: theme.spacing(0.5),
        })}
      >
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.stationGray[200]}`,
            borderRadius: theme.spacing(1),
            p: '10px 13px 10px',
            width: '50%',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            typography: 'bodySRegular',
            '& svg': {
              color: theme.palette.stationGray[400],
            },
          })}
          onClick={() => setOpenTimeSelect(true)}
        >
          <Box sx={(theme) => ({ mr: theme.spacing(2) })}>{format(dateTime, 'HH:mm')}</Box>
          <ChevronDownIcon20 />
        </Box>
      </Box>
      <TimePicker
        openTimeSelect={openTimeSelect}
        setOpenTimeSelect={setOpenTimeSelect}
        handleTime={(value: Date) => {
          setOpenTimeSelect(false);
          setDateTime(value.toISOString());
        }}
        paddingBottom={paddingBottom}
        timeSteps={usePreciseMinutes ? { hours: 1, minutes: 15 } : undefined}
        defaultValue={new Date(dateTime)}
        minTime={minTime}
        maxTime={maxTime}
      />
    </Box>
  );
};
