import { format } from 'date-fns';
import {
  TemporaryNonShiftAssignment,
  PayCode,
  RosterDataSource,
  RosterEmployee,
  RosterTemporaryNonShiftAssignment,
  TEMPORARY_NON_SHIFT_TITLES,
} from '@stationwise/share-types';
import { getOverrideEmployeePositionFields, sortStations } from '../board';
import { checkIsTemporaryNonShiftApparatus, setEmployeeActiveId } from '../id';
import { IShiftSummaryHelper } from '../types';

interface CreateProps {
  shiftSummaryHelper: IShiftSummaryHelper;
  employee: RosterEmployee;
  assignment: RosterTemporaryNonShiftAssignment;
}

const convertToDate = (date: Date, minutes: number): Date => {
  return new Date(date.getTime() + minutes * 60000);
};

export const createTemporaryNonShiftAssignment = ({
  shiftSummaryHelper,
  employee,
  assignment,
}: CreateProps): IShiftSummaryHelper => {
  const payCode = getPayCodeById(shiftSummaryHelper, assignment.payCodeId);
  if (!payCode) {
    throw new Error('Pay code not found');
  }

  const temporaryNonShiftAssignment: TemporaryNonShiftAssignment = {
    id: -(Date.now() + employee.id),
    employee: employee,
    temporaryNonShiftType: assignment.temporaryNonShiftType,
    startTime: assignment.startTime,
    endTime: assignment.endTime,
    breakDuration: 0,
    payCodes: [payCode],
    activationDate: format(assignment.activationDate, 'yyyy-MM-dd'),
    deactivationDate: format(assignment.deactivationDate, 'yyyy-MM-dd'),
  };

  const temporaryNonShiftEmployee = convertToTemporaryNonShiftEmployee(
    shiftSummaryHelper,
    employee,
    temporaryNonShiftAssignment,
    payCode,
  );

  return addEmployeeToTemporaryNonShiftApparatus(shiftSummaryHelper, temporaryNonShiftEmployee, temporaryNonShiftAssignment);
};

const getPayCodeById = (shiftSummaryHelper: IShiftSummaryHelper, payCodeId: number): PayCode | undefined => {
  return shiftSummaryHelper.departmentInfo.payCodes.find((payCode) => payCode.id === payCodeId);
};

const convertToTemporaryNonShiftEmployee = (
  shiftSummaryHelper: IShiftSummaryHelper,
  employee: RosterEmployee,
  assignment: TemporaryNonShiftAssignment,
  payCode: PayCode,
): RosterEmployee => {
  return setEmployeeActiveId({
    ...employee,
    dataSource: RosterDataSource.TEMPORARY_NON_SHIFT,
    payCodes: [payCode],
    detailCodes: [],
    startDateTime: convertToDate(shiftSummaryHelper.shiftDuration.startTime, assignment.startTime),
    endDateTime: convertToDate(shiftSummaryHelper.shiftDuration.startTime, assignment.endTime),
    ...getOverrideEmployeePositionFields(),
  });
};

const addEmployeeToTemporaryNonShiftApparatus = (
  shiftSummaryHelper: IShiftSummaryHelper,
  temporaryNonShiftEmployee: RosterEmployee,
  temporaryNonShiftAssignment: TemporaryNonShiftAssignment,
) => {
  const stationKey = 'temporary-assignment-station';

  if (!shiftSummaryHelper.allStationCards.has(stationKey)) {
    shiftSummaryHelper.allStationCards.set(stationKey, {
      stationId: stationKey,
      stationName: 'Temporary Assignment',
      dataSource: RosterDataSource.TEMPORARY_NON_SHIFT,
      apparatuses: [],
      certificationRequirements: [],
    });
    shiftSummaryHelper.allStationCards = sortStations(shiftSummaryHelper.allStationCards);
  }

  const station = shiftSummaryHelper.allStationCards.get(stationKey);
  if (!station) {
    throw new Error(`Station ${stationKey} not found`);
  }

  const apparatusName =
    TEMPORARY_NON_SHIFT_TITLES.get(temporaryNonShiftAssignment.temporaryNonShiftType) ||
    temporaryNonShiftAssignment.temporaryNonShiftType;

  const apparatusExists = station.apparatuses.some((apparatus) => apparatus.name === apparatusName);

  if (!apparatusExists) {
    station.apparatuses.push({
      id: 'temporary-assignment-apparatus',
      dataSource: RosterDataSource.TEMPORARY_NON_SHIFT,
      positions: [],
      name: apparatusName,
      isForShiftLeader: false,
      certificationRequirements: [],
    });
  }

  const apparatus = station.apparatuses.find((apparatus) => apparatus.name === apparatusName);
  if (!apparatus) {
    throw new Error(`Apparatus ${temporaryNonShiftAssignment.temporaryNonShiftType} not found in station ${stationKey}`);
  }

  if (checkIsTemporaryNonShiftApparatus(apparatus)) {
    apparatus.positions.push({
      id: `temporary-assignment-position-${temporaryNonShiftEmployee.id}`,
      dataSource: RosterDataSource.TEMPORARY_NON_SHIFT,
      startDateTime: temporaryNonShiftEmployee.startDateTime,
      endDateTime: temporaryNonShiftEmployee.endDateTime,
      employees: [temporaryNonShiftEmployee],
      certifications: temporaryNonShiftEmployee.certifications,
      driver: false,
      rank: temporaryNonShiftEmployee.rank,
      temporaryNonShiftAssignment,
    });
  }

  return shiftSummaryHelper;
};
