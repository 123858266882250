import { captureException } from '@sentry/react';
import { useState } from 'react';
import { SnackbarService, SwDialog } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { StaffingListItemEmployee } from '@stationwise/share-types';
import { EligibleEmployeesContent } from './EligibleEmployeesContent';

interface RequestVolunteersEligibleEmployeesMainProps {
  selectedDate: string;
  showRequestVolunteersEligibleEmployees: boolean;
  setShowRequestVolunteersEligibleEmployees: (show: boolean) => void;
  bulkCallIsLoading: boolean;
  setBulkCallIsLoading: (loading: boolean) => void;
  forceRefetch: () => void;
  setBulkCallIsOpen: (open: boolean) => void;
}

export const RequestVolunteersEligibleEmployeesMain = ({
  selectedDate,
  showRequestVolunteersEligibleEmployees,
  setShowRequestVolunteersEligibleEmployees,
  bulkCallIsLoading,
  setBulkCallIsLoading,
  forceRefetch,
  setBulkCallIsOpen,
}: RequestVolunteersEligibleEmployeesMainProps) => {
  const [eligibleEmployees, setEligibleEmployees] = useState<StaffingListItemEmployee[]>([]);
  const bulkCall = async () => {
    try {
      setBulkCallIsLoading(true);
      const response = await client.post(`/shift/bulk-call/`, {
        shiftDate: selectedDate,
        eligibleEmployeeIds: eligibleEmployees.map((employee) => employee.id),
      });
      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });
      forceRefetch();
      setBulkCallIsLoading(false);
      setBulkCallIsOpen(false);
      setShowRequestVolunteersEligibleEmployees(false);
    } catch (error) {
      SnackbarService.notify({
        content: 'Error sending bulk call',
        severity: 'error',
      });
      captureException(error);
      setBulkCallIsLoading(false);
      setBulkCallIsOpen(false);
      setShowRequestVolunteersEligibleEmployees(false);
    }
  };

  return (
    <SwDialog
      isOpen={showRequestVolunteersEligibleEmployees}
      onSubmit={bulkCall}
      onCancel={() => setShowRequestVolunteersEligibleEmployees(false)}
      title="Request volunteers"
      label="Send Volunteer Request"
      loading={bulkCallIsLoading}
      width="660px"
      disabled={eligibleEmployees.length === 0}
    >
      <EligibleEmployeesContent setEligibleEmployees={setEligibleEmployees} />
    </SwDialog>
  );
};
