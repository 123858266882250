import { Box } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import {
  RankBadge,
  Button,
  Modal,
  Popover,
  POPOVER_POSITION,
  RefundOptType,
  ShiftTradeRefundOptions,
  ShiftTradeCancelButtons,
} from '@stationwise/component-module';
import { EmployeeOffView } from '@stationwise/share-types';
import { WarningModal } from '../../../../../components/Common';
import { NonShiftDetails } from './NonShiftDetails';
import { RequestType } from './RequestType';

export const CancellationPopover = ({
  employeeOffData,
  anchorEl,
  popoverOpen,
  handleClose,
  cancelRequest,
}: {
  employeeOffData: EmployeeOffView;
  anchorEl: HTMLElement | null;
  popoverOpen: boolean;
  handleClose: () => void;
  cancelRequest: (opt: RefundOptType) => void;
}) => {
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState<RefundOptType>('REFUND_HOURS');
  const [step, setStep] = useState(1);

  const handleRefundOption = (opt: RefundOptType) => {
    setSelectedButton(opt);
  };

  const shiftTradeReset = () => {
    setStep(1);
    setWarningModalOpen(false);
    setSelectedButton('REFUND_HOURS');
  };
  const handleWarning = (isOpen: boolean) => {
    setWarningModalOpen(isOpen);
    shiftTradeReset();
  };
  const handleNextStep = () => {
    setStep(2);
  };

  const onCancelRequest = () => {
    setWarningModalOpen(false);
    handleClose();
    cancelRequest(selectedButton);
    shiftTradeReset();
  };

  return (
    <>
      <Popover anchorEl={anchorEl} open={popoverOpen} onClose={handleClose} {...POPOVER_POSITION.RIGHT}>
        <Box sx={(theme) => ({ width: '296px', p: theme.spacing(2) })}>
          <Box sx={(theme) => ({ display: 'flex', mb: theme.spacing(2) })}>
            <RequestType employeeOffData={employeeOffData} />
            <Box sx={(theme) => ({ typography: 'bodySMedium', color: theme.palette.common.white, ml: theme.spacing(1) })}>
              {employeeOffData.title}
            </Box>
          </Box>
          {employeeOffData.approvedAt && employeeOffData.approvedBy && (
            <Box sx={(theme) => ({ backgroundColor: theme.palette.stationGray[800], p: 2, borderRadius: 2, mb: 2 })}>
              <Box display="flex" justifyContent="space-between">
                <Box sx={(theme) => ({ typography: 'bodySMedium', color: theme.palette.stationGray[300] })}>Approved</Box>
                <Box sx={(theme) => ({ typography: 'bodyXSMedium', color: theme.palette.stationGray[400] })}>
                  {format(employeeOffData.approvedAt, 'MMM d, HH:mm')}
                </Box>
              </Box>
              <Box display="flex" sx={{ mt: '10px' }}>
                <RankBadge rank={employeeOffData.approvedBy.rank} rankField="name" dark={true} />
              </Box>
              <Box sx={(theme) => ({ typography: 'bodyMMedium', color: theme.palette.common.white, mt: '10px' })}>
                {`${employeeOffData.approvedBy.name}`}
              </Box>
            </Box>
          )}
          {employeeOffData.requestType === 'EXTENDED_LEAVE' && <NonShiftDetails employeeOffData={employeeOffData} />}
          <Button
            onClick={() => setWarningModalOpen(true)}
            buttonType="tertiary"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              borderColor: theme.palette.stationGray[500],
              borderRadius: '6px',
              color: '#fff',
              width: '100%',
              '&:hover': {
                backgroundColor: theme.palette.stationGray[500],
              },
              display: 'flex',
              gap: '10px',
              justifyContent: 'center',
              mt: 2,
            })}
          >
            {`Cancel ${employeeOffData.title.toLowerCase()}`}
          </Button>
        </Box>
      </Popover>
      <Modal open={warningModalOpen} setOpen={setWarningModalOpen} hideCloseIcon>
        {employeeOffData.requestType === 'ShiftTradeRequest' && step === 1 ? (
          <>
            <Box sx={(theme) => ({ typography: 'bodyXLSemibold', display: 'flex' })}>Cancel shift trade</Box>
            <ShiftTradeRefundOptions selectedButton={selectedButton} handleButtonClick={handleRefundOption} />
            <ShiftTradeCancelButtons onClose={shiftTradeReset} onSubmit={handleNextStep} step={step} setStep={setStep} />
          </>
        ) : (
          <WarningModal setModalOpen={handleWarning} onConfirm={onCancelRequest}>
            <Box sx={{ width: '416px' }}>
              <Box sx={{ typography: 'bodyXXLSemibold' }}>
                {`Do you wish to cancel this approved ${employeeOffData.title.toLowerCase()}?`}
              </Box>
              <Box sx={(theme) => ({ typography: 'bodyMRegular', mt: theme.spacing(2) })}>
                {`User's ${employeeOffData.title.toLowerCase()} will be removed.`}
              </Box>
            </Box>
          </WarningModal>
        )}
      </Modal>
    </>
  );
};
