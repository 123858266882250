import { Box, Checkbox } from '@mui/material';
import { CustomTable, LetterAvatar, TableProps } from '@stationwise/component-module';
import { WorkGroupMember } from '@stationwise/share-types';

interface TableMembersProps {
  employees: WorkGroupMember[];
  handleClick: (arg0: string) => void;
  selectedIds: string[];
  hideCheckbox?: boolean;
}

export const TableMembers = ({ employees, handleClick, selectedIds, hideCheckbox = false }: TableMembersProps) => {
  const columns: TableProps['columns'] = [
    {
      id: 'checkbox',
      label: hideCheckbox ? (
        ''
      ) : (
        <Checkbox
          sx={(theme) => ({
            '&.Mui-checked': {
              color: theme.palette.stationPurple[500],
            },
          })}
          checked={selectedIds.length === employees.length}
          onClick={() => handleClick('-1')}
        />
      ),
      minWidth: 20,
      align: 'center',
    },
    { id: 'name', label: 'NAME', minWidth: 200, align: 'left' },
    { id: 'rank', label: 'RANK', minWidth: 100, align: 'center' },
    { id: 'station', label: 'STATION', minWidth: 100, align: 'center' },
  ];

  const data: TableProps['data'] = employees.map((employee) => ({
    employee,

    checkbox: hideCheckbox ? (
      ''
    ) : (
      <Checkbox
        sx={(theme) => ({
          '&.Mui-checked': {
            color: theme.palette.stationPurple[500],
          },
        })}
        checked={selectedIds.includes(employee.id)}
        onClick={() => handleClick(employee.id)}
      />
    ),

    name: (
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          alignItems: 'center',
        })}
      >
        <LetterAvatar firstName={employee.name} color={employee.hasAdminPermission ? 'green' : 'lightblue'} />
        <Box>
          <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodySMedium' })}>{employee.name}</Box>
          <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>{employee.email}</Box>
        </Box>
      </Box>
    ),

    rank: (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
        {employee.rank?.name || 'N/A'}
      </Box>
    ),
    station: (
      <Box sx={(theme) => ({ color: theme.palette.stationGray[600], typography: 'bodySRegular' })}>
        {employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0
          ? employee.plannedShiftDetails[0].station
          : 'Unassigned'}
      </Box>
    ),
  }));

  return <CustomTable columns={columns} data={data} />;
};
