import { Box, SxProps, Theme } from '@mui/material';
import { DriverIcon20 } from '../../assets';
import { CardTooltip } from './CardTooltip';

interface DriverIndicatorProps {
  sx?: SxProps<Theme>;
}

export const DriverIndicator = ({ sx }: DriverIndicatorProps) => {
  return (
    <CardTooltip
      anchorContent={<DriverIcon20 />}
      anchorSx={[(theme) => ({ color: theme.palette.stationGray[500] }), ...(Array.isArray(sx) ? sx : sx ? [sx] : [])]}
      popoverSx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodyMRegular' })}
      popoverContent={
        <>
          <Box component={DriverIcon20} sx={(theme) => ({ color: theme.palette.stationGray[500] })} />
          Driver of this Apparatus
        </>
      }
    />
  );
};
