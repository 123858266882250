import { Box } from '@mui/material';
import { useState } from 'react';
import { ErrorBanner } from '../../../ErrorBanner';
import { Loader } from '../../../Loader';
import { useRosterContext } from '../RosterContext';
import { StaffingListControls, StaffingListCards } from '../StaffingList';

export const HireBackList = () => {
  const { staffingListsState } = useRosterContext();

  const [searchInput, setSearchInput] = useState('');
  const [selectedRankOptions, setSelectedRankOptions] = useState(new Set<string>());
  const [selectedCertOptions, setSelectedCertOptions] = useState(new Set<string>());
  const [selectedTeamOptions, setSelectedTeamOptions] = useState(new Set<string>());
  const [sortValue, setSortValue] = useState('Number');

  return (
    <Box data-cy="hireback-list" display="flex" flexDirection="column" width="100%" height="100%">
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', pt: 2.5 }}>
        <StaffingListControls
          items={staffingListsState.availableStaffingListItems}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          selectedRankOptions={selectedRankOptions}
          setSelectedRankOptions={setSelectedRankOptions}
          selectedCertOptions={selectedCertOptions}
          setSelectedCertOptions={setSelectedCertOptions}
          selectedTeamOptions={selectedTeamOptions}
          setSelectedTeamOptions={setSelectedTeamOptions}
          sortValue={sortValue}
          setSortValue={setSortValue}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            overflowY: 'auto',
            position: 'relative',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { width: 0 },
            px: 2,
            flexGrow: 1,
          }}
        >
          {staffingListsState.isLoading && <Loader />}
          {!staffingListsState.isLoading && staffingListsState.isError && (
            <ErrorBanner>Unable to load staffing list.</ErrorBanner>
          )}
          {!staffingListsState.isLoading && !staffingListsState.isError && (
            <StaffingListCards
              selectedStaffingList={staffingListsState.selectedStaffingList}
              items={staffingListsState.availableStaffingListItems}
              searchInput={searchInput}
              selectedRankOptions={selectedRankOptions}
              selectedCertOptions={selectedCertOptions}
              selectedTeamOptions={selectedTeamOptions}
              sortValue={sortValue}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
