import { ReactComponent as AvgPaceSymbol } from '@material-symbols/svg-400/outlined/avg_pace.svg';
import { Box, Tooltip, useTheme } from '@mui/material';
import { StaffingListItem } from '@stationwise/share-types';
import { differenceInUTCMinutes, makeTestIdentifier } from '@stationwise/share-utils';
import {
  cutDuration,
  formatShiftDuration,
  getOvertimeCardErrorMessage,
  makeOvertimeEmployeeActiveId,
} from '@stationwise/shift-summary-helper';
import { getTooltipProps } from '../../../../utils/utils';
import { HireBackCard } from '../../../HireBackCard';
import { SvgIcon } from '../../../SvgIcon';
import { getEmployeeCardStyles } from '../EmployeeCard';
import { useRosterContext } from '../RosterContext';

interface HireBackOperatorCardProps {
  operator: StaffingListItem;
  isDragOverlayCard?: boolean;
}

export const HireBackOperatorCard = ({ operator, isDragOverlayCard = false }: HireBackOperatorCardProps) => {
  const theme = useTheme();
  const { staffingListsState, selectedEmptyPositionState, shiftSummaryHelper } = useRosterContext();
  const splits = staffingListsState.employeeSplits.get(operator.employee.id) || [];
  const totalDuration = splits.reduce((sum, e) => sum + differenceInUTCMinutes(e.endDateTime, e.startDateTime), 0);
  const errorMessage = getOvertimeCardErrorMessage(shiftSummaryHelper, operator);
  const hasErrorMessageStyle = !!errorMessage && !isDragOverlayCard;

  const getPartiallyAvailableTooltipText = () => {
    const availableDurations = cutDuration(
      { startDateTime: shiftSummaryHelper.shiftDuration.startTime, endDateTime: shiftSummaryHelper.shiftDuration.endTime },
      splits,
    );
    const availableDurationTexts = availableDurations.map((d) => {
      return formatShiftDuration({ startTime: d.startDateTime, endTime: d.endDateTime });
    });
    return (
      <Box component="span" sx={{ whiteSpace: 'pre' }}>
        {['Partially Available:', ...availableDurationTexts].join('\n')}
      </Box>
    );
  };

  const card = (
    <Box
      data-cy={`ot-card-${makeTestIdentifier(operator.employee.name)}`}
      sx={[
        {
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
          borderRadius: '12px',
          border: `1px solid ${theme.palette.stationGray[300]}`,
          opacity: hasErrorMessageStyle ? 0.5 : undefined,
          position: 'relative',
        },
        getEmployeeCardStyles({
          theme,
          employeeActiveId: makeOvertimeEmployeeActiveId(`${operator.employee.id}`),
          selectedEmptyPositionState,
        }),
      ]}
    >
      <HireBackCard
        type="border"
        rank={operator.employee.rank}
        borderColor={operator.employee.team?.color || 'gray'}
        nameLabel={operator.employee.name}
        certs={operator.employee.certifications}
        number={operator.order}
        partialHoursOt={`${operator.attributes.overtimeAccHours ?? ''}`}
        lastShiftOt={operator.attributes.lastThreeOvertimes?.[0]?.date ?? ''}
        timeAsString={operator.attributes.lastThreeOvertimes?.[0]?.staffedAt ?? ''}
        cardSxProps={{ border: 0, '&:hover': { border: 0 } }}
      />
      {totalDuration > 0 && totalDuration < 24 * 60 && (
        <Tooltip arrow title={getPartiallyAvailableTooltipText()} placement="left" slotProps={getTooltipProps({ width: 148 })}>
          <Box
            sx={{
              backgroundColor: theme.palette.stationGray[900],
              color: theme.palette.common.white,
              borderRadius: '50%',
              display: 'inline-flex',
              position: 'absolute',
              bottom: theme.spacing(1),
              right: theme.spacing(1.5),
            }}
          >
            <SvgIcon component={AvgPaceSymbol} sx={{ fontSize: '20px' }} />
          </Box>
        </Tooltip>
      )}
    </Box>
  );

  if (!hasErrorMessageStyle) {
    return card;
  }

  return (
    <Tooltip arrow title={errorMessage} placement="bottom" slotProps={getTooltipProps()}>
      {card}
    </Tooltip>
  );
};
