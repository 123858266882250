import { Box, FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { useId, useMemo } from 'react';
import { DepartmentInfo, RosterEditableEmployeeSplit, TimeOffLimit } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';

export interface ReasonOption {
  text: string;
  payCode: string;
  limit: number | null;
  isUnlimited: boolean;
}

const DEFAULT_OPTION: ReasonOption = { text: 'Select a reason', payCode: '', limit: null, isUnlimited: true };

export const getTimeOffReasonOptions = (
  departmentInfo: DepartmentInfo,
  accruals: TimeOffLimit[] | null,
  initialSplits: RosterEditableEmployeeSplit[],
) => {
  const options = new Map([[DEFAULT_OPTION.payCode, DEFAULT_OPTION]]);

  if (departmentInfo.timeOffTypes) {
    departmentInfo.timeOffTypes.forEach((timeOffType) => {
      options.set(timeOffType.code, {
        text: timeOffType.name,
        payCode: timeOffType.code,
        limit: null,
        isUnlimited: true,
      });
    });
  }

  if (accruals) {
    const unsavedTimeOffDurationMinutes = new Map<string, number>();
    initialSplits.forEach((split) => {
      if (split.initialReference.type === 'TIME_OFF_REQUEST' && split.initialReference.id <= 0) {
        let durationMinutes = unsavedTimeOffDurationMinutes.get(split.initialReference.payCode.code) || 0;
        durationMinutes += differenceInUTCMinutes(split.endDateTime, split.startDateTime);
        unsavedTimeOffDurationMinutes.set(split.initialReference.payCode.code, durationMinutes);
      }
    });

    accruals.forEach((accrual) => {
      const unsaved = (unsavedTimeOffDurationMinutes.get(accrual.payCode.code) || 0) / 60;
      options.set(accrual.payCode.code, {
        text: accrual.payCode.name,
        payCode: accrual.payCode.code,
        limit: departmentInfo.settings.accrualLogicEnabled ? accrual.accruedTimeOff - accrual.pending - unsaved : null,
        isUnlimited: departmentInfo.settings.accrualLogicEnabled ? accrual.isUnlimited : true,
      });
    });
  }

  return options;
};

interface ReasonProps extends Pick<SelectProps<string>, 'value' | 'onChange'> {
  options: Map<string, ReasonOption>;
  disabled?: boolean;
}

export const Reason = ({ options, disabled, ...props }: ReasonProps) => {
  const id = useId();

  const { longestPayCode, shouldDisplayNotAvailableCode } = useMemo(() => {
    let longestPayCode = 'N/A';
    let shouldDisplayNotAvailableCode = false;
    for (const option of options.values()) {
      if (option.payCode.length > longestPayCode.length) {
        longestPayCode = option.payCode;
      }

      if (option !== DEFAULT_OPTION && option.payCode !== option.text) {
        shouldDisplayNotAvailableCode = true;
      }
    }

    return { longestPayCode, shouldDisplayNotAvailableCode };
  }, [options]);

  const checkShouldDisplayPayCode = (option: ReasonOption) => {
    return option === DEFAULT_OPTION ? shouldDisplayNotAvailableCode : option.payCode !== option.text;
  };

  return (
    <FormControl disabled={disabled} sx={{ mt: 2, width: '100%' }}>
      <InputLabel id={`${id}label`} shrink={true}>
        Reason
      </InputLabel>
      <Select
        labelId={`${id}label`}
        id={`${id}select`}
        label="Reason"
        displayEmpty={true}
        renderValue={(key) => {
          const option = options.get(key) || DEFAULT_OPTION;
          return (
            <Box
              data-cy="split-reason-select"
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {checkShouldDisplayPayCode(option) && (
                  <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodySMedium' })}>
                    {option.payCode}
                  </Box>
                )}
                <Box sx={{ typography: 'bodyMRegular' }}>{option.text}</Box>
              </Box>
              {option.limit !== null && (
                <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodyMRegular' })}>
                  {option.isUnlimited ? 'Unlimited' : `${option.limit.toFixed(2)} h`}
                </Box>
              )}
            </Box>
          );
        }}
        sx={{ width: '100%' }}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: -8, horizontal: 'left' },
        }}
        {...props}
      >
        {[...options.entries()].map(([key, option]) => (
          <MenuItem key={key} value={key} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {checkShouldDisplayPayCode(option) && (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: theme.palette.stationGray[500],
                    typography: 'bodySMedium',
                    whiteSpace: 'nowrap',
                  })}
                >
                  <Box>{option === DEFAULT_OPTION ? 'N/A' : option.payCode}</Box>
                  <Box sx={{ userSelect: 'none', visibility: 'hidden', height: 0, overflow: 'hidden' }}>{longestPayCode}</Box>
                </Box>
              )}
              <Box sx={{ typography: 'bodyMRegular' }}>{option.text}</Box>
            </Box>
            {option.limit !== null && (
              <Box sx={(theme) => ({ color: theme.palette.stationGray[500], typography: 'bodyMRegular' })}>
                {option.isUnlimited ? 'Unlimited' : `${option.limit.toFixed(2)} h`}
              </Box>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
