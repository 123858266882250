import { Dialog } from '@mui/material';
import { useRosterContext } from '../RosterContext';
import { SplitShiftOrTimeOffForm } from './SplitShiftOrTimeOffForm';

export const SplitShiftOrTimeOffModal = () => {
  const { selectedFilledPositionState, splitShiftOrTimeOffState } = useRosterContext();
  const { employee } = selectedFilledPositionState;

  return (
    <Dialog
      open={!!employee && splitShiftOrTimeOffState.isDialogOpen}
      onClose={() => splitShiftOrTimeOffState.setIsDialogOpen(false)}
      sx={{ '& .MuiDialog-paper': { borderRadius: '16px', position: 'relative', width: '600px' } }}
    >
      {employee && <SplitShiftOrTimeOffForm employee={employee} />}
    </Dialog>
  );
};
