import { Box, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useId, useState } from 'react';
import { DotsHorizontalIcon16 } from '../../assets';

export interface ActionMenuItem {
  label: string;
  onClick: () => void;
}

interface ActionsProps {
  actions: ActionMenuItem[];
  hoverColor?: string;
  color?: string;
}

export const Actions = ({ actions, hoverColor, color }: ActionsProps) => {
  const id = useId();
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const onClickMenuButton = (event: MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget);
  const onCloseMenu = () => setMenuAnchorEl(null);

  const onClickMenuItem = (action: ActionMenuItem) => {
    onCloseMenu();
    action.onClick();
  };

  return (
    <>
      <Box
        component="button"
        id={`${id}button`}
        aria-controls={menuAnchorEl ? `${id}menu` : undefined}
        aria-haspopup="true"
        aria-expanded={menuAnchorEl ? 'true' : undefined}
        onClick={onClickMenuButton}
        sx={(theme) => ({
          background: 'none',
          border: 0,
          borderRadius: 2,
          color: color || theme.palette.stationGray[900],
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 0.5,
          position: 'absolute',
          top: theme.spacing(-0.5),
          right: theme.spacing(-0.25),
          '&:hover': { background: hoverColor || theme.palette.stationGray[300] },
        })}
      >
        <DotsHorizontalIcon16 />
      </Box>
      <Menu
        id={`${id}menu`}
        anchorEl={menuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: -8, horizontal: 'right' }}
        open={!!menuAnchorEl}
        onClose={onCloseMenu}
        MenuListProps={{ 'aria-labelledby': `${id}button` }}
        sx={(theme) => ({
          '& .MuiMenu-paper': {
            border: `1px solid ${theme.palette.stationGray[100]}`,
            borderRadius: '12px',
            boxShadow: '0px 4px 6px -2px #0A0E160D, 0px 10px 15px -3px #0A0E161A',
          },
          '& .MuiMenu-list': { p: theme.spacing(0.5) },
          '& .MuiMenuItem-root': {
            borderRadius: '8px',
            color: theme.palette.stationGray[900],
            p: theme.spacing(1, 1.5),
            typography: 'bodySRegular',
          },
          '& .MuiMenuItem-root:hover': { backgroundColor: theme.palette.stationGray[300] },
        })}
      >
        {actions.map((action) => (
          <MenuItem key={action.label} onClick={() => onClickMenuItem(action)}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
