import { Box, Typography } from '@mui/material';
import { WhiteStationIcon20, RankBadge, BlankCard } from '@stationwise/component-module';
import { AllowedColors } from '@stationwise/share-types';

export interface VacancyInfoCardProps {
  rankName: string;
  rankColor: string;
  stationName: string;
  apparatusName: string;
}

export const VacancyInfoCard = ({ rankName, rankColor, stationName, apparatusName }: VacancyInfoCardProps) => {
  return (
    <BlankCard
      sxProps={() => ({
        width: '100%',
        height: '92px',
        border: 'none',
        '&:hover': {
          border: 'none',
        },
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        mb: '8px',
      })}
    >
      <Box display="flex" height="100%" width="100%" flexDirection={'column'} justifyContent="center">
        <Box display="flex" height="90%" width="100%" flexDirection="column">
          <RankBadge
            rank={{ name: rankName, code: rankName, color: rankColor as AllowedColors }}
            maxLength={20}
            sx={{ alignSelf: 'flex-start' }}
          />
          <Box display="flex" alignItems="center" sx={{ mt: '10px' }} gap="5px">
            <WhiteStationIcon20 />
            <Typography
              variant="bodyMMedium"
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                whiteSpace: 'nowrap',
              })}
            >
              {stationName}, {apparatusName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </BlankCard>
  );
};
