import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { EmployeeTypes, ListFieldsStaffingList } from '@stationwise/share-types';
import { theme, useAuthUserCapabilities } from '../../../component-module';
import { useLoadedAuthUserContext } from '../../Auth';
import { useLoadedDepartmentInfoContext } from '../../Department';
import { DisplayOption } from '../Calendar';
import { ActionButton } from './ActionButton';
import { DateSelector } from './DateSelector';

interface HeaderProps {
  title: string;
  selectedView: DisplayOption;
  prevHandle: () => void;
  nextHandle: () => void;
  requestOvertimeDisabled?: boolean;
  handleRequestOvertimeClick?: (staffingList: ListFieldsStaffingList) => void;
  handleCreateIncidentClick?: () => void;
  children?: ReactNode;
  isLoading?: boolean;
  setCreateIncident: (value: boolean) => void;
  setCreateIncidentOpen?: (value: boolean) => void;
  createIncidentOpen?: boolean;
  selectedStaffingList?: ListFieldsStaffingList | null;
  viewingPersonalCalendar: boolean;
}

export const Header = ({
  title,
  selectedView,
  prevHandle,
  nextHandle,
  requestOvertimeDisabled,
  handleRequestOvertimeClick,
  handleCreateIncidentClick,
  isLoading,
  setCreateIncident,
  setCreateIncidentOpen,
  createIncidentOpen,
  selectedStaffingList,
  viewingPersonalCalendar,
}: HeaderProps) => {
  const { state: authUserState } = useLoadedAuthUserContext();
  const { state: departmentContext } = useLoadedDepartmentInfoContext();
  const capabilities = useAuthUserCapabilities();
  const incidentReportAdminEnabled = capabilities.MANAGE_INCIDENT_REPORT;

  const overtimeRequestEnabled = departmentContext.departmentInfo.settings.overtimeRequestEnabled;
  const incidentFormEnabled = departmentContext.departmentInfo.settings.incidentFormEnabled;

  const showActionButton =
    !viewingPersonalCalendar &&
    !authUserState.employee.isNonShift &&
    !(authUserState.employee.employeeType === EmployeeTypes.VOLUNTEER) &&
    !isLoading &&
    (overtimeRequestEnabled || incidentFormEnabled) &&
    selectedView === 'dayGridMonth';

  const onClickCreateIncident = () => {
    setCreateIncident(true);
    setCreateIncidentOpen && setCreateIncidentOpen(true);
    handleCreateIncidentClick && handleCreateIncidentClick();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={(theme) => ({
        pb: '12px',
        pt: '12px',
        position: selectedView === 'listMonth' ? 'sticky' : 'unset',
        top: '0px',
        zIndex: selectedView === 'listMonth' ? theme.zIndex.appBar : theme.zIndex.fab,
        background: theme.palette.common.white,
        borderTop: `1px dashed ${theme.palette.stationGray[100]}`,
      })}
    >
      <Box sx={{ ml: '16px' }}>
        <Box sx={{ mt: { md: '10px' } }}>
          <DateSelector title={title} prevHandle={prevHandle} nextHandle={nextHandle} />
        </Box>
      </Box>
      <Box display="flex">
        {showActionButton && (
          <Box sx={(theme) => ({ mr: theme.spacing(2) })}>
            <ActionButton
              requestOvertimeDisabled={requestOvertimeDisabled || createIncidentOpen}
              handleRequestOvertimeClick={handleRequestOvertimeClick}
              setCreateIncidentOpen={setCreateIncidentOpen}
              setCreateIncident={setCreateIncident}
            />
          </Box>
        )}
        {incidentFormEnabled && incidentReportAdminEnabled && (
          <Box sx={(theme) => ({ mr: theme.spacing(1) })}>
            <Button
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                padding: theme.spacing(1, 2.75),
                borderRadius: theme.spacing(0.75),
                typography: 'buttonM',
                textTransform: 'none',
              })}
              onClick={onClickCreateIncident}
              disabled={!!selectedStaffingList}
              color="primary"
              variant="outlined"
              startIcon={<AddIcon sx={{ color: theme.palette.primary.main }} />}
            >
              <Typography variant="buttonM">Incident</Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
