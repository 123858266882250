import { Box } from '@mui/material';
import { Team } from '@stationwise/share-types';
import { getTeamColors } from '../../utils/colors';

interface CalendarCellShiftBadgeProps {
  team: Team | undefined;
}

export const CalendarCellShiftBadge = ({ team }: CalendarCellShiftBadgeProps) => {
  if (!team) {
    return;
  }
  return (
    <Box className="calendar-cell-shift" position="absolute" left="0">
      <Box
        display="flex"
        width="16px"
        height="16px"
        justifyContent="center"
        alignItems="center"
        sx={(theme) => ({
          marginTop: '2px',
          ...getTeamColors({ theme, color: team.color }),
          borderRadius: '0 16px 16px 0',
        })}
      >
        {team.name.charAt(0)}
      </Box>
    </Box>
  );
};
