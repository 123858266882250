import { ReactNode, useState } from 'react';
import { EmployeeOffView } from '@stationwise/share-types';
import { ControlledPopup } from '../../../ControlledPopup';
import { useRosterContext } from '../RosterContext';
import { ShiftTradeModal } from './ShiftTradeModal';
import { ShiftTradePopoverContent } from './ShiftTradePopoverContent';
import { RefundOptType } from './ShiftTradeRefundOptions';

interface ShiftTradePopoverProps {
  colorMode?: 'light' | 'dark';
  employeeOffData: EmployeeOffView;
  anchorEl: ReactNode;
}

export const ShiftTradePopover = ({ colorMode, employeeOffData, anchorEl }: ShiftTradePopoverProps) => {
  const [shiftTradeCancelModalIsOpen, setShiftTradeCancelModalIsOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState('REFUND_HOURS');
  const { cancelShiftTradeState, selectedFilledPositionState } = useRosterContext();

  const onCancelTrade = () => {
    setShiftTradeCancelModalIsOpen(true);
  };

  const onSubmit = () => {
    cancelShiftTradeState.handleCancelShiftTrade(employeeOffData, selectedButton);
    selectedFilledPositionState.closeDialog();
    onClose();
  };

  const onClose = () => {
    setShiftTradeCancelModalIsOpen(false);
    setSelectedButton('REFUND_HOURS');
  };

  const handleRefundOption = (opt: RefundOptType) => {
    setSelectedButton(opt);
  };

  return (
    <>
      <ShiftTradeModal
        selectedButton={selectedButton}
        handleButtonClick={handleRefundOption}
        isOpen={shiftTradeCancelModalIsOpen}
        onClose={onClose}
        onSubmit={onSubmit}
      />
      <ControlledPopup
        delay={500}
        anchorComponent={anchorEl}
        popupComponent={
          <ShiftTradePopoverContent employeeOffData={employeeOffData} colorMode={colorMode} handleCancelClick={onCancelTrade} />
        }
      />
    </>
  );
};
