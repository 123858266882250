import { Box, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, ReactNode } from 'react';
import { Rank, Certification, DEFAULT_RANK } from '@stationwise/share-types';
import { RankBadge, CertBadge } from '../Badge';

interface EmptyCardCertification extends Pick<Certification, 'code' | 'color'> {
  parentCertifications?: Pick<Certification, 'code' | 'color'>[];
}

export interface EmptyCardProps {
  rank?: Pick<Rank, 'id' | 'name' | 'code' | 'color'>;
  certs: EmptyCardCertification[];
  durationLabel: string;
  withPlusSign?: boolean;
  onClick?: () => void;
  cardSxProps?: SxProps<Theme>;
  actions?: ReactNode;
  testId?: string;
  infoText?: string;
}

export const EmptyCard = ({
  rank,
  durationLabel,
  certs,
  withPlusSign = true,
  onClick,
  cardSxProps,
  actions,
  testId = '',
  infoText,
}: EmptyCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      data-cy={testId}
      display="flex"
      flexDirection="column"
      data-clickable={onClick ? 'true' : undefined}
      onClick={onClick}
      sx={[
        (theme) => ({
          position: 'relative',
          borderRadius: theme.spacing(1.5),
          p: '8px 12px',
          background: theme.palette.stationGray[200],
          border: `2px dashed ${theme.palette.stationGray[600]}`,
          height: '104px',
          width: '100%',
          cursor: onClick ? 'pointer' : undefined,
          '&:hover': {
            background: onClick ? theme.palette.stationGray[100] : theme.palette.stationGray[200],
          },
        }),
        ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : []),
      ]}
    >
      <Box position="relative" sx={{ height: '100%', width: '100%' }}>
        <Box position="absolute" sx={{ top: 0, left: 0, width: '100%', height: '100%' }}>
          {withPlusSign && (
            <Box display="flex" alignItems="center" justifyContent="center" alignContent="center" height="100%">
              <Box sx={{ height: '18px', width: '2px', background: theme.palette.stationGray[600] }} />
              <Box position="absolute">
                <Box sx={{ height: '1.2px', width: '18px', background: theme.palette.stationGray[600] }} />
              </Box>
            </Box>
          )}
          {infoText && (
            <Box display="flex" alignItems="center" justifyContent="center" alignContent="center" height="100%">
              <Typography variant="bodyXSSemibold" sx={(theme) => ({ color: theme.palette.stationGray[500] })}>
                {infoText}
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: '100%' }}>
          <Box display="flex" alignItems="baseline" justifyContent="space-between">
            {rank && rank.id !== DEFAULT_RANK.id ? <RankBadge rank={rank} /> : <Box />}
            <Box sx={{ color: theme.palette.stationGray[600], typography: isMobile ? 'bodyMRegular' : 'bodyXSRegular' }}>
              {durationLabel}
            </Box>
          </Box>
          <Box display="flex" alignItems="baseline" justifyContent="space-between">
            <Box display="inline-flex" alignItems="center" gap={0.5}>
              {certs.map((cert, index) => (
                <CertBadge key={`${index}-${cert.code}`} cert={cert} />
              ))}
              {certs.length === 1 &&
                certs[0].parentCertifications?.map((cert, index) => (
                  <Fragment key={`${index}-${cert.code}`}>
                    <Typography variant="bodyXSSemibold" sx={(theme) => ({ color: theme.palette.stationGray[500] })}>
                      or
                    </Typography>
                    <CertBadge cert={cert} />
                  </Fragment>
                ))}
            </Box>
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[600],
                position: 'relative',
                bottom: '15px',
                typography: 'bodySSemibold',
                lineHeight: '14px',
                [theme.breakpoints.up('sm')]: { typography: 'buttonS', fontWeight: 400 },
              })}
            >
              {actions}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
