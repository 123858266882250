import { Box, Checkbox, Typography } from '@mui/material';
import { useMemo } from 'react';
import { EligibleEmployeeForHiringEngine } from '@stationwise/share-types';
import { HiringEngineVacancy } from '../Vacancies/vacanciesHelper';
import { VacancyEmployeePair } from './VacancyEmployeePair';
import { VacancyEligibilityDetailed } from './matchEmployeesToVacancies';

interface InstantHireCardsProps {
  allVacancies: HiringEngineVacancy[];
  vacancyEligibility: VacancyEligibilityDetailed[];
  selectedVacancies: HiringEngineVacancy[];
  selectedEligibleEmployeeIds: string[];
  selectAll: boolean;
  onToggleVacancy: (vacancy: HiringEngineVacancy) => void;
  onToggleSelectAll: (selectAll: boolean) => void;
}

export const InstantHireCards = ({
  allVacancies,
  vacancyEligibility,
  selectedVacancies,
  selectedEligibleEmployeeIds,
  selectAll,
  onToggleVacancy,
  onToggleSelectAll,
}: InstantHireCardsProps) => {
  const allNullOrEmpty = useMemo(() => vacancyEligibility.every((v) => v.eligibleEmployees.length === 0), [vacancyEligibility]);

  const vacancyIndexToEmployeeId = useMemo(() => {
    const map: Record<number, string | null> = {};
    selectedVacancies.forEach((vacancy, i) => {
      const vIndex = allVacancies.indexOf(vacancy);
      map[vIndex] = selectedEligibleEmployeeIds[i] || null;
    });
    return map;
  }, [selectedVacancies, selectedEligibleEmployeeIds, allVacancies]);

  const employeeMap = useMemo(() => {
    const map: Record<string, EligibleEmployeeForHiringEngine> = {};
    for (const v of vacancyEligibility) {
      for (const emp of v.eligibleEmployees) {
        map[emp.id] = emp;
      }
    }
    return map;
  }, [vacancyEligibility]);

  const orderedVacancies = useMemo(() => {
    return allVacancies.slice().sort((a, b) => {
      const aSelected = selectedVacancies.includes(a);
      const bSelected = selectedVacancies.includes(b);

      const aHasEmployee = vacancyEligibility[allVacancies.indexOf(a)].eligibleEmployees.length > 0;
      const bHasEmployee = vacancyEligibility[allVacancies.indexOf(b)].eligibleEmployees.length > 0;

      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      if (aHasEmployee && !bHasEmployee) return -1;
      if (!aHasEmployee && bHasEmployee) return 1;
      return 0;
    });
  }, [allVacancies, selectedVacancies, vacancyEligibility]);

  const selectAllDisabled = useMemo(() => {
    return orderedVacancies.some((vacancy) => {
      const originalIndex = allVacancies.indexOf(vacancy);
      const eligibleEmployees = vacancyEligibility[originalIndex].eligibleEmployees;
      const assignedEmployeesSet = new Set(selectedEligibleEmployeeIds);

      if (eligibleEmployees.length === 0) {
        return true;
      }

      const allAssigned = eligibleEmployees.every((emp) => assignedEmployeesSet.has(emp.id));
      return allAssigned && !selectedVacancies.includes(vacancy);
    });
  }, [orderedVacancies, allVacancies, vacancyEligibility, selectedEligibleEmployeeIds, selectedVacancies]);

  return (
    <Box>
      {!allNullOrEmpty && (
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            pr: 2,
            opacity: selectAllDisabled ? 0.5 : 1,
          }}
        >
          <Checkbox
            checked={selectAll && !selectAllDisabled}
            disabled={selectAllDisabled}
            onChange={() => onToggleSelectAll(!selectAll)}
          />
          <Typography variant="body1">Select all</Typography>
        </Box>
      )}
      {orderedVacancies.map((vacancy, index) => {
        const originalIndex = allVacancies.indexOf(vacancy);
        const isSelected = selectedVacancies.includes(vacancy);
        const assignedEmployeeId = vacancyIndexToEmployeeId[originalIndex];
        const assignedEmployee = assignedEmployeeId ? employeeMap[assignedEmployeeId] : undefined;

        const assignedEmployeesSet = new Set(selectedEligibleEmployeeIds);
        const eligibleEmployees = vacancyEligibility[originalIndex].eligibleEmployees;
        let isDisabled = false;
        if (eligibleEmployees.length === 0) {
          isDisabled = true;
        } else {
          const allAssigned = eligibleEmployees.every((emp) => assignedEmployeesSet.has(emp.id));
          if (allAssigned && !assignedEmployee) {
            isDisabled = true;
          }
        }
        const diffInMinutes = (vacancy.endDateTime.getTime() - vacancy.startDateTime.getTime()) / 60000;

        return (
          <VacancyEmployeePair
            key={index}
            vacancy={vacancy}
            employee={assignedEmployee}
            isSelected={isSelected}
            isDisabled={isDisabled}
            onToggle={() => onToggleVacancy(vacancy)}
            durationMinutes={diffInMinutes}
          />
        );
      })}
    </Box>
  );
};
