import { Box, Divider, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format, parseISO, setMinutes } from 'date-fns';
import { memo, useState } from 'react';
import { EmployeeNextShiftView } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { XCircleIcon20, SuccessIcon, CheckIcon20, XIcon20, ChevronDownIcon16, ChevronUpIcon16 } from '../../../assets';
import { useTimeCounterLabel } from '../../../hooks';
import { BlankCard } from '../../BlankCard';
import { GenericDrawer } from '../../GenericDrawer';
import { LetterAvatar } from '../../LetterAvatar';
import { getRequestTypeIcon } from '../../Request';
import { ActionButtons } from './ActionButtons';
import { ActionModal, ActiveAction } from './ActionModal';
import { StatusPanel } from './StatusPanel';
import { WaitlistWarning } from './WaitlistWarning';
import {
  RequestCardEmployee,
  RequestCardRequest,
  REQUEST_CARD_ACTION,
  REQUEST_CARD_STATUS,
  RequestCardCompTimeRequest,
} from './types';

export * from './types';
export * from './RequestCardLoading';

function getEmployeeName(employee: RequestCardEmployee) {
  const [firstName, ...lastNames] = employee.name.split(' ');
  return `${firstName[0]}. ${lastNames.join(' ')}`;
}

function getRequestTypeString(request: RequestCardRequest) {
  const partial = 'isPartial' in request && request.isPartial ? 'partial ' : '';
  const shiftDate = format(parseISO(request.startDate), 'M/d/yy');

  if (request.requestType === 'ShiftTradeRequest') {
    const receiverName = getEmployeeName(request.receiver);
    return `a ${partial}shift trade with ${receiverName} on ${shiftDate}`;
  } else if (request.requestType === 'AdditionalPaidTimeRequest') {
    return `additional paid time on ${shiftDate}`;
  } else if (request.requestType === 'CompTimeRequest') {
    return `comp time`;
  } else if (request.timeOffType) {
    return `${partial}${request.timeOffType.toLowerCase()} on ${shiftDate}`;
  } else {
    return '';
  }
}

const toHoursAndMinutes = (totalMinutes: number) => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  let durationStr = '';

  if (hours > 0) {
    durationStr = `${hours === 1 ? ` 1 hour ` : ` ${hours} hours `}`;
  }

  if (minutes > 0) {
    if (durationStr) {
      durationStr += 'and';
    }
    durationStr += ` ${minutes} minutes `;
  }
  return durationStr;
};

interface CardRowProps {
  label: string;
  value: string | number;
  pt?: number;
  pb?: number;
}

const CardRow = (props: CardRowProps) => {
  const { label, value, pt, pb } = props;
  const theme = useTheme();
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        typography: 'buttonM',
        color: theme.palette.stationGray[400],
        pt: pt ? theme.spacing(pt) : undefined,
        pb: pb ? theme.spacing(pb) : undefined,
      })}
    >
      <Box sx={{ color: theme.palette.stationGray[400], fontWeight: 400 }}>{label}</Box>
      <Box sx={{ color: theme.palette.stationGray[900], fontWeight: 400 }}>{value}</Box>
    </Box>
  );
};

interface CompTimeCardDetailsProps {
  request: RequestCardCompTimeRequest;
}

const CompTimeCardDetails = (props: CompTimeCardDetailsProps) => {
  const theme = useTheme();
  const { request } = props;

  return (
    <BlankCard
      type="passive"
      sxProps={{
        mt: theme.spacing(1),
        mb: theme.spacing(0.5),
        width: '100%',
      }}
    >
      <CardRow label="AMOUNT REQUESTED" value={`${request.requestedHours.toFixed(1)} h`} pt={0.5} />
      <Divider
        sx={{
          backgroundColor: theme.palette.stationGray[200],
          height: '1px',
        }}
      />
      <CardRow label="BANKED BY FAR" value={`${request.bankedByFarHours.toFixed(1)} h`} />
      <Divider
        sx={{
          backgroundColor: theme.palette.stationGray[200],
          height: '1px',
        }}
      />
      <CardRow label="OVERTIME DATE" value={format(request.startDate, 'MMM dd, yyy')} pb={0.5} />
    </BlankCard>
  );
};

interface RequestCardProps {
  request: RequestCardRequest;
  status: REQUEST_CARD_STATUS;
  nextShiftData: EmployeeNextShiftView | null;
  onConfirmReview: (action: REQUEST_CARD_ACTION, request: RequestCardRequest) => void;
}

export const RequestCard = memo((props: RequestCardProps) => {
  const { request, status, nextShiftData, onConfirmReview } = props;
  const isPending = status === REQUEST_CARD_STATUS.PENDING;
  const isApproved = status === REQUEST_CARD_STATUS.APPROVED;
  const theme = useTheme();
  const timeLabel = useTimeCounterLabel(new Date(request.createdAt));
  const requestTypeIcon = getRequestTypeIcon(request, theme);
  const requester = 'sender' in request ? request.sender : request.employee;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const showLimitWarning =
    request.requestType === 'TimeOffRequest' && status === REQUEST_CARD_STATUS.PENDING && request.waitlistOrder > request.limit;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const handleOnClose = () => {
    setDrawerOpen(false);
  };

  const [activeAction, setActiveAction] = useState<ActiveAction>({
    status: null,
  });

  const handleApprove = () => {
    setActiveAction({ status: REQUEST_CARD_ACTION.APPROVE });
  };

  const handleDeny = () => {
    setActiveAction({ status: REQUEST_CARD_ACTION.DENY });
  };

  const handlePostpone = (postponedUntil: Date | null) => {
    setActiveAction({ status: REQUEST_CARD_ACTION.POSTPONE, postponedUntil });
  };

  const startDateTime = parseISO(`${request.startDate}T${request.startTimeStr}`);
  const endDateTime = setMinutes(startDateTime, startDateTime.getMinutes() + request.duration);
  const [isHovered, setIsHovered] = useState(false);

  const boxStyle = (noteExists?: boolean) => ({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: noteExists === undefined || noteExists ? `1px solid ${theme.palette.stationGray[200]}` : '',
    paddingBottom: noteExists === undefined || noteExists ? theme.spacing(1) : '',
    marginBottom: noteExists === undefined || noteExists ? theme.spacing(1) : '',
    alignItems: 'center',
  });

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-cy={`${makeTestIdentifier(request.requestType)}-${makeTestIdentifier(requester.name)}`}
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '48px 1fr',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1.5),
        '&:hover': {
          backgroundColor: theme.palette.stationGray[100],
        },
        mb: theme.spacing(2),
        mr: !isMobile ? theme.spacing(2) : 0,
      })}
    >
      <Box sx={{ alignSelf: 'flex-start', display: 'inline-flex', position: 'relative' }}>
        <LetterAvatar firstName={requester.name} />
        {requestTypeIcon?.icon && (
          <Box
            sx={{
              background: requestTypeIcon.backgroundColor || theme.palette.stationGray[400],
              borderRadius: '50%',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              bottom: '-8px',
              left: '24px',
              width: '24px',
              height: '24px',
              '& svg path': { stroke: theme.palette.common.white },
            }}
          >
            {requestTypeIcon.icon}
          </Box>
        )}
      </Box>
      <Box>
        <Box>
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[900],
              fontSize: '14px',
              lineHeight: '20px',
            })}
          >
            <strong>{getEmployeeName(requester)}</strong> requested {getRequestTypeString(request)}{' '}
            {request.requestType === 'CompTimeRequest' ? (
              <CompTimeCardDetails request={request} />
            ) : (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'row',
                  gap: theme.spacing(1),
                })}
              >
                for <strong>{toHoursAndMinutes(request.duration)}</strong> starting at{' '}
                <strong>{format(startDateTime, 'HHmm')}</strong>
                {showLimitWarning && (
                  <WaitlistWarning requestName={request.timeOffType} limit={request.limit} order={request.waitlistOrder} />
                )}
              </Box>
            )}
          </Box>

          {!isMobile && (
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[400],
                typography: 'bodySRegular',
              })}
            >
              {timeLabel}
            </Box>
          )}
        </Box>
        <Box
          sx={(theme) => ({
            mt: theme.spacing(1.5),
          })}
        >
          {showDetails && (
            <Box
              sx={(theme) => ({
                border: '1px solid',
                borderColor: theme.palette.stationGray[200],
                borderRadius: '8px',
                padding: theme.spacing(1.5),
                mb: theme.spacing(1.5),
              })}
            >
              {'payCode' in request && (
                <Box sx={{ ...boxStyle() }}>
                  <Typography sx={{ typography: 'eyebrow' }} color={theme.palette.stationGray[400]}>
                    PAY CODE
                  </Typography>
                  <Typography sx={{ typography: 'bodyMRegular' }} color={theme.palette.stationGray[900]}>
                    {typeof request.payCode !== 'string' ? request.payCode.name : request.payCode}{' '}
                    {typeof request.payCode !== 'string' ? `(${request.payCode.code})` : ''}
                  </Typography>
                </Box>
              )}
              <Box sx={{ ...boxStyle() }}>
                <Typography sx={{ typography: 'eyebrow' }} color={theme.palette.stationGray[400]}>
                  DATE
                </Typography>
                <Typography sx={{ typography: 'bodyMRegular' }} color={theme.palette.stationGray[900]}>
                  {format(startDateTime, 'MMMM d, yyyy')}
                </Typography>
              </Box>
              <Box sx={{ ...boxStyle() }}>
                <Typography sx={{ typography: 'eyebrow' }} color={theme.palette.stationGray[400]}>
                  START TIME
                </Typography>
                <Typography sx={{ typography: 'bodyMRegular' }} color={theme.palette.stationGray[900]}>
                  {format(startDateTime, 'HHmm')}
                </Typography>
              </Box>
              <Box sx={{ ...boxStyle() }}>
                <Typography sx={{ typography: 'eyebrow' }} color={theme.palette.stationGray[400]}>
                  END TIME
                </Typography>
                <Typography sx={{ typography: 'bodyMRegular' }} color={theme.palette.stationGray[900]}>
                  {format(endDateTime, 'HHmm')}
                </Typography>
              </Box>
              <Box
                sx={{
                  ...boxStyle('note' in request || ('reasonResponse' in request && !!request.reasonResponse)),
                }}
              >
                <Typography sx={{ typography: 'eyebrow' }} color={theme.palette.stationGray[400]}>
                  DURATION
                </Typography>
                <Typography sx={{ typography: 'bodyMSemibold' }} color={theme.palette.stationGray[900]}>
                  {Math.floor(request.duration / 60)}h {request.duration % 60}m
                </Typography>
              </Box>
              {'note' in request && (
                <Box
                  sx={{
                    borderBottom:
                      'reasonResponse' in request && !!request.reasonResponse
                        ? `1px solid ${theme.palette.stationGray[200]}`
                        : '',
                    paddingBottom: 'reasonResponse' in request && !!request.reasonResponse ? theme.spacing(1) : '',
                    marginBottom: 'reasonResponse' in request && !!request.reasonResponse ? theme.spacing(1) : '',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography sx={{ typography: 'eyebrow' }} color={theme.palette.stationGray[400]}>
                    NOTE
                  </Typography>
                  {request.note && (
                    <Typography sx={{ typography: 'bodyMRegular' }} color={theme.palette.stationGray[500]}>
                      &quot;{request.note}&quot;
                    </Typography>
                  )}
                </Box>
              )}
              {'reasonResponse' in request && !!request.reasonResponse && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography sx={{ typography: 'eyebrow' }} color={theme.palette.stationGray[400]}>
                    RESPONSE NOTE
                  </Typography>
                  {request.reasonResponse && (
                    <Typography sx={{ typography: 'bodyMRegular' }} color={theme.palette.stationGray[500]}>
                      &quot;{request.reasonResponse}&quot;
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.stationGray[600],
              cursor: 'pointer',
              mb: theme.spacing(1),
              '&:hover': {
                color: theme.palette.stationGray[800],
              },
            }}
            onClick={() => setShowDetails(!showDetails)}
          >
            <Typography sx={{ typography: 'bodySRegular' }}> {showDetails ? 'Hide' : 'Show'} details</Typography>
            {showDetails ? <ChevronUpIcon16 /> : <ChevronDownIcon16 />}
          </Box>
          {isMobile && (
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[400],
                typography: 'bodySRegular',
                mb: theme.spacing(1),
              })}
            >
              {timeLabel}
            </Box>
          )}
          {isPending && (
            <ActionButtons
              handleApprove={handleApprove}
              handleDeny={handleDeny}
              handlePostpone={handlePostpone}
              request={request}
              nextShiftData={nextShiftData}
            />
          )}
          {isPending && (
            <ActionModal
              activeAction={activeAction}
              onClose={() => setActiveAction({ status: null })}
              onConfirm={onConfirmReview}
              onChangePostponedUntil={handlePostpone}
              request={request}
              isNoteRequired={showLimitWarning}
            />
          )}
          {!isPending && request.reviewedBy && request.reviewedAt && (
            <Box>
              <Box onClick={() => setDrawerOpen(true)}>
                <StatusPanel
                  reviewedBy={getEmployeeName(request.reviewedBy)}
                  formattedDate={format(request.reviewedAt, 'M/d/yy, HH:mm')}
                  icon={isApproved ? <SuccessIcon /> : <XCircleIcon20 />}
                  statusLabel={isApproved ? 'Approved' : 'Denied'}
                  isHovered={isHovered}
                />
              </Box>
              {isMobile && (
                <Box>
                  <GenericDrawer
                    anchor="bottom"
                    drawerOpen={drawerOpen}
                    handleOnClose={handleOnClose}
                    loading={false}
                    showHeader={true}
                    disableFooter={true}
                    noBorderOnHeader={true}
                    sxProps={{
                      '.MuiDrawer-paper': {
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                      },
                    }}
                  >
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                      <LetterAvatar size="xlarge" firstName={request.reviewedBy.name} />
                      <Box sx={(theme) => ({ mt: theme.spacing(2) })}>
                        <Typography
                          sx={{ typography: 'bodyMMedium' }}
                          color={isApproved ? theme.palette.stationGreen[600] : theme.palette.stationRose[600]}
                        >
                          {' '}
                          {isApproved ? 'Approved by' : 'Denied by'}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ typography: 'bodyLSemibold' }} color={theme.palette.stationGray[900]}>
                          {' '}
                          {request.reviewedBy.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          mt: theme.spacing(2),
                          gap: theme.spacing(1),
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: theme.palette.stationGray[100],
                          borderRadius: '79px',
                          padding: theme.spacing(0.5, 1.5, 0.5, 0.5),
                        })}
                      >
                        <Box
                          sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '36px',
                            height: '36px',
                            borderRadius: '32px',
                            backgroundColor: isApproved ? theme.palette.stationGreen[500] : theme.palette.stationRose[500],
                          })}
                        >
                          {isApproved && <CheckIcon20 color={'white'} />}
                          {!isApproved && <XIcon20 color={'white'} />}
                        </Box>
                        <Typography sx={{ typography: 'bodyMRegular' }} color={theme.palette.stationGray[600]}>
                          {' '}
                          {isApproved ? 'Approved' : 'Denied'} {format(request.reviewedAt, 'MMM dd, yyyy')} at{' '}
                          {format(request.reviewedAt, 'HHmm')}{' '}
                        </Typography>
                      </Box>
                      <Box
                        onClick={handleOnClose}
                        sx={(theme) => ({
                          mb: theme.spacing(3),
                          mt: theme.spacing(4),
                          display: 'flex',
                          width: '342px',
                          height: '44px',
                          padding: theme.spacing(1, 2),
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexShrink: 0,
                          borderRadius: '6px',
                          backgroundColor: theme.palette.common.white,
                          border: '1px solid',
                          borderColor: theme.palette.stationGray[300],
                          '&:hover': {
                            backgroundColor: theme.palette.stationGray[50],
                          },
                        })}
                      >
                        <Typography sx={{ typography: 'bodyMMedium' }} color={theme.palette.stationGray[700]}>
                          Close
                        </Typography>
                      </Box>
                    </Box>
                  </GenericDrawer>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
});
