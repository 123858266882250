import { Box } from '@mui/material';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  ApparatusLoginActivationPage,
  ApparatusLoginPage,
  EnterEmailPage,
  ResetPasswordPage,
  MagicLinkPage,
  RequireAnonProtector,
  Snackbar,
  SSOPage,
  CAPABILITIES,
} from '@stationwise/component-module';
import { ActivityHistory } from '../app/pages/ActivityHistory';
import { LoginPage } from '../app/pages/Auth/LoginPage';
import { MagicLinkConfirmPage } from '../app/pages/Auth/MagicLinkConfirmPage';
import { Calendar } from '../app/pages/Calendar';
import { Dashboard } from '../app/pages/Dashboard';
import { Dashboard as DashboardUI } from '../app/pages/DashboardUI';
import { DepartmentSettings } from '../app/pages/DepartmentSettings';
import { AutoHireSignUp } from '../app/pages/HiringEngine/AutoHireSignUp';
import { RequestVolunteersSignUp } from '../app/pages/HiringEngine/RequestVolunteersSignUp';
import { MessagesInbox } from '../app/pages/MessagesInbox';
import { NoMatch } from '../app/pages/NoMatch';
import { Payroll } from '../app/pages/Payroll';
import { EmployeePayroll } from '../app/pages/Payroll/components/EmployeePayroll';
import { Payroll as PersonalPayroll } from '../app/pages/PersonalPayroll';
import { PersonalStaffingLists } from '../app/pages/PersonalStaffingLists';
import { Preferences } from '../app/pages/Preferences';
import { Schedule } from '../app/pages/Schedule';
import { Schedule as ScheduleUI } from '../app/pages/ScheduleUI';
import { ShiftTemplate } from '../app/pages/ShiftTemplate';
import { ShiftTrade } from '../app/pages/ShiftTrade';
import { StaffingLists } from '../app/pages/StaffingLists';
import { TeamPage } from '../app/pages/TeamSettings';
import { EmployeeProfile } from '../app/pages/TeamSettings/components/EmployeeProfile';
import { WaitlistVerification } from '../app/pages/WaitlistVerification';
import { AuthPage } from '../components/AuthPage';
import { ROUTES } from './Routes';

export const Router = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {['/', '/login'].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <RequireAnonProtector redirectTo={ROUTES.MY_DASHBOARD.fullRoute}>
                <LoginPage />
              </RequireAnonProtector>
            }
          />
        ))}
        <Route
          path={ROUTES.MAGIC_LINK.fullRoute}
          element={
            <RequireAnonProtector redirectTo={ROUTES.MY_DASHBOARD.fullRoute}>
              <MagicLinkPage />
            </RequireAnonProtector>
          }
        />
        <Route path={ROUTES.MAGIC_LINK_CONFIRM.fullRoute} element={<MagicLinkConfirmPage />} />
        <Route
          path={ROUTES.RESET_PASSWORD.fullRoute}
          element={
            <Box>
              <Snackbar />
              <ResetPasswordPage redirectTo={ROUTES.LOGIN.fullRoute} />
            </Box>
          }
        />
        <Route
          path={ROUTES.RESET_PASSWORD_FIRST_LOGIN.fullRoute}
          element={
            <Box>
              <Snackbar />
              <ResetPasswordPage isSpecialCaseReset={true} redirectTo={ROUTES.LOGIN.fullRoute} />
            </Box>
          }
        />
        <Route path={ROUTES.FORGOT_PASSWORD.fullRoute} element={<EnterEmailPage redirectBackTo={ROUTES.LOGIN.fullRoute} />} />
        <Route path={ROUTES.APPARATUS_LOGIN.fullRoute} element={<ApparatusLoginPage />} />
        <Route
          path={ROUTES.APPARATUS_ACTIVATE.fullRoute}
          element={
            <AuthPage>
              <ApparatusLoginActivationPage />
            </AuthPage>
          }
        />
        <Route
          path={ROUTES.SSO.fullRoute}
          element={
            <RequireAnonProtector redirectTo={ROUTES.MY_DASHBOARD.fullRoute}>
              <SSOPage />
            </RequireAnonProtector>
          }
        />
        <Route path="/app">
          {/* These routes are available to all roles */}
          <Route
            path={ROUTES.MY_DASHBOARD.shortRoute}
            element={
              <AuthPage>
                <DashboardUI />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.CALENDAR.shortRoute}
            element={
              <Box>
                <AuthPage>
                  <Calendar />
                </AuthPage>
              </Box>
            }
          />
          <Route
            path={ROUTES.PERSONAL_ROSTER.shortRoute}
            element={
              <AuthPage>
                <ScheduleUI />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.MY_STAFFING_LISTS.shortRoute}
            element={
              <AuthPage>
                <PersonalStaffingLists />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.MY_PAYROLL.shortRoute}
            element={
              <AuthPage>
                <PersonalPayroll />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.PREFERENCES.shortRoute}
            element={
              <AuthPage>
                <Preferences />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.INBOX.shortRoute}
            element={
              <AuthPage>
                <MessagesInbox />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.OVERTIME_SIGN_UP.shortRoute}
            element={
              <AuthPage>
                <RequestVolunteersSignUp />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.HIRING_ENGINE_SIGN_UP.shortRoute}
            element={
              <AuthPage>
                <AutoHireSignUp />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.WAITLIST_VERIFICATION.shortRoute}
            element={
              <AuthPage>
                <WaitlistVerification />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.TRADE_REQUEST.shortRoute}
            element={
              <AuthPage>
                <ShiftTrade />
              </AuthPage>
            }
          />
          {/* These routes require CAPABILITIES to access */}
          <Route
            path={ROUTES.DASHBOARD.shortRoute}
            element={
              <AuthPage capabilities={[CAPABILITIES.VIEW_ADMIN_DASHBOARD]}>
                <Dashboard />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.SCHEDULE.shortRoute}
            element={
              <AuthPage
                capabilities={[
                  CAPABILITIES.EDIT_SHIFT_TEMPLATE,
                  CAPABILITIES.EDIT_ROSTER_ASSIGNMENTS,
                  CAPABILITIES.EDIT_OFF_ROSTER,
                  CAPABILITIES.EDIT_ROSTER_PAY_CODES,
                  CAPABILITIES.MANAGE_ROSTER_HIRING,
                  CAPABILITIES.EDIT_EVENTS,
                  CAPABILITIES.MANAGE_EVENTS,
                ]}
              >
                <Schedule />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.SHIFT_TEMPLATE.shortRoute}
            element={
              <AuthPage capabilities={[CAPABILITIES.EDIT_SHIFT_TEMPLATE]}>
                <ShiftTemplate />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.CHANGELOG.shortRoute}
            element={
              <AuthPage capabilities={[CAPABILITIES.VIEW_CHANGE_LOGS]}>
                <ActivityHistory />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.EMPLOYEES.shortRoute}
            element={
              <AuthPage capabilities={[CAPABILITIES.VIEW_EMPLOYEES_PAGE]}>
                <TeamPage />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.EMPLOYEE_PROFILE.shortRoute}
            element={
              <AuthPage capabilities={[CAPABILITIES.EDIT_EMPLOYEE_PROFILE]}>
                <EmployeeProfile />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.STAFFING_LISTS.shortRoute}
            element={
              <AuthPage capabilities={[CAPABILITIES.VIEW_STAFFING_LIST]}>
                <StaffingLists />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.PAYROLL.shortRoute}
            element={
              <AuthPage capabilities={[CAPABILITIES.VIEW_ADMIN_PAYROLL]}>
                <Payroll />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.EMPLOYEE_PAYROLL.shortRoute}
            element={
              <AuthPage capabilities={[CAPABILITIES.EDIT_ADMIN_PAYROLL]}>
                <EmployeePayroll />
              </AuthPage>
            }
          />
          <Route
            path={ROUTES.DEPARTMENT_SETTINGS.shortRoute}
            element={
              <AuthPage capabilities={[CAPABILITIES.EDIT_DEPARTMENT_SETTINGS]}>
                <DepartmentSettings />
              </AuthPage>
            }
          />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Suspense>
  );
};
