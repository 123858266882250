import { Box, Tabs, Tab, useTheme, Divider } from '@mui/material';
import React, { useDeferredValue, useEffect, useState } from 'react';
import { ChipFilter, InfiniteScroll, Loader, SearchInput } from '@stationwise/component-module';
import { ConversationListView } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { NoMessages } from '../../../MessagesInbox/components/NoMessages';
import { ConversationCard } from './ConversationCard';

const tabs = [
  {
    label: 'Sent',
  },
  // @TODO: Show these tabs once these states are supported
  // https://app.clickup.com/t/9006090230/SW-3569
  // {
  //   label: 'Drafts',
  // },
  // {
  //   label: 'Scheduled',
  // },
];

export interface FilterChips {
  label: string;
  icon: React.ElementType | null;
  filterName: string | null;
}

interface ConversationsProps {
  conversations: ConversationListView[];
  filterChips: FilterChips[];
  isLoading?: boolean;
  onConversationClick: (id: number) => void;
  onCancelConversation?: (id: number) => void;
  hasNextPage: boolean;
  loadMore: () => void;
  conversationSearch: (text: string, category: string) => void;
}

export const Conversations = ({
  conversations,
  filterChips,
  isLoading,
  onConversationClick,
  onCancelConversation,
  hasNextPage,
  loadMore,
  conversationSearch,
}: ConversationsProps) => {
  const theme = useTheme();
  const [selectedChipFilter, setSelectedChipFilter] = useState('All');
  const [selectedTab, setSelectedTab] = useState(0);
  const [inputText, setInputText] = useState('');
  const [openMessageId, setOpenMessageId] = useState<number | null>(null);
  const deferredText = useDeferredValue(inputText);

  useEffect(() => {
    conversationSearch(deferredText, selectedChipFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredText, conversationSearch]);

  const handleChipFilter = (filterName: string) => {
    setSelectedChipFilter(filterName);
    conversationSearch(deferredText, filterName);
  };

  const handleMessageClick = (id: number) => {
    setOpenMessageId(id);
    onConversationClick(id);
  };

  // Disable eslint since onChange callback from MUI expects React.ChangeEvent<{}> event
  // eslint-disable-next-line
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="0px 30px 0px 16px"
      width="100%"
      height="100%"
      sx={{ position: 'relative' }}
    >
      <Box
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.stationGray[200]}`,
          marginLeft: '-16px',
          // 30px from right parent padding
          // 16px form correct left padding
          // 46px = 30px + 16px
          width: 'calc(100% + 46px)',
        })}
      >
        <Box
          sx={(theme) => ({
            ml: theme.spacing(2),
          })}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              '& .MuiTabs-indicator': {
                height: '1px',
                backgroundColor: theme.palette.stationGray[900],
                fontWeight: 700,
              },
              '& .MuiTab-root': {
                minWidth: 'unset',
                padding: '0px 16px',
              },
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                data-cy={`message-${makeTestIdentifier(tab.label)}`}
                key={tab.label}
                label={
                  <Box
                    component="span"
                    sx={(theme) => ({
                      typography: index === selectedTab ? 'bodyMMedium' : 'bodyMRegular',
                      color: index === selectedTab ? theme.palette.stationGray[900] : theme.palette.stationGray[500],
                    })}
                  >
                    {tab.label}
                  </Box>
                }
                sx={{
                  textTransform: 'none',
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Box display="flex" gap={2} padding="24px 0px 8px 8px" ml="-8px">
        {filterChips.map((option) => (
          <ChipFilter
            key={option.label}
            label={option.label}
            icon={option.icon}
            isSelected={option.label === selectedChipFilter}
            action={() => handleChipFilter(option.label)}
          />
        ))}
      </Box>
      <Box mt="24px" mb="10px">
        <SearchInput value={inputText} color="white" setValue={setInputText} />
      </Box>
      <Box
        gap={1}
        ml="-8px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          marginRight: '-20px',
        }}
      >
        {!isLoading && conversations.length === 0 ? (
          <NoMessages selectedCategory={selectedChipFilter} />
        ) : (
          <InfiniteScroll
            onLoadMore={loadMore}
            loader={hasNextPage && <Loader sx={{ my: 2 }} />}
            sx={{
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '6px',
                ml: '16px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                background: theme.palette.stationGray[300],
                borderRadius: '6px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: theme.palette.stationGray[400],
              },
            }}
            observerOptions={{ root: null }}
          >
            {conversations?.map((conversation) => (
              <Box key={conversation.id} onClick={() => handleMessageClick(conversation.id)}>
                <ConversationCard
                  conversationId={conversation.id}
                  headerProps={{
                    personName: conversation.messages[0].sender.firstName + ' ' + conversation.messages[0].sender.lastName,
                    personRole: conversation.messages[0].sender.rank.name,
                    color: conversation.messages[0].sender.team?.color || 'gray',
                  }}
                  messageProps={{
                    subject: conversation.subject,
                    message: conversation.messages[0].bodyPlainText,
                  }}
                  recipients={conversation.recipients}
                  workGroups={conversation.workGroups}
                  team={conversation.team}
                  category={conversation.category}
                  time={new Date(conversation.messages[0].sentAt).toISOString()}
                  isLoading={isLoading}
                  isMessageOpen={conversation.id === openMessageId}
                  onConversationClick={onConversationClick}
                  onCancelConversation={onCancelConversation}
                  isCancelled={!!(conversation.cancelledAt && conversation.cancelledBy && conversation.cancelledNote)}
                  progress={(conversation.completedCount / conversation.recipients.length) * 100}
                  tasks={conversation.completedCount}
                />
                <Divider />
              </Box>
            ))}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
};
