import { Box } from '@mui/material';
import React from 'react';
import {
  ChevronDownIcon16 as ChevronDown,
  ChevronUpIcon16 as ChevronUp,
  Button,
  useRosterContext,
} from '@stationwise/component-module';
import { ResponsibilityContent } from './ResponsibilityContent';

export const Responsibility = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { shiftSummaryHelper } = useRosterContext();
  const currentDate = shiftSummaryHelper.shiftDuration.startTime;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        data-cy="responsibility-select-button"
        buttonType="tertiary"
        buttonSize="sm"
        variant="contained"
        onClick={handleClick}
        sx={(theme) => ({
          borderRadius: theme.spacing(1),
          '&:hover': {
            backgroundColor: theme.palette.common.white,
            boxShadow: 'none',
          },
        })}
      >
        <Box
          component="span"
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            typography: 'buttonM',
            height: theme.spacing(5),
            padding: '9px 17px',
          })}
        >
          Responsibilities
          {open ? <ChevronUp /> : <ChevronDown />}
        </Box>
      </Button>
      <ResponsibilityContent anchorEl={anchorEl} handleClose={handleClose} currentDate={currentDate} />
    </Box>
  );
};
