import { RosterDataSource, RosterEmployeeOffPayloads, ListFieldsStaffingList, StaffingListItem } from '@stationwise/share-types';
import { getOverrideEmployeePositionFields } from './board';
import { getEmployeeSplits } from './getEmployeeSplits';
import { makeOvertimeEmployeeActiveId } from './id';
import { getDepartmentPayCodes } from './payCode';
import { IShiftSummaryHelper } from './types';

export const getOvertimeEmployee = (
  shiftSummaryHelper: IShiftSummaryHelper,
  selectedStaffingList: ListFieldsStaffingList | undefined,
  employeeActiveId: string,
) => {
  const staffingListItem = selectedStaffingList?.items?.find((item) => {
    return employeeActiveId === makeOvertimeEmployeeActiveId(item.employee.id);
  });
  if (!staffingListItem) {
    return null;
  }
  return {
    ...staffingListItem.employee,
    activeId: makeOvertimeEmployeeActiveId(staffingListItem.employee.id),
    dataSource: RosterDataSource.OVERTIME,
    startDateTime: shiftSummaryHelper.shiftDuration.startTime,
    endDateTime: shiftSummaryHelper.shiftDuration.endTime,
    payCodes: getDepartmentPayCodes(shiftSummaryHelper.departmentInfo, [
      staffingListItem.employee.defaults.overtimeAssignmentPayCode,
    ]),
    ...getOverrideEmployeePositionFields(),
  };
};

interface GetStaffingListEmployeeSplitsInput extends RosterEmployeeOffPayloads {
  shiftSummaryHelper: IShiftSummaryHelper;
  staffingListItems: StaffingListItem[];
}

export const getStaffingListItemEmployeeSplits = ({ staffingListItems, ...input }: GetStaffingListEmployeeSplitsInput) => {
  const employeeSplits = new Map(staffingListItems.map((item) => [item.employee.id, item.employee.splits]));
  return getEmployeeSplits({ ...input, employeeSplits });
};
