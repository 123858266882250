import { Box, useTheme } from '@mui/material';
import { RosterEditableEmployeeSplit } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { CircleWarningIcon20 } from '../../../../assets';
import { ReasonOption } from './Reason';

interface TimeOffAccrualLimitErrorProps {
  split: RosterEditableEmployeeSplit;
  splits: RosterEditableEmployeeSplit[];
  options: Map<string, ReasonOption>;
}

export const TimeOffAccrualLimitError = ({ split, splits, options }: TimeOffAccrualLimitErrorProps) => {
  const theme = useTheme();

  const option = split.reference.type === 'TIME_OFF_REQUEST' ? options.get(split.reference.payCode.code) : undefined;
  if (!option || option.limit === null) {
    return null;
  }

  const newAccruedDurationMinutes = splits.reduce((sum, otherSplit) => {
    const isNewAccruedSplit =
      otherSplit !== split &&
      otherSplit.initialReference.type === 'ASSIGNMENT' &&
      otherSplit.reference.type === 'TIME_OFF_REQUEST' &&
      options.get(otherSplit.reference.payCode.code) === option;
    return sum + (isNewAccruedSplit ? differenceInUTCMinutes(otherSplit.endDateTime, otherSplit.startDateTime) : 0);
  }, 0);

  const limitHours = Math.max(0, option.limit - newAccruedDurationMinutes / 60);
  const durationHours = differenceInUTCMinutes(split.endDateTime, split.startDateTime) / 60;

  return (
    <Box
      sx={{
        background: 'linear-gradient(0deg, #FFE4E6, #FFE4E6), linear-gradient(0deg, #FECDD3, #FECDD3)',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.stationRose[200]}`,
        display: 'flex',
        gap: 1,
        p: 2,
        mt: 2,
      }}
    >
      <Box sx={{ width: '20px', height: '20px' }}>
        <CircleWarningIcon20 />
      </Box>
      <Box sx={{ ml: 1, color: theme.palette.stationRose[700], typography: 'bodyMRegular' }}>
        Selected employee only have{' '}
        <Box component="span" sx={{ fontWeight: 600 }}>
          {limitHours.toFixed(2)} hours
        </Box>{' '}
        of needed {durationHours.toFixed(2)} hours. Choose a suitable split or change time off reason.
      </Box>
    </Box>
  );
};
