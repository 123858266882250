import { Box } from '@mui/material';
import { PayrollHoursStatus } from '@stationwise/share-types';

interface PayrollStatusBadgeProps {
  status: PayrollHoursStatus;
}

export const PayrollStatusBadge = ({ status }: PayrollStatusBadgeProps) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: (() => {
          if (status === 'APPROVED') {
            return theme.palette.stationGreen[100];
          } else if (status === 'DENIED') {
            return theme.palette.stationRed[100];
          } else {
            return theme.palette.common.white;
          }
        })(),
        color: (() => {
          if (status === 'APPROVED') {
            return theme.palette.stationGreen[600];
          } else if (status === 'DENIED') {
            return theme.palette.stationRed[600];
          } else {
            return theme.palette.stationGray[700];
          }
        })(),
        textAlign: 'center',
        padding: theme.spacing(0.5, 1.5),
        border: status === 'pending' ? `1px solid ${theme.palette.stationGray[200]}` : 'none',
        borderRadius: theme.spacing(4),
        typography: 'bodySRegular',
      })}
    >
      {/* {status ? status.charAt(0).toUpperCase() + status.slice(1) : ''} */}
      {status === 'APPROVED' ? 'Approved' : status === 'DENIED' ? 'Denied' : 'Pending'}
    </Box>
  );
};
