import { Box } from '@mui/material';
import { format } from 'date-fns';
import { BlankCard, CertBadge, RankBadge } from '@stationwise/component-module';
import { SwappedEmployee } from '@stationwise/share-types';
import { filterNonRankCertifications } from '@stationwise/shift-summary-helper';

export const ReplacementEmployeeCard = ({
  replacementEmployee,
  shiftDate,
  startTime,
  endTime,
}: {
  replacementEmployee: SwappedEmployee;
  shiftDate: string;
  startTime: string;
  endTime: string;
}) => {
  const replacementNonRankCertifications = filterNonRankCertifications(
    replacementEmployee.certifications,
    replacementEmployee.rank,
  );

  return (
    <BlankCard
      sxProps={() => ({
        border: 'none',
        '&:hover': {
          border: 'none',
        },
      })}
    >
      <Box display="flex" justifyContent="space-between">
        <RankBadge rank={replacementEmployee.rank} rankField="name" />
        <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'bodyXSMedium' })}>
          {format(`${shiftDate}T${replacementEmployee.startTime || startTime}`, 'HHmm')}
          {' - '}
          {format(`${shiftDate}T${replacementEmployee.endTime || endTime}`, 'HHmm')}
        </Box>
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          color: theme.palette.stationGray[900],
          typography: 'bodyMMedium',
        })}
      >
        {`${replacementEmployee.firstName} ${replacementEmployee.lastName}`}
      </Box>
      <Box
        display="flex"
        gap={0.5}
        sx={(theme) => ({
          minHeight: theme.spacing(2.5),
          flexFlow: 'row wrap',
        })}
      >
        {replacementNonRankCertifications.map((cert, index) => (
          <CertBadge key={`${index}-${cert.code}`} cert={cert} />
        ))}
      </Box>
    </BlankCard>
  );
};
