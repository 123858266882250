import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import { Button } from '@stationwise/component-module';

type ShiftTradeDecisionProps = {
  handleDenyTradeShift: () => void;
  handleAcceptTradeShift: () => void;
};

export const ShiftTradeDecision = ({ handleDenyTradeShift, handleAcceptTradeShift }: ShiftTradeDecisionProps) => {
  return (
    <Card
      sx={(theme) => ({
        position: 'fixed',
        bottom: theme.spacing(0),
        width: '100%',
        border: '1px solid #DEE3ED',
        boxShadow: '0px -10px 15px -3px rgba(10, 14, 22, 0.1)',
        maxWidth: '768px',
      })}
    >
      <CardActions>
        <Box
          sx={(theme) => ({
            width: '100%',
            mb: theme.spacing(2),
            justifyContent: 'center',
          })}
        >
          <Box
            sx={(theme) => ({
              width: '100%',
              mb: theme.spacing(2),
              mt: theme.spacing(1),
            })}
          >
            <Button
              data-cy="deny-button"
              disableFocusRipple={true}
              disableRipple={true}
              variant="contained"
              buttonType="tertiary"
              onClick={() => handleDenyTradeShift()}
              sx={(theme) => ({
                background: theme.palette.common.white,
                color: theme.palette.common.black,
                width: '100%',
                borderWidth: theme.spacing(0.1),
                textTransform: 'none',
                borderRadius: theme.spacing(0.75),
              })}
            >
              Deny
            </Button>
          </Box>
          <Box sx={(theme) => ({ width: '100%', mb: theme.spacing(1) })}>
            <Button
              data-cy="accept-button"
              variant="contained"
              disableFocusRipple={true}
              disableRipple={true}
              onClick={() => handleAcceptTradeShift()}
              sx={(theme) => ({
                background: theme.palette.common.black,
                width: '100%',
                textTransform: 'none',
                borderRadius: theme.spacing(0.75),
              })}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};
