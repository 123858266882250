import { prepareForSlot } from '@mui/base/utils';
import { SxProps, Theme } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enUS as locale } from 'date-fns/locale/en-US';
import { CalendarIcon16 } from '../../assets';

if (locale && locale.options) {
  locale.options.weekStartsOn = 0;
}

const OpenPickerIconSlot = prepareForSlot(CalendarIcon16);

interface DatePickerCompProps {
  value: Date | null;
  setValue: (start: Date | null) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  disabledDates?: Date[];
  onMonthChange?: (date: Date) => void;
  disabled?: boolean;
  sxProps?: SxProps<Theme>;
  openIconPosition?: 'start' | 'end';
  placeholder?: string;
  format?: string;
}

export const DatePickerComp = ({
  value,
  setValue,
  disabledDates,
  onMonthChange,
  minDate,
  maxDate,
  disabled,
  sxProps,
  openIconPosition,
  placeholder,
  format,
}: DatePickerCompProps) => {
  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={disabled}
        shouldDisableDate={(day) => {
          return (disabledDates && disabledDates.some((d) => d.toDateString() === day?.toDateString())) || false;
        }}
        onMonthChange={(month) => {
          if (onMonthChange && month) onMonthChange(month);
        }}
        sx={[
          (theme) => ({
            backgroundColor: theme.palette.common.white,
            width: '100%',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.stationGray[200]}`,
            '& svg path': {
              stroke: theme.palette.stationGray[300],
            },
          }),
          { '& .MuiInputAdornment-root': { mr: '0px' } },
          ...(Array.isArray(sxProps) ? sxProps : sxProps ? [sxProps] : []),
        ]}
        value={value}
        onChange={(newValue) => setValue(newValue)}
        format={format ? format : 'MMM dd, yyyy'}
        minDate={minDate ?? undefined}
        maxDate={maxDate ?? undefined}
        slots={{
          openPickerIcon: OpenPickerIconSlot,
        }}
        slotProps={{
          textField: {
            placeholder: placeholder,
          },
          inputAdornment: {
            position: openIconPosition || 'end',
          },
        }}
      />
    </LocalizationProvider>
  );
};
