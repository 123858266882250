import { format } from 'date-fns';
import { EmployeeOffView, RosterDataSource, TemporaryNonShiftType } from '@stationwise/share-types';
import { getBoardEmployeeNoteOverride, getOverrideEmployeePositionFields } from './board';
import { setEmployeeActiveId } from './id';
import { keepPlannedEmployeeOnRoster } from './keepPlannedEmployeeOnRoster';
import { IShiftSummaryHelper } from './types';

export const getEmployeeOffDuration = ({ shiftDuration }: IShiftSummaryHelper, employeeOffData: EmployeeOffView) => {
  const shiftStartTime = format(shiftDuration.startTime, 'HH:mm');
  const shiftStartDateTime = new Date(`${employeeOffData.shiftDate}T${shiftStartTime}`);

  const startDateTime = new Date(`${employeeOffData.shiftDate}T${employeeOffData.startTime}`);
  if (startDateTime < shiftStartDateTime) startDateTime.setHours(startDateTime.getHours() + 24);

  const endDateTime = new Date(`${employeeOffData.shiftDate}T${employeeOffData.endTime}`);
  if (endDateTime <= startDateTime) endDateTime.setHours(endDateTime.getHours() + 24);

  return { startDateTime, endDateTime };
};

export const moveEmployeeOffToFloater = (shiftSummaryHelper: IShiftSummaryHelper, employeeOffData: EmployeeOffView) => {
  const employeeOffDuration = getEmployeeOffDuration(shiftSummaryHelper, employeeOffData);
  if (Object.values(TemporaryNonShiftType).some((type) => type === employeeOffData.requestType)) {
    employeeOffDuration.startDateTime = shiftSummaryHelper.shiftDuration.startTime;
    employeeOffDuration.endDateTime = shiftSummaryHelper.shiftDuration.endTime;
  }

  const floater = setEmployeeActiveId({
    id: `${employeeOffData.employeeOff.id}`,
    dataSource: RosterDataSource.FLOATER,
    name: `${employeeOffData.employeeOff.firstName} ${employeeOffData.employeeOff.lastName}`,
    rank: employeeOffData.employeeOff.rank,
    certifications: employeeOffData.employeeOff.certifications,
    team: employeeOffData.employeeOff.team,
    defaults: employeeOffData.employeeOff.defaults,
    ...employeeOffDuration,
    payCodes: [employeeOffData.employeeOff.defaults.regularAssignmentPayCode],
    detailCodes: [],
    ...getOverrideEmployeePositionFields(),
    noteOverride: getBoardEmployeeNoteOverride(shiftSummaryHelper, `${employeeOffData.employeeOff.id}`),
  });

  return keepPlannedEmployeeOnRoster(shiftSummaryHelper, floater);
};
