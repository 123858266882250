import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Divider } from '@mui/material';
import { useEffect } from 'react';
import {
  CalendarShiftCard,
  ChevronRightIcon24,
  GenericDrawer,
  Loader,
  Pencil01Icon24,
  useAuthUserCapabilities,
  XCloseIcon20,
} from '@stationwise/component-module';
import { GetIncidentOverview } from '@stationwise/share-types';
import { ShiftOverViewOptions } from '../CalendarContent';
import { useFetchRequestOverview } from '../RequestSharedComponents/hooks/useFetchRequestOverview';

interface IncidentOverviewProps {
  drawerOpen: boolean;
  handleOnClose: () => void;
  shift: EventInput | EventImpl;
  handleSelect: (option: string) => void;
  setSelectedIncident: (incident: GetIncidentOverview) => void;
  setIsCloseIncident: (isCloseIncident: boolean) => void;
}

export const IncidentOverview = ({
  drawerOpen,
  handleOnClose,
  shift,
  handleSelect,
  setSelectedIncident,
  setIsCloseIncident,
}: IncidentOverviewProps) => {
  const fetchUrl = `/incident/incidents/${shift.id}/`;
  const { isLoading, isError, error, data: overview } = useFetchRequestOverview<GetIncidentOverview>(fetchUrl);
  const capabilities = useAuthUserCapabilities();
  const canManageIncidents = capabilities.MANAGE_INCIDENT_REPORT;

  useEffect(() => {
    if (overview) {
      setSelectedIncident(overview);
    }
  }, [overview, setSelectedIncident]);

  return (
    <GenericDrawer
      anchor="bottom"
      drawerOpen={drawerOpen}
      handleOnClose={handleOnClose}
      loading={false}
      showHeader={true}
      headerTitle="Incident"
      disableFooter={true}
      noBorderOnHeader={true}
    >
      <Box>
        {isLoading && (
          <Box
            sx={(theme) => ({
              p: theme.spacing(3),
              mt: theme.spacing(3),
            })}
          >
            <Loader />
          </Box>
        )}
        {isError && <div>{`Something went wrong please try again later ${error}}`}</div>}
        {!isLoading && !isError && overview && (
          <>
            <CalendarShiftCard shift={shift} />
            <Box
              sx={(theme) => ({
                pr: theme.spacing(2),
                pl: theme.spacing(2),
              })}
            >
              <>
                {canManageIncidents && (
                  <>
                    <Box
                      data-cy="edit-incident-details-button"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => handleSelect(ShiftOverViewOptions.EDIT_INCIDENT)}
                      sx={(theme) => ({
                        py: theme.spacing(2),
                        cursor: 'pointer',
                      })}
                    >
                      <Box display="flex">
                        <Box
                          sx={(theme) => ({
                            '& svg': {
                              '& path': {
                                stroke: theme.palette.stationGray[400],
                              },
                            },
                          })}
                        >
                          <Pencil01Icon24 />
                        </Box>
                        <Box
                          sx={(theme) => ({
                            ml: theme.spacing(2),
                            color: theme.palette.stationGray[700],
                            typography: 'bodyLMedium',
                          })}
                        >
                          {(overview.isActive ? 'Edit' : 'View') + ' Incident details'}
                        </Box>
                      </Box>
                      <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
                        <ChevronRightIcon24 />
                      </Box>
                    </Box>
                    <Divider />
                    {overview.isActive && (
                      <Box
                        data-cy="close-incident-button"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={() => {
                          handleSelect(ShiftOverViewOptions.CLOSE_INCIDENT);
                          setIsCloseIncident(true);
                        }}
                        sx={(theme) => ({
                          py: theme.spacing(2),
                          cursor: 'pointer',
                        })}
                      >
                        <Box display="flex">
                          <Box
                            sx={(theme) => ({
                              '& svg': {
                                '& path': {
                                  stroke: theme.palette.stationGray[400],
                                },
                              },
                            })}
                          >
                            <XCloseIcon20 />
                          </Box>
                          <Box
                            sx={(theme) => ({
                              ml: theme.spacing(2),
                              color: theme.palette.stationGray[700],
                              typography: 'bodyLMedium',
                            })}
                          >
                            Close Incident
                          </Box>
                        </Box>
                        <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
                          <ChevronRightIcon24 />
                        </Box>
                      </Box>
                    )}

                    <Divider />
                  </>
                )}
                {/* NOTE: This action is not currently supported but could be added back if needed from Napa County FD */}
                {/* <Box
                  data-cy="request-additional-paid-time-button"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => handleSelect(ShiftOverViewOptions.ADDITIONAL_PAID_TIME)}
                  sx={(theme) => ({
                    py: theme.spacing(2),
                  })}
                >
                  <Box display="flex">
                    <Box
                      sx={(theme) => ({
                        '& svg': {
                          '& path': {
                            stroke: theme.palette.stationGray[400],
                          },
                        },
                      })}
                    >
                      <BankNoteIcon24 />
                    </Box>
                    <Box
                      sx={(theme) => ({
                        ml: theme.spacing(2),
                        color: theme.palette.stationGray[700],
                        typography: 'bodyLMedium',
                      })}
                    >
                      Request additional paid time
                    </Box>
                  </Box>
                  <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
                    <ChevronRightIcon24 />
                  </Box>
                </Box> */}
              </>
            </Box>
          </>
        )}
      </Box>
    </GenericDrawer>
  );
};
