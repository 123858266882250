import { Box } from '@mui/material';
import { getTeamColors } from '@stationwise/component-module';
import { Team } from '@stationwise/share-types';

export const TeamColoredBar = ({ shiftTeam }: { shiftTeam: Team }) => {
  return (
    <Box
      sx={(theme) => ({
        ...getTeamColors({ theme, color: shiftTeam.color }),
        p: '5px 80px',
        typography: 'eyebrow',
        fontWeight: 600,
      })}
    >
      {shiftTeam.name.toUpperCase()}
    </Box>
  );
};
