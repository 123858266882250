import { Box, Dialog, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useState } from 'react';
import { Button } from '@stationwise/component-module';

interface DurationSelectionModalProps {
  title: string;
  handleSubmit: (isOneDay: boolean) => void;
  onCancel: () => void;
  showConfirmationModal: boolean;
  setShowConfirmationModal: (value: boolean) => void;
}

export const DurationSelectionModal = ({
  title,
  handleSubmit,
  onCancel,
  showConfirmationModal,
  setShowConfirmationModal,
}: DurationSelectionModalProps) => {
  const [isOneDay, setIsOneDay] = useState(true);

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
    onCancel();
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOneDay(event.target.value === 'isOneDay');
  };

  const onSubmit = () => {
    handleSubmit(isOneDay);
    closeConfirmationModal();
  };

  return (
    <Box>
      <Dialog
        open={showConfirmationModal}
        onClose={closeConfirmationModal}
        sx={{ '& .MuiDialog-paper': { borderRadius: '16px', position: 'relative' } }}
      >
        <Box
          sx={(theme) => ({
            width: theme.spacing(39),
            padding: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          })}
        >
          <Box sx={(theme) => ({ typography: theme.typography.h6, color: theme.palette.stationGray[900], pb: theme.spacing(2) })}>
            {title}
          </Box>

          <RadioGroup value={isOneDay ? 'isOneDay' : 'isMultipleDates'} onChange={handleRadioChange}>
            <FormControlLabel value="isOneDay" control={<Radio />} label="Only this day" />
            <FormControlLabel value="isMultipleDates" control={<Radio />} label="This and all future days" />
          </RadioGroup>

          <Box sx={(theme) => ({ display: 'flex', justifyContent: 'flex-end', mt: 3 })}>
            <Button
              onClick={onCancel}
              sx={(theme) => ({
                bgcolor: theme.palette.common.white,
                color: theme.palette.stationBlue[500],
                '&:hover': {
                  bgcolor: theme.palette.common.white,
                },
              })}
            >
              Cancel
            </Button>
            <Button onClick={onSubmit} variant="contained" sx={(theme) => ({ ml: theme.spacing(1) })}>
              OK
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
