import { useTheme } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GenericDrawer, GenericResponse, useLoadedDepartmentInfoContext } from '@stationwise/component-module';
import { ROUTES } from '../../../../../core/Routes';
import { DenyMessage } from './DenyMessage';

type ShiftTradeResultProps = {
  isAccepted: boolean;
  name: string;
  requestId: number;
  date: string;
};

export const ShiftTradeResult = ({ isAccepted, name, requestId, date }: ShiftTradeResultProps) => {
  const [note, setNote] = useState('');
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isAdminApprovalRequired = departmentInfoState.departmentInfo.settings.shiftTradeRequiresAdminApproval;

  const dateUrl = date.split('T')[0];

  const navigate = useNavigate();
  const theme = useTheme();

  const goToCalendar = () => {
    navigate(ROUTES.CALENDAR.fullRoute + `?date=${dateUrl}`);
  };

  const footerButtons = isAccepted
    ? [
        {
          label: 'Show shift in calendar',
          onClick: goToCalendar,
          backgroundColor: theme.palette.common.black,
        },
      ]
    : [
        {
          label: 'Close',
          onClick: goToCalendar,
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
        },
      ];

  if (isAccepted) {
    return (
      <GenericDrawer
        anchor="bottom"
        loading={false}
        drawerOpen={true}
        handleOnClose={() => goToCalendar()}
        footerButtons={footerButtons}
      >
        <GenericResponse
          firstMessage="You accepted the trade"
          secondMessage={`You will be able to see the shift in your calendar ${isAdminApprovalRequired ? 'after an admin approval' : ''}.`}
        />
      </GenericDrawer>
    );
  } else {
    return note ? (
      <GenericDrawer
        anchor="bottom"
        loading={false}
        drawerOpen={true}
        handleOnClose={() => goToCalendar()}
        footerButtons={footerButtons}
      >
        <GenericResponse firstMessage="You denied the trade" secondMessage={`${name} will be notified about your denial.`} />
      </GenericDrawer>
    ) : (
      <DenyMessage name={name} setNote={setNote} requestId={requestId} />
    );
  }
};
