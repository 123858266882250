import { RosterEmployee, RosterEmployeeOffPayloads, ListFieldsStaffingList } from '@stationwise/share-types';
import { getBoardEmployee, getBoardEmployeeNoteOverride } from '../board';
import { COLLISION_REGION } from '../constants';
import { cutDuration } from '../datetime';
import { checkIsShift, makeApparatusOverId, makePositionOverId, makeOvertimeEmployeeActiveId, setEmployeeActiveId } from '../id';
import { getOvertimeEmployee, getStaffingListItemEmployeeSplits } from '../overtime';
import { removeEmployeeAvailability } from '../removeEmployeeAvailability';
import { getPositionRequirementErrorMessages, getOvertimeRequirementErrorMessages } from '../requirement';
import {
  IShiftSummaryHelper,
  IShiftSummaryHelperActiveParams,
  IShiftSummaryHelperOverParams,
  IShiftSummaryHelperCollisionParams,
} from '../types';
import { addActiveToApparatus, addActiveToPosition } from './board';
import { addActiveToUnassigned } from './unassigned';

interface MoveEmployeeInput {
  overId: string;
  activeId: string;
  shiftSummaryHelper: IShiftSummaryHelper;
  selectedStaffingList: ListFieldsStaffingList | undefined;
  employeeOffPayloads: RosterEmployeeOffPayloads;
}

const getOverParams = (input: MoveEmployeeInput): IShiftSummaryHelperOverParams => {
  if (input.overId === COLLISION_REGION.FLOATERS_BAR) {
    return { region: COLLISION_REGION.FLOATERS_BAR };
  }

  for (const station of input.shiftSummaryHelper.allStationCards.values()) {
    for (const apparatus of station.apparatuses) {
      if (input.overId === makeApparatusOverId(apparatus)) {
        return { region: COLLISION_REGION.APPARATUS, apparatus, position: null };
      }

      for (const position of apparatus.positions) {
        if (input.overId === makePositionOverId(position)) {
          return { region: COLLISION_REGION.APPARATUS, apparatus, position: position.isTemporary ? null : position };
        }
      }
    }
  }

  throw new Error('Cannot get over params');
};

const getActiveParams = (input: MoveEmployeeInput): IShiftSummaryHelperActiveParams => {
  const unassignedEmployee = input.shiftSummaryHelper.unassignedCards.find((employee) => input.activeId === employee.activeId);
  if (unassignedEmployee) {
    return { region: COLLISION_REGION.FLOATERS_BAR, employee: unassignedEmployee };
  }

  const overtimeEmployee = getOvertimeEmployee(input.shiftSummaryHelper, input.selectedStaffingList, input.activeId);
  if (overtimeEmployee) {
    return { region: COLLISION_REGION.OVERTIME_LIST, employee: overtimeEmployee };
  }

  const boardEmployeeResult = getBoardEmployee(input.shiftSummaryHelper, input.activeId);
  if (boardEmployeeResult.apparatus && boardEmployeeResult.position && boardEmployeeResult.employee) {
    return { region: COLLISION_REGION.APPARATUS, ...boardEmployeeResult };
  }

  throw new Error('Cannot get active params');
};

/**
 * Check whether the collision is possible. If it is not possible, it is ignored completely.
 * Note "possible" is different from "valid". See `checkIsMoveValid` for the latter.
 */
const checkIsMovePossible = ({ over, active }: IShiftSummaryHelperCollisionParams) => {
  if (over.region === COLLISION_REGION.FLOATERS_BAR) {
    // Ignore unassigned -> unassigned.
    return active.region !== COLLISION_REGION.FLOATERS_BAR;
  } else if (over.region === COLLISION_REGION.APPARATUS && !over.position) {
    // Ignore excess capacity -> excess capacity within the same apparatus.
    return !(
      active.region === COLLISION_REGION.APPARATUS &&
      active.apparatus.id === over.apparatus.id &&
      active.position.isTemporary
    );
  } else if (over.region === COLLISION_REGION.APPARATUS && over.position) {
    // Ignore position -> same position.
    // Ignore re-ordering within the same split shift position.
    return !(active.region === COLLISION_REGION.APPARATUS && active.position.id === over.position.id);
  }

  return false;
};

/**
 * Check whether the collision is valid. If it is not valid, return an error message.
 * Note "valid" is different from "possible". See `checkIsMovePossible` for the latter.
 *
 * Even if a collision is not valid, we still have to implement it because
 * we want to display the error message next to the active employee after it has moved.
 *
 * Return `canOverride: true` if the user is allowed to override the error.
 * By default this is false; the user must clear the error by undoing the move.
 */
const checkIsMoveValid = (input: MoveEmployeeInput, params: IShiftSummaryHelperCollisionParams) => {
  const { over, active } = params;

  const messages: string[] = [];
  if (over.region === COLLISION_REGION.APPARATUS && over.position) {
    messages.push(...getPositionRequirementErrorMessages(params, over.position, active.employee));
  }
  if (active.region === COLLISION_REGION.OVERTIME_LIST) {
    messages.push(...getOvertimeRequirementErrorMessages(params, active.employee));
  }

  return !messages.length ? undefined : { messages, canOverride: true };
};

/**
 * Move (i.e. drag-and-drop) an employee from one place to another.
 * @param overId - Identifies where the employee is moved to.
 *   In drag-and-drop terms, this is the drop zone.
 * @param activeId - Identifies the employee that is moving.
 *   In drag-and-drop terms, this is the employee that the user is dragging.
 */
export const moveEmployee = (input: MoveEmployeeInput) => {
  const over = getOverParams(input);
  const active = getActiveParams(input);
  let params: IShiftSummaryHelperCollisionParams = { ...input.shiftSummaryHelper, over, active };
  if (!checkIsMovePossible(params)) {
    return null;
  }

  let activeDurations = [{ startDateTime: active.employee.startDateTime, endDateTime: active.employee.endDateTime }];
  if (active.region === COLLISION_REGION.OVERTIME_LIST) {
    const activeStaffingListItem = input.selectedStaffingList?.items?.find((item) => {
      return active.employee.activeId === makeOvertimeEmployeeActiveId(item.employee.id);
    });
    const employeeSplits = getStaffingListItemEmployeeSplits({
      ...input.employeeOffPayloads,
      shiftSummaryHelper: input.shiftSummaryHelper,
      staffingListItems: activeStaffingListItem ? [activeStaffingListItem] : [],
    });
    activeDurations = cutDuration(active.employee, employeeSplits.get(active.employee.id) || []);
  }

  const newActiveEmployees: RosterEmployee[] = [];
  activeDurations.forEach((activeDuration) => {
    params.active = {
      ...params.active,
      employee: setEmployeeActiveId({
        ...active.employee,
        startDateTime: activeDuration.startDateTime,
        endDateTime: activeDuration.endDateTime,
      }),
    };

    // Remove the employee from their original location.
    params = removeEmployeeAvailability({
      shiftSummaryHelper: params,
      employeeId: params.active.employee.id,
      startTime: params.active.employee.startDateTime,
      endTime: params.active.employee.endDateTime,
      checkIsPositionExcluded: (p) => !checkIsShift(p),
    });

    // Add the employee to their new location.
    if (over.region === COLLISION_REGION.FLOATERS_BAR) {
      params = addActiveToUnassigned(params);
    } else if (over.region === COLLISION_REGION.APPARATUS && !over.position) {
      params = addActiveToApparatus(params);
    } else if (over.region === COLLISION_REGION.APPARATUS && over.position) {
      params = addActiveToPosition(params);
    }

    if (params.newActive?.employee) {
      newActiveEmployees.push(params.newActive.employee);
      if (active.region === COLLISION_REGION.OVERTIME_LIST) {
        params.newActive.employee.noteOverride = getBoardEmployeeNoteOverride(input.shiftSummaryHelper, active.employee.id);
      }
      params.newActive.employee.staffedAt = params.newActive.employee.staffedAt ?? new Date();
    }
  });

  params.error = checkIsMoveValid(input, { ...params, active });
  if (params.error && !params.error.canOverride) {
    newActiveEmployees.forEach((e) => (e.activeId += '|error'));
  }

  return params;
};
