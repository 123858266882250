import { Box } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { BlankCard, CertBadge, RankBadge, useRosterContext, RefundOptType } from '@stationwise/component-module';
import { EmployeeOffView, TemporaryNonShiftType } from '@stationwise/share-types';
import { moveEmployeeOffToFloater, filterNonRankCertifications } from '@stationwise/shift-summary-helper';
import { CancellationPopover } from './CancellationPopover';

export const EmployeeOffCard = ({ employeeOffData }: { employeeOffData: EmployeeOffView }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { isPast, employeesOffState, cancelShiftTradeState, shiftSummaryHelper, setShiftSummaryHelper, setUserHasMadeChanges } =
    useRosterContext();

  const onCardClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cancelRequest = (refundOption: RefundOptType | '' = '') => {
    if (employeeOffData.requestType === 'ShiftTradeRequest' && refundOption !== '') {
      cancelShiftTradeState.handleCancelShiftTrade(employeeOffData, refundOption);
      return;
    }

    const newShiftSummaryHelper = moveEmployeeOffToFloater(shiftSummaryHelper, employeeOffData);
    if (employeeOffData.requestType === 'TimeOffRequest') {
      const payload = { timeOffId: employeeOffData.id };
      newShiftSummaryHelper.cancelTimeOffPayloads = [...newShiftSummaryHelper.cancelTimeOffPayloads, payload];
    } else if (Object.values(TemporaryNonShiftType).some((type) => type === employeeOffData.requestType)) {
      employeesOffState.setTemporaryNonShiftToCancel(employeeOffData.id);
    }

    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
  };

  const cancelPopoverOpen = Boolean(anchorEl);
  const isCancellable = ['TimeOffRequest', 'ShiftTradeRequest', ...Object.values(TemporaryNonShiftType)].some(
    (type) => type === employeeOffData.requestType,
  );

  const nonRankCertifications = filterNonRankCertifications(
    employeeOffData.employeeOff.certifications,
    employeeOffData.employeeOff.rank,
  );

  return (
    <>
      <BlankCard
        type="disable"
        sxProps={() => ({
          border: 'none',
          '&:hover': {
            border: 'none',
          },
          cursor: isCancellable && !isPast ? 'pointer' : 'default',
        })}
        onClick={isCancellable && !isPast ? onCardClick : undefined}
      >
        <Box display="flex" justifyContent="space-between">
          <RankBadge rank={employeeOffData.employeeOff.rank} rankField="name" />
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'bodyXSMedium' })}>
            {format(`${employeeOffData.shiftDate}T${employeeOffData.startTime}`, 'HHmm')}
            {' - '}
            {format(`${employeeOffData.shiftDate}T${employeeOffData.endTime}`, 'HHmm')}
          </Box>
        </Box>

        <Box
          sx={(theme) => ({
            display: 'flex',
            color: theme.palette.stationGray[900],
            typography: 'bodyMMedium',
          })}
        >
          {`${employeeOffData.employeeOff.firstName} ${employeeOffData.employeeOff.lastName}`}
        </Box>
        <Box
          display="flex"
          gap={0.5}
          sx={(theme) => ({
            minHeight: theme.spacing(2.5),
            flexFlow: 'row wrap',
          })}
        >
          {nonRankCertifications.map((cert, index) => (
            <CertBadge key={`${index}-${cert.code}`} cert={cert} />
          ))}
        </Box>
      </BlankCard>
      <CancellationPopover
        employeeOffData={employeeOffData}
        handleClose={handleClose}
        popoverOpen={cancelPopoverOpen}
        anchorEl={anchorEl}
        cancelRequest={cancelRequest}
      />
    </>
  );
};
