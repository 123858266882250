import { Box } from '@mui/material';
import { EmptyCard } from '@stationwise/component-module';
import { formatShiftDuration } from '@stationwise/shift-summary-helper';
import { VacantStation } from './vacanciesHelper';

interface VacanciesProps {
  vacantStations: VacantStation[];
}

export const Vacancies = ({ vacantStations }: VacanciesProps) => {
  return vacantStations.map((vacantStation) => (
    <Box
      key={vacantStation.stationId}
      sx={(theme) => ({
        backgroundColor: theme.palette.stationGray[50],
        borderRadius: '16px',
        mb: 2,
        p: theme.spacing(0, 2, 2, 2),
      })}
    >
      {vacantStation.apparatuses.map((vacantApparatus) => (
        <Box key={vacantApparatus.id} sx={{ pt: 2, mt: 1 }}>
          <Box
            sx={(theme) => ({
              color: theme.palette.stationGray[500],
              display: 'flex',
              gap: 1.5,
              typography: 'bodySMedium',
            })}
          >
            {vacantStation.stationName.toUpperCase()}
            <Box sx={(theme) => ({ borderLeft: `solid 1px ${theme.palette.stationGray[500]}` })} />
            {vacantApparatus.name.toUpperCase()}
          </Box>
          {vacantApparatus.vacancies.map((vacancy, vacancyIndex) => (
            <EmptyCard
              key={vacancyIndex}
              rank={vacancy.rank}
              durationLabel={formatShiftDuration({ startTime: vacancy.startDateTime, endTime: vacancy.endDateTime })}
              certs={vacancy.certifications}
              cardSxProps={{ mt: 2, width: '255px' }}
            />
          ))}
        </Box>
      ))}
    </Box>
  ));
};
