import { Box } from '@mui/material';
import { BlankCard } from '@stationwise/component-module';

interface FloaterCardProps {
  isReplacementNeeded: boolean;
}

export const FloaterCard = ({ isReplacementNeeded }: FloaterCardProps) => {
  return (
    <BlankCard
      sxProps={(theme) => ({
        border: `1px solid ${theme.palette.stationGray[50]}`,
        boxShadow: '0px 1px 2px 0px #0A0E160D',
        '&:hover': {
          border: `1px solid ${theme.palette.stationGray[50]}`,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          color: theme.palette.stationGray[500],
          textAlign: 'center',
          typography: 'bodyLMedium',
          lineHeight: '28px',
        })}
      >
        {isReplacementNeeded ? 'Replacement pending...' : 'No replacement needed'}
      </Box>
    </BlankCard>
  );
};
