import { Box, Modal, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Button, SnackbarService, theme, Trash02Icon24, Modal as SwModal } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { EmployeePayroll } from '@stationwise/share-types';
import { QuestionModal } from '../../../../../../src/components/Common';
import { isChangedNameInvalid } from './utils';

interface ChangeColumnNameModalProps {
  changeColumnNameOpen: boolean;
  setChangeColumnNameOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedColumnName: string;
  forceRefetch: () => void;
  employeePayroll: EmployeePayroll;
}

export const ChangeColumnNameModal = ({
  changeColumnNameOpen,
  setChangeColumnNameOpen,
  selectedColumnName,
  forceRefetch,
  employeePayroll,
}: ChangeColumnNameModalProps) => {
  const [newValue, setNewValue] = useState<string>('');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handleModalClose = () => {
    setChangeColumnNameOpen(false);
    setNewValue('');
  };

  const handleDeleteAction = () => {
    setConfirmDeleteOpen(true);
  };

  const sendDeleteRequest = async () => {
    try {
      const data = {
        deletedColumn: selectedColumnName.toLowerCase(),
        payPeriodId: employeePayroll.payPeriod.id,
      };
      const response = await client.put('payroll/customize-payroll-export/', data);
      if (response.status === 200) {
        SnackbarService.notify({
          content: 'Column deleted successfully',
          severity: 'success',
          duration: 5000,
        });
        forceRefetch();
      } else {
        throw new Error('Something went wrong');
      }
    } catch {
      SnackbarService.notify({
        content: 'Something went wrong, please try again',
        severity: 'error',
        duration: 10000,
      });
    }
    handleModalClose();
  };
  const handleSubmit = async () => {
    try {
      const data = {
        column_customization: {
          [selectedColumnName]: newValue.toLowerCase(),
        },
        payPeriodId: employeePayroll.payPeriod.id,
      };
      const response = await client.put('payroll/customize-payroll-export/', data);
      if (response.status === 200) {
        SnackbarService.notify({
          content: 'Column name changed successfully',
          severity: 'success',
          duration: 5000,
        });
        forceRefetch();
      } else {
        throw new Error('Something went wrong');
      }
    } catch {
      SnackbarService.notify({
        content: 'Something went wrong, please try again',
        severity: 'error',
        duration: 10000,
      });
    }
    handleModalClose();
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={changeColumnNameOpen}
      onClose={() => handleModalClose()}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: '16px',
        })}
      >
        <Box
          sx={(theme) => ({
            padding: '24px 24px 0px 24px',
            borderRadius: '16px 16px 0px 0px',
            width: '428px',
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            maxHeight: '800px',
            gap: theme.spacing(1),
            typography: 'bodyXLSemibold',
          })}
        >
          <Typography sx={{ typography: 'bodyXLSemibold' }}>Edit Column</Typography>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
            })}
          >
            Column name
          </Box>
          <TextField
            value={newValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewValue(event.target.value);
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(145, 173, 1, 1)',
                  boxShadow: `0 0 0 4px ${theme.palette.stationPrimary[400]}`,
                },
              },
            }}
          />
          {isChangedNameInvalid(employeePayroll.payrollColumns, employeePayroll.firstColumnsNames, newValue) && (
            <Typography sx={{ color: theme.palette.error.main, typography: 'bodyLSmall' }}>
              {'This name is already used, please choose another name.'}
            </Typography>
          )}
          {newValue.includes('_') && (
            <Typography sx={{ color: theme.palette.error.main, typography: 'bodyLSmall' }}>
              {'There are invalid characters in the column name'}
            </Typography>
          )}

          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              gap: theme.spacing(2),
              width: '100%',
              paddingY: theme.spacing(3),
            })}
          >
            <Button onClick={() => handleModalClose()} buttonType="tertiary" sx={() => ({ width: '100%' })}>
              {'Cancel'}
            </Button>
            <Button
              buttonType="dark"
              onClick={handleSubmit}
              disabled={
                selectedColumnName === '' ||
                newValue.trim() === '' ||
                isChangedNameInvalid(employeePayroll.payrollColumns, employeePayroll.firstColumnsNames, newValue) ||
                newValue.trim().includes('_')
              }
              sx={() => ({ width: '100%', ':hover': { backgroundColor: theme.palette.stationGray[700] } })}
            >
              {'Submit'}
            </Button>
          </Box>
          {employeePayroll.additionalColumns.map((column) => column.toLowerCase()).includes(selectedColumnName.toLowerCase()) && (
            <Box
              sx={(theme) => ({
                gap: theme.spacing(2),
                width: '100%',
                height: '150%',

                marginBottom: theme.spacing(2),
              })}
            >
              <Button
                onClick={() => handleDeleteAction()}
                buttonType="tertiary"
                sx={() => ({ width: '100%', padding: theme.spacing(1.5), gap: theme.spacing(1) })}
              >
                <Trash02Icon24 />
                {'Delete Column'}
              </Button>
            </Box>
          )}
        </Box>
        <SwModal open={confirmDeleteOpen} setOpen={() => setConfirmDeleteOpen(true)}>
          <QuestionModal
            setModalOpen={setConfirmDeleteOpen}
            onConfirm={sendDeleteRequest}
            secondButtonText="Delete"
            secondButtonBackgroundColor={theme.palette.stationRose[600]}
          >
            <Box sx={{ width: '416px', mb: theme.spacing(3) }}>
              <Box sx={{ typography: 'bodyXXLSemibold' }}>Do you really want to delete this column?</Box>
              <Box sx={{ typography: 'bodyMRegular', mt: theme.spacing(2) }}>
                This action will delete columns from this pay period and ones coming after it. This action cannot be undone.
              </Box>
            </Box>
          </QuestionModal>
        </SwModal>
      </Box>
    </Modal>
  );
};
