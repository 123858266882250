export type PayrollHoursStatus = 'APPROVED' | 'DENIED' | 'pending';

export interface PayrollHours {
  hours: number;
  date: string;
  status: PayrollHoursStatus;
  reviewedAt?: string;
  reviewedBy?: {
    name: string;
  };
  reasonResponse?: string;
  canConvert?: boolean;
}

export interface PayrollCode {
  name: string;
  code: string;
  total: number;
  dayHours: PayrollHours[];
  payCodeType: string;
  isConvertible: boolean;
}
export interface PayPeriod {
  payPeriodType: string;
  id: number;
  startDate: string;
  endDate: string;
  duration: number;
}
export interface PayrollData {
  activeWorkingHours: number;
  nonTypicalPayroll: PayrollCode[];
  typicalWorkingPayroll: PayrollCode[];
  totalOvertimeDuration: number;
  currentPayPeriod: PayPeriod;
  signDueDate: string;
  signedAt: string;
  isSigned: boolean;
  isSignable: boolean;
  signedState: string;
  upComingPayPeriodForApproval: PayPeriod;
  pendingCompTimeDuration: number;
  pendingCompTimeRequests: PayrollCode[];
}

export enum SignedState {
  SIGNED = 'SIGNED',
  PRESSED_LATER = 'PRESSED_LATER',
  NOT_SIGNED = 'NOT_SIGNED',
}
