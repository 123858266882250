import { Box } from '@mui/material';
import { AllowedColors } from '@stationwise/share-types';
import { CertBadge } from '../../../Badge';
import { MinReqRecord } from './MinReqRecord';

interface CertificationRecordProps {
  name: string;
  total: number;
  amount: number;
  color: AllowedColors;
}

export const CertificationRecord = ({ name, total, amount, color }: CertificationRecordProps) => {
  return (
    <Box mt={1}>
      <MinReqRecord total={total} amount={amount}>
        <CertBadge cert={{ code: name, color }} dark={true} />
      </MinReqRecord>
    </Box>
  );
};
