import * as amplitude from '@amplitude/analytics-browser';

export const useAmplitude = () => {
  if (process.env['AMPLITUDE_API_KEY']) {
    try {
      amplitude.init(process.env['AMPLITUDE_API_KEY'], { defaultTracking: true });
    } catch (e) {
      console.error(e);
    }
  }
};
