import { EventInput } from '@fullcalendar/core/index.js';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { CalendarShiftCard, FeedbackMessage, GenericDrawer, Status } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { PostShiftTradeRequestDto, RequestedShiftTradeView } from '@stationwise/share-types';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { ShiftTradeBalance } from './ShiftTradeBalance';
import { ShiftTradeDuration } from './ShiftTradeDuration';
import { ShiftTradeSelect } from './ShiftTradeSelect';

interface TradeShiftProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  shift: EventInput | EventImpl;
  setRefetchEvents: Dispatch<SetStateAction<boolean>>;
}

export const TradeShift = ({ shift, setOpen, open, setRefetchEvents }: TradeShiftProps) => {
  const [shiftTradeStart, setShiftTradeStart] = useState<string>((shift.start as Date)?.toISOString());
  const [step, setStep] = useState<number>(0);
  const [duration, setDuration] = useState<number>(24);
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const [resultDrawerOpen, setResultDrawerOpen] = useState<boolean>(false);
  const [shiftTradeEnd, setShiftTradeEnd] = useState<string>(shift.end as string);
  const [status, setStatus] = useState<Status>('success');
  const [statusMessage, setStatusMessage] = useState<string>('');

  const iosWebViewPaddingBottom = useIOSPaddingBottom();

  const reset = useCallback(() => {
    setShiftTradeStart(shift.start as string);
    setStep(0);
    setDuration(24);
    setSelectedPeople([]);
    setResultDrawerOpen(false);
    setShiftTradeEnd((shift.end as Date)?.toISOString());
    setStatus('success');
    setStatusMessage('');
  }, [shift]);

  const requestTradeShift = async (requestDto: PostShiftTradeRequestDto) => {
    try {
      const response = await client.post<RequestedShiftTradeView>('request/shift-trade-request/', requestDto);

      if (response.status === 201) {
        setRefetchEvents(true);
        setStatus('success');
        setStatusMessage(
          `Your shift trade request has been successfully sent to ${requestDto.receiver_employee_ids.length} ${requestDto.receiver_employee_ids.length === 1 ? 'person' : 'persons'}.`,
        );
      } else {
        setStatus('error');
        setStatusMessage('We were unable to process your time off request. Please try again later.');
      }
    } catch (error) {
      setStatus('error');
      const message = isAxiosError(error) ? error.response?.data.detail : '';
      setStatusMessage(message || 'We were unable to process your time off request. Please try again later.');
    } finally {
      setOpen(false);
      setResultDrawerOpen(true);
    }
  };

  const handleRequest = (allPersonel?: string[]) => {
    const date = format(shift.start as string, 'yyyy-MM-dd');
    const requestDto: PostShiftTradeRequestDto = {
      shift_trade_date: date,
      receiver_employee_ids: allPersonel ? allPersonel : selectedPeople,
      start_time: 0,
      end_time: 24 * 60,
    };

    if (duration < 24) {
      const shiftStart = new Date(shift.start as string).getTime();
      const startTimeInMinutes = (new Date(shiftTradeStart).getTime() - shiftStart) / (1000 * 60);
      const endTimeInMinutes = startTimeInMinutes + duration * 60;

      requestDto.start_time = startTimeInMinutes;
      requestDto.end_time = endTimeInMinutes;
    }

    requestTradeShift(requestDto);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  useEffect(() => {
    reset();
  }, [reset, shift]);

  const steps = [
    <ShiftTradeDuration
      startDateTime={shiftTradeStart}
      setStartDateTime={setShiftTradeStart}
      endDateTime={shiftTradeEnd}
      setEndDateTime={setShiftTradeEnd}
      originalEndDateTime={(shift.end as Date)?.toISOString()}
      originalStartDateTime={(shift.start as Date)?.toISOString()}
      duration={duration}
      handleNextStep={() => setStep(step + 1)}
      setDuration={setDuration}
    />,
    <ShiftTradeSelect
      selectedPeople={selectedPeople}
      setSelectedPeople={setSelectedPeople}
      handleSubmit={handleRequest}
      shiftDate={(shift.start as Date)?.toISOString()}
    />,
  ];

  return (
    <>
      <GenericDrawer
        anchor="bottom"
        drawerOpen={open}
        handleOnClose={handleClose}
        loading={false}
        showHeader={true}
        disableFooter={true}
        headerTitle={'Trade shift'}
        noBorderOnHeader={true}
      >
        <div>
          <CalendarShiftCard shift={shift} />
          {step === 1 && (
            <Box
              sx={(theme) => ({
                border: `1px solid ${theme.palette.stationGray[300]}`,
                m: theme.spacing(2),
                borderRadius: 3,
                p: theme.spacing(1),
              })}
            >
              <ShiftTradeBalance startDateTime={shiftTradeStart} endDateTime={shiftTradeEnd} duration={duration} />
            </Box>
          )}
          <Box
            display="flex"
            alignItems="center"
            sx={(theme) => ({
              mt: theme.spacing(1),
              ml: theme.spacing(2),
              mr: theme.spacing(2),
            })}
          >
            {steps[step]}
          </Box>
        </div>
      </GenericDrawer>
      <GenericDrawer
        anchor="bottom"
        drawerOpen={resultDrawerOpen}
        handleOnClose={reset}
        footerButtons={[
          {
            label: 'Go back to calendar',
            onClick: reset,
            backgroundColor: '#111827',
          },
        ]}
        loading={false}
        paddingBottom={iosWebViewPaddingBottom}
      >
        <FeedbackMessage status={status} firstMessage={statusMessage} />
      </GenericDrawer>
    </>
  );
};
