import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { client } from '@stationwise/share-api';
import { ListFieldsStaffingList } from '@stationwise/share-types';

interface UseFetchStaffingListsProps {
  skip?: boolean;
}

export const useFetchStaffingLists = ({ skip = false }: UseFetchStaffingListsProps = {}) => {
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<ListFieldsStaffingList[] | null>(null);

  useEffect(() => {
    if (skip) {
      return;
    }
    setIsLoading(true);
    setIsError(false);
    setData(null);
    client
      .get('/staffing-list/staffing-lists/')
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setIsError(true);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [skip, refetchCounter]);

  const forceRefetch = useCallback(() => setRefetchCounter((c) => c + 1), []);

  return {
    isLoading,
    isError,
    data,
    forceRefetch,
  };
};
