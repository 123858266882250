import { captureException } from '@sentry/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { ConversationListView } from '@stationwise/share-types';
import { useLoadedAuthUserContext } from '../components/Auth';

interface ConversationResponse {
  next: string | null;
  results: ConversationListView[];
}
export const useGetConversations = () => {
  // this value is only to force refetch
  const [fetchNoRequest, setFetchNoRequest] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ConversationListView[] | []>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [hasNextPage, setHasNextPage] = useState(true);
  const [category, setCategory] = useState('All');
  const abortControllerRef = useRef<AbortController | null>(null);

  const { state: authUserState, dispatch: dispatchAuthUserState } = useLoadedAuthUserContext();
  const meRef = useRef(authUserState.employee);
  meRef.current = authUserState.employee;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);
      setError(null);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      if (page === 1) setData([]);
      client
        .get<ConversationResponse>('/conversation/', {
          params: {
            ...(search ? { search } : {}),
            page,
            ...(category ? (category === 'All' ? {} : { category }) : {}),
          },
          signal: abortControllerRef.current.signal,
        })
        .then((response) => {
          setData((oldData) => {
            const keys = new Map<number, number>();
            const newData: ConversationListView[] = [];
            const pushData = (result: ConversationListView) => {
              const index = keys.get(result.id);
              if (typeof index === 'number') {
                newData[index] = result;
              } else {
                newData.push(result);
                keys.set(result.id, newData.length - 1);
              }
            };
            oldData.forEach(pushData);
            (response.data.results || []).forEach(pushData);
            return newData;
          });

          const newUnreadMessageIds = new Set(meRef.current.unreadMessages);
          response.data.results.forEach((conversation) => {
            const recipient = conversation.recipients.find((r) => `${r.employee.id}` === meRef.current.id);
            if (recipient) {
              if (recipient.isRead || Number(meRef.current.id) === Number(conversation.createdBy)) {
                newUnreadMessageIds.delete(conversation.id);
              } else {
                newUnreadMessageIds.add(conversation.id);
              }
            }
          });
          dispatchAuthUserState({ type: 'SET_USER_UNREAD_MESSAGES.SUCCESS', payload: [...newUnreadMessageIds] });
          setHasNextPage(!!response.data.next);
          setIsLoading(false);
        })
        .catch((error) => {
          const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
          !isCanceled && captureException(error);
          !isCanceled && setIsLoading(false);
        });
    };
    fetchData();
  }, [dispatchAuthUserState, fetchNoRequest, page, search, category]);

  const forceRefetch = useCallback(() => {
    setFetchNoRequest((prevValue) => prevValue + 1);
    setPage(1);
  }, []);

  const loadMore = () => {
    if (hasNextPage && !isLoading) {
      setPage(page + 1);
    }
  };

  const searchConversation = (text: string, selectedCategory: string) => {
    setSearch(text);
    setCategory(selectedCategory);
    if (text !== search || selectedCategory !== category) {
      setPage(1);
    }
  };

  return {
    isLoading,
    isError,
    error,
    data,
    forceRefetch,
    loadMore,
    hasNextPage,
    searchConversation,
  };
};
