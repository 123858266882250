import { format, isValid, startOfDay } from 'date-fns';
import { useState } from 'react';
import { ErrorPage, Loader, useFetchStaffingLists, useSelectedStaffingList } from '@stationwise/component-module';
import { PersonalStaffingListControls } from './PersonalStaffingListControls';
import { PersonalStaffingListItems } from './PersonalStaffingListItems';
import { PersonalStaffingListPosition } from './PersonalStaffingListPosition';

export const PersonalStaffingListsContent = () => {
  const [shouldScrollToMeItem, setShouldScrollToMeItem] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(startOfDay(new Date()));
  const staffingListsResponse = useFetchStaffingLists();
  const { isLoading, isError, staffingLists, selectedStaffingList, setSelectedStaffingList } = useSelectedStaffingList({
    selectedDate: selectedDate && isValid(selectedDate) ? format(selectedDate, 'MM/dd/yyyy') : '',
    staffingListsResponse,
  });

  return (
    <>
      <PersonalStaffingListControls
        isLoading={isLoading}
        staffingLists={staffingLists}
        selectedStaffingList={selectedStaffingList}
        setSelectedStaffingList={setSelectedStaffingList}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      {isLoading && <Loader sx={{ mt: 6 }} />}
      {!isLoading && isError && <ErrorPage />}
      {!isLoading && !isError && (
        <PersonalStaffingListPosition selectedStaffingList={selectedStaffingList} onClick={() => setShouldScrollToMeItem(true)} />
      )}
      {!isError && (
        <PersonalStaffingListItems
          isLoading={isLoading}
          selectedStaffingList={selectedStaffingList}
          shouldScrollToMeItem={shouldScrollToMeItem}
          setShouldScrollToMeItem={setShouldScrollToMeItem}
        />
      )}
    </>
  );
};
