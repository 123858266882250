import { Box, Dialog } from '@mui/material';
import { Responsibility } from '@stationwise/share-types';
import { ResponsibilityForm } from './ResponsibilityForm';

interface EditResponsibilityModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentDate: Date;
  forceFetch: () => void;
  responsibility: Responsibility;
}

export const EditResponsibilityModal = ({
  open,
  setOpen,
  currentDate,
  forceFetch,
  responsibility,
}: EditResponsibilityModalProps) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { borderRadius: '16px', position: 'relative' } }}>
      <Box sx={(theme) => ({ width: '500px', padding: theme.spacing(2) })}>
        <Box
          sx={(theme) => ({
            typography: theme.typography.h6,
            color: theme.palette.stationGray[900],
            pb: theme.spacing(4),
          })}
        >
          Edit Responsibility
        </Box>
        <ResponsibilityForm
          responsibility={responsibility}
          setShowResponsibilityOpen={setOpen}
          currentDate={currentDate}
          forceFetch={forceFetch}
        />
      </Box>
    </Dialog>
  );
};
