import { Box } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Badge, RankBadge, TeamFlag } from '@stationwise/component-module';
import { ShiftDetails, TradesOwedDetails, EmployeeDetails } from '@stationwise/share-types';

const formatDate = (dateString: string) => {
  const date = parseISO(dateString);
  const formattedDate = format(date, 'dd MMM yyyy');
  const formattedTime = format(date, 'HH:mm');
  return [formattedDate, formattedTime];
};

type ShiftTradeSummaryProps = {
  shift: ShiftDetails;
  tradesOwed?: TradesOwedDetails;
  employee: EmployeeDetails;
};

export const ShiftTradeSummary = ({ shift, tradesOwed, employee }: ShiftTradeSummaryProps) => {
  const [formattedStartDate, formattedStartTime] = formatDate(shift.start);
  const [formattedEndDate, formattedEndTime] = formatDate(shift.end);

  return (
    <Box
      sx={(theme) => ({
        m: theme.spacing(2),
        borderRadius: '12px',
        border: '1px solid #eef2f8',
        boxShadow: '0px 2px 3px 0px rgba(10, 14, 22, 0.1)',
        px: theme.spacing(1),
        py: theme.spacing(1.5),
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          p: theme.spacing(1),
        })}
      >
        <Box sx={(theme) => ({ color: theme.palette.stationGray[900], typography: 'bodyLSemibold' })}>Shift summary</Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" sx={(theme) => ({ mt: theme.spacing(1) })}>
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'eyebrow' })}>START</Box>
          <Box
            sx={() => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            })}
          >
            <Box sx={(theme) => ({ color: theme.palette.stationGray[700], mr: theme.spacing(1), typography: 'bodyMMedium' })}>
              {formattedStartDate}
            </Box>
            <Box
              sx={() => ({
                borderRadius: '6px',
                border: '1px solid #eef2f8',
                boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.1)',
              })}
            >
              <Badge variant="bodyMMedium" label={`${formattedStartTime}`} color="white"></Badge>
            </Box>
          </Box>
        </Box>

        <Box
          sx={(theme) => ({
            mt: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          })}
        >
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'eyebrow' })}>END</Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            })}
          >
            <Box sx={(theme) => ({ color: theme.palette.stationGray[700], mr: theme.spacing(1), typography: 'bodyMMedium' })}>
              {formattedEndDate}
            </Box>
            <Box
              sx={(theme) => ({
                py: '1px',
                borderRadius: '6px',
                border: '1px solid #eef2f8',
                boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.1)',
              })}
            >
              <Badge variant="bodyMMedium" label={`${formattedEndTime}`} color="white"></Badge>
            </Box>
          </Box>
        </Box>

        <Box
          sx={(theme) => ({
            mt: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          })}
        >
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'eyebrow' })}>DURATION</Box>
          <Box sx={(theme) => ({ color: theme.palette.stationGray[700], typography: 'bodyMMedium' })}>{shift.duration} hours</Box>
        </Box>

        <Box
          sx={(theme) => ({
            mt: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          })}
        >
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'eyebrow' })}>WHO</Box>
          <Box
            sx={(theme) => ({
              mt: theme.spacing(1),
              display: 'flex',
              alignItems: 'center',
            })}
          >
            <RankBadge rank={employee.rank} />
            <Box sx={(theme) => ({ color: theme.palette.stationGray[700], ml: theme.spacing(1), typography: 'bodyMMedium' })}>
              {employee.name}
            </Box>
          </Box>
        </Box>

        <Box
          sx={(theme) => ({
            mt: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          })}
        >
          <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'eyebrow' })}>SHIFT</Box>
          <Box sx={() => ({ display: 'flex', alignItems: 'center', gap: 1 })}>
            <TeamFlag color={shift.type.color} />
            <Box sx={(theme) => ({ color: theme.palette.stationGray[700], typography: 'bodyMMedium' })}>{shift.type.name}</Box>
          </Box>
        </Box>

        {tradesOwed && (
          <Box
            sx={(theme) => ({
              mt: theme.spacing(1),
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            })}
          >
            <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'eyebrow' })}>TRADES OWED</Box>
            {tradesOwed.owed ? (
              <Box sx={(theme) => ({ color: theme.palette.stationOrange[400] })}>I owe {tradesOwed.owedHours.toFixed(2)} h</Box>
            ) : (
              <Box sx={(theme) => ({ color: theme.palette.stationGreen[400] })}>Owes me {tradesOwed.owedHours.toFixed(2)} h</Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
