import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';
import { AllowedColors } from '@stationwise/share-types';
import { getTeamColors } from '../../utils/colors';

export interface BlankCardProps {
  children: React.ReactNode;
  type?: 'default' | 'disable' | 'border' | 'passive';
  borderColor?: AllowedColors;
  isSelected?: boolean;
  sxProps?: SxProps<Theme>;
  selectedColor?: string;
  selectedBorderWidth?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const BlankCard = ({
  children,
  type = 'default',
  borderColor = 'gray',
  isSelected,
  sxProps,
  selectedColor,
  selectedBorderWidth,
  onClick,
}: BlankCardProps) => {
  return (
    <Box
      display="flex"
      p="8px 12px"
      flexDirection="column"
      onClick={onClick}
      sx={[
        (theme) => ({
          gap: theme.spacing(1.75),
          background: (() => {
            if (type === 'disable') {
              return `repeating-linear-gradient(-45deg, ${theme.palette.stationGray[200]}, ${theme.palette.stationGray[200]} 1.5px, ${theme.palette.stationGray[100]} 0, ${theme.palette.stationGray[100]} 10px)`;
            }
            if (type === 'border') {
              return `linear-gradient(90deg, ${getTeamColors({ theme, color: borderColor }).background} 4px, ${theme.palette.common.white} 0)`;
            }
            return theme.palette.common.white;
          })(),
          borderRadius: theme.spacing(1.5),
          width: '100%',
          height: '100%',
          border: (() => {
            if (type === 'passive') return `1px solid ${theme.palette.stationGray[300]}`;
            else if (isSelected) {
              if (selectedColor && selectedBorderWidth) return `${selectedBorderWidth} solid ${selectedColor}`;
              else if (selectedColor) return `2px solid ${selectedColor}`;
              else return `3px solid ${theme.palette.stationTeal[400]}`;
            }
            return `1px solid ${theme.palette.stationGray[300]}`;
          })(),
          transition: type === 'passive' ? 'none' : 'border linear 0.2s',
          '&:hover': (() => {
            if (type === 'passive') return {};
            else if (isSelected) {
              if (selectedColor && selectedBorderWidth) return `${selectedBorderWidth} solid ${selectedColor}`;
              else if (selectedColor) return { border: `2px solid ${selectedColor}` };
              else return { border: `3px solid ${theme.palette.stationTeal[600]}` };
            }
            return { border: `1px solid ${theme.palette.stationGray[600]}` };
          })(),
        }),
        ...(Array.isArray(sxProps) ? sxProps : sxProps ? [sxProps] : []),
      ]}
    >
      {children}
    </Box>
  );
};
