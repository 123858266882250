import {
  DETAIL_CODE_ACTING_OUT_OF_RANK,
  DETAIL_CODE_MANDATORY_OT,
  DETAIL_CODE_VOLUNTARY_OT,
  RosterPosition,
  RosterEmployee,
} from '@stationwise/share-types';
import { hasRankOrCertificationActAsRank } from './requirement';
import { IShiftSummaryHelper } from './types';

export const checkHasMandatoryOvertimeDetailCode = (employee: RosterEmployee) => {
  return employee.detailCodes.some((dc) => dc.code === DETAIL_CODE_MANDATORY_OT);
};

export const checkHasVoluntaryOvertimeDetailCode = (employee: RosterEmployee) => {
  return employee.detailCodes.some((dc) => dc.code === DETAIL_CODE_VOLUNTARY_OT);
};

export const getMandatoryDetailCodes = (
  { departmentInfo }: IShiftSummaryHelper,
  position: RosterPosition,
  employee: RosterEmployee,
) => {
  const detailCodes = [...employee.detailCodes];
  const actingDetailCode = departmentInfo.detailCodes.find((dc) => dc.code === DETAIL_CODE_ACTING_OUT_OF_RANK);
  const actingDetailCodeIndex = detailCodes.findIndex((dc) => dc.code === DETAIL_CODE_ACTING_OUT_OF_RANK);

  if (!hasRankOrCertificationActAsRank(employee, position.rank.name, departmentInfo.certificationActAsRanks)) {
    if (actingDetailCode && actingDetailCodeIndex === -1) {
      detailCodes.push(actingDetailCode);
    }
  } else if (actingDetailCodeIndex !== -1) {
    detailCodes.splice(actingDetailCodeIndex, 1);
  }

  return detailCodes;
};
