// TODO: remove OVERTIME_REQUEST and OVERTIME_OPT_OUT when staffing lists feature is fully implemented
export const SHIFT_TITLES = {
  REGULAR: 'REGULAR',
  SHIFT_TRADE: 'SHIFT TRADE',
  OFF_TRADE: 'OFF - TRADE',
  VOLUNTARY_OT: 'VOLUNTARY OT',
  MANDATORY_OT: 'MANDATORY OT',
  OVERTIME_REQUEST: 'OVERTIME REQUEST',
  OVERTIME_OPT_OUT: 'OPTED OUT OF OVERTIME',
  TRADE_REQUESTED: 'TRADE REQUESTED',
  TIME_OFF_REQUEST: 'TIME OFF REQUEST',
  ADDITIONAL_PAID_TIME_REQUEST: 'ADD. PAID TIME REQUEST',
  ADDITIONAL_PAID_TIME: 'ADDITIONAL PAID TIME',
  KELLY_DAY: 'KELLY DAY',
  INCIDENT: 'INCIDENT',
  INCIDENT_PAY: 'INCIDENT PAY',
};
export const TEMPORARY_NON_SHIFT_TITLES = {
  LIGHT_DUTY: 'LIGHT DUTY',
  EXTENDED_LEAVE: 'EXTENDED LEAVE',
};
export const TIME_OFFS_TITLES = {
  VACATION: 'VACATION',
  SICK_DAY: 'SICK',
  EMERGENCY_LEAVE: 'EMERGENCY',
  BEREAVEMENT: 'BEREAVEMENT DAY',
  COMP_TIME: 'COMP TIME',
  JURY_DUTY: 'JURY DUTY',
  // clarify: another time off requests
};
export const STATUS_TITLES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CANCELLED: 'CANCELLED',
  REVIEWING: 'REVIEWING',
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  USER_CANCELLED: 'USER_CANCELLED',
  USER_MESSAGE_SENT: 'USER_MESSAGE_SENT',
  POSTPONED: 'POSTPONED',
};
export const CALENDAR_EVENT_TYPES = {
  ADDITIONAL_PAID_TIME_REQUEST: 'ADDITIONAL_PAID_TIME_REQUEST',
  COMP_TIME_REQUEST: 'COMP_TIME_REQUEST',
  INCIDENT: 'INCIDENT',
  KELLY_DAY: 'KELLY_DAY',
  NON_SHIFT: 'NON_SHIFT',
  TEMPORARY_NON_SHIFT: 'TEMPORARY_NON_SHIFT',
  OVERRIDE_EMPLOYEE_POSITION: 'OVERRIDE_EMPLOYEE_POSITION',
  PLANNED_EMPLOYEE_POSITION: 'PLANNED_EMPLOYEE_POSITION',
  SHIFT_TRADE_REQUEST: 'SHIFT_TRADE_REQUEST',
  TIME_OFF_REQUEST: 'TIME_OFF_REQUEST',
  VOLUNTARY_OVERTIME_REQUEST: 'VOLUNTARY_OVERTIME_REQUEST',
  STAFFING_LIST_MARK: 'STAFFING_LIST_MARK',
};
