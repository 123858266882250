import { format } from 'date-fns';
import { useMemo } from 'react';
import { RosterEmployeeOffPayloads, ListFieldsStaffingList } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { IShiftSummaryHelper, getStaffingListItemEmployeeSplits } from '@stationwise/shift-summary-helper';
import { useSelectedStaffingList } from '../../../hooks/useSelectedStaffingList';

interface UseRosterStaffingListsInput {
  staffingListsResponse?: { data: ListFieldsStaffingList[] | null; isError: boolean };
  shiftSummaryHelper: IShiftSummaryHelper;
  employeeOffPayloads: RosterEmployeeOffPayloads;
}

export const useRosterStaffingLists = ({
  staffingListsResponse,
  shiftSummaryHelper,
  employeeOffPayloads,
}: UseRosterStaffingListsInput) => {
  const selectedStaffingListState = useSelectedStaffingList({
    selectedDate: format(shiftSummaryHelper.shiftDuration.startTime, 'MM/dd/yyyy'),
    staffingListsResponse,
  });
  const { selectedStaffingList } = selectedStaffingListState;

  const employeeSplits = useMemo(() => {
    return getStaffingListItemEmployeeSplits({
      ...employeeOffPayloads,
      shiftSummaryHelper,
      staffingListItems: selectedStaffingList?.items || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(employeeOffPayloads), shiftSummaryHelper, selectedStaffingList]);

  const availableStaffingListItems = useMemo(() => {
    let items = selectedStaffingList?.items || [];
    if (items.length) {
      const excludedEmployeeIds = new Set<string>();
      employeeSplits.forEach((splits, employeeId) => {
        const totalDuration = splits.reduce((sum, e) => sum + differenceInUTCMinutes(e.endDateTime, e.startDateTime), 0);
        totalDuration >= 24 * 60 && excludedEmployeeIds.add(employeeId);
      });

      items = items.filter((item) => !excludedEmployeeIds.has(item.employee.id));
    }

    return items;
  }, [selectedStaffingList, employeeSplits]);

  return { ...selectedStaffingListState, employeeSplits, availableStaffingListItems };
};
