import { Box, Button, DialogTitle } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { RosterEmployee, TimeOffLimit } from '@stationwise/share-types';
import { checkIsEvent, makeCreateTimeOffPayloads, getEmployeeSplits, splitEmployee } from '@stationwise/shift-summary-helper';
import { RankBadge } from '../../../Badge';
import { useLoadedDepartmentInfoContext } from '../../../Department';
import { useGet } from '../../hooks/useGet';
import { useRosterContext } from '../RosterContext';
import { MergeSplitsButtonGroup } from './MergeSplitsButtonGroup';
import { getTimeOffReasonOptions } from './Reason';
import { Split } from './Split';
import { getSplitErrors } from './getSplitErrors';

interface SplitShiftOrTimeOffFormProps {
  employee: RosterEmployee;
}

export const SplitShiftOrTimeOffForm = ({ employee }: SplitShiftOrTimeOffFormProps) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const {
    employeeOffPayloads,
    selectedFilledPositionState,
    splitShiftOrTimeOffState,
    setUserHasMadeChanges,
    shiftSummaryHelper,
    setShiftSummaryHelper,
  } = useRosterContext();
  const { currentBattalion, shiftDuration } = shiftSummaryHelper;

  const [initialSplits] = useState(() => {
    const employeeSplits = getEmployeeSplits({
      ...employeeOffPayloads,
      shiftSummaryHelper,
      employeeSplits: new Map([[employee.id, shiftSummaryHelper.employeeSplits.get(employee.id) || []]]),
    });
    return (employeeSplits.get(employee.id) || []).map((split) => ({ ...split, initialReference: split.reference }));
  });
  const [splits, setSplits] = useState(initialSplits);

  const { data: accruals } = useGet<TimeOffLimit[]>(`/employee/accruals/?employeeId=${employee.id}`);

  const timeOffReasonOptions = useMemo(() => {
    return getTimeOffReasonOptions(departmentInfoState.departmentInfo, accruals, initialSplits);
  }, [departmentInfoState.departmentInfo, accruals, initialSplits]);

  const splitStatuses = splits.map((split, index) => {
    const isDisabled =
      split.initialReference.type !== 'ASSIGNMENT' ||
      (!!split.initialReference.battalion && split.initialReference.battalion.id !== currentBattalion.id) ||
      (!!split.employee && checkIsEvent(split.employee));

    const errors = getSplitErrors({
      split,
      index,
      splits,
      departmentInfo: departmentInfoState.departmentInfo,
      shiftDuration,
      timeOffReasonOptions,
    });

    return { isDisabled, errors };
  });

  const [submitted, setSubmitted] = useState(false);

  const canSubmit = splits !== initialSplits && splitStatuses.every(({ errors }) => !errors.size) && !submitted;

  const onSubmit = async () => {
    setSubmitted(true);
    const newShiftSummaryHelper = splitEmployee(shiftSummaryHelper, employee.id, splits);
    newShiftSummaryHelper.createTimeOffPayloads = [...newShiftSummaryHelper.createTimeOffPayloads];
    newShiftSummaryHelper.createTimeOffPayloads.push(...makeCreateTimeOffPayloads(newShiftSummaryHelper, employee.id, splits));
    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
    selectedFilledPositionState.closeDialog();
    splitShiftOrTimeOffState.setIsDialogOpen(false);
  };

  return (
    <Box sx={{ p: 3, position: 'relative', overflowY: 'auto' }}>
      <DialogTitle component="h2" sx={{ p: 0, m: 0, typography: 'bodyXLSemibold' }}>
        Manage shift
      </DialogTitle>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 3, typography: 'bodyMMedium' }}>
        <RankBadge rank={employee.rank} />
        {employee.name}
      </Box>
      {splits.map((split, index) => (
        <Fragment key={`${index}|${splits.length}`}>
          <Split
            timeOffReasonOptions={timeOffReasonOptions}
            employee={employee}
            initialSplits={initialSplits}
            splits={splits}
            setSplits={setSplits}
            splitStatuses={splitStatuses}
            index={index}
          />
          <MergeSplitsButtonGroup splits={splits} setSplits={setSplits} splitStatuses={splitStatuses} index={index} />
        </Fragment>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
        <Button
          data-cy="manage-cancel-button"
          onClick={() => splitShiftOrTimeOffState.setIsDialogOpen(false)}
          variant="text"
          sx={{ p: '9px 8px', textTransform: 'none' }}
        >
          Cancel
        </Button>
        <Button
          data-cy="manage-apply-button"
          onClick={onSubmit}
          disabled={!canSubmit}
          variant="contained"
          sx={{ p: '9px 16px', textTransform: 'none' }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};
