import { useState } from 'react';
import { useAuthUserCapabilities } from '@stationwise/component-module';
import { MessagesPanel } from './MessagesPanel';
import { RequestsPanel } from './RequestsPanel';
import { TopBar } from './TopBar';

export const MessagesInboxContent = () => {
  const [isCreatingMessage, setIsCreatingMessage] = useState(false);

  const capabilities = useAuthUserCapabilities();
  const canReviewAllEligibleRequests = capabilities.REVIEW_ALL_ELIGIBLE_REQUESTS;
  const canSendMessages = capabilities.SEND_MESSAGES;

  const [requestsPanelOpen, setRequestsPanelOpen] = useState(canReviewAllEligibleRequests);

  const onComposeClick = () => {
    setIsCreatingMessage(true);
  };

  return (
    <div data-testid="messages-inbox-content">
      <TopBar
        requestsPanelEnabled={canReviewAllEligibleRequests}
        requestsPanelOpen={requestsPanelOpen}
        setRequestsPanelOpen={setRequestsPanelOpen}
        canSendMessages={canSendMessages}
        onComposeClick={onComposeClick}
        setIsCreatingMessage={setIsCreatingMessage}
        isCreatingMessage={isCreatingMessage}
      />
      {requestsPanelOpen && <RequestsPanel />}
      {!requestsPanelOpen && (
        <MessagesPanel
          isCreatingMessage={isCreatingMessage}
          setIsCreatingMessage={setIsCreatingMessage}
          canSendMessages={canSendMessages}
        />
      )}
    </div>
  );
};
