import { Box } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { getTeamColors } from '@stationwise/component-module';

export const Day = ({
  day,
  outsideCurrentMonth,
  selected,
  getTeamColorForDate,
  selectedDateAsDate,
  addDays,
  ...other
}: PickersDayProps<Date> & {
  getTeamColorForDate: (day: Date) => string | undefined;
  selectedDateAsDate: Date;
  addDays: (days: number) => void;
}) => {
  const teamColor = getTeamColorForDate(day);
  const isSelected = !outsideCurrentMonth && !!teamColor;
  const selectedDay =
    selectedDateAsDate &&
    day.toDateString() === selectedDateAsDate.toDateString() &&
    day.toDateString() !== new Date().toDateString();
  const handleDayClick = () => {
    const teamColor = getTeamColorForDate(day);

    if (teamColor) {
      const dayDiff = Math.floor((day.getTime() - (selectedDateAsDate || new Date()).getTime()) / (1000 * 60 * 60 * 24));
      addDays(dayDiff);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '.MuiPickersDay-root': {
          borderRadius: '50%',
          border: selectedDay ? '1px solid #111827' : 'none',
          color: selectedDay ? 'white' : '#111827',
          backgroundColor: selectedDay ? theme.palette.grey[900] : 'transparent',
          '&:hover': {
            border: '1px solid #111827',
          },
        },
      })}
      onClick={handleDayClick}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      {isSelected && (
        <Box
          sx={(theme) => ({
            width: 4,
            height: 4,
            borderRadius: '50%',
            ...getTeamColors({ theme, color: teamColor, dark: !!selectedDay }),
            position: 'absolute',
            bottom: 4,
          })}
        />
      )}
    </Box>
  );
};
