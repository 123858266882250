export const LOGIN = {
  fullRoute: '/login',
};

export const MAGIC_LINK = {
  fullRoute: '/login/magic-link',
};

export const MAGIC_LINK_CONFIRM = {
  fullRoute: '/login/magic-link/confirm',
};

export const RESET_PASSWORD = {
  fullRoute: '/login/reset-password',
};

export const RESET_PASSWORD_FIRST_LOGIN = {
  fullRoute: '/login/reset-password-first-login',
};

export const FORGOT_PASSWORD = {
  fullRoute: '/login/forgot-password',
};

export const SSO = {
  fullRoute: '/login/sso',
};

export const DASHBOARD = {
  fullRoute: '/app/dashboard',
  shortRoute: 'dashboard',
};

export const SCHEDULE = {
  fullRoute: '/app/roster-view',
  shortRoute: 'roster-view',
};

export const SHIFT_TEMPLATE = {
  fullRoute: '/app/shift-template',
  shortRoute: 'shift-template',
};
export const CHANGELOG = {
  fullRoute: '/app/changelog',
  shortRoute: 'changelog',
};
export const EMPLOYEES = {
  fullRoute: '/app/employees',
  shortRoute: 'employees',
};
export const EMPLOYEE_PROFILE = {
  fullRoute: '/app/employees/:employeeId',
  shortRoute: 'employees/:employeeId',
};
export const STAFFING_LISTS = {
  fullRoute: '/app/staffing-lists',
  shortRoute: 'staffing-lists',
};
export const PAYROLL = {
  fullRoute: '/app/payroll',
  shortRoute: 'payroll',
};
export const EMPLOYEE_PAYROLL = {
  fullRoute: '/app/payroll/employee/:payrollId',
  shortRoute: 'payroll/employee/:payrollId',
};
export const DEPARTMENT_SETTINGS = {
  fullRoute: '/app/department-settings',
  shortRoute: 'department-settings',
};
export const MY_DASHBOARD = {
  fullRoute: '/app/my-dashboard',
  shortRoute: 'my-dashboard',
};
export const CALENDAR = {
  fullRoute: '/app/calendar',
  shortRoute: 'calendar',
};
export const INBOX = {
  fullRoute: '/app/inbox',
  shortRoute: 'inbox',
};
export const MY_PAYROLL = {
  fullRoute: '/app/my-payroll',
  shortRoute: 'my-payroll',
};
export const PERSONAL_ROSTER = {
  fullRoute: '/app/personal-roster',
  shortRoute: 'personal-roster',
};
export const MY_STAFFING_LISTS = {
  fullRoute: '/app/my-staffing-lists',
  shortRoute: 'my-staffing-lists',
};
export const PREFERENCES = {
  fullRoute: '/app/preferences',
  shortRoute: 'preferences',
};
export const OVERTIME_SIGN_UP = {
  fullRoute: '/app/overtime-sign-up',
  shortRoute: 'overtime-sign-up',
};
export const HIRING_ENGINE_SIGN_UP = {
  fullRoute: '/app/hiring-engine/sign-up/:autoHireId',
  shortRoute: 'hiring-engine/sign-up/:autoHireId',
};
export const WAITLIST_VERIFICATION = {
  fullRoute: '/app/waitlist-verification',
  shortRoute: 'waitlist-verification',
};
export const TRADE_REQUEST = {
  fullRoute: '/app/trade-request',
  shortRoute: 'trade-request',
};
export const APPARATUS_ACTIVATE = {
  fullRoute: '/activate',
  shortRoute: '/activate',
};
export const APPARATUS_LOGIN = {
  fullRoute: '/apparatus-login',
  shortRoute: '/apparatus-login',
};

export const ROUTES = {
  LOGIN,
  MAGIC_LINK,
  MAGIC_LINK_CONFIRM,
  RESET_PASSWORD,
  RESET_PASSWORD_FIRST_LOGIN,
  FORGOT_PASSWORD,
  APPARATUS_ACTIVATE,
  APPARATUS_LOGIN,
  SSO,
  DASHBOARD,
  SCHEDULE,
  CHANGELOG,
  SHIFT_TEMPLATE,
  EMPLOYEES,
  EMPLOYEE_PROFILE,
  STAFFING_LISTS,
  PAYROLL,
  EMPLOYEE_PAYROLL,
  DEPARTMENT_SETTINGS,
  MY_DASHBOARD,
  CALENDAR,
  INBOX,
  MY_PAYROLL,
  PERSONAL_ROSTER,
  MY_STAFFING_LISTS,
  PREFERENCES,
  OVERTIME_SIGN_UP,
  HIRING_ENGINE_SIGN_UP,
  WAITLIST_VERIFICATION,
  TRADE_REQUEST,
};
