import { Box, Typography, Checkbox, Chip, Button, ChipProps, Tooltip } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import {
  EmptyCard,
  Loader,
  StaffingListMainControls,
  theme,
  useLoadedDepartmentInfoContext,
  useRosterContext,
  useStaffingListFilterGroup,
} from '@stationwise/component-module';
import {
  AutoHireInfoView,
  AutoHireVacancy,
  AutoHireView,
  EligibleEmployeeForHiringEngine,
  StaffingListItem,
} from '@stationwise/share-types';
import { formatShiftDuration, getMissingCertifications } from '@stationwise/shift-summary-helper';
import { HiringEngineStaffinListFilterPopover } from '../HiringEngineStaffingListFilterPopover';
import { RankOrderModal } from '../Vacancies/RankOrderModal';
import { VacancyFillRankOrderModal } from '../Vacancies/VacancyFillRankOrder';
import { HiringEngineVacancy } from '../Vacancies/vacanciesHelper';
import { generateRankOrder, getFormattedTimes } from './utils';

type MergedItem =
  | (HiringEngineVacancy & { type: 'vacancy' })
  | { type: 'autoHire'; data: AutoHireView; position: AutoHireVacancy };

interface AutoHireVacanciesProps {
  groupedVacancies: Record<number, HiringEngineVacancy[]>;
  rankOrders: Record<number, { rankId: number; isSelected: boolean }[]>;
  setRankOrders: React.Dispatch<React.SetStateAction<Record<number, { rankId: number; isSelected: boolean }[]>>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  autoHireInfo: AutoHireInfoView;
  handleAutoHireHistoryOpen: (rankId: number) => void;
  vacancyRankCallOrder: number[];
  setVacancyRankCallOrder: React.Dispatch<React.SetStateAction<number[]>>;
  isDateInPast: boolean;
  loading: boolean;
  setCurrentEligibleEmployees: (eligibleEmployees: Record<number, EligibleEmployeeForHiringEngine[]>) => void;
  checkedRanks: Record<number, boolean>;
  setCheckedRanks: React.Dispatch<React.SetStateAction<Record<number, boolean>>>;
  setSelectedStaffingListId: React.Dispatch<React.SetStateAction<number | null>>;
}

function getAutoHireChipProps(items: MergedItem[]): Pick<ChipProps, 'label' | 'color'> & { tooltip?: string } {
  const isCompleted = items.some((item) => item.type === 'autoHire' && item.data.isCompleted);
  const isActive = items.some((item) => item.type === 'autoHire' && item.data.isActive);
  const allNotificationsSent = items.some((item) => item.type === 'autoHire' && item.data.allNotificationsSent);
  const someNotificationsSent = items.some(
    (item) => item.type === 'autoHire' && item.data.history.some((entry) => entry.notificationSentTime),
  );

  let label: string;
  let color: 'success' | 'warning' | 'info' | 'primary' | 'default';
  let tooltip: string | undefined;

  if (isCompleted) {
    label = 'Completed';
    color = 'success';
    tooltip = 'All vacancies have been filled';
  } else if (isActive) {
    if (allNotificationsSent) {
      label = 'All Notified';
      color = 'success';
      tooltip = 'All eligible employees have been notified, but not all vacancies have been filled';
    } else if (someNotificationsSent) {
      label = 'In Progress';
      color = 'primary';
    } else {
      label = 'In Queue';
      color = 'info';
      tooltip = 'Waiting for the previous auto hire to complete';
    }
  } else {
    label = 'Inactive';
    color = 'default';
  }

  return { label, color, ...(tooltip && { tooltip }) };
}
export const AutoHireVacancies: React.FC<AutoHireVacanciesProps> = ({
  groupedVacancies,
  rankOrders,
  setRankOrders,
  setDisabled,
  autoHireInfo,
  handleAutoHireHistoryOpen,
  vacancyRankCallOrder,
  setVacancyRankCallOrder,
  isDateInPast,
  loading,
  setCurrentEligibleEmployees,
  checkedRanks,
  setCheckedRanks,
  setSelectedStaffingListId,
}) => {
  const [selectedRankId, setSelectedRankId] = useState<number | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isVacancyFillModalOpen, setVacancyFillModalOpen] = useState(false);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const { staffingListsState } = useRosterContext();
  const operators = staffingListsState.availableStaffingListItems;

  const [selectedTeamOptions, setSelectedTeamOptions] = useState(new Set<string>());
  const [selectedBattalionOptions, setSelectedBattalionOptions] = useState(new Set<string>());
  const [noEligibleEmployees, setNoEligibleEmployees] = useState(false);
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const filterStates = [
    useStaffingListFilterGroup(selectedTeamOptions, setSelectedTeamOptions),
    useStaffingListFilterGroup(selectedBattalionOptions, setSelectedBattalionOptions),
  ];
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  const uniqueRanks = useMemo(() => departmentInfo.ranks || [], [departmentInfo.ranks]);

  useEffect(() => {
    const anyChecked = Object.values(checkedRanks).some((checked) => checked);
    setDisabled(!anyChecked);
  }, [checkedRanks, setDisabled]);

  const handleCheckboxChange = (rankId: number) => {
    setCheckedRanks((prev) => {
      const newCheckedRanks = { ...prev, [rankId]: !prev[rankId] };

      if (newCheckedRanks[rankId]) {
        setRankOrders((prev) => {
          const autoHireOrder = autoHireInfo.autoHireDefaultRankOrders[rankId];
          return {
            ...prev,
            [rankId]: autoHireOrder ? autoHireOrder : generateRankOrder(rankId, uniqueRanks),
          };
        });
      }
      if (!Object.values(newCheckedRanks).every((checked) => checked)) {
        setAllChecked(false);
      }

      return newCheckedRanks;
    });
  };

  const handleAllCheckboxChange = () => {
    const newCheckedState = !allChecked;
    setAllChecked(newCheckedState);

    const newCheckedRanks = Object.keys(groupedVacancies).reduce<Record<number, boolean>>((acc, rankIdStr) => {
      const rankId = Number(rankIdStr);
      const hasActiveAutoHire = autoHireInfo.autoHires.some((autoHire) =>
        autoHire.positions.some((position) => position.rank.id === rankId && autoHire.isActive),
      );
      acc[rankId] = newCheckedState && !hasActiveAutoHire && eligibleEmployees[rankId].length > 0;
      return acc;
    }, {});

    setCheckedRanks(newCheckedRanks);
  };

  const handleEditButtonClick = (rankId: number) => {
    setSelectedRankId(rankId);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedRankId(null);
  };

  const handleRankOrderSubmit = (newOrders: { rankId: number; isSelected: boolean }[]) => {
    if (selectedRankId) {
      setRankOrders((prev) => ({ ...prev, [selectedRankId]: newOrders }));
    }
    setModalOpen(false);
  };
  const mergedVacancies = useMemo(() => {
    const merged: Record<number, MergedItem[]> = {};

    // Always add auto-hire positions
    autoHireInfo.autoHires.forEach((autoHire) => {
      autoHire.positions.forEach((position) => {
        if (!merged[position.rank.id]) {
          merged[position.rank.id] = [];
        }
        merged[position.rank.id].push({ type: 'autoHire', data: autoHire, position });
      });
    });

    // Add regular vacancies only if there are no auto-hire entries for that rank
    Object.entries(groupedVacancies).forEach(([rankIdStr, vacancies]) => {
      const rankId = Number(rankIdStr);
      if (merged[rankId]?.some((item) => item.type === 'autoHire')) {
        return;
      }

      if (!merged[rankId]) {
        merged[rankId] = [];
      }
      vacancies.forEach((vacancy) => {
        merged[rankId].push({ ...vacancy, type: 'vacancy' as const });
      });
    });

    return merged;
  }, [groupedVacancies, autoHireInfo.autoHires]);

  const handleVacancyFillModalClose = () => {
    setVacancyFillModalOpen(false);
  };

  const handleVacancyFillOrderSubmit = (newVacancyFillOrder: number[]) => {
    setVacancyRankCallOrder(newVacancyFillOrder);
    setVacancyFillModalOpen(false);
  };

  const handleEditFillOrder = () => {
    setVacancyFillModalOpen(true);
  };

  const sortedRankIds = Object.keys(mergedVacancies)
    .map(Number)
    .sort((a, b) => {
      const isActiveA = mergedVacancies[a].some((item) => item.type === 'autoHire' && item.data.isActive);
      const isActiveB = mergedVacancies[b].some((item) => item.type === 'autoHire' && item.data.isActive);

      if (isActiveA && !isActiveB) return -1;
      if (!isActiveA && isActiveB) return 1;

      return (vacancyRankCallOrder?.indexOf(a) ?? Infinity) - (vacancyRankCallOrder?.indexOf(b) ?? Infinity);
    });

  const activeAutoHireRanks = useMemo(() => {
    return autoHireInfo.autoHires
      .filter((autoHire) => autoHire.isActive)
      .map((autoHire) => {
        const rank = uniqueRanks.find((rank) => rank.code === autoHire.rankCode);
        if (!rank) {
          throw new Error(`Could not find rank with code ${autoHire.rankCode}`);
        }
        return rank;
      });
  }, [autoHireInfo.autoHires, uniqueRanks]);

  const eligibleEmployees = useMemo(() => {
    const matchEmployeesToVacancies = (
      staffingListItems: StaffingListItem[],
    ): Record<number, EligibleEmployeeForHiringEngine[]> => {
      const filteredStaffingListItems = staffingListItems.filter((item) => {
        if (selectedTeamOptions.size && !selectedTeamOptions.has(item.employee.team?.name || '')) {
          return false;
        }
        if (selectedBattalionOptions.size && !selectedBattalionOptions.has(item.employee.battalion?.name || '')) {
          return false;
        }
        return true;
      });

      const rankGroupEligibleEmployeeMapping: Record<number, EligibleEmployeeForHiringEngine[]> = Object.keys(
        groupedVacancies,
      ).reduce(
        (acc, rankId) => {
          acc[Number(rankId)] = [];
          return acc;
        },
        {} as Record<number, EligibleEmployeeForHiringEngine[]>,
      );

      filteredStaffingListItems.forEach((staffingListItem) => {
        Object.entries(groupedVacancies).forEach(([rankId, vacancies]) => {
          const targetRankId = Number(rankId);
          // Check if either there's no rank order yet, or if the employee's rank is selected in the rank order
          const isRankEligible =
            !rankOrders[targetRankId] ||
            rankOrders[targetRankId].some(
              (rankOrder) => rankOrder.rankId === staffingListItem.employee.rank.id && rankOrder.isSelected,
            );

          if (isRankEligible) {
            // Check certifications
            if (
              vacancies.some(
                (vacancy) =>
                  getMissingCertifications(vacancy.certifications, staffingListItem.employee.certifications).length === 0,
              )
            ) {
              rankGroupEligibleEmployeeMapping[targetRankId].push({
                id: staffingListItem.employee.id,
                name: staffingListItem.employee.name,
                rank: staffingListItem.employee.rank,
                certifications: staffingListItem.employee.certifications,
                plannedShiftDetails: [],
              });
            }
          }
        });
      });

      // Sort each rank group based on rank orders
      Object.keys(rankGroupEligibleEmployeeMapping).forEach((rankId) => {
        const targetRankId = Number(rankId);
        if (rankOrders[targetRankId]) {
          rankGroupEligibleEmployeeMapping[targetRankId].sort((a, b) => {
            const rankOrderA = rankOrders[targetRankId].findIndex((order) => order.rankId === a.rank.id && order.isSelected);
            const rankOrderB = rankOrders[targetRankId].findIndex((order) => order.rankId === b.rank.id && order.isSelected);

            if (rankOrderA === rankOrderB) {
              // If same rank priority, maintain original staffing list order
              return (
                filteredStaffingListItems.findIndex((item) => item.employee.id === a.id) -
                filteredStaffingListItems.findIndex((item) => item.employee.id === b.id)
              );
            }

            return rankOrderA - rankOrderB;
          });
        }
      });

      return rankGroupEligibleEmployeeMapping;
    };
    return matchEmployeesToVacancies(operators);
  }, [operators, groupedVacancies, selectedTeamOptions, selectedBattalionOptions, rankOrders]);

  useEffect(() => {
    setCurrentEligibleEmployees(eligibleEmployees);
  }, [eligibleEmployees, setCurrentEligibleEmployees]);

  useEffect(() => {
    if (staffingListsState.selectedStaffingList?.id) {
      setSelectedStaffingListId(staffingListsState.selectedStaffingList.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffingListsState.selectedStaffingList]);

  useEffect(() => {
    let noEligibleEmployeesFlag = true;
    Object.keys(groupedVacancies).forEach((rankId) => {
      if (eligibleEmployees[Number(rankId)].length === 0) {
        checkedRanks[Number(rankId)] = false;
      } else {
        noEligibleEmployeesFlag = false;
      }
      setNoEligibleEmployees(noEligibleEmployeesFlag);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibleEmployees, checkedRanks, allChecked]);

  const allStarted = useMemo(() => {
    return Object.values(mergedVacancies).every((items) => items.some((item) => item.type === 'autoHire'));
  }, [mergedVacancies]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: 1.5, height: '750px' }}>
      {!allStarted && (
        <>
          <StaffingListMainControls
            setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
            filterStates={filterStates}
            isHiringEngine={true}
          />
          <HiringEngineStaffinListFilterPopover
            items={operators}
            filterPopoverAnchorEl={filterPopoverAnchorEl}
            setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
            filterStates={filterStates}
          />
        </>
      )}
      {Object.keys(groupedVacancies).length !== Object.keys(autoHireInfo.autoHires).length && !isDateInPast && (
        <Box sx={(theme) => ({ display: 'flex', alignItems: 'center', px: theme.spacing(2), justifyContent: 'space-between' })}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox checked={allChecked} onChange={handleAllCheckboxChange} disabled={noEligibleEmployees} />
            <Typography sx={{ typography: 'bodySMedium', opacity: noEligibleEmployees ? 0.5 : 1 }}>Select All</Typography>
          </Box>
          <Button variant="text" color="primary" size="small" onClick={handleEditFillOrder} disabled={loading}>
            Set Vacancy Fill Order
          </Button>
        </Box>
      )}
      {staffingListsState.isLoading && <Loader />}
      {!staffingListsState.isLoading && !staffingListsState.isError && (
        <Box
          sx={() => ({
            flex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          })}
        >
          {sortedRankIds.map((rankId) => {
            const items = mergedVacancies[rankId] || [];
            if (items.length === 0) return null;

            const rankName = uniqueRanks
              .find((rank) => rank.id === rankId)
              ?.name.toLowerCase()
              .replace(/^\w/, (c) => c.toUpperCase());

            return (
              <Box
                key={rankId}
                sx={{
                  p: theme.spacing(0, 2, 2, 2),
                  mt: 1,
                  width: '100%',
                  backgroundColor: theme.palette.stationGray[50],
                  marginTop: theme.spacing(2),
                  borderRadius: '16px',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(1.5) }}>
                  {!items.some((item) => item.type === 'autoHire') && eligibleEmployees[rankId].length === 0 && (
                    <Chip
                      label="No eligible employees"
                      color="error"
                      size="small"
                      variant="filled"
                      sx={{ mb: theme.spacing(0.5), width: '50%' }}
                    />
                  )}
                </Box>
                <Box
                  sx={(theme) => ({
                    color: theme.palette.stationGray[500],
                    display: 'flex',
                    gap: theme.spacing(4),
                    typography: 'bodySMedium',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  })}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {!items.some((item) => item.type === 'autoHire') && (
                      <Checkbox
                        checked={checkedRanks[rankId] || false}
                        onChange={() => handleCheckboxChange(rankId)}
                        disabled={eligibleEmployees[rankId].length <= 0}
                      />
                    )}
                    <Typography>{rankName}</Typography>
                  </Box>
                  {items.every((item) => item.type === 'vacancy') && (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={() => handleEditButtonClick(rankId)}
                      disabled={loading}
                    >
                      Edit Rank Order
                    </Button>
                  )}
                  {items.some((item) => item.type === 'autoHire') && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
                      {(() => {
                        const chipProps = getAutoHireChipProps(items);
                        return chipProps.tooltip ? (
                          <Tooltip title={chipProps.tooltip}>
                            <Chip {...chipProps} size="small" variant="filled" />
                          </Tooltip>
                        ) : (
                          <Chip {...chipProps} size="small" variant="filled" />
                        );
                      })()}
                      <Button variant="text" color="success" size="small" onClick={() => handleAutoHireHistoryOpen(rankId)}>
                        View
                      </Button>
                    </Box>
                  )}
                </Box>
                {items.map((item, index) => (
                  <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '100%', maxWidth: '255px' }}>
                      <Typography
                        variant="caption"
                        sx={(theme) => ({
                          color: theme.palette.stationGray[400],
                          display: 'block',
                          mb: theme.spacing(0.5),
                          mt: theme.spacing(1),
                          textAlign: 'center',
                        })}
                      >
                        {item.type === 'vacancy'
                          ? `${item.stationName.toUpperCase()} | ${item.apparatusName.toUpperCase()}`
                          : `${item.position.stationName?.toUpperCase()} | ${item.position.apparatusName?.toUpperCase()}`}
                      </Typography>
                      <EmptyCard
                        rank={item.type === 'vacancy' ? item.rank : item.position.rank}
                        durationLabel={
                          item.type === 'vacancy'
                            ? formatShiftDuration({
                                startTime: item.startDateTime,
                                endTime: item.endDateTime,
                              })
                            : `${getFormattedTimes(item.position, departmentInfo).startTimeFormatted} - ${getFormattedTimes(item.position, departmentInfo).endTimeFormatted}`
                        }
                        certs={[]}
                        cardSxProps={{
                          height: '48px',
                          [theme.breakpoints.up('sm')]: {
                            height: '40px',
                          },
                          overflow: 'hidden',
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            );
          })}
        </Box>
      )}
      {selectedRankId && (
        <RankOrderModal
          open={isModalOpen}
          setOpen={handleModalClose}
          selectedRankId={selectedRankId}
          rankOrders={rankOrders[selectedRankId] || []}
          onSubmit={handleRankOrderSubmit}
          uniqueRanks={uniqueRanks}
        />
      )}
      <VacancyFillRankOrderModal
        open={isVacancyFillModalOpen}
        setOpen={handleVacancyFillModalClose}
        vacantRanks={uniqueRanks.filter((rank) => mergedVacancies[rank.id])}
        onSubmit={handleVacancyFillOrderSubmit}
        currentOrder={vacancyRankCallOrder}
        activeAutoHireRanks={activeAutoHireRanks}
      />
    </Box>
  );
};
