import { Box, useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { RankBadge } from '../Badge';
import { BlankCard } from '../BlankCard';
import { CardProps, CardCertBadges } from '../Card';

type CardPropNames = 'rank' | 'borderColor' | 'nameLabel' | 'certs' | 'type' | 'cardSxProps';

export interface HireBackCardProps extends Pick<CardProps, CardPropNames> {
  number: number;
  partialHoursOt?: string;
  lastShiftOt?: string;
  timeAsString?: string;
}

export const HireBackCard = ({
  rank,
  borderColor = 'gray',
  nameLabel,
  certs,
  number,
  partialHoursOt = '',
  lastShiftOt = '',
  timeAsString = '',
  type,
  cardSxProps,
}: HireBackCardProps) => {
  const theme = useTheme();

  return (
    <BlankCard
      type={type}
      borderColor={borderColor}
      sxProps={[{ position: 'relative' }, ...(Array.isArray(cardSxProps) ? cardSxProps : cardSxProps ? [cardSxProps] : [])]}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <RankBadge rank={rank} rankField="name" sx={{ alignSelf: 'flex-start' }} />
          <Box display="flex" alignItems="center" sx={{ mt: '10px' }}>
            <button
              type="button"
              style={{
                borderRadius: '50%',
                position: 'relative',
                width: '25px',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                cursor: 'pointer',
                backgroundColor: theme.palette.stationGray[600],
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 99,
                }}
              >
                <Box
                  sx={(theme) => ({
                    color: theme.palette.common.white,
                    typography: 'bodyXSRegular',
                  })}
                >
                  {String(number)}
                </Box>
              </Box>
            </button>
            <Box
              mr={1}
              ml={1}
              sx={{
                '&:after':
                  nameLabel.length > 11
                    ? {
                        position: 'absolute',
                        background: 'linear-gradient(90deg, transparent 2%, white)',
                        width: '15px',
                        height: '30px',
                        marginLeft: '85px',
                        marginTop: '-20px',
                        content: '""',
                      }
                    : '',
              }}
            >
              <Box
                sx={(theme) => ({
                  color: theme.palette.stationGray[900],
                  whiteSpace: 'nowrap',
                  maxWidth: '95px',
                  width: '95px',
                  overflow: 'hidden',
                  typography: 'bodyMMedium',
                })}
              >
                {nameLabel}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[400],
                mr: '5px',
                whiteSpace: 'nowrap',
                typography: 'buttonS',
              })}
            >
              Last shift OT
            </Box>
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[400],
                whiteSpace: 'nowrap',
                typography: 'buttonS',
              })}
            >
              Partial hours
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ mt: '16px' }}>
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                typography: 'bodySRegular',
              })}
            >
              {lastShiftOt ? format(parseISO(lastShiftOt), 'MM/dd/yy') : 'N/A'}
            </Box>
            <Box
              sx={(theme) => ({
                color: theme.palette.stationGray[900],
                pr: theme.spacing(0.5),
                typography: 'bodySRegular',
              })}
            >
              {partialHoursOt || 0} h
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          display="flex"
          gap={0.5}
          sx={{
            width: { xs: '137px', md: '51%' },
            minWidth: { xs: '137px', md: '51%' },
          }}
        >
          <CardCertBadges
            certs={certs}
            sx={{
              maxWidth: '140px',
              '&:after': {
                display: certs.length > 3 ? 'block' : 'none',
                position: 'absolute',
                background: 'linear-gradient(90deg, transparent 2%, white)',
                top: 0,
                right: 0,
                width: '55px',
                height: '100%',
                content: '""',
              },
            }}
          />
        </Box>
        {timeAsString && (
          <Box
            sx={{
              backgroundColor: theme.palette.stationGray[100],
              color: theme.palette.stationGray[600],
              width: '58px',
              height: '20px',
              borderRadius: '17px',
              mr: '93px',
              typography: 'bodyXSRegular',
              textAlign: 'center',
            }}
          >
            {format(timeAsString, 'HH:mm:ss')}
          </Box>
        )}
      </Box>
    </BlankCard>
  );
};
