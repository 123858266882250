import { Box, useTheme } from '@mui/material';
import { useDocumentTitle } from '@stationwise/component-module';
import { AppPageLayout } from '../../../AppPageLayout';
import { PersonalStaffingListsContent } from './PersonalStaffingListsContent';

export const PersonalStaffingListsMain = () => {
  useDocumentTitle('Staffing Lists');
  const theme = useTheme();

  return (
    <AppPageLayout sx={{ width: '100%' }}>
      <Box
        sx={{
          background: theme.palette.common.white,
          height: { xs: 'calc(100vh - 56px)', lg: '100vh' },
          width: '100%',
          maxWidth: '768px',
          margin: '0 auto',
          p: theme.spacing(3.5, 2, 4.5, 2),
          overflowY: 'auto',
        }}
      >
        <Box component="h1" sx={{ typography: 'bodyXXLSemibold', m: theme.spacing(0, 0, 1, 0) }}>
          My Staffing Lists
        </Box>
        <PersonalStaffingListsContent />
      </Box>
    </AppPageLayout>
  );
};
