import { Box, Popover, PopoverOrigin, SxProps, Theme } from '@mui/material';
import { ReactNode, useId, useState } from 'react';

interface CardTooltipProps {
  anchorSx?: SxProps<Theme>;
  anchorContent: ReactNode;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  popoverSx?: SxProps<Theme>;
  popoverContent: ReactNode;
}

export const CardTooltip = ({
  anchorSx,
  anchorContent,
  anchorOrigin,
  transformOrigin,
  popoverSx,
  popoverContent,
}: CardTooltipProps) => {
  const id = useId();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  return (
    <>
      <Box
        aria-describedby={`${id}popover`}
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        sx={[{ display: 'inline-flex' }, ...(Array.isArray(anchorSx) ? anchorSx : anchorSx ? [anchorSx] : [])]}
      >
        {anchorContent}
      </Box>
      <Popover
        open={!!anchorEl}
        id={`${id}popover`}
        sx={{ pointerEvents: 'none' }}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin || { vertical: 'top', horizontal: 'center' }}
        transformOrigin={transformOrigin || { vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        <Box
          sx={[
            (theme) => ({
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              p: theme.spacing(1, 1.5),
              background: theme.palette.common.white,
              color: theme.palette.stationGray[500],
              typography: 'bodyXSRegular',
            }),
            ...(Array.isArray(popoverSx) ? popoverSx : popoverSx ? [popoverSx] : []),
          ]}
        >
          {popoverContent}
        </Box>
      </Popover>
    </>
  );
};
