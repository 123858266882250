import { useTheme } from '@mui/material';
import React from 'react';
import { Chip } from '../Card/Chip';

interface OvalChipProps {
  label: string;
  backgroundColor?: string;
  color?: string;
}

export const OvalChip = ({ label, backgroundColor, color }: OvalChipProps) => {
  const theme = useTheme();
  const _backgroundColor = backgroundColor ? backgroundColor : theme.palette.stationGreen[900];
  const _color = color ? color : theme.palette.common.white;

  return (
    <Chip
      label={label}
      chipSxProps={() => ({
        backgroundColor: _backgroundColor,
        height: '16px',
        width: '100%',
        p: '0px 4px',
        borderRadius: '7px',
        '&:after': {
          content: `url('data:image/svg+xml,<svg width="10" height="16" viewBox="0 0 10 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.30463 2.93237C4.15848 1.10758 2.15488 0 0 0V24C2.15488 24 4.15848 22.8924 5.30463 21.0676L8.66146 15.7232C10.0912 13.4469 10.0912 10.5531 8.66147 8.27681L5.30463 2.93237Z" fill="${_backgroundColor.replace(
            '#',
            '%23',
          )}"/></svg>')`,
          position: 'absolute',
          height: '100%',
          width: '10px',
          right: '-4px',
        },
        '&:before': {
          content: `url('data:image/svg+xml,<svg width="10" height="16" viewBox="0 0 10 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.69537 2.93237C5.84152 1.10758 7.84512 0 10 0V0V24V24C7.84512 24 5.84152 22.8924 4.69537 21.0676L1.33854 15.7232C-0.0911757 13.4469 -0.091176 10.5531 1.33853 8.27681L4.69537 2.93237Z" fill="${_backgroundColor.replace(
            '#',
            '%23',
          )}"/></svg>')`,
          position: 'absolute',
          height: '100%',
          width: '10px',
          left: '-4px',
        },
      })}
      variant="tagline2"
      typographyStyles={{ color: _color }}
    />
  );
};
