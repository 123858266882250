import { useEffect, useState } from 'react';
import { Capability, useAuthUserCapabilities } from '../../../utils';
import { LottieLogoPageLoader } from '../../LottieLogo';

interface RequireCapabilityProtectorProps {
  capabilities: Capability[];
  children: JSX.Element;
  restrictedComponent: React.ReactElement;
}

export const RequireCapabilityProtector = ({ capabilities, children, restrictedComponent }: RequireCapabilityProtectorProps) => {
  const userCapabilities = useAuthUserCapabilities();
  const [hasChecked, setHasChecked] = useState(false);
  const [hasCapability, setHasCapability] = useState(false);

  useEffect(() => {
    if (!hasCapability) {
      setHasCapability(capabilities.some((cap) => userCapabilities[cap]));
    }
    setHasChecked(true);
  }, [capabilities, hasCapability, userCapabilities]);

  if (!hasChecked) return <LottieLogoPageLoader />;
  if (!hasCapability) return restrictedComponent;
  return children;
};
