import { Box } from '@mui/material';

export interface ShiftTradeRefundOptionsProps {
  selectedButton: string;
  handleButtonClick: (arg0: RefundOptType) => void;
}

export type RefundOptType = 'REFUND_HOURS' | 'KEEP_BALANCE';

export const ShiftTradeRefundOptions = ({ selectedButton, handleButtonClick }: ShiftTradeRefundOptionsProps) => {
  return (
    <>
      <Box
        sx={(theme) => ({
          typography: 'bodySMedium',
          textAlign: 'left',
          color: theme.palette.stationGray[700],
          mt: theme.spacing(2),
        })}
      >
        Trade hours balance
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          py: theme.spacing(1),
        })}
      >
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.palette.stationGray[200]}`,
            padding: '10px 12px',
            backgroundColor: selectedButton === 'REFUND_HOURS' ? theme.palette.common.black : theme.palette.common.white,
            color: selectedButton === 'REFUND_HOURS' ? theme.palette.common.white : theme.palette.common.black,
            borderRadius: theme.spacing(1),
            '&:hover': {
              cursor: 'pointer',
            },
            typography: 'bodyMRegular',
            width: '100%',
          })}
          onClick={() => handleButtonClick('REFUND_HOURS')}
        >
          Undo traded hours
        </Box>
        <Box
          onClick={() => handleButtonClick('KEEP_BALANCE')}
          sx={(theme) => ({
            width: '100%',
            border: `1px solid ${theme.palette.stationGray[200]}`,
            padding: '10px 12px',
            backgroundColor: selectedButton === 'KEEP_BALANCE' ? theme.palette.common.black : theme.palette.common.white,
            color: selectedButton === 'KEEP_BALANCE' ? theme.palette.common.white : theme.palette.common.black,
            borderRadius: theme.spacing(1),
            marginLeft: theme.spacing(1.5),
            '&:hover': {
              cursor: 'pointer',
            },
            typography: 'bodyMRegular',
          })}
        >
          Leave hours balance
        </Box>
      </Box>
    </>
  );
};
