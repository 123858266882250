import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { differenceInDays, formatISO, startOfDay } from 'date-fns';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ChevronRightIcon24,
  GenericDrawer,
  Loader,
  useLoadedAuthUserContext,
  useLoadedDepartmentInfoContext,
  XCircleIcon24,
  SHIFT_TITLES,
  STATUS_TITLES,
  isTimeOffEvent,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { RequestOverview as IRequestOverview, Waitlist } from '@stationwise/share-types';
import { TimeOffWaitlist } from '../TimeOffRequest/TimeOffWaitlist';
import { WaitlistWarning } from '../TimeOffRequest/warning/WaitlistWarning';
import { CancelCommonRequest } from './CancelCommonRequest';
import { OverviewCard } from './OverviewCard';
import { StatusCard } from './StatusCard';
import { useFetchRequestOverview } from './hooks/useFetchRequestOverview';

const getOverviewArgs = (shift: EventInput | EventImpl) => {
  let overviewArgs;
  if (isTimeOffEvent(shift)) {
    overviewArgs = {
      fetchUrl: `request/time-off-request/${shift.id}/overview/`,
      cancelUrl: `request/time-off-request/${shift.id}/`,
      title: 'Time off',
    };
  } else if (shift.title === SHIFT_TITLES.TRADE_REQUESTED || shift.title === SHIFT_TITLES.OFF_TRADE) {
    overviewArgs = {
      fetchUrl: `request/shift-trade-request/${shift.id}/overview/`,
      cancelUrl: `/request/shift-trade-request/${shift.id}/cancel/`,
      title: 'Shift trade',
    };
  }
  return (
    overviewArgs || {
      fetchUrl: '',
      cancelUrl: '',
      title: '',
    }
  );
};

interface RequestOverviewProps {
  drawerOpen: boolean;
  handleOnClose: () => void;
  eventColor?: string;
  setRefetchEvents: Dispatch<React.SetStateAction<boolean>>;
  shift: EventInput | EventImpl;
}

export const RequestOverview = ({ drawerOpen, handleOnClose, setRefetchEvents, shift }: RequestOverviewProps) => {
  const overviewInfo = useMemo(() => getOverviewArgs(shift), [shift]);
  const { isLoading, isError, error, data: overview } = useFetchRequestOverview<IRequestOverview>(overviewInfo.fetchUrl);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [waitlist, setWaitlist] = useState<Waitlist | null>(null);
  const { state: authUserState } = useLoadedAuthUserContext();
  const {
    state: { departmentInfo },
  } = useLoadedDepartmentInfoContext();

  const cancellationNoticeDays = departmentInfo.settings.timeOffCancellationNoticeDays;
  const today = startOfDay(new Date());
  const shiftDate = startOfDay(shift.start as Date);
  const daysUntilShift = differenceInDays(shiftDate, today);
  const isCancellable = daysUntilShift >= cancellationNoticeDays;

  useEffect(() => {
    if (!departmentInfo.settings.timeOffWaitlistEnabled || !overview) return;
    if (overview.payCode?.payCodeType !== 'TIME_OFF') return;
    if (!shift.start) return;
    const shiftDate = formatISO(shift.start as Date, { representation: 'date' });
    const fetchWaitlist = async () => {
      try {
        const response = await client.get('/request/time-off-waitlist/', {
          params: {
            date: shiftDate,
            rank: authUserState.employee.rank.id,
            payCode: overview.payCode.code,
          },
        });
        setWaitlist(response.data === '' ? null : response.data);
      } catch (error) {
        captureException(error);
      }
    };
    fetchWaitlist();
  }, [shift, overview, authUserState.employee.rank.id, departmentInfo.settings.timeOffWaitlistEnabled]);

  const handleCancelRequest = () => {
    setShowCancelModal(true);
  };

  if (showCancelModal && overview) {
    return (
      <CancelCommonRequest
        drawerOpen={showCancelModal}
        handleOnClose={handleOnClose}
        url={overviewInfo.cancelUrl}
        title={overviewInfo.title}
        setRefetchEvents={setRefetchEvents}
      />
    );
  }

  return (
    <GenericDrawer
      anchor="bottom"
      drawerOpen={drawerOpen}
      handleOnClose={handleOnClose}
      loading={false}
      showHeader={true}
      headerTitle={overviewInfo.title}
      disableFooter={true}
      noBorderOnHeader={true}
    >
      <Box>
        <TimeOffWaitlist setOpen={setShowWaitlist} isOpen={showWaitlist} waitlist={waitlist} />
        {isLoading && (
          <Box
            sx={(theme) => ({
              p: theme.spacing(3),
              mt: theme.spacing(3),
            })}
          >
            <Loader />
          </Box>
        )}
        {isError && <div>{`Something went wrong please try again later ${error}}`}</div>}
        {!isLoading && !isError && overview && (
          <Box
            sx={(theme) => ({
              p: `0 ${theme.spacing(2)}`,
            })}
          >
            <OverviewCard overview={overview} eventColor={shift.backgroundColor} />
            <Box sx={(theme) => ({ mt: theme.spacing(2) })}>
              <StatusCard
                overview={overview}
                eventColor={shift.backgroundColor}
                waitlist={waitlist}
                employee={authUserState.employee}
              />
              {![STATUS_TITLES.APPROVED, STATUS_TITLES.DENIED, STATUS_TITLES.USER_CANCELLED].includes(overview.status) && (
                <WaitlistWarning setOpen={setShowWaitlist} waitlist={waitlist} alreadyOnWaitlist={true} />
              )}
            </Box>
            {isCancellable && ![STATUS_TITLES.DENIED, STATUS_TITLES.USER_CANCELLED].includes(overview.status) ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => handleCancelRequest()}
                sx={(theme) => ({
                  pb: theme.spacing(2),
                  pt: theme.spacing(4),
                })}
              >
                <Box display="flex">
                  <Box
                    sx={(theme) => ({
                      color: theme.palette.stationRose[400],
                    })}
                  >
                    <XCircleIcon24 />
                  </Box>
                  <Box sx={(theme) => ({ ml: theme.spacing(2) })}>
                    <Typography
                      variant="bodyLMedium"
                      sx={(theme) => ({
                        color: theme.palette.stationRose[600],
                      })}
                    >
                      Cancel request
                    </Typography>
                  </Box>
                </Box>

                <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
                  <ChevronRightIcon24 />
                </Box>
              </Box>
            ) : (
              <Box sx={(theme) => ({ p: `${theme.spacing(2)} 0` })}>
                <Button onClick={handleOnClose} buttonType="tertiary" buttonSize="md" sx={() => ({ width: '100%' })}>
                  Close
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </GenericDrawer>
  );
};
