import { DepartmentInfo, RosterEditableEmployeeSplit, RosterShiftDuration } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { ReasonOption } from './Reason';

interface GetSplitErrorsProps {
  split: RosterEditableEmployeeSplit;
  index: number;
  splits: RosterEditableEmployeeSplit[];
  departmentInfo: DepartmentInfo;
  shiftDuration: RosterShiftDuration;
  timeOffReasonOptions: Map<string, ReasonOption>;
}

const checkHasDurationError = ({ split, shiftDuration, ...props }: GetSplitErrorsProps) => {
  const durationMinutes = differenceInUTCMinutes(split.endDateTime, split.startDateTime);
  const prevSplit = props.splits[props.index - 1];
  const nextSplit = props.splits[props.index + 1];
  return (
    isNaN(durationMinutes) ||
    durationMinutes <= 0 ||
    durationMinutes > 24 * 60 ||
    split.startDateTime < shiftDuration.startTime ||
    split.endDateTime > shiftDuration.endTime ||
    split.startDateTime < (prevSplit?.endDateTime || shiftDuration.startTime) ||
    split.endDateTime > (nextSplit?.startDateTime || shiftDuration.endTime)
  );
};

const checkHasTimeOffReasonError = ({ split }: GetSplitErrorsProps) => {
  if (!(split.initialReference.type === 'ASSIGNMENT' && split.reference.type === 'TIME_OFF_REQUEST')) {
    return false;
  }
  return !split.reference.payCode.code;
};

const checkHasTimeOffAccrualLimitError = ({ split, ...props }: GetSplitErrorsProps) => {
  if (
    !props.departmentInfo.settings.accrualLogicEnabled ||
    !(split.initialReference.type === 'ASSIGNMENT' && split.reference.type === 'TIME_OFF_REQUEST')
  ) {
    return false;
  }

  const option = props.timeOffReasonOptions.get(split.reference.payCode.code);
  if (!option || option.limit === null || option.isUnlimited) {
    return false;
  }

  const newAccruedDurationMinutes = props.splits.reduce((sum, otherSplit) => {
    const isNewAccruedSplit =
      otherSplit.initialReference.type === 'ASSIGNMENT' &&
      otherSplit.reference.type === 'TIME_OFF_REQUEST' &&
      props.timeOffReasonOptions.get(otherSplit.reference.payCode.code) === option;
    return sum + (isNewAccruedSplit ? differenceInUTCMinutes(otherSplit.endDateTime, otherSplit.startDateTime) : 0);
  }, 0);
  return newAccruedDurationMinutes > 60 * option.limit;
};

export const getSplitErrors = (props: GetSplitErrorsProps) => {
  const errors = new Set<string>();
  checkHasDurationError(props) && errors.add('DURATION');
  checkHasTimeOffReasonError(props) && errors.add('TIME_OFF_REASON');
  checkHasTimeOffAccrualLimitError(props) && errors.add('TIME_OFF_ACCRUAL_LIMIT');
  return errors;
};
