import { Box, Typography, Tooltip } from '@mui/material';
import { Badge, theme } from '@stationwise/component-module';

interface HiringEngineServicesProps {
  title: string;
  description: string;
  isActive: boolean;
  onClick?: () => void;
  disabled?: boolean;
  tooltip?: string;
}

export const HiringEngineServices = ({ title, description, isActive, onClick, disabled, tooltip }: HiringEngineServicesProps) => {
  const content = (
    <Box
      onClick={disabled ? undefined : onClick}
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        borderRadius: 2,
        maxWidth: '285px',
        border: `1px solid ${theme.palette.stationGray[100]}`,
        boxShadow: '0px 0px 0px 1px #E0E0E0',
        p: 2,
        cursor: disabled ? 'default' : 'pointer',
        opacity: disabled ? 0.5 : 1,
        '&:hover': {
          backgroundColor: disabled ? 'transparent' : theme.palette.stationGray[50],
        },
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>{title}</Typography>
          {isActive && (
            <Badge
              label={'Active'}
              type="sm"
              isRound={true}
              customBackgroundColor={theme.palette.stationLightBlue[700]}
              customColor={theme.palette.common.white}
              variant="chipLabel"
              overrideMaxLength={20}
              isCustom
            />
          )}
        </Box>
        <Typography variant="bodySRegular" sx={(theme) => ({ color: theme.palette.stationGray[500] })}>
          {description}
        </Typography>
      </Box>
    </Box>
  );

  return tooltip ? (
    <Tooltip title={tooltip}>
      <span>{content}</span>
    </Tooltip>
  ) : (
    content
  );
};
