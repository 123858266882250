import { SxProps, Theme } from '@mui/material';
import { Rank } from '@stationwise/share-types';
import { RankBadge } from '../Badge';
import { CardTooltip } from './CardTooltip';
import { Chip } from './Chip';

interface ActingAsIndicatorProps {
  rank: Pick<Rank, 'name' | 'code' | 'color'>;
  sx?: SxProps<Theme>;
}

export const ActingAsIndicator = ({ rank, sx }: ActingAsIndicatorProps) => {
  return (
    <CardTooltip
      anchorSx={sx}
      anchorContent={<Chip label="A" />}
      popoverSx={{ gap: 1, p: '12px 14px' }}
      popoverContent={
        <>
          <Chip label="Acting as" />
          <RankBadge rank={rank} rankField="name" />
        </>
      }
    />
  );
};
