import { Box } from '@mui/material';
import { useMemo } from 'react';
import { RosterApparatus, RosterPosition, RosterEmployee } from '@stationwise/share-types';
import { differenceInUTCMinutes } from '@stationwise/share-utils';
import { checkIsShift, checkIsStrikeTeam } from '@stationwise/shift-summary-helper';
import { useRosterContext } from '../RosterContext';

interface DefaultStaffingSectionProps {
  apparatus: RosterApparatus | null;
  position: RosterPosition | null;
  employee: RosterEmployee;
}

export const DefaultStaffingSection = ({ apparatus, position, employee }: DefaultStaffingSectionProps) => {
  const { shiftSummaryHelper } = useRosterContext();
  const plannedAssignments = shiftSummaryHelper.plannedAssignments.get(employee.id);
  const { currentTeams, allStationCards, shiftDuration } = shiftSummaryHelper;

  const isPlanned = useMemo(() => {
    if (!plannedAssignments) {
      return false;
    }

    const employeeStartTime = differenceInUTCMinutes(employee.startDateTime, shiftDuration.startTime);
    const employeeEndTime = differenceInUTCMinutes(employee.endDateTime, shiftDuration.startTime);
    for (const pa of plannedAssignments) {
      const isPlannedTeam = currentTeams.some((team) => team.id === pa.shiftTeamId);

      let isPlannedPosition = false;
      if (!position) {
        isPlannedPosition = !pa.apparatusId && !pa.positionId;
      } else if (position.isTemporary) {
        isPlannedPosition = `${pa.apparatusId}` === apparatus?.id && !pa.positionId;
      } else {
        isPlannedPosition = `${pa.positionId}` === position.id;
      }

      const isPlannedDuration = employeeStartTime === pa.startTime && employeeEndTime === pa.endTime;
      if (isPlannedTeam && isPlannedPosition && isPlannedDuration) {
        return true;
      }
    }

    return false;
  }, [apparatus, position, employee, currentTeams, shiftDuration, plannedAssignments]);

  if (!checkIsShift(employee) && !checkIsStrikeTeam(employee)) {
    return null;
  }

  if (!plannedAssignments || isPlanned) {
    return null;
  }

  let renderedPlannedAssignments = plannedAssignments.map((pa) => {
    if (!pa.apparatusId && !pa.positionId) {
      return 'Floater';
    }

    for (const station of allStationCards.values()) {
      for (const apparatus of station.apparatuses) {
        if (`${pa.apparatusId}` === apparatus.id || apparatus.positions.some((p) => `${pa.positionId}` === p.id)) {
          return `${station.stationName} / ${apparatus.name}`;
        }
      }
    }

    return null;
  });
  renderedPlannedAssignments = Array.from(new Set(renderedPlannedAssignments.filter(Boolean)));

  return (
    <Box
      sx={(theme) => ({
        color: theme.palette.common.white,
        mt: 2,
        typography: 'bodyMMedium',
        '& ul': { listStyleType: 'disc', m: 0, p: theme.spacing(0, 0, 0, 1.75), typography: 'bodySRegular' },
        '& li': { m: 0, p: 0 },
      })}
    >
      <Box>Default Staffing</Box>
      {renderedPlannedAssignments.length === 1 && renderedPlannedAssignments}
      {renderedPlannedAssignments.length > 1 && (
        <ul>
          {renderedPlannedAssignments.map((rpa, i) => (
            <li key={i}>{rpa}</li>
          ))}
        </ul>
      )}
    </Box>
  );
};
