import { Dispatch, SetStateAction } from 'react';
import { EmployeeOffView } from '@stationwise/share-types';
import { IShiftSummaryHelper, cancelShiftTrade, getEmployeeOffDuration } from '@stationwise/shift-summary-helper';

interface UseCancelShiftTradeInput {
  shiftSummaryHelper: IShiftSummaryHelper;
  setShiftSummaryHelper: Dispatch<SetStateAction<IShiftSummaryHelper>>;
  setUserHasMadeChanges: Dispatch<SetStateAction<boolean>>;
}

export const useCancelShiftTrade = ({
  shiftSummaryHelper,
  setShiftSummaryHelper,
  setUserHasMadeChanges,
}: UseCancelShiftTradeInput) => {
  const handleCancelShiftTrade = (employeeOffData: EmployeeOffView, tradedHoursBalancePolicy: string) => {
    const payload = { shiftTradeId: employeeOffData.id, tradedHoursBalancePolicy };
    const { startDateTime, endDateTime } = getEmployeeOffDuration(shiftSummaryHelper, employeeOffData);
    const newShiftSummaryHelper = cancelShiftTrade(
      shiftSummaryHelper,
      `${employeeOffData.replacementEmployees[0].id}`,
      {
        ...employeeOffData.employeeOff,
        id: `${employeeOffData.employeeOff.id}`,
        name: `${employeeOffData.employeeOff.firstName} ${employeeOffData.employeeOff.lastName}`,
      },
      startDateTime,
      endDateTime,
    );
    newShiftSummaryHelper.cancelShiftTradePayloads = [...newShiftSummaryHelper.cancelShiftTradePayloads, payload];
    setShiftSummaryHelper(newShiftSummaryHelper);
    setUserHasMadeChanges(true);
  };

  return { handleCancelShiftTrade };
};
