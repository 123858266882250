import { Box, useTheme } from '@mui/material';
import { CertBadge } from '../../../Badge';
import { CardProps } from '../../../Card';
import { LetterAvatar } from '../../../LetterAvatar';

export type CardNameProps = Pick<CardProps, 'rank' | 'certs' | 'nameLabel'>;

export const CardName = ({ rank, certs, nameLabel }: CardNameProps) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column">
      <Box sx={{ color: theme.palette.grey[400], typography: 'bodyXSRegular' }}>{rank.name}</Box>
      <Box display="flex" mt={1} alignItems="center">
        <LetterAvatar firstName={nameLabel} size="xsmall" color="orange" dark={true} />
        <Box sx={{ color: theme.palette.common.white, ml: 1, typography: 'bodyMSemibold' }}>{nameLabel}</Box>
      </Box>
      <Box display="flex" flexWrap="wrap" gap={0.5} mt={1}>
        {certs.map((cert, index) => (
          <CertBadge key={`${index}-${cert.code}`} cert={cert} dark={true} />
        ))}
      </Box>
    </Box>
  );
};
