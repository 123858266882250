import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Box, Typography, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { useFetchStaffingLists } from '../../../hooks';

interface ActionButtonProps {
  requestOvertimeDisabled?: boolean;
  handleRequestOvertimeClick?: (staffingList: ListFieldsStaffingList) => void;
  setCreateIncidentOpen?: (value: boolean) => void;
  setCreateIncident: (value: boolean) => void;
}

export const ActionButton = ({
  requestOvertimeDisabled,
  handleRequestOvertimeClick,
  setCreateIncidentOpen,
  setCreateIncident,
}: ActionButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { isLoading: staffingListsIsLoading, data } = useFetchStaffingLists();
  const staffingLists = data?.map((sl: ListFieldsStaffingList) => sl) || [];
  const disabled = requestOvertimeDisabled || staffingListsIsLoading;

  const filteredStaffingLists = staffingLists.filter((sl) => sl.isOptOut || sl.isSignUp);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        data-cy="request-overtime-button"
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          height: '40px',
          padding: theme.spacing(1, 2.75),
          borderRadius: theme.spacing(0.75),
          typography: 'buttonM',
          textTransform: 'none',
          boxShadow: 'none',
        })}
        endIcon={<KeyboardArrowDownIcon />}
        variant="contained"
        disabled={disabled}
        onClick={handleClick}
      >
        <Typography variant="buttonM"> Sign Up</Typography>
        <Menu
          id="avatar-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {filteredStaffingLists.map((staffingList: ListFieldsStaffingList) => (
            <MenuItem
              key={`Request_${staffingList.id}`}
              data-cy={`request-staffing-list-${makeTestIdentifier(staffingList.name)}`}
              onClick={(event) => {
                setCreateIncident(false);
                setCreateIncidentOpen && setCreateIncidentOpen(false);
                if (handleRequestOvertimeClick) handleRequestOvertimeClick(staffingList);
                handleClose(event);
              }}
              sx={{
                typography: 'bodySMRegular',
              }}
            >
              {`${staffingList.isOptOut ? 'Opt out from ' : 'Sign up for '}${staffingList.name}`}
            </MenuItem>
          ))}
        </Menu>
      </Button>
    </Box>
  );
};
