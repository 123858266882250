import { axios } from '@stationwise/share-api';
import { SnackbarService } from '../components/Snackbar';

const CURRENT_VERSION = process.env['UI_APP_VERSION'] ?? '0';

function reloadPage() {
  window.location.reload();
}

export async function checkVersion() {
  const versionResponse = await axios({ method: 'GET', url: '/static-client/ui-app-version', baseURL: '/' });
  const matched = versionResponse.data === CURRENT_VERSION;

  if (!matched) {
    SnackbarService.notify({
      severity: 'info',
      content: 'There is a new version of the application.',
      actionButtonText: 'Reload',
      showCloseButton: false,
      onCallToAction: () => {
        reloadPage();
      },
    });
  }
}
