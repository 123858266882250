import { Box, Typography } from '@mui/material';
import { Dispatch, useState } from 'react';
import {
  Button,
  ChevronRightIcon24,
  GenericDrawer,
  Loader,
  XCircleIcon24,
  STATUS_TITLES,
  SHIFT_TITLES,
} from '@stationwise/component-module';
import { RequestOverview } from '@stationwise/share-types';
import { CancelCommonRequest } from '../../RequestSharedComponents/CancelCommonRequest';
import { StatusCard } from '../../RequestSharedComponents/StatusCard';
import { useFetchRequestOverview } from '../../RequestSharedComponents/hooks/useFetchRequestOverview';
import { OverviewCard } from './OverviewCard';

interface AdditionalPaidTimeOverviewProps {
  id: string;
  drawerOpen: boolean;
  handleOnClose: () => void;
  eventColor?: string;
  setRefetchEvents: Dispatch<React.SetStateAction<boolean>>;
}
export const AdditionalPaidTimeOverview = ({
  id,
  drawerOpen,
  handleOnClose,
  eventColor,
  setRefetchEvents,
}: AdditionalPaidTimeOverviewProps) => {
  const cancelUrl = `/request/additional-paid-time-request/${id}/`;
  const fetchUrl = `/request/additional-paid-time-request/${id}/overview/`;
  const { isLoading, isError, error, data: addPaidTimeOverview } = useFetchRequestOverview<RequestOverview>(fetchUrl);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleCancelRequest = () => {
    setShowCancelModal(true);
  };

  if (showCancelModal && addPaidTimeOverview) {
    return (
      <CancelCommonRequest
        drawerOpen={showCancelModal}
        handleOnClose={handleOnClose}
        url={cancelUrl}
        title="Additional paid time"
        setRefetchEvents={setRefetchEvents}
      />
    );
  }

  return (
    <GenericDrawer
      anchor="bottom"
      drawerOpen={drawerOpen}
      handleOnClose={handleOnClose}
      loading={false}
      showHeader={true}
      headerTitle={
        addPaidTimeOverview?.title?.toUpperCase().includes(SHIFT_TITLES.INCIDENT_PAY)
          ? 'Incident Pay'
          : 'Additional paid time request'
      }
      disableFooter={true}
      noBorderOnHeader={true}
    >
      <Box>
        {isLoading && (
          <Box
            sx={(theme) => ({
              p: theme.spacing(3),
              mt: theme.spacing(3),
            })}
          >
            <Loader />
          </Box>
        )}
        {isError && <div>{`Something went wrong please try again later ${error}}`}</div>}
        {!isLoading && !isError && addPaidTimeOverview && (
          <Box
            sx={(theme) => ({
              p: `0 ${theme.spacing(2)}`,
            })}
          >
            <OverviewCard additionalPaidTime={addPaidTimeOverview} eventColor={eventColor} />
            <Box sx={(theme) => ({ mt: theme.spacing(2) })}>
              <StatusCard overview={addPaidTimeOverview} eventColor={eventColor} />
            </Box>
            {![STATUS_TITLES.APPROVED, STATUS_TITLES.DENIED, STATUS_TITLES.USER_CANCELLED].includes(
              addPaidTimeOverview.status,
            ) ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => handleCancelRequest()}
                sx={(theme) => ({
                  pb: theme.spacing(2),
                  pt: theme.spacing(4),
                })}
              >
                <Box display="flex">
                  <Box
                    sx={(theme) => ({
                      color: theme.palette.stationRose[400],
                    })}
                  >
                    <XCircleIcon24 />
                  </Box>
                  <Box sx={(theme) => ({ ml: theme.spacing(2) })}>
                    <Typography
                      variant="bodyLMedium"
                      sx={(theme) => ({
                        color: theme.palette.stationRose[600],
                      })}
                    >
                      Cancel request
                    </Typography>
                  </Box>
                </Box>

                <Box sx={(theme) => ({ color: theme.palette.stationGray[400] })}>
                  <ChevronRightIcon24 />
                </Box>
              </Box>
            ) : (
              <Box sx={(theme) => ({ p: `${theme.spacing(2)} 0` })}>
                <Button onClick={handleOnClose} buttonType="tertiary" buttonSize="md" sx={() => ({ width: '100%' })}>
                  Close
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </GenericDrawer>
  );
};
