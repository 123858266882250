import { ReactComponent as CancelSymbol } from '@material-symbols/svg-400/outlined/cancel-fill.svg';
import { ReactComponent as FilterAltFillSymbol } from '@material-symbols/svg-400/outlined/filter_alt-fill.svg';
import { ReactComponent as SearchSymbol } from '@material-symbols/svg-400/outlined/search.svg';
import {
  Badge,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useId } from 'react';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { SvgIcon } from '../../../../SvgIcon';
import { useRosterContext } from '../../RosterContext';
import { StaffingListFilterState } from './StaffingListFilterGroup';

const MAX_DISPLAYED_FILTER_OPTIONS = 3;
const SORT_OPTIONS = ['Number', 'Rank'];

interface StaffingListMainControlsProps {
  searchInput?: string;
  setSearchInput?: Dispatch<SetStateAction<string>>;
  setFilterPopoverAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  filterStates: StaffingListFilterState[];
  sortValue?: string;
  setSortValue?: Dispatch<SetStateAction<string>>;
  isHiringEngine?: boolean;
}

export const StaffingListMainControls = (props: StaffingListMainControlsProps) => {
  const { staffingListsState } = useRosterContext();
  const id = useId();
  const theme = useTheme();

  const filterCount = props.filterStates.map((state) => state.appliedOptions.size).reduce((acc, curr) => acc + curr, 0);

  const renderSelectOption = (option: string) => (
    <MenuItem key={option} data-cy={`${makeTestIdentifier(option)}-option`} value={option}>
      {option}
    </MenuItem>
  );

  const renderFilterChips = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', p: theme.spacing(0, 1.5, 2, 2) }}>
        {(() => {
          const chips: ReactNode[] = [];
          props.filterStates.forEach((state, stateIndex) => {
            state.appliedOptions.forEach((option) => {
              if (chips.length < MAX_DISPLAYED_FILTER_OPTIONS) {
                chips.push(
                  <Chip
                    key={`${stateIndex}:${option}`}
                    size="small"
                    label={option}
                    sx={{ '& .MuiChip-label': { maxWidth: 68 } }}
                    deleteIcon={<SvgIcon component={CancelSymbol} />}
                    onDelete={() => {
                      const newAppliedOptions = new Set(state.appliedOptions);
                      newAppliedOptions.delete(option);
                      state.setAppliedOptions(newAppliedOptions);
                    }}
                  />,
                );
              }
            });
          });
          if (chips.length < filterCount) {
            chips.push(<Chip key="more" size="small" label={`+${filterCount - chips.length}`} />);
          }

          return <Box sx={{ flex: 1, display: 'flex', gap: 0.25 }}>{chips}</Box>;
        })()}
        <Button
          variant="text"
          color="primary"
          size="small"
          sx={{ p: 0.5, typography: 'buttonM', whiteSpace: 'nowrap' }}
          onClick={() => props.filterStates.forEach((state) => state.setAppliedOptions(new Set()))}
        >
          Clear all
        </Button>
      </Box>
    );
  };

  return (
    <>
      {!props.isHiringEngine && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, p: theme.spacing(0, 2, 2, 2) }}>
          <Box sx={{ flex: 1 }}>
            <TextField
              fullWidth={true}
              value={props.searchInput}
              onChange={(event) => props.setSearchInput?.(event.target.value)}
              placeholder="Name"
              type="search"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start" sx={{ mr: -0.5, pointerEvents: 'none' }}>
                      <SvgIcon component={SearchSymbol} sx={{ color: theme.palette.action.active }} />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>

          <Badge badgeContent={filterCount} color="primary">
            <IconButton
              data-cy="open-staffing-list-filters-button"
              aria-label="Filters"
              disabled={!staffingListsState.selectedStaffingList?.items?.length}
              onClick={(event) => props.setFilterPopoverAnchorEl(event.currentTarget)}
            >
              <SvgIcon component={FilterAltFillSymbol} sx={{ color: theme.palette.action.active }} />
            </IconButton>
          </Badge>
        </Box>
      )}
      {!!filterCount && !props.isHiringEngine && renderFilterChips()}
      <Box
        sx={{
          borderTop: props.isHiringEngine ? 'none' : `1px solid ${theme.palette.divider}`,
          display: 'flex',
          gap: 2,
          p: 2,
        }}
      >
        {!props.isHiringEngine && (
          <FormControl sx={{ width: '35%' }}>
            <InputLabel id={`${id}sort`}>Sort by</InputLabel>
            <Select
              disabled={!staffingListsState.selectedStaffingList?.items?.length}
              labelId={`${id}sort`}
              label="Sort by"
              value={props.sortValue}
              onChange={(event) => props.setSortValue?.(event.target.value)}
            >
              {SORT_OPTIONS.map((option) => renderSelectOption(option))}
            </Select>
          </FormControl>
        )}
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id={`${id}staffing-list`}>Staffing list</InputLabel>
          <Select
            data-cy="staffing-list-select"
            disabled={!staffingListsState.staffingLists.length}
            labelId={`${id}staffing-list`}
            label="Staffing list"
            value={staffingListsState.selectedStaffingList?.name || (staffingListsState.isLoading ? 'Loading...' : 'None')}
            onChange={(event) => {
              const newSelectedStaffingList = staffingListsState.staffingLists.find((sl) => sl.name === event.target.value);
              staffingListsState.setSelectedStaffingList(newSelectedStaffingList);
            }}
          >
            {!staffingListsState.staffingLists.length && renderSelectOption(staffingListsState.isLoading ? 'Loading...' : 'None')}
            {staffingListsState.staffingLists.map((option) => renderSelectOption(option.name))}
          </Select>
        </FormControl>
        {props.isHiringEngine && (
          <Badge badgeContent={filterCount} color="primary">
            <IconButton
              data-cy="open-staffing-list-filters-button"
              aria-label="Filters"
              disabled={!staffingListsState.selectedStaffingList?.items?.length}
              onClick={(event) => props.setFilterPopoverAnchorEl(event.currentTarget)}
            >
              <SvgIcon component={FilterAltFillSymbol} sx={{ color: theme.palette.action.active }} />
            </IconButton>
          </Badge>
        )}
      </Box>
      {!!filterCount && props.isHiringEngine && renderFilterChips()}
    </>
  );
};
