import { Box } from '@mui/material';
import { format } from 'date-fns';
import { BlankCard, RankBadge } from '@stationwise/component-module';
import { SwappedEmployee } from '@stationwise/share-types';

interface EmployeeOffCardProps {
  employeeOff: SwappedEmployee;
  shiftDate: string;
  startTime: string;
  endTime: string;
}

export const EmployeeOffCard = ({ employeeOff, shiftDate, startTime, endTime }: EmployeeOffCardProps) => {
  return (
    <BlankCard
      type="disable"
      sxProps={(theme) => ({
        border: `1px solid ${theme.palette.stationGray[50]}`,
        boxShadow: '0px 1px 2px 0px #0A0E160D',
        '&:hover': {
          border: `1px solid ${theme.palette.stationGray[50]}`,
        },
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <RankBadge rank={employeeOff.rank} />
        <Box sx={(theme) => ({ flex: 1, color: theme.palette.stationGray[900], typography: 'bodyLMedium' })}>
          {`${employeeOff.firstName} ${employeeOff.lastName}`}
        </Box>
        <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'bodyMRegular' })}>
          {format(`${shiftDate}T${startTime}`, 'HHmm')}
          {' - '}
          {format(`${shiftDate}T${endTime}`, 'HHmm')}
        </Box>
      </Box>
    </BlankCard>
  );
};
