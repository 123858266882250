import { Certification } from '../certification';
import { WorkGroup, Manager } from '../conversation';
import { NonShiftAssignmentDetail } from '../nonShift';
import { Rank, CertificationActAsRank, Team } from '../organization';
import { TimeOffLimit } from '../request/models';
import { AllowedColors } from '../utils/colors';
import { EmployeeDefaults } from './models';

export interface PromotionHistory {
  rank: Rank;
  createdAt: string;
  promotionDate: string;
}

export interface EmployeeShiftDetails {
  station?: string;
  apparatus?: string;
  teamId?: string;
  battalionId?: string;
  shiftColor: AllowedColors;
  teamName: string;
}

export interface EmployeeProfileDetailsView {
  firstName: string;
  lastName: string;
  name?: string;
  rank: Rank;
  role: string;
  accruals: TimeOffLimit[];
  phone: string | null;
  email: string;
  id: string;
  payrollId: string;
  defaults: EmployeeDefaults;
  emergencyContactName: string | null;
  emergencyContactPhone: string | null;
  emergencyContactRelationship: string | null;
  optionalEmergencyContactName: string | null;
  optionalEmergencyContactPhone: string | null;
  optionalEmergencyContactRelationship: string | null;
  certifications: Certification[];
  certificationActAsRank: CertificationActAsRank | null;
  promotionHistory: PromotionHistory[];
  nonShiftDetail: NonShiftAssignmentDetail;
  plannedShiftDetails: EmployeeShiftDetails[] | null;
  team?: Team;
  driversLicenseNumber: string | null;
  driversLicenseClasses: string[];
  workGroups: WorkGroup[];
  isActive: boolean;
  canDeactivate: boolean;
  managerOne: Manager;
  managerTwo: Manager;
  managerThree: Manager;
  lastPasswordChange: string;
  hireDate: string | null;
}

export enum MANAGER_NAMES {
  MANAGER_ONE = 'Manager One',
  MANAGER_TWO = 'Manager Two',
  MANAGER_THREE = 'Manager Three',
}
