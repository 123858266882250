import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { useState } from 'react';
import {
  NoEmployeesOff,
  NoShiftTrades,
  NoTimeOffs,
  StateCard,
  useLoadedDepartmentInfoContext,
  useRosterContext,
} from '@stationwise/component-module';
import { AllowedEmployeeOffTypes, TemporaryNonShiftType } from '@stationwise/share-types';
import { AbsenceCard } from './AbsenceCard';

interface AbsenceType {
  name: string;
  id: number;
  type?: AllowedEmployeeOffTypes;
}
const allAbsenceTypes: AbsenceType[] = [
  { name: 'All', id: 0 },
  { name: 'Shift trades', id: 1, type: 'ShiftTradeRequest' },
  { name: 'Time offs', id: 2, type: 'TimeOffRequest' },
  { name: 'Extended Leave', id: 3, type: TemporaryNonShiftType.EXTENDED_LEAVE },
  { name: 'Kelly Day', id: 4, type: 'KellyDay' },
];

export const EmployeesOff = () => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const { employeesOffState, shiftSummaryHelper } = useRosterContext();

  const absenceTypes = allAbsenceTypes.filter((absenceType) => {
    if (absenceType.type === 'KellyDay') {
      return departmentInfoState.departmentInfo.settings.kellyDaysEnabled;
    }

    return true;
  });

  const [absenceTypeSelected, setAbsenceTypeSelected] = useState<number>(absenceTypes[0].id);
  const absenceSelected = absenceTypes.find((absenceType) => absenceType.id === absenceTypeSelected);
  const isAllSelected = !!absenceSelected && absenceSelected === absenceTypes[0];

  const theme = useTheme();

  const tabSxProps = {
    textTransform: 'none',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: theme.spacing(2.5),
    padding: '6px 12px',
    borderRadius: theme.spacing(4),
    color: theme.palette.stationGray[900],
    backgroundColor: theme.palette.stationGray[100],
    mt: theme.spacing(2.5),
    mb: theme.spacing(0.5),
    mr: theme.spacing(1),
    minHeight: theme.spacing(3),
    minWidth: theme.spacing(4),
    '&.Mui-selected': {
      color: theme.palette.common.white,
      fontWeight: 600,
      backgroundColor: theme.palette.stationGray[900],
    },
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setAbsenceTypeSelected(newValue);
  };

  const employeesOffNotCancelled = employeesOffState.employeesOff.filter((employeeOff) => {
    if (employeeOff.requestType === 'TimeOffRequest') {
      return !shiftSummaryHelper.cancelTimeOffPayloads.some((payload) => payload.timeOffId === employeeOff.id);
    }
    if (Object.values(TemporaryNonShiftType).some((type) => type === employeeOff.requestType)) {
      return !employeesOffState.temporaryNonShiftIdsToCancel.includes(employeeOff.id);
    }
    if (employeeOff.requestType === 'ShiftTradeRequest') {
      return !shiftSummaryHelper.cancelShiftTradePayloads.some((payload) => payload.shiftTradeId === employeeOff.id);
    }

    return true;
  });

  const employeesOffSelectedTab = employeesOffNotCancelled.filter((employeeOff) => {
    const isTypeSelected = !!absenceSelected && absenceSelected.type === employeeOff.requestType;
    return isAllSelected || isTypeSelected;
  });

  const hasEmployeesOffInSomeTab = !!employeesOffNotCancelled.length;
  const hasEmployeesOffInSelectedTab = !!employeesOffSelectedTab.length;

  return (
    <>
      <Box>
        {hasEmployeesOffInSomeTab && (
          <Tabs
            value={absenceTypeSelected}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              borderBottom: 'none',
              pl: theme.spacing(2),
              pr: theme.spacing(1.5),
              '& .MuiTabs-indicator': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {absenceTypes.map((absenceType) => (
              <Tab key={absenceType.id} sx={tabSxProps} label={absenceType.name} />
            ))}
          </Tabs>
        )}
        {!hasEmployeesOffInSomeTab && (
          <StateCard
            primaryText="Your team is in full force!"
            secondaryText="There are no employees off roster for this day. Enjoy having all of your resources available."
          >
            <NoEmployeesOff />
          </StateCard>
        )}
      </Box>
      <Box sx={{ scrollbarWidth: 'none', '&::-webkit-scrollbar': { width: 0 }, overflowY: 'auto' }}>
        {hasEmployeesOffInSomeTab && absenceSelected && (
          <Box sx={{ height: '100%', p: theme.spacing(2) }}>
            {absenceSelected.type === 'ShiftTradeRequest' && !hasEmployeesOffInSelectedTab && (
              <StateCard primaryText="No shift trades" secondaryText="When someone trades a shift, you will see it here.">
                <NoShiftTrades />
              </StateCard>
            )}
            {absenceSelected.type === 'TimeOffRequest' && !hasEmployeesOffInSelectedTab && (
              <StateCard primaryText="No time offs" secondaryText="When your employees are off, you will see it here.">
                <NoTimeOffs />
              </StateCard>
            )}
            {absenceSelected.type === 'KellyDay' && !hasEmployeesOffInSelectedTab && (
              <StateCard primaryText="No Kelly Day" secondaryText="When your employees are on Kelly Day, you will see it here.">
                <NoTimeOffs />
              </StateCard>
            )}
            {absenceSelected.type === TemporaryNonShiftType.EXTENDED_LEAVE && !hasEmployeesOffInSelectedTab && (
              <StateCard
                primaryText="No extended leave"
                secondaryText="When your employees are on extended leave, you will see it here."
              >
                <NoTimeOffs />
              </StateCard>
            )}
            {employeesOffSelectedTab.map((employeeOffData) => (
              <AbsenceCard key={`${employeeOffData.requestType}_${employeeOffData.id}`} employeeOffData={employeeOffData} />
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};
