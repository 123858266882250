import { Box } from '@mui/material';

interface HeaderProps {
  text?: string;
}

export const Header = ({ text }: HeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        paddingBottom: '16px',
        typography: 'bodyLSemibold',
      }}
    >
      {text}
    </Box>
  );
};
