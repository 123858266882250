export const capitalize = (string?: string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.substring(1);
};

// Words that we generally do not want to capitalize
// eg we want "Apparatus of Station1", not "Apparatus Of Station1"
const plainWords = new Set(['the', 'of', 'to']);

export const makeFirstLettersCapital = (rawStr: string, usePlainWords?: boolean, plainWordList?: string[]) => {
  if (usePlainWords) {
    const customPlainWords = plainWordList && plainWordList.length ? new Set(plainWordList) : plainWords;
    return rawStr
      .split(/[\s_]+/)
      .map((word) => (customPlainWords.has(word) ? word : capitalize(word)))
      .join(' ');
  }
  return rawStr
    .split(/[\s_]+/)
    .map(capitalize)
    .join(' ');
};

export const makeTestIdentifier = (rawStr: string) => {
  return rawStr
    .split(/[\s_:]+/)
    .map((word) => word.toLowerCase())
    .join('-');
};

export function camelCaseToTitle(str: string): string {
  return str.replace(/([A-Z])/g, ' $1').trim();
}

export function snakeCaseToTitle(str: string): string {
  const result = str.replace(/_/g, ' ').toLowerCase();
  return result.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function prettifyPhoneNumber(phone: string): string {
  const cleaned = phone.replace(/\D/g, ''); // Remove non-digit characters
  const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/); // Match US numbers
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phone;
}

export const formatEmployeeName = (name: string): string => {
  const [firstName, ...lastNames] = name.split(' ');
  return `${firstName[0]}. ${lastNames.join(' ')}`;
};
