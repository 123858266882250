import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { useState } from 'react';
import { RosterStation } from '@stationwise/share-types';
import {
  getApparatusCertifications,
  getStationCertifications,
  getApparatusRanks,
  getTotalStaffed,
  checkIsEvent,
} from '@stationwise/shift-summary-helper';
import { Users03Icon20 } from '../../../../assets';
import { SearchInput } from '../../../SearchInput';
import { ApparatusMinReq } from './ApparatusMinReq';
import { CertificationRecord } from './CertificationRecord';
import { Chip } from './Chip';
import { MinReqRecord } from './MinReqRecord';

export interface StationMinReqProps {
  onClose?: () => void;
  station: RosterStation;
}

export interface IChipFilterType {
  type: ChipFilterType;
}

export enum ChipFilterType {
  errors = 'errors',
  ranks = 'ranks',
  certifications = 'certifications',
  all = 'all',
}

const chipFilterOptions = [{ label: 'All' }, { label: 'Errors' }, { label: 'Ranks' }, { label: 'Certifications' }];

export const mapFilterChipOptionToIChipFilterType = (optionIndex: number): ChipFilterType => {
  switch (optionIndex) {
    case 0:
      return ChipFilterType.all;
    case 1:
      return ChipFilterType.errors;
    case 2:
      return ChipFilterType.ranks;
    case 3:
      return ChipFilterType.certifications;
    default:
      return ChipFilterType.all;
  }
};

export const StationMinReq = ({ onClose, station }: StationMinReqProps) => {
  const [searchInput, setSearchInput] = useState('');
  const [selectedChipFilter, setSelectedChipFilter] = useState(0);

  const totalStaffed = getTotalStaffed(station);

  const apparatusesWithRequirements = station.apparatuses.map((apparatus) => {
    return {
      name: apparatus.name,
      certMap: getApparatusCertifications(apparatus),
      rankMap: getApparatusRanks(apparatus),
    };
  });

  const stationCertifications = getStationCertifications(station);
  const stationCertWithMap = Array.from(stationCertifications, ([key, values]) => ({
    name: key,
    values,
  }));

  const chipFilterType = mapFilterChipOptionToIChipFilterType(selectedChipFilter);
  const isEventStation = checkIsEvent(station);

  return (
    <Box>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 0.5, justifyContent: 'space-between' }}>
          <Box sx={(theme) => ({ color: theme.palette.common.white, typography: 'bodyMRegular' })}>
            {`${station.stationName} requirements`}
          </Box>
          {onClose && (
            <Box
              data-cy="close-station-min-req"
              onClick={onClose}
              sx={(theme) => ({
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                m: theme.spacing(-0.75, -1, 0, 0),
                p: 0.5,
                color: theme.palette.stationGray[400],
                '&:hover': {
                  background: theme.palette.stationGray[600],
                  borderRadius: 2,
                  color: theme.palette.common.white,
                },
              })}
            >
              <CloseIcon />
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <SearchInput
            value={searchInput}
            setValue={setSearchInput}
            color="dark"
            placeHolder={`Search ${isEventStation ? 'event' : 'apparatus'}`}
          />
          <Box display="flex" mt={2} justifyContent="space-between">
            {chipFilterOptions.map((chipValues, index) => (
              <Chip
                key={chipValues.label}
                label={chipValues.label}
                isSelected={index === selectedChipFilter}
                onClick={() => setSelectedChipFilter(index)}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={(theme) => ({ backgroundColor: theme.palette.stationGray[900], p: 2, pt: !isEventStation ? 2 : 0.5 })}>
        {!isEventStation && (
          <MinReqRecord total={totalStaffed.total} amount={totalStaffed.amount} size="lg">
            <Box component={Users03Icon20} sx={(theme) => ({ color: theme.palette.common.white })} />
            <Box sx={(theme) => ({ color: theme.palette.stationGray[400], ml: 1, typography: 'bodySMedium' })}>Total staffed</Box>
          </MinReqRecord>
        )}
        {!isEventStation && (
          <Box mb={3}>
            {chipFilterType !== ChipFilterType.ranks &&
              stationCertWithMap.map((stationCerts) => {
                if (chipFilterType === ChipFilterType.errors && stationCerts.values.total <= stationCerts.values.amount) {
                  return null;
                }
                return (
                  <CertificationRecord
                    name={stationCerts.name}
                    total={stationCerts.values.total}
                    amount={stationCerts.values.amount}
                    key={stationCerts.name}
                    color={stationCerts.values.obj?.color || 'gray'}
                  />
                );
              })}
          </Box>
        )}
        {apparatusesWithRequirements.map((apparatus) => {
          if (searchInput !== '' && !apparatus.name.toUpperCase().includes(searchInput.toUpperCase())) {
            return null;
          }
          return (
            <Box key={apparatus.name}>
              <ApparatusMinReq apparatus={apparatus} type={chipFilterType} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
