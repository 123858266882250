import { Box } from '@mui/material';
import { format } from 'date-fns';
import { BlankCard, RankBadge } from '@stationwise/component-module';
import { SwappedEmployee } from '@stationwise/share-types';

interface ReplacementEmployeeCardProps {
  replacementEmployee: SwappedEmployee;
  shiftDate: string;
  startTime: string;
  endTime: string;
}

export const ReplacementEmployeeCard = ({ replacementEmployee, shiftDate, startTime, endTime }: ReplacementEmployeeCardProps) => {
  return (
    <BlankCard
      sxProps={(theme) => ({
        border: `1px solid ${theme.palette.stationGray[50]}`,
        boxShadow: '0px 1px 2px 0px #0A0E160D',
        '&:hover': {
          border: `1px solid ${theme.palette.stationGray[50]}`,
        },
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <RankBadge rank={replacementEmployee.rank} />
        <Box sx={(theme) => ({ flex: 1, color: theme.palette.stationGray[900], typography: 'bodyLMedium' })}>
          {`${replacementEmployee.firstName} ${replacementEmployee.lastName}`}
        </Box>
        <Box sx={(theme) => ({ color: theme.palette.stationGray[400], typography: 'bodyMRegular' })}>
          {format(`${shiftDate}T${replacementEmployee.startTime || startTime}`, 'HHmm')}
          {' - '}
          {format(`${shiftDate}T${replacementEmployee.endTime || endTime}`, 'HHmm')}
        </Box>
      </Box>
    </BlankCard>
  );
};
