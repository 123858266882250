import { useDroppable } from '@dnd-kit/core';
import { Box } from '@mui/material';
import { RosterApparatus as RosterApparatusType } from '@stationwise/share-types';
import {
  checkIsCollapsedApparatus,
  checkIsShift,
  checkIsStrikeTeamApparatus,
  makeApparatusOverId,
  makePositionOverId,
} from '@stationwise/shift-summary-helper';
import { useAuthUserCapabilities } from '../../../../utils/capabilities';
import { Apparatus } from '../../../Apparatus';
import { useRosterContext } from '../RosterContext';
import { RosterPosition } from '../RosterPosition';
import { StrikeTeamActions } from '../StrikeTeamActions';

interface RosterApparatusProps {
  apparatus: RosterApparatusType;
}

export const RosterApparatus = ({ apparatus }: RosterApparatusProps) => {
  const capabilities = useAuthUserCapabilities();
  const { isReadonly, preferences } = useRosterContext();
  const isDroppableDisabled = !checkIsShift(apparatus);
  const droppable = useDroppable({ id: makeApparatusOverId(apparatus), disabled: isDroppableDisabled });

  const isCollapsed = checkIsCollapsedApparatus(apparatus, preferences.isCollapsed);

  const isOver = (() => {
    const overId = `${droppable.over?.id || ''}`;
    if (!overId || isDroppableDisabled) {
      return false;
    }

    if (overId === makeApparatusOverId(apparatus)) {
      return true;
    }

    return apparatus.positions.some((position) => overId === makePositionOverId(position));
  })();

  if (!apparatus.positions.length) {
    return null;
  }

  const actions = !isReadonly && capabilities.MANAGE_EVENTS && checkIsStrikeTeamApparatus(apparatus) && (
    <StrikeTeamActions apparatus={apparatus} />
  );

  return (
    <Apparatus apparatus={apparatus} isOver={isOver} actions={actions}>
      {apparatus.positions.map((position) => {
        return <RosterPosition key={position.id} apparatus={apparatus} position={position} isCollapsed={isCollapsed} />;
      })}
      {!isDroppableDisabled && (
        <Box
          ref={droppable.setNodeRef}
          sx={(theme) => ({
            mt: isOver ? 1 : 0,
            opacity: isOver ? 1 : 0,
            height: isOver ? (isCollapsed ? '40px' : '104px') : '0px',
            transition: isOver ? 'opacity 0.4s ease-in-out, height 0.4s ease-in-out' : 'none',
            background: theme.palette.stationGray[100],
            borderRadius: '12px',
          })}
        />
      )}
    </Apparatus>
  );
};
