import React, { createContext, useContext } from 'react';
import { UseShiftTemplateReturnType } from '../hooks/useShiftTemplate';

const ShiftTemplateContext = createContext<UseShiftTemplateReturnType | null>(null);

interface ShiftTemplateContextProviderProps {
  shiftTemplate: UseShiftTemplateReturnType;
  children: React.ReactNode;
}

export const ShiftTemplateContextProvider = ({ shiftTemplate, children }: ShiftTemplateContextProviderProps) => {
  return <ShiftTemplateContext.Provider value={shiftTemplate}>{children}</ShiftTemplateContext.Provider>;
};

export const useShiftTemplateContext = () => {
  const context = useContext(ShiftTemplateContext);
  if (!context) {
    throw new Error('useShiftTemplateContext must be within a ShiftTemplateContextProvider');
  }
  return context;
};
