import { Box, useTheme } from '@mui/material';
import { Fragment } from 'react';
import { ListFieldsStaffingList, StaffingListItem } from '@stationwise/share-types';
import { makeOvertimeEmployeeActiveId } from '@stationwise/shift-summary-helper';
import { useAuthUserCapabilities } from '../../../../utils/capabilities';
import { DraggableCard } from '../DraggableCard';
import { HireBackOperatorCard } from '../HireBackList/HireBackOperatorCard';

interface StaffingListCardsProps {
  selectedStaffingList: ListFieldsStaffingList | undefined;
  items: StaffingListItem[];
  searchInput: string;
  selectedRankOptions: Set<string>;
  selectedCertOptions: Set<string>;
  selectedTeamOptions: Set<string>;
  sortValue: string;
}

export const StaffingListCards = (props: StaffingListCardsProps) => {
  const theme = useTheme();
  const capabilities = useAuthUserCapabilities();

  const items = props.items.filter((item) => {
    if (props.searchInput && !item.employee.name.toLowerCase().includes(props.searchInput.toLowerCase())) {
      return false;
    }

    if (props.selectedRankOptions.size && !props.selectedRankOptions.has(item.employee.rank.name)) {
      return false;
    }

    if (props.selectedCertOptions.size && !item.employee.certifications.some((c) => props.selectedCertOptions.has(c.code))) {
      return false;
    }

    if (props.selectedTeamOptions.size && !props.selectedTeamOptions.has(item.employee.team?.name || '')) {
      return false;
    }

    return true;
  });

  if (!items.length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', typography: 'bodyMMedium' }}>
        {!props.selectedStaffingList ? 'There are no lists' : 'This list is empty'}
      </Box>
    );
  }

  items.sort((a, b) => {
    if (props.sortValue === 'Number') {
      return a.order - b.order;
    }

    if (props.sortValue === 'Rank') {
      return a.employee.rank.sortOrder - b.employee.rank.sortOrder;
    }

    return 0;
  });

  const groups = new Map<string, StaffingListItem[]>();
  items.forEach((item) => {
    let key = '';
    if (props.sortValue === 'Rank') {
      key = item.employee.rank.name;
    }

    const value = groups.get(key) || [];
    value.push(item);
    groups.set(key, value);
  });

  return Array.from(groups.entries()).map(([title, group]) => (
    <Fragment key={title}>
      {title && <Box sx={{ color: theme.palette.stationGray[400], mt: 2, '&:first-of-type': { mt: 0 } }}>{title}</Box>}
      {group.map((item) => (
        <DraggableCard
          key={item.employee.id}
          activeId={makeOvertimeEmployeeActiveId(item.employee.id)}
          disabled={!capabilities.MANAGE_ROSTER_HIRING}
          sx={{ height: 'auto' }}
        >
          <HireBackOperatorCard operator={item} />
        </DraggableCard>
      ))}
    </Fragment>
  ));
};
