import { Dispatch, SetStateAction, useState } from 'react';
import { StaffingListItem } from '@stationwise/share-types';
import { useStaffingListFilterGroup } from './StaffingListFilterGroup';
import { StaffingListFilterPopover } from './StaffingListFilterPopover';
import { StaffingListMainControls } from './StaffingListMainControls';

interface StaffingListControlsProps {
  items: StaffingListItem[];
  searchInput: string;
  setSearchInput: Dispatch<SetStateAction<string>>;
  selectedRankOptions: Set<string>;
  setSelectedRankOptions: Dispatch<SetStateAction<Set<string>>>;
  selectedCertOptions: Set<string>;
  setSelectedCertOptions: Dispatch<SetStateAction<Set<string>>>;
  selectedTeamOptions: Set<string>;
  setSelectedTeamOptions: Dispatch<SetStateAction<Set<string>>>;
  sortValue: string;
  setSortValue: Dispatch<SetStateAction<string>>;
  isHiringEngine?: boolean;
}

export const StaffingListControls = (props: StaffingListControlsProps) => {
  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const filterStates = [
    useStaffingListFilterGroup(props.selectedRankOptions, props.setSelectedRankOptions),
    useStaffingListFilterGroup(props.selectedCertOptions, props.setSelectedCertOptions),
    useStaffingListFilterGroup(props.selectedTeamOptions, props.setSelectedTeamOptions),
  ];

  return (
    <>
      <StaffingListMainControls
        searchInput={props.searchInput}
        setSearchInput={props.setSearchInput}
        setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
        filterStates={filterStates}
        sortValue={props.sortValue}
        setSortValue={props.setSortValue}
        isHiringEngine={props.isHiringEngine}
      />
      <StaffingListFilterPopover
        items={props.items}
        filterPopoverAnchorEl={filterPopoverAnchorEl}
        setFilterPopoverAnchorEl={setFilterPopoverAnchorEl}
        filterStates={filterStates}
      />
    </>
  );
};
