import { Box } from '@mui/material';
import { RosterBoard, LottieLogo } from '@stationwise/component-module';

interface DayScheduleProps {
  isLoading: boolean;
}

export const DaySchedule = ({ isLoading }: DayScheduleProps) => {
  if (isLoading) {
    return (
      <Box sx={{ height: '100%', width: '100%' }} display="flex" alignItems="center" justifyContent="center">
        <LottieLogo height="200px" width="200px" />
      </Box>
    );
  }

  return <RosterBoard />;
};
