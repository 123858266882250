import { RosterStation, RosterApparatus, RosterPosition } from '@stationwise/share-types';
import { checkIsShift, getVacancies } from '@stationwise/shift-summary-helper';

export type Vacancy = Pick<RosterPosition, 'rank' | 'certifications' | 'startDateTime' | 'endDateTime' | 'id'>;

export interface HiringEngineVacancy extends Vacancy {
  stationName: string;
  apparatusName: string;
}
export interface VacantApparatus extends Omit<RosterApparatus, 'positions'> {
  vacancies: Vacancy[];
}

export interface VacantStation extends Omit<RosterStation, 'apparatuses'> {
  apparatuses: VacantApparatus[];
}

export const getVacantStations = (stations: RosterStation[]) => {
  const vacantStations: VacantStation[] = [];
  stations.forEach((station) => {
    if (!checkIsShift(station)) {
      return;
    }

    const vacantApparatuses: VacantApparatus[] = [];
    station.apparatuses.forEach((apparatus) => {
      const vacancies: Vacancy[] = [];
      apparatus.positions.forEach((position) => {
        vacancies.push(...getVacancies(position));
      });

      if (vacancies.length > 0) {
        vacancies.sort((a, b) => a.rank.sortOrder - b.rank.sortOrder);
        vacantApparatuses.push({ ...apparatus, vacancies });
      }
    });

    if (vacantApparatuses.length > 0) {
      vacantStations.push({ ...station, apparatuses: vacantApparatuses });
    }
  });

  return vacantStations;
};
