import { Box } from '@mui/material';
import { addHours, differenceInMinutes } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIOSPaddingBottom } from '../../../../../context/DeviceInfoContext';
import { TimeSelect } from './TimeSelect';

interface NonShiftTimeSelectProps {
  label?: string;
  usePreciseMinutes?: boolean;
  shiftStart: Date;
  shiftEnd: Date;
  startDateTime: string;
  setStartDateTime: Dispatch<SetStateAction<string>>;
  setDuration: Dispatch<SetStateAction<number>>;
  duration: number;
}

export const NonShiftTimeSelect = ({
  usePreciseMinutes = false,
  shiftStart,
  shiftEnd,
  setStartDateTime,
  setDuration,
  startDateTime,
  duration,
}: NonShiftTimeSelectProps) => {
  const iosWebViewPaddingBottom = useIOSPaddingBottom();
  const [endDateTime, setEndDateTime] = useState<string>(addHours(new Date(startDateTime), duration).toISOString());

  useEffect(() => {
    setDuration(differenceInMinutes(new Date(endDateTime), new Date(startDateTime)) / 60);
  }, [endDateTime, setDuration, startDateTime]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <TimeSelect
        label="Start time"
        dateTime={startDateTime}
        setDateTime={setStartDateTime}
        minTime={shiftStart}
        maxTime={isNaN(new Date(endDateTime).getTime()) ? shiftEnd : new Date(endDateTime)}
        usePreciseMinutes={usePreciseMinutes}
        paddingBottom={iosWebViewPaddingBottom}
      />
      <TimeSelect
        label="End time"
        dateTime={endDateTime}
        setDateTime={setEndDateTime}
        minTime={isNaN(new Date(startDateTime).getTime()) ? shiftStart : new Date(startDateTime)}
        maxTime={shiftEnd}
        usePreciseMinutes={usePreciseMinutes}
        paddingBottom={iosWebViewPaddingBottom}
      />
    </Box>
  );
};
