import {
  UploadFile as UploadFileIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  ArrowForward,
} from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { captureException } from '@sentry/react';
import { useState } from 'react';
import { Button, theme, exportData, LetterAvatar, SnackbarService, ExclamationMarkIcon24 } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { PayCode } from '@stationwise/share-types';

export const UploadAccrualsModal = ({ setUploadAccrualsOpen }: { setUploadAccrualsOpen: (open: boolean) => void }) => {
  const [fileDetails, setFileDetails] = useState<{ name: string; size: string } | null>(null);
  const [uploadStatus, setUploadStatus] = useState<'loading' | 'complete' | null>(null);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [accrualChanges, setAccrualChanges] = useState<
    {
      employeeName: string;
      email: string;
      payCode: PayCode;
      oldValue: number;
      newValue: number;
    }[]
  >([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setUploadAccrualsOpen(false);
    setFileDetails(null);
    setUploadStatus(null);
    setWarningDialogOpen(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await client.post('/employee/accruals/bulk-update/', {
        updates: accrualChanges.map((change) => ({
          employee_email: change.email,
          pay_code: change.payCode.code,
          accrued_time_off: change.newValue,
        })),
      });

      handleClose();
      SnackbarService.notify({
        content: 'Accruals updated successfully',
        severity: 'success',
        showCloseButton: true,
        duration: 5000,
      });
    } catch (error) {
      captureException(error);
      SnackbarService.notify({
        content: 'There was an issue processing your request. ',
        severity: 'error',
        duration: 5000,
      });
      handleClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFile = (file: File) => {
    if (file && file.type === 'text/csv') {
      setFileDetails({ name: file.name, size: `${file.size} bytes` });
      setUploadStatus('loading');

      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target?.result as string;

        const rows = content.split('\n');
        const parsedAccruals = rows
          .slice(1)
          .filter((row) => row.trim())
          .map((row) => {
            const [timeOffCode, hoursRemaining, email] = row.split(',').map((val) => val.trim());
            return {
              employee_email: email,
              pay_code: timeOffCode,
              accrued_time_off: hoursRemaining === 'Unlimited' ? 1e308 : parseFloat(hoursRemaining),
            };
          });

        try {
          const response = await client.post('/employee/accruals/compare/', { imported_accruals: parsedAccruals });
          setAccrualChanges(response.data.changes);
          setUploadStatus('complete');
        } catch (error) {
          captureException(error);
          SnackbarService.notify({
            content: 'Error processing the CSV file',
            severity: 'error',
            duration: 5000,
          });
          handleDelete();
        }
      };
      reader.readAsText(file);
    }
  };

  const handleDelete = () => {
    setFileDetails(null);
    setUploadStatus(null);
  };

  const handleExport = async () => {
    await exportData('/employee/accruals/export/', {}, 'accruals.csv');
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          justifyContent: 'center',
          borderRadius: theme.spacing(1.5),
          // p: theme.spacing(3),
          width: '496px',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <DialogTitle
          sx={{
            typography: 'h6',
            textAlign: 'left',
          }}
        >
          Upload accruals CSV
        </DialogTitle>

        <Box
          sx={(theme) => ({
            mx: theme.spacing(3),
            border: '2px dashed',
            borderColor: theme.palette.grey[300],
            borderRadius: theme.spacing(1),
            p: theme.spacing(4),
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': {
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.grey[50],
            },
          })}
          onDrop={(e) => {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            handleFile(file);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          <UploadFileIcon sx={(theme) => ({ fontSize: '24px', color: theme.palette.primary.main })} />

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography
              onClick={() => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = '.csv';
                input.onchange = (e) => {
                  const file = (e.target as HTMLInputElement).files?.[0];
                  if (file) handleFile(file);
                };
                input.click();
              }}
              sx={{
                typography: 'subtitle1',
                mb: 1,
                color: theme.palette.primary.main,
                textDecoration: 'underline',
                '&:hover': {
                  color: theme.palette.primary.dark,
                },
              }}
            >
              Click to upload
            </Typography>
            <Box sx={{ width: theme.spacing(0.5) }} />
            <Typography sx={{ typography: 'subtitle1', mb: 1 }}>or drag and drop </Typography>
          </Box>
          <Typography sx={{ typography: 'bodyXSRegular', color: theme.palette.grey[500] }}>{'CSV file (max. 3MB)'}</Typography>
        </Box>

        {fileDetails && (
          <Box
            sx={(theme) => ({
              borderRadius: '4px',
              padding: theme.spacing(2),
              mx: theme.spacing(3),
              boxShadow:
                '0px 1px 3px 0px rgba(17, 24, 39, 0.04), 0px 1px 1px 0px rgba(17, 24, 39, 0.07), 0px 2px 1px -1px rgba(17, 24, 39, 0.10)',
            })}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography sx={{ typography: 'bodyMRegular', color: theme.palette.text.primary }}>{fileDetails.name}</Typography>
                <Typography sx={{ typography: 'bodySRegular', color: theme.palette.text.secondary }}>
                  {fileDetails.size} • {uploadStatus === 'loading' && <span>Loading...</span>}
                  {uploadStatus === 'complete' && <span>Complete</span>}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
                <DeleteIcon
                  onClick={handleDelete}
                  sx={(theme) => ({
                    color: theme.palette.action.active,
                    width: theme.spacing(2.5),
                    height: theme.spacing(2.5),
                    cursor: 'pointer',
                    '&:hover': {
                      color: theme.palette.error.main,
                    },
                  })}
                />
                {uploadStatus === 'loading' && <CircularProgress size={16} sx={{ ml: theme.spacing(1) }} />}
                {uploadStatus === 'complete' && <CheckCircleIcon sx={(theme) => ({ color: theme.palette.success.main })} />}
              </Box>
            </Box>
          </Box>
        )}
        {uploadStatus !== 'complete' && (
          <Typography
            sx={{ typography: 'body1', color: theme.palette.text.primary, mt: theme.spacing(3), mx: theme.spacing(3) }}
            onClick={handleExport}
          >
            Your CSV should be in pre-defined format to work properly.{' '}
            <span style={{ textDecoration: 'underline', color: theme.palette.primary.main, cursor: 'pointer' }}>
              Download your current CSV,
            </span>{' '}
            edit the data, and upload back to achieve best results.
          </Typography>
        )}

        {uploadStatus === 'complete' && (
          <>
            <Typography
              sx={{ typography: 'subtitle1', color: theme.palette.text.primary, mt: theme.spacing(3), mx: theme.spacing(3) }}
            >
              Change log
            </Typography>
            {accrualChanges.length === 0 ? (
              <Typography
                sx={{ typography: 'body2', color: theme.palette.text.secondary, mt: theme.spacing(2), mx: theme.spacing(3) }}
              >
                There are no changes found in your file, make sure you uploaded the correct CSV file
              </Typography>
            ) : (
              <Box
                sx={{
                  mt: theme.spacing(2),
                  mx: theme.spacing(3),
                  padding: theme.spacing(2),
                  gap: theme.spacing(1),
                  maxHeight: '200px',
                  overflowY: 'auto',
                  borderRadius: '6px',
                  border: `1px solid ${theme.palette.grey[200]}`,
                }}
              >
                {Object.entries(
                  accrualChanges.reduce(
                    (acc, change) => {
                      if (!acc[change.email]) {
                        acc[change.email] = {
                          name: change.employeeName,
                          changes: [],
                        };
                      }
                      acc[change.email].changes.push(change);
                      return acc;
                    },
                    {} as Record<string, { name: string; changes: (typeof accrualChanges)[number][] }>,
                  ),
                ).map(([email, { name, changes }], index, array) => (
                  <Box
                    key={email}
                    sx={{
                      borderBottom: index === array.length - 1 ? 'none' : `1px solid ${theme.palette.grey[200]}`,
                      pb: index === array.length - 1 ? 0 : theme.spacing(2),
                      mb: index === array.length - 1 ? 0 : theme.spacing(2),
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1), mb: theme.spacing(1) }}>
                      <LetterAvatar firstName={name} />
                      <Typography sx={{ typography: 'body2' }}>{name}</Typography>
                    </Box>

                    {changes.map((change, idx) => (
                      <Box
                        key={`${email}-${change.payCode.code}`}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mt: idx > 0 ? 1 : 0,
                        }}
                      >
                        <Typography sx={{ typography: 'body2' }}>{change.payCode.name}</Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                          <Typography sx={{ typography: 'body2', color: theme.palette.text.secondary }}>
                            {change.oldValue === 1e308 ? 'Unlimited' : `${change.oldValue}h`}
                          </Typography>
                          <ArrowForward fontSize="small" sx={{ color: theme.palette.text.secondary }} />
                          <Typography sx={{ typography: 'body2' }}>
                            {change.newValue === 1e308 ? 'Unlimited' : `${change.newValue}h`}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
        <Box
          sx={(theme) => ({
            justifyContent: 'space-between',
            display: 'flex',
            mt: theme.spacing(5),
            width: '100%',
          })}
        >
          <Button
            buttonType="tertiary"
            variant="contained"
            style={{
              width: '216px',
              height: '44px',
              padding: '9px 17px 9px 17px',
              marginLeft: theme.spacing(3),
              marginBottom: theme.spacing(3),
            }}
            onClick={handleClose}
          >
            <Box component="span" sx={{ typography: 'buttonM' }}>
              Cancel
            </Box>
          </Button>
          <Button
            style={{
              width: '216px',
              height: '44px',
              padding: '9px 17px 9px 17px',
              marginRight: theme.spacing(3),
              marginBottom: theme.spacing(3),
            }}
            sx={(theme) => ({
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              borderRadius: theme.spacing(0.75),
              '&:hover': { backgroundColor: theme.palette.primary.dark, boxShadow: 'none' },
            })}
            buttonType="tertiary"
            variant="contained"
            disableFocusRipple={true}
            disableTouchRipple={true}
            disabled={uploadStatus !== 'complete' || accrualChanges.length === 0}
            onClick={() => setWarningDialogOpen(true)}
          >
            <Box component="span" sx={{ typography: 'buttonM' }}>
              {'Submit'}
            </Box>
          </Button>
        </Box>
      </Box>
      <Dialog open={warningDialogOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={(theme) => ({
              '& svg path': { stroke: theme.palette.stationOrange[500] },
              borderRadius: '80px',
              borderColor: theme.palette.stationGray[50],
              boxShadow: '0px 2px 4px -1px rgba(10, 14, 22, 0.06), 0px 4px 6px -1px rgba(10, 14, 22, 0.10)',
              width: '56px',
              height: '56px',
              p: theme.spacing(2),
              my: theme.spacing(2),
            })}
          >
            <ExclamationMarkIcon24 />
          </Box>
        </Box>
        <DialogTitle id="alert-dialog-title" sx={{ typography: 'bodyXLSemibold' }}>
          {'Are you sure you want to submit the changes?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ typography: 'bodyMRegular', color: theme.palette.text.primary }}>
            <>
              This is a big change and will <br />
              {`1) Overwrite data for multiple users and`}
              <br />
              {`2) Impact whether they can request certain time off.`}
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button buttonType="tertiary" onClick={() => setWarningDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} autoFocus>
            {isSubmitting ? 'Submitting...' : 'Yes, submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};
