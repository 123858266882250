import { EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { Box, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { makeTestIdentifier } from '@stationwise/share-utils';
import { BigArrowRightIcon } from '../../assets';
import { BlankCard } from '../BlankCard';
import { Chip } from '../Card/Chip';
import { useLoadedDepartmentInfoContext } from '../Department';
import { getEventDisplayedTitle } from '../EmployeeCalendar/calendarHelper';

export const CalendarShiftCard = ({
  shift,
  children,
  handleClick,
  statusBadge,
  forVoluntaryOvertime,
  selectedView,
}: {
  shift: EventInput | EventImpl;
  children?: React.ReactNode;
  handleClick?: (arg0: EventInput | EventImpl) => void;
  statusBadge?: React.ReactNode;
  forVoluntaryOvertime?: boolean;
  selectedView?: string;
}) => {
  const { state: departmentInfoState } = useLoadedDepartmentInfoContext();
  const isSmallScreen = useMediaQuery('(max-width:400px)');
  const truncateTitle = (title: string, maxLength: number) => {
    return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
  };
  return (
    <Box
      data-cy={`event-card-${makeTestIdentifier(shift.title || '')}`}
      className="shift-card-box"
      sx={{
        mx: 2,
        pb: { xs: selectedView === 'dayGridMonth' ? 2 : '', lg: '' },
      }}
      onClick={() => handleClick && handleClick(shift)}
    >
      <BlankCard>
        <Box display="flex" justifyContent="space-between">
          {!forVoluntaryOvertime && (
            <Box>
              <Chip
                label={truncateTitle(
                  getEventDisplayedTitle(departmentInfoState, shift.title || '').toUpperCase(),
                  isSmallScreen ? 32 : 37,
                )}
                chipSxProps={() => ({
                  background: shift.backgroundColor,
                  height: '24px',
                  width: '110%',
                  p: '4px 2px',
                  borderRadius: '7px',
                })}
                variant="eyebrow"
                typographyStyles={{
                  color: shift.textColor ? shift.textColor : 'inherit',
                }}
              />
            </Box>
          )}
          {statusBadge && <Box>{statusBadge}</Box>}
        </Box>

        <Box sx={{ display: 'flex', typography: 'bodyMRegular' }}>
          {shift.start?.toString() && <Box>{format(shift.start?.toString(), 'M/d/yy')}</Box>}
          <Box sx={{ ml: '9px', mr: '9px', mt: '2px' }}>
            <BigArrowRightIcon />
          </Box>
          {shift.end?.toString() && <Box>{format(shift.end?.toString(), 'M/d/yy')}</Box>}
          <Box
            display="flex"
            sx={(theme) => ({
              ml: '10px',
              color: theme.palette.stationGray[400],
            })}
          >
            {shift.start?.toString() && <Box>{format(shift.start?.toString(), 'HH:mm')}</Box>}
            <Box sx={{ ml: '3px', mr: '3px' }}>-</Box>
            {shift.end?.toString() && <Box>{format(shift.end?.toString(), 'HH:mm')}</Box>}
          </Box>
        </Box>
        {children}
      </BlankCard>
    </Box>
  );
};
