import { Box, Modal } from '@mui/material';
import {
  Button,
  DatePickerComp,
  Input,
  theme,
  useDepartmentInfoContext,
  MultipleSelect,
  AdminSelect,
} from '@stationwise/component-module';
import { AddEmployeeProps } from './MyTeamMain';

interface AddEmployeeDetailsProps {
  detailsOpen: boolean;
  setDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employeeInfo: AddEmployeeProps;
  addEmployeeInfo: (employeeInfo: AddEmployeeProps) => void;
  initialState: AddEmployeeProps;
}

export const AddEmployeeDetails = ({
  detailsOpen,
  setDetailsOpen,
  setDefaultsOpen,
  employeeInfo,
  addEmployeeInfo,
  initialState,
}: AddEmployeeDetailsProps) => {
  const { state: department } = useDepartmentInfoContext();

  const isDisabled = !employeeInfo.rank || !employeeInfo.role || !employeeInfo.type;

  const handleFieldChange = (fieldName: string, value: string | number | Date | string[] | null) => {
    addEmployeeInfo({ ...employeeInfo, [fieldName]: value });
  };

  const handleModalClose = () => {
    addEmployeeInfo(initialState);
    setDetailsOpen(false);
  };

  const openDefaultsModal = () => {
    setDetailsOpen(false);
    setDefaultsOpen(true);
  };

  const rankOptions = department.departmentInfo?.ranks.map((rank) => ({
    label: rank.name,
    value: rank.code,
  }));

  const roleOptions = [
    { label: 'User', value: 'USER' },
    { label: 'Admin', value: 'ADMIN' },
    { label: 'Super Admin', value: 'SUPER_ADMIN' },
    { label: 'Officer', value: 'OFFICER' },
  ];

  const typeOptions = [
    { label: 'Full time', value: 'FULL_TIME' },
    { label: 'Volunteer', value: 'VOLUNTEER' },
  ];

  const licenseClassOptions = ['A', 'B', 'C', 'D', 'E'].map((cls) => ({
    label: '',
    value: cls,
  }));

  return (
    <Modal open={detailsOpen}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '95vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            justifyContent: 'center',
            borderRadius: theme.spacing(1.5),
            p: theme.spacing(3),
            width: '496px',
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              textAlign: 'left',
            }}
          >
            Add details
          </Box>
          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Hire date
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            <DatePickerComp
              value={employeeInfo.hireDate}
              setValue={(date) => handleFieldChange('hireDate', date)}
              openIconPosition="start"
              placeholder="Choose date"
              sxProps={{ '& .MuiInputBase-input': { pl: '0px' }, flex: 3 }}
              maxDate={new Date()}
            />
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Role <span style={{ color: theme.palette.stationRed[600] }}>*</span>
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            {roleOptions && (
              <AdminSelect
                items={roleOptions}
                selectedItem={employeeInfo.role || ''}
                setSelectedItem={(role) => handleFieldChange('role', role)}
                placeholder="Choose a role"
              />
            )}
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Type <span style={{ color: theme.palette.stationRed[600] }}>*</span>
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            {typeOptions && (
              <AdminSelect
                items={typeOptions}
                selectedItem={employeeInfo.type || ''}
                setSelectedItem={(type) => handleFieldChange('type', type)}
                placeholder="Choose employee type"
              />
            )}
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Rank <span style={{ color: theme.palette.stationRed[600] }}>*</span>
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
            })}
          >
            {rankOptions && (
              <AdminSelect
                items={rankOptions}
                selectedItem={employeeInfo.rank || ''}
                setSelectedItem={(rank) => handleFieldChange('rank', rank)}
                placeholder="Choose a rank"
              />
            )}
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Driver's license no.
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            <Input
              value={employeeInfo.driversLicenseNumber || ''}
              onChange={(event) => {
                handleFieldChange('driversLicenseNumber', event.target.value);
              }}
              placeholder="Enter a license number"
            />
          </Box>

          <Box
            sx={(theme) => ({
              typography: 'bodySMedium',
              textAlign: 'left',
              paddingTop: theme.spacing(2),
              color: theme.palette.stationGray[700],
            })}
          >
            Driver's license classes
          </Box>

          <Box
            sx={(theme) => ({
              py: theme.spacing(1),
              display: 'block',
            })}
          >
            <MultipleSelect
              items={licenseClassOptions}
              selectedItems={employeeInfo.driversLicenseClasses || []}
              setSelectedItems={(classes) => handleFieldChange('driversLicenseClasses', classes)}
              placeholder="Select license classes"
            />
          </Box>

          <Box
            sx={(theme) => ({
              justifyContent: 'space-between',
              display: 'flex',
              mt: theme.spacing(5),
              width: '100%',
            })}
          >
            <Button
              buttonType="tertiary"
              variant="contained"
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              onClick={() => handleModalClose()}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Cancel
              </Box>
            </Button>
            <Button
              style={{
                width: '216px',
                height: '44px',
                padding: '9px 17px 9px 17px',
              }}
              sx={(theme) => ({
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                borderRadius: theme.spacing(0.75),
                '&:hover': { backgroundColor: 'black', boxShadow: 'none' },
              })}
              buttonType="tertiary"
              variant="contained"
              disableFocusRipple={true}
              disableTouchRipple={true}
              disabled={isDisabled}
              onClick={openDefaultsModal}
            >
              <Box component="span" sx={{ typography: 'buttonM' }}>
                Continue
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
